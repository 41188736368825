import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';

const defaultProps = {
    nullable: false,
    defaultValue: false
}

const MCheckbox = React.memo((props) => {
    const { source, label, onChange,
        defaultValue = defaultProps.defaultValue,
        nullable = defaultProps.nullable } = props;
    const defaultStatus = [
        { id: 0, checked: null, indeterminate: true },
        { id: 1, checked: true, indeterminate: false },
        { id: 2, checked: false, indeterminate: false }
    ];
    var statuses = defaultStatus.map((m) => Object.assign({}, m)); // clone array
    let allowNull = nullable;
    if (defaultValue == null) allowNull = true;
    if (!allowNull) statuses.shift(); // remove isnull;
    var defaultItem = statuses.find(x => x.checked == defaultValue);

    const [item, setItem] = React.useState(defaultItem);

    React.useEffect(() => {
        setItem((prev) => ({ ...prev, checked: defaultValue }));
        changeDefaultIfAny();
    }, [defaultValue]);

    /**
     * First set default value, then change default value
     */
    function changeDefaultIfAny() {
        if (item.checked != null && defaultValue == null ||
            item.checked == null && defaultValue != null
        ) {
            let next = statuses.find(x => x.checked == defaultValue);
            setItem((prev) => ({ ...prev, ...next }));
            onChange(next.checked);
        }
    }

    const handleChange = (event) => {
        const { checked } = event.target;
        var next;
        if (allowNull) {
            var nextId = item.id + 1;
            if (nextId > 2) nextId = 0;
            next = statuses.find(x => x.id == nextId);
        } else {
            next = statuses.find(x => x.checked == checked);
        }
        setItem((prev) => ({ ...prev, ...next }));
        onChange(next.checked);
    }
    // Remove Warning => remove nullable
    const newProps = Object.assign({}, props);
    delete newProps.nullable;
    //

    return (
        <FormControlLabel
            control={
                <Checkbox {...newProps}
                    name={source}
                    checked={item.checked}
                    indeterminate={item.indeterminate}
                    onChange={handleChange} />
            }
            label={label}
        />
    )
});

MCheckbox.propTypes = {
    nullable: PropTypes.bool,
    defaultValue: PropTypes.bool
}

export default MCheckbox;