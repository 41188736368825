import React, { useState } from 'react';
import { Button } from '@mui/material';

const SportModeButton = (props) => {
    const { setInfo, info, setDefaultValues } = props;

    const handleSportMode = (ev) => {
        setInfo((prev) => ({
            ...prev, sportmode: !info.sportmode,
            title: !info.sportmode ? 'Sports Analysis Page' : 'Order Detail Analysis'
        }));
        setDefaultValues((prev) => ({ ...prev, isgrouping_eq: !info.sportmode }));      
    }
    return (
        <Button onClick={handleSportMode}
            color={info.sportmode ? 'success' : 'info'}
            variant={info.sportmode ? 'contained' : 'outlined'}
        >SPORT: {info.sportmode ? 'ON' : 'OFF'}</Button>
    )
}
export default React.memo(SportModeButton);