import * as React from "react";
import { BooleanField, SelectArrayInput, SelectField, List, DateInput, TextInput, TextField, DateField, NumberField, Datagrid, NumberInput, SelectInput } from 'react-admin';
import { RaPagination } from "../../components/ra-list/RaList";
import ConditionalNumberField from "./ConditionalNumberField";
import BulkUpdateStatusButton from "./BulkUpdateStatusButton";
const DivvyCompanies = [
    { id: "Q29tcGFueTo2MDMwMQ==", name: "Song Technologies LLC" },
    { id: "Q29tcGFueTo2NjIyNQ==", name: "Song Tech Prepay" },
];
const Status = [
    { id: 0, name: "Pending" },
    { id: 1, name: "Completed" },
];
const postFilters = [
    <SelectInput label="Status" source="status" choices={Status} placeholder="ANY" alwaysOn />,
    <SelectArrayInput label="Company" source="companyId_in" choices={DivvyCompanies} placeholder="ANY" alwaysOn />,
    //<SelectArrayInput label="Exclude Company" source="companyId_nin" choices={DivvyCompanies} alwaysOn />, // demo 'not in'
    <DateInput label="Cleared At After" source="clearedAt_ge" alwaysOn />,
    <DateInput label="Cleared At Before" source="clearedAt_le" alwaysOn />,
    <TextInput source="lastFour" />,
    <TextInput source="cardName" />,
    <TextInput source="cleanedMerchantName" />,
    <NumberInput label="Amount from" source="amount_ge" alwaysOn />,
    <NumberInput label="Amount to" source="amount_le" alwaysOn />,
];

const DivvytransactionList = () => (
    <List
        storeKey={false}
        disableSyncWithLocation
        exporter={false}
        pagination={<RaPagination />}
        perPage={25}
        sort={{ field: 'clearedAt', order: 'DESC' }}
        filters={postFilters}
        filterDefaultValues={{ 'clearedAt_ge': new Date((new Date().getTime() - 15 * 24 * 60 * 60 * 1000)).toISOString() }}>

        <Datagrid bulkActionButtons={<BulkUpdateStatusButton />}>
            <SelectField source="companyId" choices={DivvyCompanies} translateChoice={false} />
            {/* <TextField source="budget" /> */}
            <TextField source="lastFour" />
            <TextField source="cardName" />
            <NumberField source="amount" options={{ style: 'currency', currency: 'USD' }} />
            <ConditionalNumberField source="localAmount" options={{ style: 'currency', currency: 'CAD' }} />
            <TextField source="cleanedMerchantName" />
            <TextField source="merchantName" />
            <DateField source="clearedAt" options={{ year: 'numeric', month: 'short', day: 'numeric' }} />
            <TextField source="type" />
            <SelectField source="status" choices={Status} translateChoice={false} />
            {/* <TextField source="subtype" /> */}
            {/* <TextField source="userName" /> */}
        </Datagrid>
    </List>
);
const DivvyPage = {
    name: 'DivvyTransaction',
    list: DivvytransactionList
};
export default DivvyPage;