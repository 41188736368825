import { STATUS_ENUM, STATUS_NAME } from "../../../utils/enum";
import React from "react";
import { EventEmitter } from 'events';

const excludedEditableStatuses = [STATUS_ENUM.ProblemOrders, STATUS_ENUM.Missing, STATUS_ENUM.AutoProcessing, STATUS_ENUM.Decreasing, STATUS_ENUM.Transfering, STATUS_ENUM.Unconfirmed];

const bus_events = {
    opening_orders: 'opening_orders',
    grid_completed: 'grid_completed',
    grid_before_send: 'grid_before_send',
    grid_is_refreshing: 'grid_is_refreshing',
    grid_selection_changed: 'grid_selection_changed',
    grid_order_shown: 'grid_order_shown',
    refresh_highlight_box: 'refresh_highlight_box',
    order_status_changed: 'order_status_changed',
    order_status_changing: 'order_status_changing',
    refresh_grid: "refresh_grid",
    order_saved: "order_saved",
    order_note_saved: "order_note_saved",
    receipt_available: "receipt_available",
    broadcast: "broadcast" //used by VividIO.
};

const sharedTags = [
    "Problem", "Offered Alts", "Will Call", "Mistake", "Denied Entry", "Canceled Event", "AP Skip", "Skip Sync", "Waiting to Process",
    'Fast', 'Gecko', "Non Transferable", "POSTPONED", "Needs to be refunded", "Check CC refund", "Urgent", "Divvy", "BLUMENTHAL - SPECIAL ACCT REQ'D", 'Special Accts', 'Shipping Mistake', 'Processing Mistake', 'Mapping Error',
    "venue refunded", "Order Support", "View Issue", "Nonrefundable", "Venue Converted to WC", "Wait To Ship", "Pending Venue Response", "Unable To Process", "Delivery", "Account Surrender",
    "Missed IHD", "Mutual", "Prio", 'Missing', 'Awaiting Exchange Action', 'AP Purchase Failed', 'GL Gmail', 'CTO', 'Account Access Only', 'Email FWD'];

const historicalTags = ['Gremlin', 'Gremlin Resolved', 'Owl', 'Hoot', "Transfer Issue", "Busted", "received hard ticket", "Will-Call", 'Awaiting VS Action', 'Awaiting TN Action'];
class App {
    constructor() {
    };
    //#region Socket-IO
    getSocketIONameSpace = () => "vivid";
    getSocketIOEndPoint = (url) => {
        var endPoint = 'wss://' + (url || "io.songtek.net") + '/' + this.getSocketIONameSpace();
        return endPoint;
    };
    createBus = () => {
        var bus = new EventEmitter();
        bus.broadcast = function (msg, data) {
            return bus.emit(bus_events.broadcast, msg, data);
        };
        console.log('Create bus....');
        this.bus = bus;
    };
    //#endregion

    shouldConfirmShippingStatus = () => false;
    getEditableStatuses(allowed, excluded = []) {
        excluded = excluded.concat(excludedEditableStatuses);
        return (allowed || Object.keys(STATUS_ENUM).map(k => STATUS_ENUM[k])).filter(x => excluded.indexOf(x) == -1)
            .map(status => ({ name: STATUS_NAME[status], value: status, id: status }));
    };
    // getStatuses(allowed, excluded = []) {
    //     return (allowed || Object.keys(STATUS_ENUM).map(k => STATUS_ENUM[k])).filter(x => excluded.indexOf(x) == -1)
    //         .map(status => ({ name: STATUS_NAME[status], value: status }));
    // };
    getStatuses(allowed, excluded = []) {
        let ids = (allowed || Object.values(STATUS_ENUM));
        let mapped = Object.entries(STATUS_ENUM)
            .map(x => ({ id: x[1], value: x[0], name: STATUS_NAME[x[1]] }))
            .filter(x => ids.some(y => y == x.id))
            .filter(x => excluded.indexOf(x.id) == -1);

        if (allowed) { // An array with a previously sorted order
            mapped = allowed.map(x => mapped.find(y => y.id == x))
        }
        //The OData of enum type sent to the backend must be in string format, so the id needs to be converted to a string.
        return mapped.filter(x => x).map(m => ({ ...m, id: m.value }));
    };
    checkIfUseStrOrderNumber = () => false;
    hideShippingFilter = () => true;
    buildEventBlock(record) {
        return (<>
            <span>{record.event}</span><br />
            <span style={{ fontSize: '12px' }}>{record.venue}</span><br />
            <a href={record.event_url} target='_blank' style={{ textDecoration: 'none' }}>
                <span className='custom-Tag' style={{ backgroundColor: '#337ab7', fontSize: '13px' }}>{record.event_market}</span>
            </a>
        </>);
    };
    checkIfNeedSyncNote = () => false;
    //force to override    
}

//#region Vivid
// ---------------------------------
// Vivid
// ---------------------------------
class Vivid extends App {
    constructor() {
        super();
    };
    getSocketIONameSpace = () => "vivid";
    getEditableStatuses() {
        return super.getEditableStatuses(null, [STATUS_ENUM.TNProcessing]);
    };
    getStatuses() {
        return super.getStatuses(null, [STATUS_ENUM.TNProcessing]);//.concat([statusHardTicket, statusFedex, statusAirBill, statusAPFailed]);
    };
    getTags() {
        return ['Tickets On Sale', 'Rejected'].concat(sharedTags);
    }
    shouldRemoveProblemTag = () => true;

    getTitle = () => 'ViVid Orders';
}
//#endregion

//#region TicketNetwork
// ---------------------------------
// TicketNetwork
// ---------------------------------
class TicketNetwork extends App {
    constructor() {
        super();
        this.allowedStatuses = [
            STATUS_ENUM.Unconfirmed, STATUS_ENUM.Unprocessed, STATUS_ENUM.TNProcessing, STATUS_ENUM.PendingShipment, STATUS_ENUM.DelayedUnshippedOrders,
            STATUS_ENUM.Completed, STATUS_ENUM.ProblemOrders, STATUS_ENUM.Rejected, STATUS_ENUM.Canceled, STATUS_ENUM.Transfering, STATUS_ENUM.Undelivered
        ];
    };
    getSocketIONameSpace = () => "ticketnetwork";
    shouldConfirmShippingStatus = () => true;
    getEditableStatuses() {
        return super.getEditableStatuses(this.allowedStatuses);
    };
    getStatuses() {
        return super.getStatuses(this.allowedStatuses);//.concat([statusHardTicket, statusFedex, statusAPFailed]);
    };
    getTags() {
        //https://app.getflow.com/organizations/344018/teams/292068/tasks/22348033
        return ["Preconfirm Rejected", "Auto-Rejected"].concat(sharedTags);
    };
    getTitle = () => 'TicketNetwork Orders';
    hideShippingFilter = () => false;
    buildEventBlock(record) {
        return (<>
            <span>{record.event}</span><br />
            <span style={{ fontSize: '12px' }}>{record.venue}</span><br />
            <span style={{ justifyContent: 'space-between', display: 'flex' }}>
                <a href={record.event_url} target='_blank' style={{ textDecoration: 'none' }}>
                    <span className='custom-Tag' style={{ backgroundColor: '#337ab7', fontSize: '13px' }}>{record.event_market}</span>
                </a>
                <strong>{`$${record.wholesale > 0 ? record.wholesale : (record.unit_list_price || 0)}/ $${record.order_total}`}</strong>
            </span>
        </>)
    };
    checkIfNeedSyncNote = () => true;
}
//#endregion

//#region 
// ---------------------------------
// GoTickets
// ---------------------------------

class GoTickets extends App {
    getSocketIONameSpace = () => "gotickets";
    getTags() {
        return ['Tickets On Sale', 'Rejected', 'Mutual Cancellation'].concat(sharedTags);
    };
    getEditableStatuses() {
        return super.getEditableStatuses(null, [STATUS_ENUM.TNProcessing]);
    };
    getStatuses() {
        const NotUploadedYet = { id: 'NotUploadedYet', name: STATUS_NAME[16] };
        return super.getStatuses(null, [STATUS_ENUM.TNProcessing]).concat([NotUploadedYet]);
    };

    getTitle = () => 'GoTickets Orders';
}
//#endregion

function create(controller) {
    var vendorMap = {
        "Vivid": function () { return new Vivid(); },
        "TicketNetwork": function () { return new TicketNetwork(); },
        "GoTickets": function () { return new GoTickets(); }
        // "SeatGeek": function () { return new SeatGeek(); },
        // "TickPick": function () { return new TickPick(); },
        // "Gany": function () { return new Gany(); },

    };

    var app = vendorMap[controller]();
    app.controller = controller; // backend controller
    app.createBus();
    return app;
}

export { create, historicalTags, bus_events, sharedTags };