import * as React from 'react';
import { Button } from '@mui/material';
import { useFilterList } from '../../../../context/FilterListProvider';
import { selectRule } from './common';

export const NewRuleButton = ({ callback }) => {
    const [filterList] = useFilterList();

    const onNewRule = () => {
        var rule = selectRule(-1, -1, filterList);
        callback(-1, rule);
    }

    return (
        <Button size="small" onClick={onNewRule}>New Rule</Button>
    )
}

export default NewRuleButton;