import * as React from 'react';

const FormatDate = ({ formatFunc, datetime, useFormat }) => {
    if (datetime) {
        return (
            <>
                {useFormat ?
                    (
                        <>
                            <span>{formatFunc(datetime).format('MM/DD/YYYY')}</span>
                            <br />
                            <span>{formatFunc(datetime).format('hh:mm A')}</span>
                        </>
                    )
                    :
                    (
                        <>
                            <span>{formatFunc(datetime, 'MM/DD/YYYY')}</span>
                            <br />
                            <span>{formatFunc(datetime, 'hh:mm A')}</span>
                        </>
                    )
                }
            </>
        )
    }
    return null;
}

export {
    FormatDate
}