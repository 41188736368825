import React, { useState } from "react";
import RenderControls from "../../components/ra-list/RenderControls";
import AgGrid from "../../components/ag-grid/ag-grid";
import { dayjs } from '../../components/ex_dayjs'
import './style/style.css';
import { useNotify } from "react-admin";
import LeaveOptions from "./component/leave-options";
import { calcMonthRender, getNotification, getZero, isWeekend, leaveTypes, mappingSchedule } from "./common.js";
import { dataProvider } from '../../DataProvider';
import SaveAllButton from "./component/saveall-button";
import { Backdrop, CircularProgress, Divider } from "@mui/material";
import { Star, SouthOutlined, AccessTime } from "@mui/icons-material";
import { WorkDataImport } from "./component/work-data-import";
import SaveReviewButton from "./component/save-preview";
import ShiftTimeDialog from "./component/shift-time";
import { FilterListProvider } from "../../context/FilterListProvider.js";

const WeeklyWorkTime = () => {
    const notify = useNotify();
    const [isLoading, setIsLoading] = useState(false);
    const [lengthOfTime, setLengthOfTime] = useState({
        startDate: dayjs().set('date', 1),
        endDate: dayjs().add(1, 'month').set('date', dayjs().add(1, 'month').daysInMonth()),
        teamId: 0,
        isFilter: true
    })
    const leaveNames = ['1HT', '2HT', 'AL'];
    const [defaultValues, setDefaultValues] = useState({
        period: [lengthOfTime.startDate, lengthOfTime.endDate]
    });
    const [monthArr, setMonthArr] = useState(null);
    const [workTime, setWorkTime] = useState(null);
    const [countryTeam, setCountryTeam] = useState(null);
    const [twoWeeksSchedule, setTwoWeeksSchedule] = useState([]);
    const [hideSavePreview, setHideSavePreview] = useState(true);
    const [shiftWorkTime, setShiftWorkTime] = useState({ open: false, renderCount: 0 });

    React.useEffect(() => {
        if (dayjs(lengthOfTime.endDate).diff(dayjs(lengthOfTime.startDate), 'month') > 6) {
            notify(`Date range must be within 6 months`, { type: 'error' });
        }
        else {
            setMonthArr(calcMonthRender(lengthOfTime));
        }
    }, [lengthOfTime]);

    React.useEffect(() => {
        //after render grid, get data then fill in grid
        if (monthArr != null) {
            let selectedWeeks = `From ${twoWeeksSchedule.weekDays?.[0]?.day}, Month= ${twoWeeksSchedule.month}`
            let pData = { start: lengthOfTime.startDate, end: lengthOfTime.endDate, teamId: lengthOfTime.teamId, months: monthArr, isFilter: lengthOfTime.isFilter, selectedWeeks: selectedWeeks }
            setIsLoading(true);
            dataProvider.postData('WeeklyWorkTime', 'GetSchedules', pData)
                .then((response) => {
                    if (response.status !== 200 && response.data) {
                        let arrData = JSON.parse(response.data);
                        if (arrData.team != null) {
                            setCountryTeam(Object.keys(arrData.team).map(key => ({
                                id: parseInt(key),
                                name: arrData.team[key]
                            })));
                        }
                        if (monthArr.isFilter) {
                            setWorkTime(setLeaveDefault(arrData.data));
                        } else {
                            //fill data with review
                            let newData = mappingSchedule(twoWeeksSchedule, arrData.data);
                            setWorkTime(newData);
                        }

                    } else if (response.status == 401) {
                        notify(`Session expired - Reload`, { type: 'error' });
                        location.reload();
                    } else {
                        let mess = getNotification(response);
                        notify(mess, { type: 'error' });
                    }
                }).catch(err => {
                    console.log(`Error:`, err);
                }).finally(_ => setIsLoading(false));
        }
    }, [monthArr])

    const calcDaysInMonth = (month, year, day) => {//month = 11/2023
        let fullMonth = getZero(month);
        let currDate = dayjs();
        let currMonth = currDate.month() + 1;
        let currYear = currDate.year();
        let dayInMonth = currDate.$D;

        var colDefs = [
            {
                field: '',
                headerGroupComponent: SaveAllButton,
                headerGroupComponentParams: params => {
                    return {
                        showSaveAll: (year > currYear || (year == currYear && month >= currMonth)),
                        monthYear: `${year}-${fullMonth}`,
                        setIsLoading: setIsLoading,
                        lengthOfTime: lengthOfTime
                    }
                },
                children: [
                    {
                        field: 'AliasName', headerName: `MONTH: ${getZero(month) + '/' + year}`, pinned: 'left', width: 225,
                        headerClass: (month == currMonth && year == currYear) ? 'current-month' : 'month',
                        cellClass: params => (params.data.TeamLead ? 'teamlead' : '')
                    }]
            },
            { field: 'MonthYear', headerName: `Time`, hide: true },
            { field: 'Id', headerName: `Id`, hide: true },
        ]

        let dayIndex = day ? (lengthOfTime.startDate == `${year}-${fullMonth}-${getZero(day)}` ? day : 1) : 1;
        let daysLimit = day ? (lengthOfTime.endDate == `${year}-${fullMonth}-${getZero(day)}` ? day : null) : null;

        for (let _dayindex = dayIndex; _dayindex <= (daysLimit || dayjs(`${year}-${fullMonth}-01`).daysInMonth()); _dayindex++) {
            let dateString = year + '-' + fullMonth + '-' + _dayindex;
            let dayjsIndex = dayjs(dateString);
            let dayInWeek = dayjsIndex.format('ddd');
            colDefs.push({
                headerName: dayInWeek,
                headerClass: params => {
                    let classArr = [];
                    if (isWeekend(dayInWeek)) classArr.push('weekend');
                    if (isPast(_dayindex)) classArr.push('past-cell');
                    return classArr;
                },
                children: [{
                    colId: dateString,
                    field: `Day_${_dayindex.toString()}`,
                    headerName: _dayindex.toString(),
                    headerClass: params => {
                        let classArr = [];
                        if (isWeekend(dayInWeek)) classArr.push('weekend');
                        if (isPast(_dayindex)) classArr.push('past-cell');
                        return classArr;
                    },
                    width: 53,
                    suppressMovable: true,
                    cellRenderer: params => {
                        return (<>{params.data['ChangedList']?.find(x => x.applyDay == `Day_${_dayindex.toString()}`) && <Star style={{ fontSize: '10px', color: "red" }} />}
                            {params.data[`Day_${_dayindex.toString()}`] == 1 ? 'x' : leaveTypes.find(x => x.id == params.data[`Day_${_dayindex.toString()}`])?.name}
                            {params.data['ShiftWorkDateDetails']?.find(x => x.DayInMonth == `Day_${_dayindex}`) && <AccessTime style={{ fontSize: '10px', color: "chartreuse" }} />}
                        </>)
                    },
                    cellEditor: LeaveOptions,
                    editable: isPast(_dayindex) ? false : true,
                    cellEditorPopup: true,
                    cellEditorPopupPosition: 'over',
                    cellClass: params => {
                        let classArr = ['center-cell'];
                        let applyName = leaveTypes.find(x => x.id == params.data[`Day_${_dayindex.toString()}`])?.name;
                        if (isPast(_dayindex)) { classArr.push('past-cell'); }
                        else classArr.push('bold-cell');

                        if (isWeekend(dayInWeek) && !leaveNames.some(type => type == applyName)) { classArr.push('weekend'); }
                        if (dayjsIndex.isSame(currDate, 'week') && dayjsIndex.isSame(currDate, 'year')) {
                            if (!isWeekend(dayInWeek)) {
                                classArr.push('current-week');
                            }
                            if (currDate.isSame(dayjsIndex, 'day')) {
                                classArr.push('today');
                            }
                        }
                        if (currDate.get)
                            if (leaveNames.some(type => type == applyName)) { classArr.push('leave-cell', 'attractive-cell'); }

                        return classArr;
                    },
                }],

            });
        }
        colDefs.push(
            { field: 'Created', headerName: `Created`, pinned: 'right', hide: true },
            { field: 'CreatedBy', headerName: `Created By`, pinned: 'right', hide: true },
            { field: 'Modified', headerName: `Modified`, pinned: 'right', hide: true },
            { field: 'ModifiedBy', headerName: `Modified By`, pinned: 'right', hide: true },
            { field: 'Status', headerName: `Status`, pinned: 'right', hide: true }
        )

        return colDefs;

        function isPast(dayIndex) {
            if (currYear > year) return true;
            else if (currYear == year) {
                if (currMonth > month) return true;
                else if (currMonth == month && dayInMonth > dayIndex) return true;
            }
        }
    }
    function setLeaveDefault(arr) {//if no data, set off-days in weekend
        arr.forEach(mem => {
            Object.keys(mem).forEach(key => {
                if (key.includes('Day_')) {
                    let dayName = dayjs(`${mem['MonthYear']}-${key.split('_')[1]}`).format('ddd');
                    if (isWeekend(dayName) && mem.id == 0) {//only set value if record has not been created yet
                        mem[key] = 0;//OFF
                    }
                }
            })
            mem['ChangedList'] = [];
        });
        return arr;
    }
    const fillInData = (month, year) => {
        if (workTime == null) return [];
        return workTime.filter(w => w.MonthYear == `${year}-${getZero(month)}`)
            .sort((a, b) => a.Order - b.Order)
            .sort((a, b) => a.TeamId - b.TeamId);
    }

    const handleSearch = (filter) => {
        setLengthOfTime({
            startDate: dayjs(filter.period_ge.substr(0, 10)).format('YYYY-MM-DD'),
            endDate: dayjs(filter.period_le.substr(0, 10)).format('YYYY-MM-DD'),
            teamId: filter.teamId,
            isFilter: true
        })
        setTwoWeeksSchedule([]);
    }

    const defaultColDef = React.useMemo(() => {
        return {
            singleClickEdit: true,
        };
    }, []);

    const renderFilter = [
        { component: 'daterange', name: 'period', label: 'Period' },
        {
            component: 'select', name: 'teamId', label: 'Team', xs: 1, isHide: countryTeam == null,
            choices: countryTeam || []
        }
    ]

    function showSavePreview(index) {
        if (monthArr) {
            if (!twoWeeksSchedule.destFrom && twoWeeksSchedule.destTo && twoWeeksSchedule.destTo.slice(0, 7) == `${monthArr[index + 1]?.year}-${getZero(monthArr[index + 1]?.month)}`) {
                return true;
            } else if (twoWeeksSchedule.destFrom && twoWeeksSchedule.destFrom.slice(0, 7) == `${monthArr[index + 1]?.year}-${getZero(monthArr[index + 1]?.month)}`) {
                return true;
            }
        }
        return false;
    }

    const addShiftTime = (param) => {
        let selectedDate = dayjs(param.column.colId);
        let today = dayjs();
        if ((!selectedDate.isSame(today, 'day') && selectedDate.isBefore(today)) || param.value != 1) return;
        setShiftWorkTime(prev => ({ ...prev, open: true, ref: param, renderCount: prev.renderCount + 1 }));
    }

    return (
        <div id="schedule">
            <FilterListProvider>
                <RenderControls
                    controls={renderFilter}
                    onSearch={handleSearch}
                    defaultValues={defaultValues}
                    actions={
                        <>
                            {countryTeam && <WorkDataImport setTwoWeeksSchedule={setTwoWeeksSchedule} setLengthOfTime={setLengthOfTime} setHideSavePreview={setHideSavePreview} />}
                        </>
                    }
                />
            </FilterListProvider>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}>
                <CircularProgress color='inherit' />
            </Backdrop>
            {monthArr && monthArr.map((mon, index) => {
                return <div key={index + 9}><AgGrid
                    id={`WeeklyWorkTime${mon.month}${mon.year}`}
                    list={`WeeklyWorkTime${mon.month}`}
                    columnDefs={calcDaysInMonth(mon.month, mon.year, mon.day)}
                    defaultColDef={defaultColDef}
                    headerHeight={26}
                    rowHeight={26}
                    rowData={fillInData(mon.month, mon.year)}//fill data here for each grid
                    gridHeight={'35vh'}
                    hidePaging={true}
                    rowSelection={'single'}
                    onCellContextMenu={addShiftTime}
                />
                    <Divider variant="middle" style={{ marginTop: '8px', marginBottom: '8px' }} >
                        {showSavePreview(index) && !hideSavePreview && <><SouthOutlined />
                            <SaveReviewButton
                                monthYear={monthArr.slice(index + 1).map(x => `${x.year}-${getZero(x.month)}`)}
                                workTime={workTime}
                                setIsLoading={setIsLoading}
                                setHideSavePreview={setHideSavePreview} /><SouthOutlined /></>}
                    </Divider>
                </div>
            })}
            {shiftWorkTime.ref && <ShiftTimeDialog shiftWorkTime={shiftWorkTime} setShiftWorkTime={setShiftWorkTime} />}
        </div>
    )
}
export default WeeklyWorkTime;