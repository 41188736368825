import React, { useState } from 'react';
import { useRecordContext, useDataProvider, useNotify } from 'react-admin';
import { lmstatus, normalstatus } from '../common';
import { LinearProgress, MenuItem, FormControl, Select } from '@mui/material';
import { tnMode } from '../common';
import { toLocalTime } from '../../../components/ex_dayjs';
const StatusField = (props) => {
    const { info, setNotesData } = props;
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [isLoading, setIsLoading] = useState(false);
    const record = useRecordContext();

    if (!record) return null;

    // const [statusChangeInfo, setStatusChangeInfo] = useState({user: null, datetime: null})
    const arr = info.mode == tnMode.normalwithstatus ? normalstatus : lmstatus
    const handleChange = (props) => {
        let status = props.target.value;
        setIsLoading(true);

        dataProvider.postData('ExchangeTicketCount', 'AddStatus', { id: record.id, userMode: info.mode, Status: status })
            .then(rs => {
                setIsLoading(false);
                if (rs.data) {
                    record.status = status;
                    if (info.mode == tnMode.lastminute) {
                        record.lastNotes = rs.data;
                        record.statusUpdatedUser = rs.data[0].firstName + ' ' + rs.data[0].lastName;
                    } else {
                        record.statusUpdatedUser = rs.data.firstName + ' ' + rs.data.lastName;
                    }

                    record.statusUpdatedTime = toLocalTime();
                    setNotesData((prev) => ({ ...prev, data: rs.data, recordId: record.id }));
                    notify('Change status successfully', { type: 'success', autoHideDuration: 2000 });
                }
                else if(rs.status == 400){
                    notify(`Change status failed: ${rs.body}`, { type: 'error', autoHideDuration: 3000 });
                }
            })
            .catch(error => {
                setIsLoading(false);
                console.log('status change failed:', error);
                notify(`Change status failed: Status ${error.status}, Error ${error}`, { type: 'error', autoHideDuration: 3000 });
            })

    }

    return (
        <FormControl sx={{ m: 1, minWidth: 120, padding: 0 }} size="small" variant='standard'>
            {isLoading && <LinearProgress color="secondary" />}
            <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={record.status}
                defaultValue={record.status}
                disableUnderline={true}
                onChange={handleChange}
                sx={{ padding: 0 }}
            >
                {arr.map((m, index) => {
                    return <MenuItem key={index} id={m.id} value={m.id}>{m.name}</MenuItem>
                })}
            </Select>
            <b style={{ color: '#2196f3' }}>{record.statusUpdatedUser}</b>
            {record.statusUpdatedUser != "" && <>{toLocalTime(record.statusUpdatedTime).format('MMM DD, YYYY hh:mm A')}</>}
        </FormControl>
    )
}
export default React.memo(StatusField);