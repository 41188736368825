import React from 'react';
import OrderBase from '../order-base';
import { create as createSpecificVendor } from '../order-base/vendor';

const TicketNetworkOrder = () => {       
    const specificVendor = createSpecificVendor('TicketNetwork'); 
    return (
        <OrderBase     
            vendor={specificVendor}
        >

        </OrderBase>
    )
}
const TicketNetworkOrderPage = {
    name: 'TicketNetwork',
    list: TicketNetworkOrder
}
export default TicketNetworkOrderPage;