import dayjs from 'dayjs';
import { REMOTE_API } from '../pages/csv-merge/utils';

/**
 * This keys for getList of dataProvider, using RenderControl, RaList
 */
const RaKeys = {
    /** Refresh data after get data from getList */
    isCompleteSearch: 'isCompleteSearch',
    /** When user click Filter button */
    isSearch: 'isSearch',
    isRefresh: 'isRefresh'
};

function loadSourceType(dataProvider, callback, toLower = true) {
    let raKey = `marketType${toLower ? '' : '-rules'}`;
    let marketTypes = getRaStore(raKey);
    if (marketTypes) {
        callback(marketTypes);
    } else {
        dataProvider
            .fetchData('Common', `SoureTypes?lower=${toLower}`)
            .then((response) => {
                if (response.status !== 200 && response.data) {
                    marketTypes = response.data.items.sort((a, b) => a.id.localeCompare(b.id));
                    setRaStore(raKey, marketTypes);
                    callback(marketTypes);
                }
            })
            .catch((error) => console.log(error));
    }
}

function loadAccountMaps(dataProvider, callback) {
    let accounts = getRaStore('accounts');
    if (accounts) callback(accounts);
    else {
        dataProvider
            .fetchData('Common', 'GetAccountMaps')
            .then((response) => {
                if (response.status !== 200 && response.data) {
                    setRaStore('accounts', response.data);
                    callback(response.data);
                }
            })
            .catch((error) => console.log(error));
    }
}
function loadDeliveryMethod(dataProvider, callback) {
    let delivery = getRaStore('deliveryMethod');
    if (delivery) {
        callback(delivery);
    } else {
        dataProvider
            .fetchData('Common', 'GetDeliveryMethod')
            .then((response) => {
                if (response.status !== 200 && response.data) {
                    let arr = convertDataForMSelect(response.data);
                    callback(arr);
                    setRaStore('deliveryMethod', arr);
                }
            })
            .catch(console.error);
    }
}
function convertDataForMSelect(data, isEnum = true) {
    let arr = [];
    for (let key in data) {
        arr.push({ id: isEnum ? (key / 1) : key, name: isEnum ? formatEnumToName(data[key]) : data[key] });
    }
    return arr;
}
function loadExchanges(dataProvider, callback) {
    let exchanges = getRaStore('exchanges');
    if (exchanges) {
        callback(exchanges);
    } else {
        dataProvider
            .fetchData('Common', 'GetExchanges')
            .then((response) => {
                if (response.status !== 200 && response.data) {
                    let arr = convertDataForMSelect(response.data);
                    callback(arr);
                    setRaStore('exchanges', arr);
                }
            })
            .catch(console.error);
    }
}

function loadCountryTeam(dataProvider, callback) {
    dataProvider
        .fetchData('Common', 'GetCountryTeam')
        .then((response) => {
            if (response.status !== 200 && response.data) {
                let arr = convertDataForMSelect(response.data);
                callback(arr);
            }
        })
        .catch(console.error);
}

function loadResourceCSV(dataProvider, callback) {
    const ACTIVE_ACCOUNTS = [1, 2, 3, 4, 10, 109, 131, 240, 241];
    dataProvider.fetchData(REMOTE_API, 'sources')
        .then((response) => {
            if (response && response.data) {
                let filterData = response.data.filter(x => ACTIVE_ACCOUNTS.some(y => y == x.Id));
                callback(filterData.sort((a, b) => a.Id - b.Id));
            }
        })
        .catch((error) => {
            console.log("error ", error);
        });
}

function setRaStore(key, value) {
    if (!key.startsWith('RaStore.')) key = `RaStore.${key}`;
    sessionStorage.setItem(key, JSON.stringify(value));
}

function getRaStore(key) {
    if (!key.startsWith('RaStore.')) key = `RaStore.${key}`;
    var storeValue = sessionStorage.getItem(key);
    if (storeValue) return JSON.parse(storeValue);
}

function formatEnumToName(str) {
    //    let matches = str.match(/[A-Z][a-z]+/g);
    //    return matches.join(' ');
    let words = [];

    // Split the input string into individual characters
    let chars = str.split("");

    // Keep track of the current word being built
    let word = chars[0];

    // Loop through each character in the input string
    for (let i = 1; i < chars.length; i++) {
        // Check if the current character is uppercase
        if (chars[i] === chars[i].toUpperCase()) {
            // If it is, add the current word to the array and start a new word
            words.push(word);
            word = chars[i];
        } else {
            // If it's not, add the current character to the current word
            word += chars[i];
        }
    }

    // Add the final word to the array
    words.push(word);

    return words.join(' ');
}

/**
 * convert string to float, if decimal null then convert to int
 * @param {*} num 
 * @param {*} decimal 
 * @returns 
 */
function roundNumber(num, decimal = null) {
    if (!num) return null;
    if (decimal) {
        var point = Math.pow(10, decimal);
        return Math.round(num * point) / point;
    } else return parseInt(num);
}

function getDefaultUtcOffset() {
    const utcOffset = dayjs().utcOffset();
    return utcOffset * 60;
}

function changeType(value, type, boolAsInt) {
    switch (type) {
        case 'integer': return parseInt(value);
        case 'double': return parseFloat(value);
        case 'boolean':
            var bool = value.trim().toLowerCase() === 'true' || value.trim() === '1' || value === 1;
            return boolAsInt ? (bool ? 1 : 0) : bool;
        default: return value;
    }
};

/**
 * After do action: create, update, delete => then reload page => prevent load previous cache data
 * @param {*} resource 
 * @param {*} refButton 
 */
function reloadPage(resource, refButton) {
    if (resource && refButton) {
        refButton.current.click();
    }
}

function refreshCeilsAGrid(props, cols = []) {
    props.api.refreshCells({
        columns: cols,
        rowNodes: [props.node],
        force: true
    });
}

/**
 * method to convert the URLSearchParams object
 * @param {*} urlSearch
 * @param {*} defaultValues
 */
function setDefaultValuesFromURL(urlSearch = location.search, defaultValues, cols = []) {
    var searchParams = new URLSearchParams(urlSearch);
    const params = {};
    for (let [key, value] of searchParams.entries()) {
        try {
            let control = cols.find(x => x.name === key);
            if (control.component === "text" || value == '' || value == 'null') {
                //Ignore if it is required field
                if (!control?.required)
                    params[key] = value == 'null' ? null : value;
            }
            else {
                const parsedValue = JSON.parse(value);
                if (['number', 'select'].some(x => control.component === x) && typeof parsedValue === "number" && Number.isFinite(parsedValue)) {
                    params[key] = parsedValue;
                } else if (control.component == 'checkbox' && typeof parsedValue === "boolean") {
                    params[key] = parsedValue;
                } else {
                    params[key] = parsedValue;
                }

            }
        } catch {
            params[key] = value;
        }
    }
    Object.assign(defaultValues, params);
}
function autoLoad(refFilter, timeout = 400) {
    if (refFilter) {
        setTimeout(() => {
            refFilter.current.click()
        }, timeout);

    }
}
export {
    RaKeys,
    loadSourceType,
    loadAccountMaps,
    loadDeliveryMethod,
    loadExchanges,
    loadCountryTeam,
    setRaStore,
    getRaStore,
    formatEnumToName,
    roundNumber,
    getDefaultUtcOffset,
    changeType,
    loadResourceCSV,
    reloadPage,
    setDefaultValuesFromURL,
    refreshCeilsAGrid,
    convertDataForMSelect,
    autoLoad
};