import * as React from 'react';
import { SimpleForm, DateField, EditProps, Labeled, TextField, Show, useRecordContext } from 'react-admin';
import { Box, Grid, Stack, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Email } from '../../types';
import { EmailBodyView } from './EmailBodyView';
import HighlightedTextField from './HighlightedTextField';
interface Props extends EditProps<Email> {
    onCancel: () => void;
}
const EmailView = ({ onCancel, ...props }: Props) => {
    return (
        <Show  {...props}>
            <Box pt={5} width={{ xs: '100vW', sm: 800 }} mt={{ xs: 2, sm: 1 }}>
                <Stack direction="row" p={2}>
                    <Typography variant="caption" flex="1" >
                        <HighlightedTextField source="subject" />
                    </Typography>
                    <IconButton onClick={onCancel} size="small">
                        <CloseIcon />
                    </IconButton>
                </Stack>
                <SimpleForm
                    sx={{ pt: 0, pb: 0 }}
                    toolbar={false}
                >
                    <Grid container rowSpacing={1} mb={1}>
                        <Grid item xs={2}>
                            <Labeled>
                                <DateField source="date" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={5}>
                            <Labeled>
                                <TextField source="from" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={5}>
                            <Labeled>
                                <TextField source="to" />
                            </Labeled>
                        </Grid>
                    </Grid>
                    <EmailBodyView />
                </SimpleForm>
            </Box>
        </Show  >
    );
};

export default EmailView;