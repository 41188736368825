import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormGroup, Grid, Typography } from '@mui/material';

const MCheckboxGroup = React.memo((props) => {
    const { data, onChange, label, ...params } = props;
    const defaultStatus = [
        { id: 0, checked: null, indeterminate: true },
        { id: 1, checked: true, indeterminate: false },
        { id: 2, checked: false, indeterminate: false }
    ];
    data.forEach(cb => {
        cb.nullable = typeof (cb.nullable) === 'undefined' ? false : cb.nullable; // default value = false
        cb.defaultValue = typeof (cb.defaultValue) === 'undefined' ? false : cb.defaultValue; // // default value = false
    })
    /**
     * nullable=true + defaultValue=true: set checkbox is nullable and has default value = true
     */
    data.forEach(cb => {
        let allowNull = cb.nullable;
        let statuses = defaultStatus.map((m) => Object.assign({}, m)); // clone array
        if (cb.defaultValue == null) allowNull = true;
        if (!allowNull) statuses.shift() // remove isnull;
        let defaultItem = statuses.find(x => x.checked == cb.defaultValue);

        cb.allowNull = allowNull;
        cb.defaultItem = defaultItem;
        cb.statuses = statuses;
        cb.isPush = defaultItem.checked;//should add into filter or not
        changeDefaultIfAny(cb);
    });

    const [item, setItem] = React.useState(data);

    React.useEffect(() => {
        let values = getValue(item);
        onChange(values);
    }, [item]);
    /**
     * First set default value, then change default value
     */
    function changeDefaultIfAny(checkbox) {
        if (checkbox.defaultItem.checked != null && checkbox.defaultValue == null ||
            checkbox.defaultItem.checked == null && checkbox.defaultValue != null
        ) {
            let next = checkbox.statuses.find(x => x.checked == checkbox.defaultValue);
            checkbox.defaultItem = next;
        }
    }
    function getValue(data) {
        let checkValues = data.reduce((final, item) => {
            if (item.defaultItem.checked != null && item.isPush) {
                final.push({ name: item.name, value: item.defaultItem.checked });
            }
            return final;
        }, []);
        return checkValues;
    }
    const handleChange = (event) => {
        const { checked, name } = event.target;
        var next;
        let cb = item.find(x => x.name == name);
        if (cb.allowNull) {
            var nextId = cb.defaultItem.id + 1;
            if (nextId > 2) nextId = 0;
            next = cb.statuses.find(x => x.id == nextId);
        } else {
            next = cb.statuses.find(x => x.checked == checked);
        }
        setItem((prev) => prev.map(cb => cb.name == name ? { ...cb, defaultItem: next, isPush: true } : cb));
    }

    return (
        <Grid container spacing={1} className={props.className}>
            <Grid item alignContent={'center'} sx={{ marginRight: '5px' }}>
                <label className="MuiFormLabel-root"><b>{label}</b></label>
            </Grid>
            <Grid item xs={10}>
                <FormGroup row={true}>
                    {
                        item.map(cb => {
                            return <FormControlLabel key={`key-${cb.name}`}
                                control={
                                    <Checkbox
                                        {...params}
                                        name={cb.name}
                                        size='small'
                                        checked={cb.defaultItem.checked}
                                        indeterminate={cb.defaultItem.indeterminate}
                                        onChange={handleChange}
                                        sx={{ margin: '-5px -5px -4px 0' }}
                                    />
                                }
                                label={<Typography variant='caption'>{cb.label}</Typography>}
                                sx={{ marginRight: '4px' }}
                            />
                        })
                    }
                </FormGroup>
            </Grid>
        </Grid>
    )
});
export default MCheckboxGroup;