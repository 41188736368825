import * as React from 'react';
import { useMemo, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import { TextField, Box, LinearProgress, useColorScheme } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { dayjs, formatDateTime } from "../../../components/ex_dayjs";
import { AgGridReact } from 'ag-grid-react';
import { dataProvider } from '../../../DataProvider';
import EventActionPopUp from './EventActionPopUp';


const ViewEventButton = (props) => {
    return (<Button sx={{ margin: 1 }} variant="contained" onClick={() => props.viewClick(props.value)}>View</Button>)
}

const SearchEvent = ({ open, handleClose, handleViewClick }) => {
    const gridStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const containerStyle = useMemo(() => ({ width: '100%', height: '250px', marginTop: '20px' }), []);
    // const dateValue = dayjs(dayjs.utc().tz("America/Los_Angeles")).endOf('day').format('YYYY-MM-DD HH:mm:ss') + 'Z';
    const [value, setValue] = useState(null);
    const [tmEvent, setTmEvent] = useState({
        id: '',
        date: ''
    });
    const handleChange = (e, id) => {
        setTmEvent(prev => ({
            ...prev,
            [id]: e.target?.value || formatDateTime(e)
        }))
    }

    const [rowData, setRowData] = useState([]);
    const colDefs = useMemo(() => [
        {
            field: 'nga_tmid', headerName: 'View',
            cellRenderer: ViewEventButton,
            maxWidth: 115,
            colId: "nga_tmid",
            cellRendererParams: { viewClick: handleViewClick },
        },
        { field: 'nga_tmid', headerName: 'Event ID', wrapText: true, maxWidth: 170, suppressSizeToFit: true, },
        {
            field: 'nga_event_date', headerName: 'Event Date',
            valueFormatter: params => { return formatDateTime(params.value); },
            maxWidth: 175,
        },
        { field: 'nga_event', headerName: 'Event Name' },
        { field: 'nga_event_url', headerName: 'Event URL' },

    ]);

    const defaultColDef = useMemo(() => {
        return {
            cellStyle: { fontSize: '14px' },
            sortable: true,
            // wrapText: true,
            autoHeight: true,
            flex: 1,
            editable: true,
            suppressRowHoverHighlight: true,
            suppressHorizontalScroll: true,
            suppressMovable: true,//block move columns
            resizable: true
        };
    }, []);
    const { mode } = useColorScheme();
    const agTheme = "ag-theme-alpine" + (mode == "dark" ? "-dark" : "");

    const handleSearch = () => {

        dataProvider.postData('ReceiptsCounter', 'Search', tmEvent, 'POST')
            .then((response) => {
                console.log("response ", response);
                setRowData(response.data.items)
                // if (response && response.data)
            })
            .catch((error) => {
                console.log("error ", error);
            });

    }
    return (
        // <EventActionPopUp open={open} handleClose={handleClose} title='Search Events'>
        <>
            <DialogContentText>
                <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <TextField
                            onChange={(e) => handleChange(e, 'id')}
                            variant="filled"
                            autoFocus
                            label="Event Name"
                            type="text"
                            fullWidth
                            placeholder='Cirque du Soleil Crystal'
                        />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Event Date"
                                value={value}
                                onChange={(newValue) => {
                                    setValue(newValue);
                                    handleChange(newValue, 'date');
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box flex={1} sx={{ pt: 2 }} mr={{ xs: 0, sm: '0.2em' }}>
                        <Button variant="contained" onClick={handleSearch}>Search events</Button>
                    </Box>
                </Box>
            </DialogContentText>
            <div className={agTheme} style={gridStyle}>
                <div style={containerStyle}>
                    <AgGridReact
                        columnDefs={colDefs}
                        defaultColDef={defaultColDef}
                        rowData={rowData}
                        loadingCellRenderer={<LinearProgress />}
                        readOnlyEdit={true}
                    ></AgGridReact>
                </div>
            </div>
        </>
        // </EventActionPopUp>
    );
}

export default SearchEvent;