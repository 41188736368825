import {
    experimental_extendTheme,
    Experimental_CssVarsProvider,
    THEME_ID,
} from '@mui/material/styles';
import { defaultTheme } from 'react-admin';


const materialTheme = experimental_extendTheme({
    ...defaultTheme,
    components: {
        ...defaultTheme.components,
    }
});

export {
    THEME_ID as MATERIAL_THEME_ID,
    materialTheme as MATERIAL_THEME,
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
};