import React, { useState } from "react";
import { LinearProgress, Divider, Box, Button, TextField } from "@mui/material";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { useRecordContext, useNotify, useDataProvider } from "react-admin";
import { DataGrid } from "@mui/x-data-grid";
import { dayjs, toLocalTime } from "../../../components/ex_dayjs";

const SaleNote = (props) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const record = useRecordContext();
    const [notes, setNotes] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');

    if (!record) return null;

    React.useEffect(() => {
        setIsLoading(true);
        dataProvider.fetchData('ExchangeTicketCount', `Notes/?eventId=${record.id}`)
            .then(rs => {
                setIsLoading(false);
                if (rs.data) {
                    setNotes(rs.data);
                }
            })
            .catch(error => {
                notify(`Load notes failed: Status ${error.status}, Error ${error}`, { type: 'error', autoHideDuration: 2000 })
            })
    }, [])

    const addNote = (ev) => {
        ev.preventDefault();
        setIsLoading(true);

        dataProvider.postData('ExchangeTicketCount', 'AddNote', { EventId: record.id, Note: inputValue })
            .then(rs => {
                setIsLoading(false);
                if (rs.data) {
                    setNotes((prev) => [...prev, rs.data])
                    setInputValue('');
                    notify('Add note successfully', { type: 'success', autoHideDuration: 2000 });
                }
            })
            .catch(error => {
                notify(`Add note failed: Status ${error.status}, Error ${error}`, { type: 'error', autoHideDuration: 3000 })
            })
    }
    const handleInput = (ev) => {
        setInputValue(ev.target.value);
    }
    const columnDefs = [
        { field: 'insertedDate', headerName: 'Date', width: 200, valueFormatter: ({ value }) => toLocalTime(value).format('MMM DD, YYYY hh:mm A') },
        { field: 'firstName', headerName: 'User', width: 200 },
        // { field: 'email', headerName: 'Email', width: 200 },
        { field: 'note', headerName: 'Note', width: 400 },
    ];
    return record ? (
        <div>
            <form onSubmit={addNote}>
                <TextField
                    sx={{ width: '40%' }}
                    variant='standard'
                    id='user-note'
                    label='Your comment or note about this item ...'
                    value={inputValue}
                    onChange={handleInput}
                    required
                >
                </TextField>
                <Button variant="contained" size="small" sx={{ marginLeft: '5px', marginTop: '20px' }} startIcon={<ChatBubbleOutlineIcon />} type='submit'>Add Note</Button>
            </form>
            {isLoading && <LinearProgress color="secondary" />}

            <Box sx={{ height: 300, width: '100%' }}>

                {isLoading && <LinearProgress color='secondary' />}
                <DataGrid
                    rows={notes}
                    columns={columnDefs}
                    // getRowId={(r) => r.event_id}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 25,
                            },
                        },
                    }}
                />
            </Box>
        </div>
    ) : null;
}
export default React.memo(SaleNote)