import * as Colors from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { Identifier } from 'react-admin';
import { Email } from '../../types';
import KnownDomains, { categorize } from './Util';

const availableColors = Object.entries(Colors).sort((a, b) => a[0].localeCompare(b[0]));
const domainColors = Object.keys(KnownDomains).sort().reduce((acc, domain, index) => {
    acc[domain] = availableColors[index % availableColors.length];
    return acc;
}, {});



const rowStyle = (selectedRow?: Identifier) => (record: Email) => {
    const theme = useTheme();
    let style = {};
    if (!record) {
        return style;
    }
    if (selectedRow && selectedRow === record.id) {
        style = {
            ...style,
            backgroundColor: theme.palette.action.selected,
        };
    }
    let category = categorize(record),
        [_, color] = domainColors[category] || [];
    if (color) return {
        ...style,
        borderLeftColor: color[500],
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    };
    return style;
};

export default rowStyle;
