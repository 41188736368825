import * as React from "react";
import { useRecordContext } from 'react-admin';

const text_primary = "#007bff";
const text_danger = "#dc3545";

export const TicketFormatter = (props) => {
    const obj = useRecordContext(props);
    var listingId = obj.broker_id;
    if (obj.haveCompetitor != null) {
        let color = obj.haveCompetitor ? text_danger : text_primary;
        let title = obj.haveCompetitor ? 'have a competitor' : `don't have a competitor`;
        return <span style={{ color }} title={title}>{listingId}</span>
    }
    return <span>{listingId}</span>;
}

TicketFormatter.defaultProps = { label: 'ExchangeId' };