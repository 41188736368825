import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const defaultProps = {
    isEmpty: true
}

const MSelectGrouping = React.memo(({ label, choices, defaultValue, onChange, isEmpty = defaultProps.isEmpty, ...props }) => {
    const [value, setValue] = React.useState(defaultValue || '');
    React.useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const handleChange = (e) => {
        const { value } = e.target;
        setValue(value);
        onChange(value);
    }

    return (
        <FormControl sx={{ minWidth: '100%' }}>
            <InputLabel id="demo-select-small">{label}</InputLabel>
            <Select
                {...props}
                labelId="demo-select-small"
                id="group-native-select" native
                value={value}
                label={label}
                onChange={handleChange}
                {...props}
            >
                {isEmpty ? <option aria-label="None" value="" /> : null}
                {choices.map((group) => (
                    <optgroup label={group.label}>
                        {group.options.map((option, index) => (
                            <option value={option.id} >
                                {option.name}
                            </option>
                        ))}
                    </optgroup>
                ))}
            </Select>
        </FormControl>
    );
})

export default MSelectGrouping;