import * as React from 'react';
import { DateCalendar, PickersDay } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { dayjs } from '../../../components/ex_dayjs'
import { styled, useColorScheme } from '@mui/material/styles';
import updateLocale from 'dayjs/plugin/updateLocale';
import { useNotify } from 'react-admin';
dayjs.extend(updateLocale);
dayjs.updateLocale('en', { weekStart: 1 });

export const WeekSelector = (props) => {
    const { handleFirstMonday, handleSourceChange } = props;
    const notify = useNotify();
    const { mode } = useColorScheme();
    const [hoveredDay, setHoveredDay] = React.useState(null);
    const [value, setValue] = React.useState(null);

    const CustomPickersDay = styled(PickersDay, {
        shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
    })(({ theme, isSelected, isHovered, selectedday, day }) => ({
        borderRadius: 0,
        ...(isSelected && validWeeks(selectedday) && {
            backgroundColor: '#55a1f1 !important',
            color: `${theme.palette.primary.contrastText} !important`,
            '&:hover,&:focus': {
                backgroundColor: '#55a1f1 !important',
                borderColor: `#55a1f1 !important`
            },
            borderTopLeftRadius: (day.$D == 1) || (day.day() === 1 && day.isSame(selectedday, 'w')) ? '50%' : '',
            borderBottomLeftRadius: (day.$D == 1) || (day.day() === 1 && day.isSame(selectedday, 'w')) ? '50%' : '',
            borderTopRightRadius: (day.$D == selectedday.daysInMonth()) || (day.day() === 0 && day.isAfter(selectedday, 'w')) ? '50%' : '',
            borderBottomRightRadius: (day.$D == selectedday.daysInMonth()) || (day.day() === 0 && day.isAfter(selectedday, 'w')) ? '50%' : ''
        }),
        ...((!isSelected || (isSelected && !validWeeks(selectedday))) && {
            backgroundColor: `${mode == 'dark' ? '#383838' : 'white'} !important`,
        }),
        ...(isHovered && {
            backgroundColor: `#90caf9 !important`,
            '&:hover, &:focus': {
                backgroundColor: `#90caf9 !important`,
                borderColor: `#90caf9 !important`
            },
        }),
    }));
    const validWeeks = (dayInput) => {
        let hasMonday = dayInput.startOf('w').isSame(dayInput, 'month');
        let hasSunday = dayInput.add(1, 'w').endOf('w').isSame(dayInput, 'month');
        return hasMonday && hasSunday;
    }
    const isInSameWeek = (dayA, dayB) => {
        if (dayB == null) {
            return false;
        }

        return dayA.isSame(dayB, 'week');
    };
    const isInSame2Week = (dayA, dayB) => {
        if (dayB == null) {
            return false;
        }

        return dayA.isSame(dayB, 'week') || (dayA.get('w') - 1 == dayB.get('w'));
    };
    function Day(props) {
        const { day, selectedday, hoveredDay, ...other } = props;

        return (
            <CustomPickersDay
                {...other}
                day={day}
                sx={{ px: 2.5 }}
                disableMargin
                selected={false}
                isSelected={isInSame2Week(day, selectedday)}
                selectedday={selectedday}
                isHovered={isInSameWeek(day, hoveredDay)}
            />
        );
    }
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar id={'week-range-select'}
                    //disableHighlightToday
                    value={value}
                    onChange={(newValue) => {
                        setValue(newValue);
                        handleSourceChange(newValue);
                        if (validWeeks(newValue)) {
                            handleFirstMonday(newValue.startOf('week').format('YYYY-MM-DD'));
                        } else {
                            handleFirstMonday(null);
                            notify(`Invalid selected weeks.`, { type: 'error', autoHideDuration: 2000 })
                        }
                    }}
                    showDaysOutsideCurrentMonth={false}
                    slots={{ day: Day }}
                    slotProps={{
                        day: (ownerState) => ({
                            selectedday: value,
                            hoveredDay,
                            onPointerEnter: () => setHoveredDay(ownerState.day),
                            onPointerLeave: () => setHoveredDay(null),
                        }),
                    }}
                />
            </LocalizationProvider>
        </>

    )
}