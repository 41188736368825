import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const defaultProps = {
    isEmptyOption: true
}

const MSelect = ({
    isEmptyOption = defaultProps.isEmptyOption,
    label, choices, defaultValue, value, onChange, ...props
}) => {
    const [mValue, setMValue] = React.useState('');
    const [mDefaultValue, setDefaultValue] = React.useState('');
    React.useEffect(() => {
        if (defaultValue !== undefined) {
            setDefaultValue(defaultValue);
            setMValue(defaultValue);
        }
    }, [defaultValue]);

    React.useEffect(() => {
        if (value !== undefined) setMValue(value);
    }, [value]);

    const handleChange = (e) => {
        const { value } = e.target;
        setMValue(value);
        onChange(value);
    }

    return (
        <FormControl sx={{ minWidth: '100%' }} size="small">
            <InputLabel id="demo-select-small">{label}</InputLabel>
            <Select
                {...props}
                labelId="demo-select-small"
                id="demo-select-small"
                defaultValue={mDefaultValue}
                value={mValue}
                label={label}
                MenuProps={{
                    className: props.className ?? ""
                }}
                onChange={handleChange}
                {...props}
            >
                {isEmptyOption ? <MenuItem key={-1} value="" ><em>None</em></MenuItem> : null}
                {choices.map((m, index) => {
                    return <MenuItem key={index} id={m.id} value={m.id}>{m.name}</MenuItem>
                })}
            </Select>
        </FormControl>
    );
}

export default React.memo(MSelect);