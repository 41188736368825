import React, { useState } from "react";
import { Datagrid, FunctionField, TextField, useListController } from "react-admin";
import { formatDateTime, formatLocalTime } from "../../components/ex_dayjs";
import { Box } from "@mui/material";
import RaList from "../../components/ra-list/RaList";
import RenderControls from "../../components/ra-list/RenderControls";
import MDialog from "../../components/controls/MDialog";
import { getDefaultUtcOffset } from "../../utils/common";
import RaExporter from "../../components/ra-list/RaExporter";
import './style.css';

const colsCss = {
    orderNumber: 'onecallnow-col-orderNumber',
    date: 'onecallnow-col-date'
};

const ReportType = [
    { id: 'NotExportedToday', name: 'Not exported to day' },
    { id: 'NotExportedAllTime', name: 'Not exported all time' }
];

const OneCallNow = () => {
    const [sortModel] = useState(["inserted_on asc"]);
    const [isConfirm, setConfirm] = useState(false);

    const [exportParams, setExportParams] = useState({
        user_name: '',
        type: 'NotExportedToday',
    });
    const { resource } = useListController();

    const handleChangeUser = (e) => {
        const { value } = e;
        setExportParams((prev) => ({ ...prev, user_name: value }));
    };

    const handleChangeReportType = (e) => {
        const { value } = e;
        setExportParams((prev) => ({ ...prev, type: value }));
    };

    const handleExport = (isPreview = false) => {
        if (!exportParams.user_name) setConfirm(true);
        else {
            var utc_offset = getDefaultUtcOffset();
            return {
                resource: resource,
                params: {
                    filter: { filter: isPreview ? 'preview' : '', user_name_eq: exportParams.user_name, type: exportParams.type, utc_offset_eq: utc_offset },
                    sort: { field: 'inserted_on', order: 'asc' }
                },
                exportFile: {
                    filename: `OneCallNows${isPreview ? '-Preview' : ''}-${formatDateTime(new Date(), 'YY-MM-DD')}`,
                    columns: ['vivid_account', 'order_number', 'phone', 'country', 'user_name']
                }
            };
        }
    };

    return (
        <Box >
            <RaList
                title={"One Call Now"}
                sortby={sortModel}
                perPage={200}
                pagination={[200, 250, 500, 1000]}
                filters={
                    <div>
                        <RenderControls
                            controls={[
                                { component: 'text', name: 'user_name_eq', label: 'User', onChange: (e) => handleChangeUser(e) },
                                { component: 'number', name: 'order_number_eq', label: 'Order Number', xs: 1 },
                                { component: 'number', name: 'phone', isTextNumber: true },
                                { component: 'text', name: 'country', xs: 1 },
                                { component: 'text', name: 'vivid_account_eq', label: 'Account', xs: 1 },
                                { component: 'daterange', name: 'inserted_on', label: 'Recorded on', isUTC: true },
                                { component: 'daterange', name: 'last_exported_on', label: 'Last Exported On', isUTC: true },
                                { component: 'text', name: 'utc_offset_eq', isHide: true }
                            ]}
                            defaultValues={{ utc_offset_eq: getDefaultUtcOffset() }}
                        />
                        <RenderControls
                            hideFilter
                            hideProgressBar
                            autoCalXsActions={false}
                            controls={[
                                { component: 'select', name: 'report_type', label: 'Report Type', choices: ReportType, isEmptyOption: false, onChange: (e) => handleChangeReportType(e) }
                            ]}
                            actions={
                                <div>
                                    <RaExporter onPrepare={handleExport} />
                                    <RaExporter onPrepare={() => handleExport(true)} exportFile={{ label: 'Preview' }} />
                                </div>
                            }
                            defaultValues={{ report_type: 'NotExportedToday' }}
                        />
                    </div>
                }
            >
                <Datagrid
                    optimized
                    bulkActionButtons={false}
                >
                    <TextField source="user_name" label="User" />
                    <TextField source="vivid_account" label="Account" />
                    <TextField source="order_number" label="Order Number" />
                    <TextField source="phone" />
                    <TextField source="country" />
                    <FunctionField source="inserted_on" label="Recorded on" render={record => record.inserted_on && formatLocalTime(record.inserted_on)} cellClassName={colsCss.date} />
                    <FunctionField source="last_exported_on" label="Last Exported On" render={record => record.last_exported_on && formatLocalTime(record.last_exported_on)} cellClassName={colsCss.date} />
                </Datagrid>
            </RaList>
            <MDialog open={isConfirm} handleClose={() => setConfirm(false)} title={"Alert"}>{"Please input username"}</MDialog>
        </Box>
    );
};

const OneCallNowPage = {
    name: 'OneCallNow',
    list: OneCallNow
};
export default OneCallNowPage;