import React from 'react';
import { useNotify, useRefresh, useUnselectAll, useDataProvider, useListContext } from 'react-admin';
import { Button } from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';

const BulkUpdateStatusButton = () => {
    const { selectedIds } = useListContext();
    const notify = useNotify();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll('DivvyTransaction');
    const dataProvider = useDataProvider();

    const handleBulkUpdate = async () => {
        try {
            // debugger;
            await dataProvider.updateMany('DivvyTransaction', {
                ids: selectedIds,
                data: { status: 1 }, // Update status to 1 for all selected records
            });
            notify('Status updated to 1 for selected records', { type: 'success' });
            refresh(); // Refresh the list after the update
            unselectAll(); // Unselect all records
        } catch (error) {
            notify('Error updating status', { type: 'warning' });
        }
    };

    return (
        <Button variant='contained' onClick={handleBulkUpdate} startIcon={<UpdateIcon />}>
            Update Status to Completed
        </Button>
    );
};

export default BulkUpdateStatusButton;
