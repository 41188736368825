import * as React from 'react';
import { TextField, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { roundNumber } from '../../utils/common';

const defaultProps = {
    decimal: null,
    range: null,
    isTextNumber: false
}


const MNumber = ({ 
    decimal = defaultProps.decimal,
    range = defaultProps.range, 
    isTextNumber = defaultProps.isTextNumber,
    onChange, defaultValue, value, tooltip, startAdornment, ...props 
}) => {
    React.useEffect(() => {
        if (defaultValue !== undefined) {
            setDefaultNumValue(defaultValue);
            setNumValue(defaultValue);
        }
    }, [defaultValue]);

    React.useEffect(() => {
        if (value !== undefined) setNumValue(value);
    }, [value]);

    const [focused, setFocused] = React.useState(false);
    const [numValue, setNumValue] = React.useState('');
    const [defaultNumValue, setDefaultNumValue] = React.useState();

    const handleChange = (e) => {
        const { value } = e.target;
        var _newValue = value;
        if (!isTextNumber) _newValue = roundNumber(value, decimal);
        if (value != '') {
            if (typeof range?.[0] === 'number') {
                if (_newValue < range?.[0]) _newValue = range?.[0];
            }
            if (typeof range?.[1] === 'number') {
                if (_newValue > range?.[1]) _newValue = range?.[1];
            }
        }
        setNumValue(_newValue);
        onChange(_newValue);
    }

    const handleKeyPress = (event) => {
        let isPrevent = false;
        if (isTextNumber) {
            const phonePattern = /^[\d()+-\s]+$/;
            if (!phonePattern.test(event.key)) isPrevent = true;
        } else {
            // prevent dot character from being entered
            if (!decimal && event.key === '.') isPrevent = true;
            if (event.key === 'e') isPrevent = true;
            if (decimal > 0) {
                let countDecimal = event.target.value.split('.')?.[1];
                if (countDecimal?.length >= decimal) {
                    isPrevent = true;
                    // Text was selection
                    const selection = window.getSelection();
                    if (selection.type === 'Range' && selection.toString() === value) isPrevent = false;
                }
            }
        }
        if (isPrevent) event.preventDefault();
    };

    const handleFocus = () => {
        setFocused(true);
    }

    const handleBlur = () => {
        setFocused(false);
    }

    //Remove waring
    const newProps = Object.assign({}, props);
    delete newProps.range;
    //

    return (
        <Tooltip title={tooltip ?? ''}>
            <TextField {...newProps}
                fullWidth
                defaultValue={defaultNumValue}
                value={numValue}
                onChange={handleChange}
                InputLabelProps={{
                    shrink: focused || numValue !== undefined || defaultNumValue !== undefined
                }}
                InputProps={{
                    inputProps: {
                        type: isTextNumber ? 'text' : 'number',
                        autoComplete: 'on',
                        onKeyPress: handleKeyPress,
                        min: range?.[0],
                        max: range?.[1],
                        onFocus: handleFocus,
                        onBlur: handleBlur
                    },
                    startAdornment: startAdornment || null
                }}              
                />
        </Tooltip>
    )
}

MNumber.propTypes = {
    /** total digits after int, ex: 10.05 => .05 : 2 digits */
    decimal: PropTypes.number,
    /** Allow input from number to number, ex: min 0 -> max 5 */
    range: PropTypes.array,
    /** default: false / true: allow input text like phone numer: +1 (770) 323-1122, +84123222, 09123222, ,.... */
    isTextNumber: PropTypes.bool,
    tooltip: PropTypes.string
}
export default React.memo(MNumber);