import React from 'react';
import { Chip } from '@mui/material';
import { blue, grey, red, green, deepOrange } from "@mui/material/colors";
import { useUpdate, useNotify, Confirm } from 'react-admin';
import { useState } from 'react';



const Tags = (props) => {
  const [open, setOpen] = useState(false);//react-admin confirm
  const [newTags, setNewTags] = useState('');
  const [handleUpdate] = useUpdate();
  const notify = useNotify();
  const hanldeDelete = (_, value) => {
    props.api.stopEditing();
    setNewTags(removeTags(props.value, value));
    setOpen(true);
  }

  const removeTags = (oldTag, tag) => {
    // Remove the commas from the start and end of the string
    oldTag = oldTag.replace(/^,|,$/g, '');
    // Split the string by commas and filter out the unwanted tag
    const tags = oldTag.split(',').filter(t => t !== tag);
    // Join the filtered tags with commas
    return tags.join(',');
  }

  const handleConfirm = () => {
    let event = props.data;
    event.tags = newTags;
    handleUpdate('EventMatcher', { id: event.id, data: event }, {
      onSuccess: (result) => {
        notify('Update successfully', { type: 'success', autoHideDuration: 2000 });
        UpdateGrid(props);
        props.api.stopEditing();
        props.reloadPage();
      },
      onError: (error) => {
        notify(`Update error: ${error.message}`, { type: 'error', autoHideDuration: 5000 });
        props.api.stopEditing();
      },
    });
    setOpen(false);
    function UpdateGrid(props) {
      props.data = event;
      props.api.refreshCells({
        columns: ["tags"],
        rowNodes: [props.node],
        force: true
      });
    }
  }

  const tags = props.data.tags.split(",").filter(value => value !== "");
  const getStyles = (tagName) => {
    switch (tagName.trim()) {
      case 'high value':
        return green[500];
      case 'low value':
        return red[600];
      case 'support ticket':
        return blue[500];
      case 'skip':
      case 'unmapped':
      case 'manual review':
        return grey[500];
      default:
        return deepOrange[400];
    }
  }
  return (
    <>
      {tags.map((tag) => (
        <Chip
          size="small"
          style={{ backgroundColor: getStyles(tag.toString().toLowerCase()) }}
          onDelete={(e) => hanldeDelete(e, tag)}
          key={tag} label={tag} />
      ))}
      <Confirm isOpen={open} title='Delete Tag' content='Are you sure want to delete this tag?' onConfirm={handleConfirm} onClose={() => setOpen(false)} />
    </>
  );
};


export default Tags;
