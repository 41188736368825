function selectRule(id, baseId, _data) {
    let data = undefined;
    if (id <= 0) {
        if (baseId == -1) {
            data = {
                event: _data.event,
                venue: _data.venue,
                section: _data.section,
                sources: _data.account_id_,
                tags: ['']
            };
        }
        else {
            data = {
                sources: [_data.account],
                event: _data.event,
                venue: _data.venue,
                section: _data.section,
                row: _data.row,
                eventDate: _data.event_date.substr(0, 10),
                eventTime: _data.event_date.substr(11, _data.event_date.length - 10).trim(),
                tags: ['']
            };
        }
    }
    return data;
}

export {
    selectRule
}