import * as React from 'react';
import {
    Typography
} from '@mui/material';
import { useTranslate, Title } from 'react-admin';

const Home = () => {
    const translate = useTranslate();

    return (
        <>
            <Typography variant="h4" component="h4">
                Welcome to Analysis System
            </Typography>
            <div>
                This page give you updates of the analysis system.
            </div>
        </>

    );
};

export default Home;
