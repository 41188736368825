import * as React from 'react';
import MDialog from '../../../components/controls/MDialog';
import { Typography, Stack, Divider, Box, Button, Link } from '@mui/material';
import { formatDateTime } from '../../../components/ex_dayjs';
import { DataGrid } from '@mui/x-data-grid';
import { Confirm, useNotify } from 'react-admin';
import { MTypography } from './MTypography';


const WrapText = (props) => {
    return (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', overflow: 'hidden' }}>
            {props.formattedValue}
        </div>
    );
};

const SyncEventPopup = ({ data, dataProvider, handleClose }) => {
    const { s_isOpen, s_data } = data;
    const notify = useNotify();
    const [state, setState] = React.useState({
        c_isPortal: true,
        c_isOpen: false, // dialog confirm
        rows: []
    });

    React.useEffect(() => {
        if (s_data && s_data.json) {
            var _rows = JSON.parse(s_data.json);
            _rows.forEach((d, index) => d.id = index);
            setState((prev) => ({ ...prev, rows: _rows, c_isPortal: s_data.isPortal }));
        }
    }, [s_data]);

    const columnDefs = [
        { field: 'Name', headerName: 'Name', minWidth: 220, renderCell: (params) => <WrapText {...params} /> },
        { field: 'SmName', headerName: 'SmName', minWidth: 220, renderCell: (params) => <WrapText {...params} /> },
        { field: 'Venue', headerName: 'Venue', minWidth: 180, renderCell: (params) => <WrapText {...params} /> },
        { field: 'SmVenue', headerName: 'SmVenue', minWidth: 180, renderCell: (params) => <WrapText {...params} /> },
        { field: 'DateTime', headerName: 'DateTime', minWidth: 120, valueFormatter: ({ value }) => formatDateTime(value), renderCell: (params) => <WrapText {...params} /> },
        { field: 'SmDateTime', headerName: 'SmDateTime', minWidth: 120, valueFormatter: ({ value }) => formatDateTime(value), renderCell: (params) => <WrapText {...params} /> },
        { field: 'Score', headerName: 'Score', minWidth: 70 },
        { field: 'Mapped', headerName: 'Action', minWidth: 100, renderCell: (params) => <WrapText {...params} /> },
        { field: 'Mapped_By', headerName: 'Mapped By', minWidth: 120, renderCell: (params) => <WrapText {...params} /> },
        { field: 'Mapped_Time', headerName: 'Mapped Time', minWidth: 120, valueFormatter: ({ value }) => formatDateTime(value), renderCell: (params) => <WrapText {...params} /> }
    ];

    const handleSync = () => {
        closeDialog();
        dataProvider.postData('ListingsMapping', `RunSyncEvent`, s_data)
            .then((response) => {
                if (response && response.data) {
                    if (response.data === true) {
                        notify(`The event '${s_data.sm_event_name}' has been synced!!! `, { type: 'success' });
                        handleClose(null, 's_isOpen');
                    } else notify(`${response.data}`, { type: 'error' });
                }
            })
            .catch((error) => notify(`Error: ${error}`, { type: 'error' }));
    };

    const closeDialog = () => {
        setState((prev) => ({ ...prev, c_isOpen: false }));
    };

    return (
        <MDialog name='s_isOpen'
            maxWidth="xl"
            open={s_isOpen}
            title={
                <span>
                    <Link underline="hover" href={s_data.sm_event_url} target="_blank" >#{s_data.sm_event_id}</Link>
                    {state.c_isPortal ? "" : <span style={{ color: 'yellow' }}> (Not found on Portal!)</span>}
                </span>
            }
            handleClose={handleClose}
            action={state.c_isPortal && <Button onClick={() => setState((prev) => ({ ...prev, c_isOpen: true }))}>Run Sync!</Button>}
        >
            <Typography variant="h5">Sync event from Portal/API</Typography>
            <Divider orientation="horizontal" flexItem style={{ paddingTop: 5 }} />
            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={1}
                style={{ paddingTop: 10 }}
            >
                <MTypography label='Name' name={s_data.sm_event_name} />
                <MTypography label='Venue' name={s_data.sm_event_venue} />
            </Stack>
            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={1}
                style={{ paddingTop: 10 }}
            >
                <MTypography label='Event Date' datetime={s_data.sm_event_date} />
                <MTypography label='City' name={s_data.sm_event_city} />
            </Stack>
            <br />
            <Typography variant="h5">History</Typography>
            <Divider orientation="horizontal" flexItem style={{ paddingTop: 10, paddingBottom: 5 }} />
            <Box sx={{ height: 450, width: '100%' }}>
                <DataGrid
                    style={{ height: '100%' }}
                    rows={state.rows}
                    columns={columnDefs}
                    autoPageSize={true}
                    rowHeight={100}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 25,
                            },
                        },
                    }}
                />
            </Box>
            <Confirm isOpen={state.c_isOpen} title={"Are you sure sync for this event?"} onConfirm={handleSync} onClose={closeDialog} />
        </MDialog>
    );
};

export default SyncEventPopup;