import * as React from 'react';
import { Backdrop, CircularProgress, Typography } from '@mui/material';

const defaultProps = {
    isOpen: false,
    message: 'Loading...'
}

const MBackdrop = ({ isOpen = defaultProps.isOpen, message = defaultProps.message }) => {
    return (
        <Backdrop open={isOpen} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <CircularProgress color="inherit" />
            <Typography variant="h6" component="div" sx={{ ml: 2 }}>
                {message}
            </Typography>
        </Backdrop>
    );
}

export default MBackdrop;