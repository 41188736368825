import React, { useState } from 'react'
import RaList from '../../components/ra-list/RaList';
import RenderControls from '../../components/ra-list/RenderControls';
import { Datagrid, FunctionField, TextField } from 'react-admin';
import { getRaStore, loadExchanges, loadSourceType, setRaStore } from '../../utils/common';
import { dataProvider } from '../../DataProvider';
import { dayjs, formatDateTime, formatLocalTime, toLocalTime } from '../../components/ex_dayjs';

const SmEventCheck = () => {

    const [exchange, setExchange] = useState([]);
    const [defaultSort, setDefaultSort] = useState(['mapped_date desc']);
    const [marketType, setMarketType] = useState([]);
    const [defaultFilter, setDefaultFilter] = useState({
        event_date: [dayjs(), dayjs().add(6, 'M')],
        exchange_src_eq: 1,
        exchange_des_eq: 13
    });
    const [key, setKey] = useState('');

    React.useEffect(() => {
        setKey(new Date());
        loadExchanges(dataProvider, (response) => {
            setExchange(response.filter(obj => [1, 2, 13].includes(obj.id)));
        })
        loadSourceType(dataProvider, response => {
            setMarketType(response);
        })
    }, [])

    const controlFilters = [
        { component: 'select', name: 'exchange_src_eq', label: 'Source Exchange', choices: exchange, xs: 1.3, isEmptyOption: false },
        { component: 'select', name: 'exchange_des_eq', label: 'Destination Exchange', choices: exchange, xs: 1.3, isEmptyOption: false },

        { component: 'text', name: 'event_name_contains', label: 'Name' },
        { component: 'text', name: 'event_venue_contains', label: 'Venue' },
        { component: 'daterange', name: 'event_date', label: 'Date Time' },
        { component: '', name: '', xs: 3 },
        { component: 'select', name: 'market_type_eq', label: 'Type', choices: marketType, xs: 1.3 },
        { component: 'daterange', name: 'mapped_date', label: 'Mapped Time' },
        { component: 'text', name: 'mapped_by_contains', label: 'By' },
    ]

    return (
        <>
            <RaList
                perPage={25}
                title={'Event Comparison Tool'}
                sortby={defaultSort}
                filters={
                    <RenderControls
                        controls={controlFilters}
                        defaultValues={defaultFilter}
                        exportFile={{
                            filename: `SmEventCheck-${formatLocalTime(new Date(), 'YY-MM-DD')}`,
                            columns: ['market_type', 'sm_original_id', 'event_name', 'sm_event_name', 'event_venue', 'sm_event_venue',
                                'event_city', 'sm_event_city', 'event_state', 'sm_event_state', 'event_date', 'mapped_by', 'mapped_date', 'sm_event_date', 'event_url', 'sm_event_url'],
                            limit: 1000000
                        }}
                    />
                }
            >
                <Datagrid bulkActionButtons={false} key={key}>
                    <TextField source="market_type" label={'Type'} />
                    <TextField source="sm_original_id" label={'Sm EventID'} />
                    <FunctionField source="event_name" label={'Name'} render={record =>
                        <a href={record.event_url} target="_blank" style={{ color: record.event_url ? 'orange' : '' }}>{record.event_name}</a>}
                    />
                    <FunctionField source="sm_event_name" label={'SmEvent'} render={record =>
                        <a href={record.sm_event_url} target="_blank" style={{ color: record.sm_event_url ? 'orange' : '' }}>{record.sm_event_name}</a>}
                    />

                    <TextField source="event_venue" label={'Venue'} />
                    <TextField source="sm_event_venue" label={'SmVenue'} />
                    <TextField source="event_city" label={'City'} />
                    <TextField source="sm_event_city" label={'SmCity'} />
                    <TextField source="event_state" label={'State'} />
                    <TextField source="sm_event_state" label={'SmState'} />

                    <FunctionField label='DateTime' source='event_date' render={record => (<>{formatDateTime(record.event_date, 'MM-DD-YYYY')} <br />{formatDateTime(record.event_date, 'hh:mm A')}</>)} />
                    <FunctionField label='SmDateTime' source='sm_event_date' render={record => (<>{formatDateTime(record.sm_event_date, 'MM-DD-YYYY')} <br />{formatDateTime(record.sm_event_date, 'hh:mm A')}</>)} />

                    <TextField source="mapped_by" label={'By'} />
                    <FunctionField label='Mapped Time' source='mapped_date' render={record => (<>{formatDateTime(toLocalTime(record.mapped_date), 'MM-DD-YYYY')} <br />{formatDateTime(toLocalTime(record.mapped_date), 'hh:mm A')}</>)} />
                </Datagrid>
            </RaList >
        </>

    )
}
const SmEventCheckPage = {
    name: "SmEventCheck",
    list: SmEventCheck
};
export default SmEventCheckPage;