import * as React from 'react';
import { Button, TextField, LinearProgress, Stack } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import MDialog from '../../../components/controls/MDialog';
import { useDataProvider, useNotify } from 'react-admin';
import StubHubDialog from './stubhub-dialog';
export const TicketNetWorkSearch = (props) => {
    const { source } = props;
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [open, setOpen] = React.useState(false);
    const [inputValue, setInputValue] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [openStubhub, setOpenStubhub] = React.useState({ open: false, data: null });


    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }
    const handle2ndDialogClose = () => {
        setOpenStubhub({ ...openStubhub, open: false });
    }
    const handleInput = (ev) => {
        setInputValue(ev.target.value)
    }
    const handleSubmit = () => {
        setIsLoading(true);
        dataProvider.fetchData(source, `FindEvent?eventId=${inputValue}TN`)
            .then(response => {
                setIsLoading(false);
                if (response.data) {
                    setOpen(false);                 
                    setOpenStubhub({...openStubhub, open: true, data: JSON.parse(response.data)});
                    // alert('www.ticketstatistics.com is temporary down');
                };
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.status == 400) {
                    notify('Wrong Event ID. Please double check on TicketNetwork website', { type: 'error', autoHideDuration: 3000 })
                } else {
                    notify(`error status:${error.status}, error: ${error}`, { type: 'error', autoHideDuration: 4000 })
                }
            });
    }
    return (
        <>
            <Button variant='outlined' onClick={handleOpen}>
                Get Data From TicketStatistics
            </Button>
            <MDialog
                open={open}
                handleClose={handleClose}
                title={'Information'}
                name={'ticketnetwork-search'}
                action={<Stack spacing={2}><Button variant='contained' color='info' startIcon={<SendIcon />} onClick={handleSubmit}>Submit</Button></Stack>}
                children={
                    <div>
                        <p>You're about to download data from TicketStatistics. Please enter an event ID on TicketNetwork:</p>
                        <TextField
                            sx={{ width: '100%' }}
                            variant='outlined'
                            id='ticketnetwork-search-text'
                            value={inputValue}
                            onChange={handleInput}
                        >
                        </TextField>
                        {isLoading && <LinearProgress color='secondary' />}
                    </div>
                }
            />
            {openStubhub.open && <StubHubDialog onClose={handle2ndDialogClose} eventData={openStubhub.data} source='SingleEventAnalysis' />}
        </>

    )
}