import React from 'react';
import { useRecordContext } from 'react-admin';

const EventIdField = () => {
    const record = useRecordContext();
    if (!record) return null;
    return (
        <a target='_blank' href={`${location.origin}/SingleEventAnalysis/?event_id=${record.id}`} style={{ color: 'orange' }}>
                {record['id']}
            </a>
    )
}
export default EventIdField