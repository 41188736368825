import React, { useEffect, useRef, useState } from 'react';
import { useFilterList } from '../../context/FilterListProvider';
import { ExportButton, useLoading, useRefresh, useListContext, useListController, SelectColumnsButton } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { Grid, Stack, Button, LinearProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MSelect from '../controls/MSelect';
import MDatePicker from '../controls/MDatePicker';
import MAutoComplete from '../controls/MAutoComplete';
import MCheckbox from '../controls/MCheckbox';
import InputSearch from '../controls/InputSearch';
import MDateRangePicker from '../controls/MDateRangePicker';
import MNumber from '../controls/MNumber';
import MNumberRange from '../controls/MNumberRange';
import MTextRange from '../controls/MTextRange';
import MInputFilter from '../controls/MInputFilter';
import RaExporter from './RaExporter';
import MSelectGrouping from '../controls/MSelectGrouping';
import MRadio from '../controls/MRadio';
import MTextField from '../controls/MTextField';
import PropTypes from 'prop-types';
import { RaKeys, getRaStore, setRaStore } from '../../utils/common';
import { formattedDate } from '../controls/MDateControl';
import './style.css';
import MCheckboxGroup from '../controls/MCheckboxGroup';

const _ = require('lodash');
const defaultProps = {
    controls: [],
    defaultValues: {},
    exporter: false,
    filterInline: true,
    inputsSearch: [],
    actions: null,
    refFilter: null,
    sizeFilter: 'small',
    positionActions: 'end',
    hideFilter: false,
    hideProgressBar: false,
    autoCalXsActions: true,
    useHashUrl: false
};
/**
 * 
 * @param {*} props 
 * Referect MUI
 * controls => list array control [{component, name, label, xs, ...}]
 * @returns 
 */
const RenderControls = ({
    controls = defaultProps.controls,
    actions = defaultProps.actions,
    positionActions = defaultProps.positionActions,
    sizeFilter = defaultProps.sizeFilter,
    defaultValues = defaultProps.defaultValues,
    exporter = defaultProps.exporter,
    hideProgressBar = defaultProps.hideProgressBar,
    inputsSearch = defaultProps.inputsSearch,
    refFilter = defaultProps.refFilter,
    filterInline = defaultProps.filterInline,
    autoCalXsActions = defaultProps.autoCalXsActions,
    useHashUrl = defaultProps.useHashUrl,
    hideFilter = defaultProps.hideFilter,
    actionsStyle, selectColumn, onSearch, agSource, agSort, exportFile, disabled, beforeSearch
}) => {
    let sort, resource, perPage, page, setPage, setPerPage;
    if (!onSearch) {
        ({ sort, resource, perPage, page, setPage, setPerPage } = useListContext());
    }
    const { setFilters } = useListController({ perPage: perPage, filterDefaultValues: { ...defaultValues, isSearch: false }, storeKey: false, disableSyncWithLocation: true });
    var [filterList, setFilterList] = useState({});
    const _filterListContext = useFilterList();
    if (_filterListContext) [filterList, setFilterList] = _filterListContext;
    const [toggleFilter, setToggleFilter] = useState(false); // For react-admin
    const [sortPaging, setSortPaging] = useState({ page, perPage, sort });
    const [isChangeSortPaging, setChangeSortPaging] = useState(false);
    // For search general include: input text
    const [isOpenSearch, setIsOpenSearch] = useState(false);
    const [hashUrl, setHashUrl] = useState('');
    const isLoading = useLoading();
    const refresh = useRefresh();
    const location = useLocation();

    if (!refFilter) refFilter = useRef(null);

    //When completed search of React-admin, then refresh data
    if (resource) {
        const isCompleteSearch = getRaStore(`${resource}.${RaKeys.isCompleteSearch}`);
        if (isCompleteSearch === true) {
            setRaStore(`${resource}.${RaKeys.isCompleteSearch}`, false);
            setRaStore(`${resource}.${RaKeys.isRefresh}`, false);
            refresh();
        }
    }

    useEffect(() => {
        if (resource) {
            setRaStore(`${resource}.${RaKeys.isSearch}`, false);
        }
    }, []);

    useEffect(() => {
        var cloneFilter = Object.assign({}, filterList);
        for (let v in defaultValues) {
            var controlName = controls.find(x => x.name == v);
            var isRange = /range$/.test(controlName?._component);
            if (isRange && Array.isArray(defaultValues[v])) {
                var p = Object.keys(cloneFilter).filter(x => x == `${v}_ge` || x == `${v}_le`);
                if (p?.length) p.map((m) => delete cloneFilter[m]); // Delete old value
            }
        }
        setFilterList(combineParams({ ...cloneFilter, ...defaultValues }));
    }, [defaultValues]);

    useEffect(() => {
        let isChangePage = page != sortPaging.page || perPage != sortPaging.perPage;
        if (!_.isEqual(sort, sortPaging.sort) || isChangePage) {
            setChangeSortPaging(true);
            setSortPaging({ sort, page, perPage });
            if (isChangePage) {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }
        }
    }, [sort, page, perPage]);

    useEffect(() => {
        let newFilters = combineParams(filterList);
        newFilters.sortPaging = sortPaging;
        if (isChangeSortPaging) {
            setChangeSortPaging(false);
            setFilters(newFilters);
            const isRefresh = getRaStore(`${resource}.${RaKeys.isRefresh}`);
            // Array sort is only for first default sort, after changed column sort then ignore
            if (!isRefresh && !Array.isArray(sort.field)) {
                setRaStore(`${resource}.${RaKeys.isSearch}`, true);
            }
        }
    }, [sortPaging, isChangeSortPaging]);

    useEffect(() => {
        // For the page has hashUrl
        // When second refresh page, not auto search => must click manual filter button
        if (resource && hashUrl && useHashUrl && !isLoading) {
            setFilters(combineParams());
            setTimeout(() => refFilter.current.click(), 100);
        }
    }, [hashUrl]);

    useEffect(() => {
        var queryString = location.search ?? location.hash;
        if (resource && queryString && useHashUrl) setHashUrl(queryString);
    }, []);

    function isChangePaging(data) {
        let isChange = false;
        const defaultPaging = { page: 1, perPage: perPage, sort: sort };
        if (page != defaultPaging.page) {
            setPage(defaultPaging.page);
            isChange = true;
        }
        if (perPage != defaultPaging.perPage) {
            setPerPage(defaultPaging.perPage);
            isChange = true;
        }
        data.sortPaging = defaultPaging;
        return isChange;
    }

    const FilterButton = ({ style }) => {
        return (
            <Stack style={{ ...style || {}, display: hideFilter ? 'none' : '' }}>
                <Button ref={refFilter} size={sizeFilter} startIcon={<SearchIcon />} onClick={handleSearch}>Filter</Button>
            </Stack>
        );
    };

    const ActionFilter = () => {
        return (
            <Stack style={actionsStyle}
                direction="row"
                spacing={1}>
                {actions}
                {exportFile && (
                    <RaExporter
                        resource={resource || agSource}
                        // disabled={total === 0}
                        exportFile={exportFile}
                        params={{
                            filter: combineParams(onSearch ? getValueFromMui() : getValueSearchFromRA()),
                            pagination: exportFile.limit > 0 ? { page: 1, perPage: exportFile.limit } : { page: 1, perPage: 1000000 },
                            sort: sort || agSort
                        }}
                    />
                )}
                {exporter && <ExportButton />}
                {selectColumn && <SelectColumnsButton />}
            </Stack>
        );
    };

    disabled = isLoading || disabled;
    let filterStyle = {}, totalXs = 0, actionXs = 1, lastComponent = controls.filter(x => !x.isHide).slice(-1)?.[0];
    //Remove The tag <text>,<number>,<checkboxgroup>,... is unrecognized in this browser => replace 'component' to 'input'
    let inputControls = (controls || []).map(x => {
        let { component, ...rest } = x;
        return { ...rest, _component: component };
    });
    return (
        <div style={{ width: '100%' }} className={`rendercontrols ${disabled ? 'disabled' : ''}`}>
            {inputsSearch.length > 0 && (
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <InputSearch
                            isOpenSearch={isOpenSearch}
                            setIsOpenSearch={setIsOpenSearch}
                            setFilters={setFilters}
                            inputs={inputsSearch}
                            resource={resource}
                            defaultFilters={defaultValues}
                            onSearch={onSearch}
                        />
                    </Grid>
                </Grid>)}
            {(isOpenSearch || inputsSearch.length == 0) && (
                <Grid container direction="row"
                    justifyContent="flex-start"
                    alignItems="center" spacing={1}>
                    {inputControls.map((control, index) => {
                        if (!control.label) {
                            control.label = control.name;
                            if (control.label)
                                control.label = control.label.charAt(0).toUpperCase() + control.label.slice(1); // Uppercase for first character
                        }
                        if (!control.hasOwnProperty('isHide')) control.isHide = false;
                        if (!control.hasOwnProperty('defaultValue')) {
                            let isHasDefault = defaultValues.hasOwnProperty(control.name);
                            if (isHasDefault) control.defaultValue = defaultValues[control.name];
                        }
                        if (!control.isHide) {
                            let _xs = getGridItem(control);
                            totalXs += _xs;
                            if (totalXs > 12) totalXs = _xs;

                            if (lastComponent && lastComponent.name == control.name) {
                                if (!['checkbox', 'radio'].some(x => x == control._component)) filterStyle = { marginTop: 20 };
                            }
                            actionXs = 12 - totalXs - 0.5; // - 0.5 mean ignore Filter button
                            return (
                                <Grid item xs={_xs} key={index}>
                                    {getControl(control)}
                                </Grid>
                            );
                        }
                    })}
                    {filterInline && (
                        <>
                            <Grid item xs={0.5} style={filterStyle}>
                                <FilterButton />
                            </Grid>
                            {autoCalXsActions && (
                                <Grid item container justifyContent='flex-end' xs={actionXs} style={filterStyle}>
                                    <ActionFilter />
                                </Grid>
                            )}
                            {!autoCalXsActions && (
                                <Grid item spacing={1} style={filterStyle}>
                                    <ActionFilter />
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            )}
            {!filterInline && (
                <Stack
                    direction="row"
                    justifyContent={`flex-${positionActions}`}
                    alignItems="flex-start"
                    spacing={1}
                    style={{ marginBottom: '5px' }}>
                    <FilterButton style={actionsStyle} />
                    <ActionFilter />
                </Stack>
            )}
            {disabled && !hideProgressBar && <LinearProgress color="secondary" />}
        </div>
    );

    function combineParams(params) {
        var newObj = {}, newParams = params || defaultValues;
        for (let p in newParams) {
            var item = newParams[p];
            // numberrange or daterange, but ignore autocomplete
            var controlName = controls.find(x => x.name == p);
            if (controlName?.component) controlName._component = controlName.component;
            var isRange = /range$/.test(controlName?._component);
            if (isRange && Array.isArray(item)) {
                let isDateRange = controlName._component == 'daterange';
                let isTime = isDateRange && controlName.format?.includes(':');
                if (item[0]) newObj[`${p}_ge`] = isDateRange ? formattedDate(item[0], controlName.isUTC, isTime) : item[0];
                if (item[1]) newObj[`${p}_le`] = isDateRange ? formattedDate(item[1], controlName.isUTC, isTime, true) : item[1];
            } else if (controlName?._component == 'checkboxgroup') {
                if (Array.isArray(item)) newObj[p] = JSON.stringify(item);
                else newObj[p] = item;
            } else if (controlName?._component == 'date') {
                newObj[p] = formattedDate(item, controlName.isUTC, controlName.format?.includes(':'));;
            } else if (Array.isArray(item)) { // for array object or array => to get array 
                newObj[p] = item.map(x => x.id !== undefined ? x.id : x);
            } else if (p == 'undefined' || item == null || (typeof item === 'string' && item.trim() === ''))
                continue;
            else newObj[p] = item;
        }
        return newObj;
    }

    function getValueSearchFromRA(filter) {
        var dataSearch = filter || filterList;
        for (const [key, value] of Object.entries(dataSearch)) {
            if (key == 'undefined' || value == null || (typeof value === 'string' && value.trim() === ''))
                delete dataSearch[key];
        }
        return dataSearch;
    }

    function getValueFromMui(filter) {
        return filter || filterList;
    }

    function handleSearchReactAdmin(filter) {
        var dataSearch = getValueSearchFromRA(filter);
        // toggle to allow search, because state not update when state not changed => allow click search even not change
        dataSearch._toggleFilter = toggleFilter;
        setToggleFilter(!toggleFilter);
        var _newData = combineParams(dataSearch);
        // Reset paging for search
        let isChange = isChangePaging(_newData);
        if (setFilters) setFilters(_newData);
        if (!isChange) setRaStore(`${resource}.isSearch`, true);
    }

    function handleSearchMui(filter) {
        var dataSearch = getValueFromMui(filter);
        delete dataSearch['_toggleFilter'];
        if (resource) setRaStore(`${resource}.${RaKeys.isSearch}`, true);
        onSearch(combineParams(dataSearch));
    }

    function handleSearch() {
        if (beforeSearch) beforeSearch();
        if (onSearch) handleSearchMui();
        else handleSearchReactAdmin();
    }

    function handleChange(control, e, filterName) {
        var { name, onChange } = control;
        var value = e;
        if (e?.target) value = e.target.value;
        var prevSource = combineParams(filterList);
        if (filterName && !/range$/.test(control._component)) {
            for (let key in prevSource) {
                if (key.includes(name))
                    delete prevSource[key];
            }
        }
        name = filterName || name;
        if (value == null || (typeof value === 'string' && value.trim() === '')) delete prevSource[name];
        else prevSource[name] = value;
        setFilterList(prevSource);
        if (onChange) onChange({ name, value });
        if (control.autoSearch) {
            if (setFilters) handleSearchReactAdmin(prevSource);
            else handleSearchMui(prevSource);
        }
    }

    function handleKeyDown(event) {
        if (event.key === 'Enter') handleSearch();
    }

    function getGridItem(control) {
        if (control.xs) return control.xs;
        switch (control._component) {
            case 'text':
            case 'multiselect':
            case 'autocomplete':
            case 'daterange':
                return 2;
            default: return 1;
        }
    }

    function getControl(control) {
        // Remove Warning: React does not recognize the `isHide` prop on a DOM element.
        delete control.isHide;
        // render
        switch (control._component) {
            case 'text':
                return <MTextField {...control} onChange={(e) => handleChange(control, e)} onKeyDown={handleKeyDown} />;
            case 'number':
                return <MNumber {...control} onChange={(e) => handleChange(control, e)} onKeyDown={handleKeyDown} />;
            case 'select':
                return <MSelect {...control} onChange={(e) => handleChange(control, e)} />;
            case 'selectgrouping':
                return <MSelectGrouping {...control} onChange={(e) => handleChange(control, e)} />;
            case 'numberrange':
                return <MNumberRange {...control} onChange={(e, params) => handleChange(control, e, params)} />;
            case 'textrange':
                return <MTextRange {...control} onChange={(e, params) => handleChange(control, e, params)} />;
            case 'date':
                return <MDatePicker {...control} onChange={(e) => handleChange(control, e)} />;
            case 'daterange':
                return <MDateRangePicker {...control} onChange={(e, params) => handleChange(control, e, params)} />;
            case 'autocomplete':
                return <MAutoComplete {...control} onChange={(e) => handleChange(control, e)} />;
            case 'checkbox':
                return <MCheckbox {...control} onChange={(e) => handleChange(control, e)} style={{ paddingTop: 10 }} />;
            case 'checkboxgroup':
                return <MCheckboxGroup {...control} onChange={(e) => handleChange(control, e)} />;
            case 'textfilter':
                return <MInputFilter {...control} inputProps={{ autoComplete: 'on' }} onChange={(e, params) => handleChange(control, e, params)} onKeyDown={handleKeyDown} />;
            case 'radio':
                return <MRadio {...control} onChange={(e, params) => handleChange(control, e)} />;
            default:
                return <></>;
        }
    }
};

const Controls = PropTypes.shape({
    /** text | number | numberrange | select | multiselect | autocomplete | date | daterange | checkbox */
    component: PropTypes.string.isRequired,
    /** name is mapping with field in your table */
    name: PropTypes.string.isRequired,
    /** Label for control, ifnull then eq name */
    label: PropTypes.string,
    /**hide control, default: false */
    isHide: PropTypes.any,
    /** array object with [{id, name}]; 
     * autocomplete only array string ['blue', 'red'] */
    choices: PropTypes.array,
    /** Object is for default values: {account_eq: 1, ...}*/
    defaultValue: PropTypes.any,
    /**For enable null value for checkbox */
    nullable: PropTypes.bool,
    /** total digits after int, ex: 10.05 => .05 : 2 digits. Default: 2 */
    decimal: PropTypes.number,
    /** Range number: ex: min 0, max 100 => [0,100] */
    range: PropTypes.array,
    /** Range Min of numberrange: ex: min 0, max 100 => [0,100] */
    rangeMin: PropTypes.array,
    /** Range Max of numberrange: ex: min 0, max 100 => [0,100] */
    rangeMax: PropTypes.array,
    /** default: false / true: allow input text like phone numer: +84123222, 09123222,.... */
    isTextNumber: PropTypes.bool,
    /**Select allow show empty option => default true*/
    isEmptyOption: PropTypes.bool,
    /**Using with Grid: xs={2},.... */
    xs: PropTypes.number,
    /**Auto search after changed for React admin*/
    autoSearch: PropTypes.bool,
    /**Override onChange */
    onChange: PropTypes.func,
    /**Override onKeyDown Enter*/
    onEnter: PropTypes.func,
    /**For will return value utc, default = false*/
    isUTC: PropTypes.bool,
    /**For Format for datepicker, default: 'MM/DD/YYYY'*/
    format: PropTypes.string
});

RenderControls.propTypes = {
    controls: PropTypes.arrayOf(Controls).isRequired,
    /** Use a useState: numberrange: [2,5], autocomplete: ["", ""], select: [{id, name}] */
    defaultValues: PropTypes.object,
    /** Show export button */
    exporter: PropTypes.bool,
    /** paramns ex: {filename: 'abc', columns: ['list columns'], limit: 100 } 
     *  Call api "Export" on server
     *  (default limit = total) */
    exportFile: PropTypes.object,
    /** For search general => only apply search with Number and String */
    inputsSearch: PropTypes.array,
    /** Custom action of List react-admin */
    actions: PropTypes.any,
    /**Override onSearch, default will be call getList in dataprovider*/
    onSearch: PropTypes.func,
    /**Default show filter in the same line with component, otherwise is show in newline*/
    filterInline: PropTypes.bool,
    /**Default show filter button, false will hide button*/
    hideFilter: PropTypes.bool,
    /**Default allow show progress bar, false will hide*/
    hideProgressBar: PropTypes.bool,
    /** Ref of button filter */
    refFilter: PropTypes.any,
    /** position actions at start or end */
    positionActions: PropTypes.string,
    /** Set styles for List actions */
    actionsStyle: PropTypes.object,
    /** Set size filter: small | normal | large */
    sizeFilter: PropTypes.string,
    /** Set disabled for all controls*/
    disabled: PropTypes.bool,
    /** Auto calculator (Grid item xs) of actions (buttons). Default: true*/
    autoCalXsActions: PropTypes.bool,
    /** Default false, if true => auto search with hashUrl (includes # in url)*/
    useHashUrl: PropTypes.bool,
    /** selectColumn = boolean => hide | show columns is DatagridConfigurable */
    selectColumn: PropTypes.bool
};

export default React.memo(RenderControls);