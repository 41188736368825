import React, { useEffect, useRef, useState } from "react";
import { Title, useDataProvider } from 'react-admin';

import { DataGrid } from '@mui/x-data-grid';
import { Grid, Typography, Box } from '@mui/material';
import RenderControls from "../../../components/ra-list/RenderControls";
import { dayjs } from "../../../components/ex_dayjs";
import { getDefaultUtcOffset } from "../../../utils/common";

const RAReport = () => {
    const [state, setState] = useState({
        loaded: false,
        data: {}
    });

    let currentDate = new Date();
    const [defaultValues] = useState({
        order_date: [currentDate, currentDate]
    });

    const dataProvider = useDataProvider();
    const handleSearch = (params) => {
        setState({ ...state, loaded: false });

        let fDate = params.order_date_ge ?? dayjs(currentDate).format('YYYY-MM-DD') + ' 00:00:00Z';
        let tDate = params.order_date_le ?? dayjs(currentDate).format('YYYY-MM-DD') + ' 23:59:59Z';
        let query = `Index?from=${fDate}&to=${tDate}` + `&utcOffset=` + getDefaultUtcOffset();
        dataProvider
            .fetchData('OrderEntryReport', query)
            .then((response) => {
                setState({ ...state, loaded: true, data: response.data });
            });
    }
    const refFilter = useRef(null);
    useEffect(() => {
        handleSearch(defaultValues);
    }, [])

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2// round the currency value to 2 decimal places
    });

    const usdPrice = {
        type: 'number',
        valueFormatter: ({ value }) => value != 0 ? currencyFormatter.format(value) : 0
    };

    const MyDataGrid = (props) => {
        const { id, title, sortArr } = props;
        const [sortModel, setSortModel] = useState(sortArr);

        const handleSortModelChange = (newModel) => {
            setSortModel(newModel);
        };

        return (
            <Box sx={{
                '& .MuiDataGrid-root.hide-pagination .MuiDataGrid-footerContainer': {
                    display: "none"
                },
                '& .MuiDataGrid-cell.text-red, .MuiDataGrid-columnHeader.text-red': {
                    color: 'red',
                },
                width: '100%', minHeight: 150
            }}>
                <Typography display="inline" variant="h6" gutterBottom flex="1" fontWeight="bold">{title}</Typography>
                {props.subtitle && <Typography display="inline" variant="subtitle2"> {props.subtitle}</Typography>}
                <DataGrid
                    {...props}
                    getRowClassName={(_, index) => _.id == 999 ? 'row-bold' : ''}
                    density="compact"
                    autoHeight 
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    // Add a unique id prop to each DataGrid component
                    id={id}
                    disableColumnMenu={true}
                    className="hide-pagination"
                    hideFooterPagination={true}
                />
            </Box>
        );
    }

    return (
        <Box>
            <Title title={"Order entry report"}></Title>
            <Grid container columnSpacing={2}>
                <Grid item md={12}>
                    <RenderControls
                        refFilter={refFilter}
                        controls={[
                            { component: 'daterange', name: 'order_date', label: 'Order date (from)', xs: 3 }
                        ]}
                        defaultValues={defaultValues}
                        onSearch={handleSearch}
                        disabled={!state.loaded}
                    />

                </Grid>
                <Grid item md={5.5}>
                    {
                        /* CUSTOM ORDERS */
                        <MyDataGrid
                            id="custom"
                            title="Problem Orders & Autoprice By Market"
                            rows={state?.data?.markets || []}
                            columns={[
                                { field: 'eventMarket', headerName: 'Market', flex: 1 },
                                {
                                    field: 'problem', headerName: 'Problem Orders', type: 'number', width: 120,
                                },
                                {
                                    field: 'problemPercent', headerName: 'Problem %', type: 'number',
                                    cellClassName: (params) => {
                                        return params.value > 0 ? "text-red" : "";
                                    },
                                    valueFormatter: (params) => {
                                        const value = params.value > 0 ? parseFloat(params.value).toFixed(0) : 0;
                                        return `${value}%`;
                                    },
                                },
                                {
                                    field: 'autoPriced', headerName: 'Autopriced Orders', type: 'number', width: 140
                                },
                                {
                                    field: 'autoPricedPercent', headerName: 'Autopriced %', type: 'number', width: 120,
                                    cellClassName: (params) => {
                                        return params.value > 0 ? "text-red" : "";
                                    },
                                    valueFormatter: (params) => {
                                        const value = params.value > 0 ? parseFloat(params.value).toFixed(0) : 0;
                                        return `${value}%`;
                                    }
                                },
                                { field: 'total', headerName: 'Total Orders', type: 'number' }
                            ]}
                            sortArr={[{ field: 'eventMarket', sort: 'asc' }]}
                            getRowId={(r) => r.eventMarket}
                        />
                    }
                </Grid>
                <Grid item md={6.5}>
                    {
                        /* CUSTOM ORDERS */
                        <MyDataGrid
                            id="custom"
                            title="Order entry By Account"
                            rows={state?.data?.accounts || []}
                            columns={[
                                { field: 'accountId', headerName: 'Acct #', type: 'number', valueFormatter: (params) => params.value != 999 ? params.value : "" },
                                {
                                    field: 'accountName', headerName: 'Account Name', flex: 1
                                },
                                {
                                    field: 'unProcessed', headerName: 'Unprocessed', type: 'number', width: 120
                                },
                                { field: 'total', headerName: 'Total Orders', type: 'number' },
                                { field: 'profit', headerName: 'Profit', type: 'number', ...usdPrice },
                                { field: 'lost', headerName: 'Lost', type: 'number', ...usdPrice },
                                { field: 'penalties', headerClassName: "text-red", headerName: 'Penalties', cellClassName: "text-red", type: 'number', ...usdPrice },
                                { field: 'totalProfit', headerName: 'Total Profit', type: 'number', ...usdPrice }
                            ]}
                            sortArr={[{ field: 'accountId', sort: 'asc' }]}
                            getRowId={(r) => r.accountId}
                        />
                    }
                </Grid>
            </Grid>
        </Box>
    );
};

const RAPage = {
    name: 'OrderEntryReport',
    list: RAReport
}
export default RAPage;