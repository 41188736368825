import React, { useState } from 'react';
import { useRecordContext, useDataProvider, useNotify } from 'react-admin';
import { lmstatus, normalstatus, tnMode } from '../../tnsale-analysis/common';
import { LinearProgress, MenuItem, FormControl, Select } from '@mui/material';
import { toLocalTime } from '../../../components/ex_dayjs';

const StatusField = (props) => {
    const mode = tnMode.lastminute;
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [isLoading, setIsLoading] = useState(false);
    const record = useRecordContext();

    if (!record) return null;
   
    const arr = lmstatus;
    const handleChange = (props) => {
        let status = props.target.value;
        setIsLoading(true);

        dataProvider.postData('ExchangeTicketCount', 'AddStatus', { id: record.id, userMode: mode, Status: status })
            .then(rs => {
                setIsLoading(false);
                if (rs.data) {
                    record.status = status;
                    record.lastNotes = rs.data;
                    record.statusUpdatedUser = rs.data[0].firstName + ' ' + rs.data[0].lastName;

                    record.statusUpdatedTime = toLocalTime();                  
                    notify('Change status successfully', { type: 'success', autoHideDuration: 2000 });
                }
                else if(rs.status == 400){
                    notify(`Change status failed: ${rs.body}`, { type: 'error', autoHideDuration: 3000 });
                }
            })
            .catch(error => {
                setIsLoading(false);
                console.log('status change failed:', error);
                notify(`Change status failed: Status ${error.status}, Error ${error}`, { type: 'error', autoHideDuration: 3000 });
            })

    }

    return (
        <FormControl sx={{ m: 1, minWidth: 120, padding: 0 }} size="small" variant='standard'>
            {isLoading && <LinearProgress color="secondary" />}
            <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={record.status}            
                disableUnderline={true}
                onChange={handleChange}
                sx={{ padding: 0}}
            >
                {arr.map((m, index) => {
                    return <MenuItem key={index} id={m.id} value={m.id}>{m.name}</MenuItem>
                })}
            </Select>
            <b style={{ color: '#2196f3' }}>{record.statusUpdatedUser}</b>
            {record.statusUpdatedUser != ''  && <>{toLocalTime(record.statusUpdatedTime).format('MMM DD, YYYY hh:mm A')}</>}
        </FormControl>
    )
}
export default React.memo(StatusField);