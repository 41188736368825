import React, { useEffect, useRef } from "react";
import { Title, useDataProvider } from "react-admin";
import { Box, Grid } from '@mui/material';
import { formatDateTime } from "../../components/ex_dayjs";
import RenderControls from "../../components/ra-list/RenderControls";
import { DataGrid } from "@mui/x-data-grid";
import { REMOTE_API, objectToFormData } from "./utils";
import SourceSelect from "./components/SourceSelect";
import { loadResourceCSV, loadSourceType } from "../../utils/common";
import RuleEditPopup from "./components/RuleEditPopup";
import TagsAutoComplete from "./components/TagsAutoComplete";
import { FilterListProvider } from "../../context/FilterListProvider";
import NewRuleButton from "./components/NewRuleButton";

const CsvMerge = () => {
    const dataProvider = useDataProvider();
    const refbtnFilter = useRef(null);
    const [sortModel, setSortModel] = React.useState([{ field: 'Timestamp', sort: 'desc' }]);

    const [state, setState] = React.useState({
        sourceName: '',
        tags: [],
        isLoading: false,
        sources: [],
        rows: [],
        marketType: [],
        // for rule dialog
        isOpenRule: false,
        rowSelected: null, // row selected
        sourceVariable: []
    });

    useEffect(() => {
        getResource();
        loadSourceType(dataProvider, (response) => {
            setState((prev) => ({ ...prev, marketType: response.map(x => ({ id: x.id.toLowerCase(), name: x.name.toLowerCase() })) }));
        }, false);
        loadSourceVariable();
        refbtnFilter.current.click();
    }, []);

    function getResource() {
        loadResourceCSV(dataProvider, (response) => setState((prev) => ({ ...prev, sources: response })));
    }

    function loadSourceVariable() {
        dataProvider.fetchData('SourceVariable', 'Filter')
            .then(function (response) {
                if (response && response.data)
                    setState((prev) => ({ ...prev, sourceVariable: response.data.items || [] }));
            }).catch((error) => console.error(error));
    }

    const handleSortModelChange = (newModel) => {
        setSortModel(newModel);
    };

    const reloadCsv = (data) => {
        var _source = state.sourceName;
        if (_source == '' || _source == undefined) {
            _source = "_";
        }
        var _tags = state.tags;
        if (_tags == '' || _tags == undefined) {
            _tags = "_";
        }

        dataProvider.postData(REMOTE_API, `rules/by/source/` + _source + "/tags/" + _tags, objectToFormData(data))
            .then((response) => {
                if (response && response.data) {
                    var rows = [];
                    response.data.map((m) => {
                        var applyFor = m.ApplyFor.join(',');
                        rows.push({ ...m.RuleData, Source: applyFor, Tags: tagFormatter(m.RuleData.Tags) });
                    });
                    setState((prev) => ({ ...prev, rows, isLoading: false }));
                }
            }).catch((error) => console.error(error));
    };

    function tagFormatter(value) {
        if (value === undefined || value == ',,') {
            value = '';
            return value;
        }
        if (value.length == 0) return value;
        var result = value.replace(/^\,/, '').replace(/,$/, '');
        return result;
    }

    const handleSearch = (data) => {
        setState((prev) => ({ ...prev, isLoading: true }));
        reloadCsv(data);
    };

    const handleRowClick = (e) => {
        setState((prev) => ({ ...prev, isOpenRule: true, rowSelected: e.row, ruleId: e.id }));
    };

    const onNewRule = (filterList) => {
        setState((prev) => ({
            ...prev,
            isOpenRule: true,
            rowSelected: null,
            ruleId: [
                -1, {
                    sources: [prev.sourceName].filter(x => x != '' && x != '_'),
                    tags: prev.tags,
                    name: filterList.name
                }
            ]
        }));
    };

    return (
        <Box>
            <SourceSelect label="Source"
                choices={state.sources}
                marketType={state.marketType}
                sourceVariable={state.sourceVariable}
                dataProvider={dataProvider}
                onSelect={(value) => {
                    setState((prev) => ({ ...prev, sourceName: value }));
                    setTimeout(() => refbtnFilter.current.click());
                }}
                onDeleted={() => getResource()} />
            <Grid container item xs={3}>
                <TagsAutoComplete label={"Tags"} onChange={(e) => setState((prev) => ({ ...prev, tags: e }))} />
            </Grid>
            <Title title={'Csv Merge Filters'}></Title>
            <FilterListProvider>
                <RenderControls
                    controls={[
                        { component: 'text', name: 'name' },
                        { component: 'number', name: 'id' },
                        { component: 'text', name: 'createdby' },
                        { component: 'autocomplete', name: 'matches', choices: ['Event', 'Venue', 'EventDate', 'EventTime', 'Quantity', 'Section', 'Row', 'Cost', 'InHandDate', 'DeliveryMethod', 'TicketID', 'Notes'] },
                        { component: 'autocomplete', name: 'actions', choices: ['Remove', 'Event', 'Venue', 'EventDate', 'EventTime', 'Quantity', 'Section', 'Row', 'Cost', 'InHandDate', 'DeliveryMethod', 'TicketID', 'Notes'].map(m => m == 'Remove' ? 'Remove' : 'Modify ' + m) }
                    ]}
                    refFilter={refbtnFilter}
                    disabled={state.isLoading}
                    onSearch={handleSearch}
                    actions={<NewRuleButton callback={onNewRule} />}
                />
            </FilterListProvider>
            <Box sx={{ height: 750, width: '100%' }}>
                <DataGrid
                    columns={[
                        { field: 'Id', headerName: 'Id', maxWidth: 100, flex: 0.3 },
                        { field: 'Name', headerName: 'Name', maxWidth: 600, flex: 1 },
                        { field: 'Source', headerName: 'Source', maxWidth: 600, flex: 1 },
                        { field: 'CreatedBy', headerName: 'Created by', maxWidth: 300, flex: 0.5 },
                        { field: 'Timestamp', headerName: 'Timestamp', maxWidth: 250, valueFormatter: ({ value }) => formatDateTime(value), flex: 0.3 },
                        { field: 'Tags', headerName: 'Tags', maxWidth: 200, flex: 0.3 }
                    ]}
                    rows={state.rows}
                    getRowId={(r) => r.Id}
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    onRowClick={handleRowClick}
                />
            </Box>
            {state.isOpenRule &&
                <RuleEditPopup
                    data={state}
                    dataProvider={dataProvider}
                    reloadPage={() => refbtnFilter.current.click()}
                    onClose={() => setState((prev) => ({ ...prev, isOpenRule: false }))} />}
        </Box>
    );
};

const CsvMergePage = {
    name: 'CsvMerge',
    list: CsvMerge
};
export default CsvMergePage;