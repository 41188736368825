import * as React from "react";
import { TextField, InputAdornment, IconButton, Tooltip } from '@mui/material';
import { Search, FilterList, FilterListOff } from '@mui/icons-material';
import { setRaStore } from "../../utils/common";
import PropTypes from 'prop-types';

const InputSearch = ({ resource, setFilters, isOpenSearch, setIsOpenSearch, inputs, defaultFilters, onSearch }) => {
    const [search, setSearch] = React.useState("");
    const [disabled, setDisabled] = React.useState(false);
    const [label, setLabel] = React.useState("Search");

    React.useEffect(() => {
        var name = "";
        (inputs || []).forEach((e) => {
            name += e + " or ";
        });
        if (name) name = name.slice(0, -4);
        setLabel(`Search ${name}`);
    }, []);

    const handleChange = (event) => {
        setSearch(event.target.value);
    }

    const handleSearch = (event) => {
        if (event.which === 13 || event.keyCode === 13 || event.type === "click") {
            if (disabled) return;
            if (!search.trim()) return;
            if (onSearch) onSearch(search);
            else {
                setFilters({ ...(defaultFilters || {}), q: search });
                setRaStore(`${resource}.isSearch`, true);
            }
        }
    }

    const handleOpenSearch = () => {
        let isOpen = !isOpenSearch;
        setIsOpenSearch(isOpen);
        setDisabled(isOpen);
    }

    const MInputAdornment = ({ children, title, handleClick }) => {
        return (
            <InputAdornment position="end">
                <Tooltip title={title}>
                    <IconButton onClick={handleClick}>
                        {children}
                    </IconButton>
                </Tooltip>
            </InputAdornment>
        )
    }

    return (
        <TextField
            id="textSearch"
            name="q"
            placeholder={label}
            autoComplete="search"
            // fullWidth
            disabled={disabled}
            value={search}
            InputProps={{
                startAdornment: (
                    <MInputAdornment handleClick={handleSearch}>
                        <Search />
                    </MInputAdornment>
                ),
                endAdornment: (
                    <>
                        {!isOpenSearch && (
                            <MInputAdornment title={"Open advanced search"} handleClick={handleOpenSearch}>
                                <FilterList />
                            </MInputAdornment>
                        )}
                        {isOpenSearch && (
                            <MInputAdornment title={"Close advanced search"} handleClick={handleOpenSearch}>
                                <FilterListOff />
                            </MInputAdornment>
                        )}
                    </>
                )
            }}
            onChange={handleChange}
            onKeyPress={handleSearch}
        />
    )
}

InputSearch.propTypes = {
    setFilters: PropTypes.func,
    setIsOpenSearch: PropTypes.func,
    isOpenSearch: PropTypes.bool,
    inputs: PropTypes.array,
    onSearch: PropTypes.func
};

export default React.memo(InputSearch);