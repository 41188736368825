import * as React from 'react';
import { Box, Button, LinearProgress, TextField, Typography } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { useDataProvider, useNotify } from 'react-admin';
import MDialog from '../../components/controls/MDialog';
import { formatDateTime } from '../../components/ex_dayjs';

const AccountAssignments = React.memo(({ data, handleClose, onChange }) => {
    const { acct_isOpen, orderId } = data;
    const [isLoading, setIsLoading] = React.useState(false);
    const [ruleId, setRuleId] = React.useState(null);
    const [isShowEventDate, setShowEventDate] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const dataProvider = useDataProvider();
    const apiRef = useGridApiRef();
    const initialState = {
        hideColumns: { eventDate: false }
    };

    React.useEffect(() => {
        setIsLoading(true);
        dataProvider.fetchData('Vivid', `SuggestAccountsTesing?orderNumber=${orderId}`)
            .then((response) => {
                var result = response.data;
                if (result.success) {
                    if (result.data.some(e => e.eventDate)) {
                        apiRef.current.setColumnVisibilityModel({});
                        setShowEventDate(true);
                    }
                    setRows(result.data);
                    setRuleId(result.ruleId);
                }
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false);
            });
    }, []);

    const columnDefs = [
        { field: 'primaryAccount', headerName: 'Primary Account', flex: 1 },
        { field: 'eventDate', headerName: 'Event Date', valueFormatter: params => { return formatDateTime(params.value); }, flex: 0.8 },
        { field: 'totalTickets', headerName: 'Total Tickets', flex: 0.5, type: 'number' },
        { field: 'totalOrders', headerName: 'Total Orders', flex: 0.5, type: 'number' },
        { field: 'limitTickets', headerName: 'Limit Tickets', flex: 0.5, type: 'number' },
        { field: 'limitOrders', headerName: 'Limit Orders', flex: 0.5, type: 'number' },
        // { field: 'state', headerName: 'State', flex: 0.3 },
        // { field: 'city', headerName: 'City', flex: 0.5 },
    ];

    const onCellClick = (params) => {
        onChange(params.row.primaryAccount);
    }

    const onRowDoubleClick = (params) => {
        onChange(params.row.primaryAccount);
        handleClose();
    }

    const onSelect = () => {
        handleClose();
    }

    return (
        <MDialog name='m_isOpen'
            open={acct_isOpen}
            title={"Accounts Suggestion"}
            handleClose={handleClose}
            maxWidth="lg"
            action={<Button variant="contained" onClick={onSelect}>OK</Button>}
        >
            {isLoading && <LinearProgress color='secondary' />}
            <Typography style={{ fontWeight: 'bold' }}>Applied by ruleId: {ruleId}</Typography>
            <Box sx={{ height: 500, width: '100%' }}>
                <DataGrid
                    apiRef={apiRef}
                    rows={rows}
                    columns={columnDefs}
                    autoPageSize={true}
                    density="compact"
                    getRowId={(x) => isShowEventDate ? x.primaryAccount + '_' + x.eventDate : x.primaryAccount}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 25,
                            },
                        },
                        columns: {
                            columnVisibilityModel: initialState.hideColumns
                        },
                    }}
                    onCellClick={onCellClick}
                    onRowDoubleClick={onRowDoubleClick}
                />
            </Box>
        </MDialog>
    )
});

const AAT_AccountAssignmentPage = () => {
    const [orderId, setOrderId] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [primaryAccount, setPrimaryAccount] = React.useState();
    const notify = useNotify();

    React.useEffect(() => {
        notify(`Selected: ${primaryAccount}`, { type: 'success', autoHideDuration: 1000 });
    }, [primaryAccount]);

    const handleChange = (e) => {
        let { value } = e.target;
        setOrderId(value);
    }

    const handleClick = () => {
        console.log('cha', orderId);
        setOpen(true);
    }

    const handlePrimaryAccountChange = React.useCallback((data) => {
        setPrimaryAccount(data);
    }, []);

    const handleClose = () => setOpen(false);

    return (
        <Box>
            <TextField onChange={handleChange} fullWidth={false} value={orderId} placeholder='Order Number' />
            <Button onClick={handleClick} variant='outlined'>Suggest</Button>
            <Typography>Selected: {primaryAccount}</Typography>
            {open && <AccountAssignments data={{ acct_isOpen: open, orderId }} onChange={handlePrimaryAccountChange} handleClose={handleClose} />}
        </Box>
    )
}

export default React.memo(AAT_AccountAssignmentPage);