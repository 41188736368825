import React, { memo, useState, forwardRef } from 'react';
import MSelect from '../../../components/controls/MSelect';
import { leaveTypes } from '../common';

const LeaveOPtions = memo(forwardRef((props, ref) => {
    const [expand, setExpand] = useState(true);

    var selectedIndex = null;
    const selectTimeoffType = async (value) => {
        let _index = value === '' ? 1 : value;
        selectedIndex = _index;

        if (_index != null) {
            let applyDay = `Day_${props.column.colId.split('-')[2]}`;
            let originValue = props.data[applyDay];

            props.data[applyDay] = _index;
            if (!props.data['ChangedList'].find(x => x.applyDay == applyDay)) {              
                props.data['ChangedList'].push({ applyDay, originValue });
            }else {
                let originValue = props.data['ChangedList'].find(x => x.applyDay === applyDay)?.originValue;
                if(originValue === _index) {
                    props.data['ChangedList'] = props.data['ChangedList'].filter(x => x.applyDay != applyDay);
                }
            }
        }
    }
  
    return (
        <div>
            <MSelect
                choices={leaveTypes}
                onChange={selectTimeoffType}
                open={expand}
                value={props.data[`Day_${props.column.colId.split('-')[2]}`]}
                onClose={() => {
                    if (selectedIndex == null || selectedIndex == props.data[`Day_${props.column.colId.split('-')[2]}`]) {
                        props.api.stopEditing();
                    }
                }}
            ></MSelect>
        </div>
    );
})
);
export default LeaveOPtions;