import * as React from "react";
import { useDataProvider, useNotify } from "react-admin";
import { Button, CircularProgress, Typography } from '@mui/material';
import { GetApp } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { formatDateTime } from "../ex_dayjs";

const RaExporter = ({ resource, params, exportFile, onPrepare }) => {
    const [disabled, setDisabled] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [progress, setProgress] = React.useState();
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const handleClick = async () => {
        if (onPrepare) {
            var _p = onPrepare();
            if (!_p) return;
            resource = _p.resource;
            params = _p.params;
            exportFile = _p.exportFile;
        }
        setLoading(true);
        setDisabled(true);
        setProgress(0);

        // Set default if null
        if (!params.sort) params.sort = { field: 'id', order: 'asc' };
        if (!params.pagination) params.pagination = { page: 1, perPage: exportFile.limit || 0 };
        params.pagination.perPage = Math.max(params.pagination.perPage, 1000000);
        if (!exportFile.filename) exportFile.filename = `${resource}-${formatDateTime(new Date(), 'YY-MM-DD')}`;

        await dataProvider.export(resource, params, exportFile)
            .then(async (resp) => {
                if (resp.ok) await downloadFile(resp);
                else resetState();
            })
            .catch((error) => {
                resetState();
                notify(`Export has error ${error}`, { type: 'error' });
            });
    };

    function resetState() {
        setLoading(false);
        setDisabled(false);
        setProgress(100);
    }

    async function downloadFile(response) {
        var contentType = response.headers.get("Content-Type");
        if (contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || contentType === "text/csv") {
            var totalSize = parseInt(response.headers.get("Content-Length")); // Get the total size
            var downloadedSize = 0, downloadedParts = [];
            const reader = response.body.getReader();
            while (true) {
                const { done, value } = await reader.read();
                let length = value?.length || 0;
                if (done) break;
                downloadedParts.push(value);
                // Calculate the percentage downloaded
                downloadedSize += length;
                const percentComplete = (downloadedSize / totalSize) * 100;
                setProgress(percentComplete);
            }
            const mergedBlob = new Blob(downloadedParts, { type: contentType });
            const mergedUrl = window.URL.createObjectURL(mergedBlob);
            const downloadLink = document.createElement('a');
            downloadLink.href = mergedUrl;
            downloadLink.download = exportFile.filename;
            downloadLink.click();
            console.log(`Saved: ` + new Date());
            resetState();
        }
    }
    return (
        <Button size="small"
            startIcon={(isLoading && progress < 100) ?
                (<div style={{ position: 'relative', maxHeight: 20 }}>
                    <CircularProgress size={20} variant="determinate" color="inherit" value={progress} thickness={5} />
                    <Typography
                        fontSize={8}
                        color="textSecondary"
                        style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                    >
                        {`${Math.round(progress)}%`}
                    </Typography>
                </div>) : (<GetApp />)}
            onClick={handleClick}
            disabled={disabled}>
            {exportFile?.label || 'Export'}
        </Button>
    );
};

RaExporter.propTypes = {
    /** paramns ex: 
     * {
            filter: filterValues,
            pagination: {page, perPage},
            sort: sort
        }
     */
    params: PropTypes.object,
    /** lable of button, default Export */
    label: PropTypes.string,
};

export default React.memo(RaExporter);
