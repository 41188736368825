import * as React from 'react';
import { Button } from '@mui/material';
import { useFilterList } from '../../../context/FilterListProvider';

export const ChartButton = ({ callback }) => {
    const [filterList] = useFilterList();
    const onNewRule = () => callback(filterList);
    return (
        <Button size="small" onClick={onNewRule} >Chart View</Button>
    )
}

export default ChartButton;