import * as React from 'react';
import { matchPath } from 'react-router-dom';
import {
    Create, Edit, useNotify, useRefresh, useRedirect, TabbedForm, TextInput, required, NumberInput,
    Toolbar, SaveButton, ListButton, SelectInput,
    useCreate, useUpdate, DeleteWithConfirmButton, NullableBooleanInput,
    useRecordContext
} from 'react-admin';
import { Grid } from '@mui/material';

const CardTypes = [
    { id: 0, name: "VISA" },
    { id: 1, name: "MASTER" },
    { id: 2, name: "EXPRESS" },
    { id: 3, name: "DISCOVERY" },
];

const ExpMonths = Array.from({ length: 12 }, (item, index) => {
    return { id: index + 1, name: index + 1 };
});

const ExpYears = Array.from({ length: 10 }, (item, index) => {
    let year = new Date().getFullYear() + index + 1;
    return { id: year, name: year };
});

const PostForm = ({ objFilter }) => {
    const isNew = matchPath('/primaryaccount/create/*', location.pathname);
    const Form = () => {
        const record = useRecordContext();
        const redirect = useRedirect();
        const notify = useNotify();
        const refresh = useRefresh();
        const [updating] = !isNew ? useUpdate() : useCreate();

        const handleSubmit = (data) => {
            updating('PrimaryAccount', { id: data.id, data: data },
                {
                    onSuccess: () => {
                        notify('Changes updated', { type: 'success' });
                        redirect('/PrimaryAccount');
                        refresh();
                    },
                    onError: (error) => {
                        notify(`Save fail: ${error.message}`, { type: 'error' });
                    }
                }
            );
        };

        const MyToolbar = () => {
            return (
                <Toolbar>
                    <SaveButton />
                    <ListButton />
                    {
                        !isNew && <DeleteWithConfirmButton translateOptions={{ name: record.tmUsername }} />
                    }
                </Toolbar>
            );
        };
        return (
            <TabbedForm defaultValues={({ market: 0 })} toolbar={<MyToolbar />} mode="onBlur" reValidateMode="onBlur" onSubmit={handleSubmit}>
                <TabbedForm.Tab label="Account">
                    <Grid container columnSpacing={2}>
                        <Grid item xs={6}>
                            <TextInput source="tmUsername" validate={required()} resettable />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput source="tmPassword" resettable />
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={4}>
                            <TextInput source="proxy" resettable />
                        </Grid>
                        <Grid item xs={4}>
                            <NumberInput source="priority" />
                        </Grid>
                        <Grid item xs={4}>
                            <NumberInput source="frequence" />
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={6}>
                            <SelectInput validate={required()} label="Primary market" source="market"
                                choices={objFilter.PrimaryMarkets}
                                translateChoice={false} resettable />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput source="display_Name" resettable />
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={6}>
                            <SelectInput label="Client ID" source="clientId" choices={objFilter.ClientIds}
                                translateChoice={false} resettable />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput label="Remote (TV/AD)" source="remoteID" resettable />
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12}>
                            <TextInput source="note" multiline resettable />
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={4}>
                            <NullableBooleanInput label="Allow AP" source="allowAP" />
                        </Grid>
                        <Grid item xs={4}>
                            <NullableBooleanInput source="allowPurchase" />
                        </Grid>
                        <Grid item xs={4}>
                            <NullableBooleanInput source="allowTransfer" />
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Credit Card Information">
                    <Grid container columnSpacing={2}>
                        <Grid item xs={4}>
                            <TextInput label="CC" source="cc" resettable />
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput label="CVV" source="cvvCode" resettable />
                        </Grid>
                        <Grid item xs={4}>
                            <SelectInput label="TYPE" source="card_Type" choices={CardTypes} translateChoice={false} resettable />
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={6}>
                            <SelectInput label="Exp. month" source="card_Month" choices={ExpMonths} translateChoice={false} resettable />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectInput label="Year" source="card_Year" choices={ExpYears} translateChoice={false} resettable />
                        </Grid>
                    </Grid>

                    <Grid container columnSpacing={2}>
                        <Grid item xs={6}>
                            <TextInput source="firstName" resettable />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput source="lastName" resettable />
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={6}>
                            <TextInput source="address" resettable />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput source="country" resettable />
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={6}>
                            <TextInput source="city" resettable />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput source="state" />
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={6}>
                            <TextInput source="zip" resettable />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput source="phone" resettable />
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
            </TabbedForm>
        );
    };

    return isNew ? (<Create ><Form /></Create>) : (<Edit ><Form /></Edit>);
};

export default PostForm;