import React, { useEffect, useRef } from "react";
import { Datagrid, FunctionField, TextField } from "react-admin";
import { Link } from "@mui/material";
import RaList from "../../components/ra-list/RaList";
import RenderControls from "../../components/ra-list/RenderControls";
import { formatDateTime } from "../../components/ex_dayjs";

const TMProcessingReport = () => {
    const refbtnFilter = useRef(null);

    useEffect(() => {
        refbtnFilter.current.click();
    }, []);

    const processingFormatter = (record) => {
        var tickets = record.tickets || [];
        var steps = tickets.reduce((m, x) => {
            if (!m[x.section]) {
                m[x.section] = [];
            }

            m[x.section].push({
                orderNumber: x.orderNumber,
                row: x.row,
                qty: x.qty,
                amount: x.amount
            });

            return m;
        }, {});

        var display = '<ul>';
        for (var section in steps) {
            display += `<li>Section: ${section}`;
            display += steps[section].map(row => {
                return `<br/>- Order#: ${row.orderNumber} -> Row: ${row.row} Qty: ${row.qty}, Price: ${row.amount}`;
            }).join('<br/>');
            display += '</li>';
        }
        display += '</ul>';
        return { __html: display };
    };

    return (
        <RaList
            title={"TM Processing Report"}
            perPage={50}
            filters={<RenderControls refFilter={refbtnFilter}
                exportFile={{
                    filename: `TMProcessingReport-${formatDateTime(new Date(), 'YY-MM-DD')}`,
                    columns: ['Event', 'Tickets', 'TotalQty', 'TotalAmount', 'id']
                }}
            />}
        >
            <Datagrid
                optimized
                bulkActionButtons={false}
            >
                <FunctionField source="event" render={(record) => (
                    <>
                        <Link target="_blank" href={record.url}>{record.event}</Link>
                        <br />
                        <span>{record.venue}</span>
                    </>
                )} />
                <FunctionField source="tickets" label="Processing" render={(record) => <div dangerouslySetInnerHTML={processingFormatter(record)}></div>} sortable={false} />
                <TextField source="totalQty" label="Total Qty" />
                <TextField source="totalAmount" label="Total Amount" />
                <TextField source="id" label="Candidate Account" />
            </Datagrid>
        </RaList>
    );
};

const TMProcessingReportPage = {
    name: 'AccountReport',
    list: TMProcessingReport
};
export default TMProcessingReportPage;