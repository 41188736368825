import React, { } from 'react'
import { Toolbar, SimpleForm, TextInput, SaveButton, useDataProvider } from 'react-admin';
import { useMutation } from '@tanstack/react-query';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';


const Launch = async (dataProvider, url, data) => {
    let fingerprint = await exec(url, 'generate', { "deviceCategory": "desktop", "platform": "Win32", "vendor": "Google Inc." });
    fingerprint.proxy = data.proxy;
    fingerprint.startUrl = data.startUrl;
    return await exec(url, 'launch', fingerprint);
    async function exec(url, path, data) {
        let response = await dataProvider.fetch(new URL(path, url).toString(), {
            method: 'POST',
            body: JSON.stringify(data)
        });
        return response.json;
    }
};
const LaunchToolbar = props => (
    <Toolbar {...props} >
        <SaveButton label='Launch' alwaysEnable={!props.disabled} icon={<RocketLaunchIcon />} />
    </Toolbar>
);
interface Props {
    serviceUrl: string;
}
const LaunchForm = (props: Props) => {
    const dataProvider = useDataProvider();
    const { mutate, isPending } = useMutation({
        mutationFn: (data) => Launch(dataProvider, props.serviceUrl, data)
    });
    return <SimpleForm onSubmit={mutate.bind(window)} toolbar={<LaunchToolbar disabled={isPending} />}>
        <TextInput source="proxy" placeholder='123.466.123.456:3128' />
        <TextInput source="startUrl" defaultValue={'https://fingerprint.com/demo/'} />
    </SimpleForm >
};

export default LaunchForm;