import { useState } from "react";
import React from "react";
import Checkbox from '@mui/material/Checkbox';

const CheckBoxHeader = (props) =>{
    const [highlight, setHighLight] = useState(true);
    const hanldeClick = () => {
        setHighLight(!highlight);
        props.SetHighLight(!highlight);
    }
    return (
      <div className="custom-group-header">
        <span>{props.displayName}</span> 
        <Checkbox checked={highlight} sx={{padding:0}} onClick={hanldeClick}/>
      </div>
    );
  }

export default CheckBoxHeader;