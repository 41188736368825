
    // This file was automatically generated from the node script: ~/frontent/tools/KnownDomains/index.js
    // Don't edit this file manually
    const KnownDomains ={
        accesso: /(cityboxoffice\.com|ridgefieldplayhouse\.org|hardrockcasinotulsa\.com|marincenter\.org|avalontheatre\.com|seattlethunderbirds\.com|southernct\.edu|riverspirittulsa\.com|showare\.com|tarrytownmusichall\.org|accessoshowarecenter\.com|thefair\.com|luhrscenterboxoffice\.com|snotickets\.com|thetripledoor\.net|casinoregina\.com)/,
arttix: /(kyopera\.org|marylandhall\.org|bso\.org|filmlinc\.org|basshall\.com|mayoarts\.org|scottsdalearts\.org|theshedd\.org|mirvish\.com|thecarsoncenter\.org|austinsymphony\.org|carnegiehall\.org|dallassymphony\.org|nycballet\.com|dso\.org|galloarts\.org|guthrietheater\.org|lensic\.org|nyu\.edu|orsymphony\.org|roundhousetheatre\.org|scfta\.org|steppenwolf\.org|ticketomaha\.com|artisnaples\.org|bsomusic\.org|drphillipscenter\.org|flynnvt\.org|vancouversymphony\.ca|jaxsymphony\.org|seattlesymphony\.org|arttix\.org|handelandhaydn\.org|coloradosymphony\.org|mondaviarts\.org|overture\.org|phoenixsymphony\.org|stanfordlivetickets\.org|tso\.ca|usuo\.org|arenastage\.org|lutherburbankcenter\.org|houstonsymphony\.org|jazz\.org|kauffmancenter\.org|kentuckyperformingarts\.org|nyphil\.org|strathmore\.org|minnesotaorchestra\.org|cso\.org|mhrth\.com|thehobbycenter\.org|liedcenter\.org|granadasb\.org|dacamera\.com|slso\.org|thegrandwilmington\.org|louisvilleorchestra\.org|chambermusicsociety\.org|louisvilleballet\.org|coc\.ca|austinopera\.org|auditoriumtheatre\.org|signaturetheatre\.org|tuts\.com|pnb\.org|fwsymphony\.org|coloradoballet\.org|balletaustin\.org|centerstage\.org|centertheatregroup\.org|armoryonpark\.org|cincyplay\.com|bostonballet\.org|cathedralchoralsociety\.org|cathedral\.org)/,
axs: /(axs\.com)/,
benedum: /(trustarts\.org)/,
carolinatix: /(secutix\.com|kravis\.org|carolinatix\.org|mesaartscenter\.com|gocrimson\.com|thehanovertheatre\.org|tacomaartslive\.org|amtshows\.com|kelownaactorsstudio\.com|uiowa\.edu|thegrand\.com|ordway\.org|denvercenter\.org|sonoma\.edu|mesaamp\.com)/,
etix: /(etix\.com)/,
eventbrite: /(eventbrite\.com|eventbrite\.ca|eventbrite\.co\.uk|eventbrite\.hk|eventbrite\.fr|eventbrite\.com\.au|eventbrite\.com\.mx|eventbrite\.es)/,
Exact: /(ticketmaster\.com\.mx|ticketmaster\.com|ticketmaster\.ca|livenation\.com)/,
kennedy: /(basshall\.com|kennedy-center\.org|goodmantheatre\.org|sfjazz\.org|arshtcenter\.org|carnegiehall\.org|philorch\.org|metopera\.org|strathmore\.org|steppenwolf\.org|clevelandorchestra\.com|theoldglobe\.org|nycitycenter\.org|kimmelculturalcampus\.org|chicagoshakes\.com)/,
paciolan: /(evenue\.net|ticketalternative\.com)/,
spektrix: /(vrcfa\.com|rcmusic\.com|symphonyspace\.org|parkerarts\.org|calgaryphil\.com|rotarycentreforthearts\.com|lebowskycenter\.com|foxtucson\.com|cincyshakes\.com|gaillardcenter\.org|musicacademy\.org|capitoltheatre\.org|delphioperahouse\.org|caramoor\.org|www\.arshtcenter\.orgnull|arshtcenter\.org)/,
tdc: /(tickets\.com)/,
tele: /(telecharge\.com)/,
Tele: /(telecharge\.com)/,
ticketforce: /(force\.com|salesforce-sites\.com)/,
ticketweb: /(ticketweb\.com)/
    }
    module.exports = KnownDomains;