import React, { useState } from 'react';
import { RecordContextProvider, DatagridBody } from 'react-admin';
import { TableRow, TableCell, Divider } from '@mui/material';

const MyDatagridRow = ({ record, id, onToggleItem, children, ...props }) => {
    const emptyFields = ['event_id', 'event_name', 'venue_name', 'event_date', 'sevenDayDiff', 'tnInventory'];
    const splitFields = ['yesterday', 'twoDay', 'threeDay', 'fiveDay', 'sevenDay', 'avgSale', 'totalSales'];
    return (
        <RecordContextProvider value={record}>
            <TableRow hover={true}>
                {React.Children.map(children, field => (
                    <TableCell
                        key={`${id}-${field.props.source}`}
                        style={{ textAlign: typeof (record[field.props.source]) === 'number' ? 'right' : '' }}
                    >
                        {field}
                        {splitFields.includes(field.props.source) ?
                            <><Divider />
                                <span style={{ color: 'red', textAlign: typeof (record[field.props.source]) === 'number' ? 'right' : '' }}>
                                    {record[field.props.source + 'Ex'] ?? 0}
                                </span>
                            </>
                            : ''
                        }
                    </TableCell>
                ))}
            </TableRow>
        </RecordContextProvider>
    )
};
const TnCustomBody = (props) => {
    return (
        <DatagridBody {...props} row={<MyDatagridRow grouping={props.grouping} />} key={new Date()} />
    )
}
export default React.memo(TnCustomBody);