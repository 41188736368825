
import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


const TagSelector = ({ removeCheck, setRemoveCheck }) => {
    return (
        <>
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={removeCheck}
                onChange={(e) => setRemoveCheck(e.target.value)}
            >
                <FormControlLabel value='remove' control={<Radio />} label="Remove row matched from the result" />
                <FormControlLabel value='modify' control={<Radio />} label="Modify them as" />
            </RadioGroup>
        </>
    );
}

export default TagSelector;