import { getRaStore, setRaStore } from "../../utils/common";

function getDictionary(action, dataProvider, callback) {
    let dictionary = getRaStore(action);
    if (dictionary) {
        callback(dictionary);
    } else {
        dataProvider
            .fetchData('AAT_PrimaryAccount', action)
            .then((response) => {
                if (response.status !== 200 && response.data) {
                    let enumData = Object.entries(response.data).map(([value, name]) => ({ id: value, name: name }));
                    callback(enumData);
                    setRaStore(action, enumData);
                }
            })
            .catch(console.error);
    }
}

export {
    getDictionary
}