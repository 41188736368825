import React, { useState, useRef } from 'react';
import OrderBase from '../order-base';
import { create as createSpecificVendor } from '../order-base/vendor';


const VividOrder = () => {
    const specificVendor = createSpecificVendor('Vivid'); 
    return (
        <OrderBase     
            vendor={specificVendor}
        >

        </OrderBase>
    )
}
const VividOrderPage = {
    name: 'Vivid',
    list: VividOrder
}
export default VividOrderPage;