import React, { useState, useRef } from "react";
import { dayjs, formatLocalTime, toLocalTime } from "../../components/ex_dayjs";
import { BooleanField, DatagridConfigurable, FunctionField, TextField, useDataProvider } from "react-admin";
import RaList from "../../components/ra-list/RaList";
import RenderControls from "../../components/ra-list/RenderControls";
import { convertDataForMSelect, formatEnumToName, getRaStore, setDefaultValuesFromURL, setRaStore } from '../../utils/common';
import ProcessError from "./component/process-error";
import DetailsView from "./component/details-view";

const ProcessTrackingReport = () => {
    const dataProvider = useDataProvider();
    const refbtnFilter = useRef(null);
    const [defaultSort, setDefaultSort] = useState(['utc_Posted_On desc']);
    const [isLoadingCategory, setIsLoadingCategory] = useState(false);
    const [allTypes, setAllTypes] = useState(null);
    const [errorList, setErrorList] = useState([]);
    const [detailPopup, setDetailPopup] = useState({open: false});
    const [defaultValueFilter, setDefaultValuesFilter] = useState({
        utc_Posted_On: [dayjs().startOf('M'), dayjs()]
    });

    const handleMarketChange = (param) => {
        if (param.value == '') {
            setErrorList(allTypes.errors);
        } else {
            setErrorList(allTypes.errors.filter(x => x.split(':')[0]?.toLowerCase().trim() == param.value));
        }
    }

    const controls = [
        { component: 'select', name: 'type_eq', label: 'Market', choices: allTypes?.market || [], onChange: (handleMarketChange) },
        { component: 'daterange', name: 'utc_Posted_On', label: 'Date', xs: 3, isUTC: true },
        { component: 'text', name: 'event', label: 'Event', xs: 3 },
        { component: 'text', name: 'venue', label: 'Venue', xs: 3 },        
        { component: 'number', name: 'Order_Number_eq', label: 'Order Number', xs: 2 },
        { component: 'text', name: 'ip', label: 'IP', xs: 1 },
        { component: 'select', name: 'Ip_Type_eq', label: 'IP Type', xs: 1.5, choices: allTypes?.ipTypes || [] },
        { component: 'select', name: 'Browser_eq', label: 'Browser', choices: allTypes?.browserTypes || [] },
        { component: 'select', name: 'Account_Type_eq', label: 'Account Type', choices: allTypes?.accountTypes || [] },
        { component: 'autocomplete', name: 'Error_eq', label: 'Error', xs: 4, choices: errorList },
    ]

    const handleRowClick = (id, resource, record) => {
        setDetailPopup(prev => ({...prev, open: true, data: record}));
    }

    React.useEffect(() => {
        let _orderProcessTypes = getRaStore('orderProcessTypes');
        if (_orderProcessTypes == null) {
            setIsLoadingCategory(true);
            dataProvider.fetchData('ProcessTrackingReport', 'GetAllTypes').then((response) => {
                if (response.status !== 200 && response.data) {
                    let accountTypes = convertDataForMSelect(response.data.accountTypes)
                    let ipTypes = convertDataForMSelect(response.data.ipTypes, false);
                    let browserTypes = convertDataForMSelect(response.data.browserTypes, false);
                    let errors = Object.keys(response.data.errors).sort();
                    let market = convertDataForMSelect(response.data.market, false);
                    setErrorList(errors);
                    setAllTypes({ market, ipTypes, accountTypes, browserTypes, errors });
                    setRaStore('orderProcessTypes', { market, ipTypes, accountTypes, browserTypes, errors })

                    setIsLoadingCategory(false);                                      
                }
            }).catch((error) => console.log(error));
        } else {
            setAllTypes(_orderProcessTypes);
            setErrorList(_orderProcessTypes.errors);          
        }

        if (location.search) {
            var newDefaultValue = {...defaultValueFilter};
            setDefaultValuesFromURL(location.search, newDefaultValue, controls);        
            setDefaultValuesFilter(newDefaultValue);                      
        }       
    }, [])

    React.useEffect(() =>{
        if (defaultValueFilter && allTypes) {
            if(Object.keys(defaultValueFilter).find(x => x == 'type_eq')){
                setErrorList(allTypes.errors.filter(x => x.split(':')[0]?.toLowerCase().trim() == defaultValueFilter['type_eq']));
            }
            // setTimeout(() => refbtnFilter.current.click(), 500);
        }        
    }, [defaultValueFilter, allTypes]);

    return (
        <>
            <RaList
                sortby={defaultSort}
                perPage={25}              
                title={'Process Tracking Report'}                
                filters={
                    <RenderControls
                        controls={controls}
                        refFilter={refbtnFilter}
                        defaultValues={defaultValueFilter}
                        useHashUrl
                        selectColumn={true}
                        actions={allTypes?.errors.length > 0 && <ProcessError errorList={allTypes?.errors} market={allTypes.market} setErrorList={setErrorList} setAllTypes={setAllTypes} />}
                        exportFile={{
                            filename: `ProcessTrackingReport-${formatLocalTime(new Date(), 'YY-MM-DD')}`,
                            columns: ['Type', 'Order_Number', 'Event', 'Venue', 'Ip', 'Ip_Type', 'Browser', 'Incognito_Browser', 'Card_Brand_Name', 'Same_Billing_Venue',
                                'Other_State','Primary_Account', 'Account_Type_Name', 'Error', 'Remote_Comp'],
                            limit: 1000000
                        }}
                    />
                }
            >
                {isLoadingCategory && <p>Loading category ...</p>}
                <DatagridConfigurable omit={['order_Number', 'venue', 'incognito_Browser', 'other_State','account_Type_Name']}
                    bulkActionButtons={false}
                    rowClick={(handleRowClick)}                    
                >                  
                    <TextField source="type" label={'Market'} />
                    <TextField source="order_Number" label={'Order Num'} />                   
                    <TextField source="event" label={'Event'} />                   
                    <TextField source="venue" label={'Venue'} />                   
                    <TextField source="ip" label={'IP'} />
                    <TextField source="ip_Type_Name" label={'IP Type'} />
                    <TextField source="browser_Name" label={'Browser'} />
                    <BooleanField source="incognito_Browser" label={'Incognito Browser'} textAlign="center" />
                    <TextField source="card_Brand_Name" label={'Card Brand'} />
                    <BooleanField source="same_Billing_Venue" label={'Same Billing / Venue Location'} textAlign="center" />
                    <TextField source="other_State" label={'Other State'} />                   
                    <TextField source="primary_Account" label={'Primary Account'} />
                    <FunctionField source="account_Type_Name" label={'Account Type'} render={record => formatEnumToName(record.account_Type_Name)}/>
                    <TextField source="error" label={'Error'} />
                    <BooleanField source="remote_Comp" label={'Remote Comp'} textAlign="center"/>
                    <TextField source="author" label={'Author'} />
                    <FunctionField label='Date' source='utc_Posted_On' render={record => (                       
                        <><b>{toLocalTime(record.utc_Posted_On).format('MM/DD/YYYY')}</b>
                        <br />{toLocalTime(record.utc_Posted_On).format('hh:mm A')}</>)} 
                    />                    
                </DatagridConfigurable>
                {detailPopup.data && <DetailsView detailPopup={detailPopup} setDetailPopup={setDetailPopup}/>}
            </RaList >
        </>
    );

}

const ProcessTrackingReportPage = {
    name: 'ProcessTrackingReport',
    list: ProcessTrackingReport
}
export default ProcessTrackingReportPage;