import React, { useState } from 'react';
import RaList from '../../components/ra-list/RaList';
import RenderControls from '../../components/ra-list/RenderControls';
import { Datagrid, TextField, FunctionField, NumberField, useDataProvider } from 'react-admin';
import { dayjs, formatDateTime, formatLocalTime } from '../../components/ex_dayjs';
import CustomCellColor from './components/custom-cell-color';

const TNHourlyData = () => {

    const dataProvider = useDataProvider();
    const [defaultSort, setDefaultSort] = useState(['eventDate desc']);
    const [lastUpdate, setLastUpdate] = useState(undefined);
   
    React.useEffect(() => {
        dataProvider.fetchData('TNHourlyData', 'LastUpdate').then((response) => {
            if (response.status !== 200 && response.data) {
                setLastUpdate(dayjs.utc(response.data).fromNow());
            }
        }).catch((error) => console.log(error));
    }, [])

    const controls = [
        { component: 'number', name: 'days_in_futures_eq', label: 'Days in Future' },
        { component: 'number', name: 'EventID', label: 'Event ID' },
        { component: 'text', name: 'Event', label: 'Event', xs: 2 },
        { component: 'text', name: 'Venue', label: 'Venue', xs: 2 },
        { component: 'daterange', name: 'EventDate', label: 'Event Date' },
    ]

    return (
        <>
            <RaList
                sortby={defaultSort}
                perPage={25}
                title={'TN Hourly Data'}
                filters={
                    <RenderControls
                        controls={controls}                   
                        exportFile={{
                            filename: `TNHourlyData-${formatLocalTime(new Date(), 'YY-MM-DD')}`,
                            columns: ['EventID', 'Event', 'Venue', 'EventDate', 'NumOrders', 'sold_1_hour', 'sold_2_hours',
                             'sold_4_hours', 'sold_8_hours', 'sold_16_hours', 'orders_by_pos'],
                            limit: 1000000
                        }}
                    />
                }
            >
                {lastUpdate && <p>Last updated {lastUpdate}</p>}
                <Datagrid bulkActionButtons={false} body={<CustomCellColor />}>
                    <TextField source="eventID" label={'Event ID'} />
                    <TextField source="event" label={'Event'} />
                    <TextField source="venue" label={'Venue'} />
                    <FunctionField label='EventDate' source='eventDate' render={record => (formatDateTime(record.eventDate))} />
                    <NumberField source="numOrders" label={'Num Orders'} />
                    <NumberField source="sold_1_hour" />
                    <NumberField source="sold_2_hours" />
                    <NumberField source="sold_4_hours" />
                    <NumberField source="sold_8_hours" />
                    <NumberField source="sold_16_hours" />
                    <NumberField source="orders_by_pos" />
                </Datagrid>
            </RaList >
        </>
    );

}
export default TNHourlyData;