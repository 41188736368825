import React, { useState } from 'react';
import { Button } from '@mui/material';
import { tnMode } from '../common';
const NormalWithStatusButton = (props) => {
    const { setInfo, info, setDefaultValues } = props;

    const handleStatus = (ev) => {      
        setInfo((prev) => ({
            ...prev, mode: info.mode == tnMode.normalwithstatus ? tnMode.normal : tnMode.normalwithstatus,
            title: info.mode == tnMode.normalwithstatus ? 'TN Sales Analysis' :'TN Sales Analysis (With Status)'
        }));    
        setDefaultValues((prev) => ({ ...prev, user_mode_eq: info.mode == tnMode.normalwithstatus ? tnMode.normal : tnMode.normalwithstatus }))                
    }
    return (
        <Button onClick={handleStatus}
            color={info.mode == tnMode.normalwithstatus ? 'success' : 'info'}
            variant={info.mode == tnMode.normalwithstatus ? 'contained' : 'outlined'}
        >Normal with status</Button>
    )
}
export default NormalWithStatusButton