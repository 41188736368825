import * as React from 'react';
import RenderControls from '../../../components/ra-list/RenderControls';
import { TeamOptions } from '../../../utils/enum';
import { formatLocalTime } from '../../../components/ex_dayjs';
import { getDefaultUtcOffset } from '../../../utils/common';
const Filter = ({ type, setType, handleSearch, teamId }) => {

  const lengthOfTime = {
    startDate: new Date(),
    endDate: new Date()
  };
  const defaultFilter = {
    period: [lengthOfTime.startDate, lengthOfTime.endDate],
    teamId: teamId,
    utcOffset: getDefaultUtcOffset()
  };

  const handleChange = (event) => {
    setType(event.value);
  };

  const [defaultValues, setDefaultValues] = React.useState({
    period: [lengthOfTime.startDate, lengthOfTime.endDate],
    teamId: teamId
  });

  const renderFilter = [
    { component: 'daterange', name: 'period', label: 'Date' },
    {
      component: 'select', name: 'teamId', label: 'Team', xs: 1,
      choices: TeamOptions,
      disabled: teamId !== 0
    },
    { component: 'radio', label: 'Chart Type', choices: [{ id: 'bar', name: 'Bar' }, { id: 'pie', name: 'Pie' }], defaultValue: type, onChange: handleChange },
  ];
  React.useEffect(() => {
    setDefaultValues(defaultFilter);

  }, [teamId]);
  return (
    <>
      <RenderControls
        controls={renderFilter}
        onSearch={handleSearch}
        defaultValues={defaultValues}
        agSource={'UserDashboard'}
        agSort={{ field: 'id', order: 'asc' }}
        exportFile={{
          filename: `UserDashboard-${formatLocalTime(new Date(), 'YY-MM-DD')}`,
          columns: ['id', 'Name', 'Processed', 'A_ProcessingFailed', 'Unprocessed', 'IHD', 'General', 'Any', 'ProcessingFailed'],
          limit: 1000000
        }}
      />
    </>
  );
};
export default Filter;