import React from 'react';
import { useRecordContext } from 'react-admin';
import { dayjs } from '../../../components/ex_dayjs';
import { Box } from '@mui/material';
const NoteField = (props) => {
    const { notesData } = props;
    const record = useRecordContext();

    if (!record || record.lastNotes?.length == 0) return null;
  
    let arr = record.lastNotes;
    if (notesData.data != null && notesData.recordId == record.id) {
        arr = notesData.data;
    }
    return (
        <Box>
            {arr?.map(item => (
                <><b>{item.firstName}</b>{' » ' + item.note + " ("}{dayjs.utc(item.insertedDate).fromNow() + ')'}<br /> </>
            ))}
        </Box>
    )
}
export default React.memo(NoteField);