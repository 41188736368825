import * as React from "react";
import {
    TopToolbar,
    FilterButton,
    CreateButton,
    List, Datagrid, useListContext,
    TextField, NumberField, BooleanField,
    TextInput, NumberInput, SelectInput, NullableBooleanInput, SelectField, FunctionField
} from 'react-admin';
import PostForm from "./Form";
import _exporter from "../../exporter";
import { formatLocalTime } from "../../../components/ex_dayjs";
import { RaPagination } from "../../../components/ra-list/RaList";
import RaExporter from "../../../components/ra-list/RaExporter";

const objFilter = {
    PrimaryMarkets: [
        { id: 0, name: "Ticketmaster" },
        { id: 2, name: "3TM" },
    ],
    ClientIds: [
        { id: 'VIVID_EL_A', name: "VIVID_EL_A" },
        { id: 'VIVID_EL_B', name: "VIVID_EL_B" },
        { id: 'VIVID_EL_C', name: "VIVID_EL_C" },
        { id: 'VIVID_EL_D', name: "VIVID_EL_D" },
        { id: 'EL2', name: "EL2" },
        { id: 'SPEC 1', name: "SPEC 1" },
        { id: 'POS3N', name: "POS3N" },
        { id: 'POS3N_A', name: "POS3N_A" },
        { id: 'POS3N_B', name: "POS3N_B" },
        { id: 'POS4', name: "POS4" },
        { id: 'LM', name: "VIVID_LM" },
    ]
};

const FormView = () => <PostForm objFilter={objFilter}></PostForm>;

const ListActions = () => {
    const { filter, sort, resource } = useListContext();
    return (
        <TopToolbar>
            <FilterButton />
            <CreateButton />
            <RaExporter resource={resource}
                exportFile={{ columns: ['TmUsername', 'TmPassword', 'CvvCode', 'ClientId', 'Proxy', 'AllowAP', 'AllowTransfer', 'Updated', 'UpdatedBy'] }}
                params={{ filter, sort }} />
        </TopToolbar>
    );
};
const postFilters = [
    <TextInput source="tmUsername" alwaysOn />,
    <TextInput source="proxy" alwaysOn />,
    <SelectInput label="Client ID" source="clientId" choices={objFilter.ClientIds} translateChoice={false} alwaysOn />,
    <SelectInput label="Primary market" source="market" choices={objFilter.PrimaryMarkets} translateChoice={false} alwaysOn fullWidth />,
    <NullableBooleanInput label="Allow AP" source="allowAP" alwaysOn />,
    <NullableBooleanInput label="Allow AT" source="allowTransfer" alwaysOn />,
    <NullableBooleanInput source="allowPurchase" />,
    <NumberInput source="priority" />
];

const RAList = () => (
    <List title="Primary accounts"
        storeKey={false}
        disableSyncWithLocation
        exporter={(records) => _exporter(records,
            ['TmUserName', 'TmPassword', 'CvvCode', 'ClientId', 'Proxy', 'AllowAP', 'AllowTransfer', 'Updated', 'UpdatedBy'])}
        perPage={25}
        pagination={<RaPagination />}
        sort={{ field: 'Updated', order: 'DESC' }}
        filters={postFilters}
        actions={<ListActions />}>
        <Datagrid rowClick="edit" bulkActionButtons={false}>
            <TextField source="id" />
            <TextField source="tmUsername" />
            <TextField source="tmPassword" />
            <SelectField source="market" choices={objFilter.PrimaryMarkets} translateChoice={false} />,
            <TextField source="cvvCode" label="CVV" />
            {/* <TextField source="priority" />
            <NumberField source="frequence" /> */}
            <NumberField label="Client ID" source="clientId" />
            <NumberField source="proxy" />
            <BooleanField label="Allow AP" source="allowAP" />
            <BooleanField label="Allow AT" source="allowTransfer" />
            {/* <BooleanField source="allowPurchase" /> */}
            <FunctionField source="updated" render={record => formatLocalTime(record.updated)} />
            < TextField source="updatedBy" />
            {/* <EditButton /> */}
            {/* <DeleteButton /> */}
        </Datagrid>
    </List>
);
const RAPage = {
    name: 'PrimaryAccount',
    create: FormView,
    edit: FormView,
    list: RAList
};
export default RAPage;