import * as React from 'react';
import MDialog from "../../../../../components/controls/MDialog";
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, Grid, IconButton, InputAdornment, LinearProgress, TextField, Typography } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { formatDateTime, formatLocalTime } from '../../../../../components/ex_dayjs';
import MAutoComplete from '../../../../../components/controls/MAutoComplete';
import { useNotify } from 'react-admin';

const SuggestAccountsPopup = ({ vendor, dataProvider, data, onClose, onChange }) => {
    const { order } = data;
    const { s_isOpen } = data.dlgState;
    const [isLoading, setIsLoading] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [filteredRows, setFilteredRows] = React.useState([]);
    const [rowSelected, setRowSelected] = React.useState();
    const [isShowEventDate, setShowEventDate] = React.useState(false);
    const [state, setState] = React.useState({
        ruleId: '',
        searchAcct: '',
        categorySelected: '',
        categories: []
    });
    const apiRef = useGridApiRef();
    const searchAcctRef = React.useRef(null);
    const notify = useNotify();

    const initialState = {
        hideColumns: { eventDate: false }
    };

    React.useEffect(() => {
        if (s_isOpen && order) {
            setRows([]);
            setFilteredRows([]);
            setIsLoading(true);
            dataProvider.fetchData(vendor.controller, `SuggestAccounts?orderId=${order.id}`)
                .then((response) => {
                    var result = response.data;
                    if (result.success) {
                        if (result.data.some(e => e.eventDate)) {
                            apiRef.current.setColumnVisibilityModel({});
                            setShowEventDate(true);
                        }
                        setRows(result.data);
                        setFilteredRows(result.data);
                    }
                    let categoryOptions = [...new Set(result.data.map(x => x.category.trim().replace(/\r\n/g, "")))];
                    setState({ ruleId: result.ruleId, categories: categoryOptions });
                    setIsLoading(false);
                })
                .catch(err => {
                    notify(`Get sussgest accounts has error ${err}`, { type: 'error', autoHideDuration: 2000 });
                    setIsLoading(false);
                });
        }
    }, [s_isOpen, order]);

    const columnDefs = [
        { field: 'primaryAccount', headerName: 'Primary Account', flex: 2 },
        { field: 'account_State', headerName: 'State', flex: 0.5 },
        { field: 'category', headerName: 'Category', flex: 0.8 },
        { field: 'account_Tags', headerName: 'Tags', flex: 0.6 },
        { field: 'eventDate', headerName: 'Event Date', valueFormatter: params => { return formatDateTime(params.value); }, flex: 1 },
        { field: 'totalTickets', headerName: 'Total Tickets', flex: 0.5, type: 'number' },
        { field: 'totalOrders', headerName: 'Total Orders', flex: 0.5, type: 'number', cellClassName: 'total-orders highlight' },
        { field: 'limitTickets', headerName: 'Limit Tickets', flex: 0.5, type: 'number' },
        { field: 'limitOrders', headerName: 'Limit Orders', flex: 0.5, type: 'number' },
        { field: 'lastPurchase', headerName: 'Last Purchase', valueFormatter: params => { return !!params.value ? formatLocalTime(params.value) : null; }, flex: 1 },
    ];

    const closeDialog = () => onClose(null, 's_isOpen');

    const onCellClick = (params) => {
        setRowSelected(params.row.primaryAccount);
    }

    const onRowDoubleClick = (params) => {
        onChange(params.row.primaryAccount);
        closeDialog();
    }

    const onSelect = () => {
        onChange(rowSelected);
        closeDialog();
    }

    const onSearchAccount = (e) => {
        const { value } = e.target;
        setState((prev) => ({ ...prev, searchAcct: value }));
    }

    const onChangeCategory = (value) => {
        setState((prev) => ({ ...prev, categorySelected: value }));
    }

    const handleClear = () => {
        setState((prev) => ({ ...prev, searchAcct: '' }));
        searchAcctRef.current.querySelector('input').value = '';
    };


    const onFilter = () => {
        let newSource = rows;
        if (state.searchAcct) {
            newSource = newSource.filter(e => e.primaryAccount.toLowerCase().includes(state.searchAcct.toLowerCase()));
        }
        if (state.categorySelected) {
            newSource = newSource.filter(e => e.category.includes(state.categorySelected));
        }
        setFilteredRows(newSource);
    }

    React.useEffect(() => {
        onFilter();
    }, [state.searchAcct, state.categorySelected]);

    return (
        <MDialog
            className={'order-dialog'}
            name={'s_isOpen'}
            open={s_isOpen}
            title={`Accounts Suggestion #${order?.order_number}`}
            handleClose={onClose}
            maxWidth="lg"
            height={800}
            action={<Button variant="contained" onClick={onSelect}>OK</Button>}
        >
            <Typography style={{ fontWeight: 'bold' }}>Applied by ruleId: {state.ruleId}</Typography>
            <Grid container spacing={2} sx={{ marginBottom: '5px' }}>
                <Grid item xs={6}>
                    <TextField
                        label={'Search by account'}
                        onKeyUp={onSearchAccount}
                        ref={searchAcctRef}
                        disabled={rows.length == 0}
                        InputProps={{
                            endAdornment: state.searchAcct ? (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleClear} edge="end">
                                        <ClearIcon style={{ fontSize: 'large' }} />
                                    </IconButton>
                                </InputAdornment>
                            ) : null,
                        }}
                    />
                </Grid>
                <Grid item container xs={6} >
                    <MAutoComplete
                        label={'Category'}
                        choices={state.categories}
                        isMulti={false}
                        onChange={onChangeCategory}
                        disabled={rows.length == 0} />
                </Grid>
            </Grid>
            {isLoading && <LinearProgress color='secondary' />}
            <Box sx={{
                height: 550, width: '100%',
                [`.total-orders.highlight`]: {
                    fontWeight: 'bold',
                    color: 'chocolate',
                },
            }}>
                <DataGrid
                    apiRef={apiRef}
                    rows={filteredRows}
                    columns={columnDefs}
                    autoPageSize={true}
                    density="compact"
                    getRowId={(x) => isShowEventDate ? x.primaryAccount + '_' + x.eventDate : x.primaryAccount}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 25,
                            },
                        },
                        columns: {
                            columnVisibilityModel: initialState.hideColumns
                        },
                    }}
                    onCellClick={onCellClick}
                    onRowDoubleClick={onRowDoubleClick}
                />
            </Box>
        </MDialog>
    )
}

export default React.memo(SuggestAccountsPopup);