import * as React from "react";
import { Typography } from "@mui/material";
import { selectRule } from "./common";

export const RuleFormatter = ({ record, callback }) => {
    const onSelectRule = (e, id) => {
        e.preventDefault();
        let rule = selectRule(id, record.id, record);
        callback(id, rule);
    }

    const style = { fontSize: '0.875rem', color: 'rgb(0, 123, 255)', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } };

    const links = record.affected_by_rule_IDs.split(',')
        .filter(v => v != '')
        .map((v) => (
            <>
                <Typography key={v} component="a" href="#"
                    onClick={(e) => onSelectRule(e, v)}
                    sx={style}>
                    {v},
                </Typography>
                &nbsp;
            </>
        ));
    return (
        <div>
            {links}
            <Typography key={'new'} component="a" href="#"
                onClick={(e) => onSelectRule(e, -1)}
                sx={style}>
                New
            </Typography>
        </div>
    )
}

RuleFormatter.defaultProps = { label: 'Rule ID' };