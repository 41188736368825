import * as React from 'react';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Modal } from '@mui/material';
import SourceVariableModel from './SourceVariableModel';
import { ExportButton, useDataProvider } from "react-admin";
import { formatDateTime, formatLocalTime } from "../../components/ex_dayjs";
import {
    SelectInput,
    ReferenceInput,
    List,
    BooleanField,
    TextInput,
    TextField,
    NumberField,
    Datagrid,
    CreateButton,
    TopToolbar
} from 'react-admin';
import { RaPagination } from '../../components/ra-list/RaList';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const PostListActions = ({ onCreate, onExport }) => (
    <Box width="100%">
        <TopToolbar>
            <CreateButton onClick={onCreate} />
            <ExportButton exporter={onExport} ></ExportButton>
        </TopToolbar>
    </Box>
);
const SourceVariableList = () => {
    const [openEdit, setOpenEdit] = useState(false);
    const [sourceVariable, setSourceVariable] = useState(null);
    const dataProvider = useDataProvider();
    const navigate = useNavigate();
    const updateSubmitModel = useCallback(() => {
        handleClose();
    }, []);

    const postFilters = [
        <TextInput label="Upstream" source="id" alwaysOn />,
        <ReferenceInput source='sourceType' reference='Common/SoureTypes' sort={{ field: 'name', order: 'ASC' }} alwaysOn >
            <SelectInput optionText="name" /></ReferenceInput>,
        <TextInput source="VariableName" alwaysOn />,
    ];
    const handleRowClick = (id, resource, record) => {
        isEdit = true;
        setOpenEdit(true);
        setSourceVariable(record);
        window.history.replaceState(null, null, `SourceVariable/${id}`);
    };
    const handleCreate = () => {
        isEdit = false;
        setOpenEdit(true);
    };

    const handleClose = useCallback(() => {
        navigate('/SourceVariable');
        setOpenEdit(false);
        setSourceVariable(null);
    }, [navigate]);

    const handleExport = async () => {
        const params = {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000000
            },
            sort: {
                field: ["id asc"]
            }
        };
        const columns = ['id', 'SourceType', 'VariableName', 'IsExact', 'InsertInHoursAgo', 'FromDay', 'Today', 'Active'];
        const filename = `SourceVariable-${formatLocalTime(new Date(), 'YY-MM-DD')}`;
        const exportFile = {
            columns: columns,
            filename: filename
        };
        await dataProvider.export('SourceVariable', params, exportFile)
            .then(async (resp) => {
                if (resp.ok) await downloadFile(resp, exportFile);
            })
            .catch((error) => {
                notify(`Export has error ${error}`, { type: 'error' });
            });

    };

    async function downloadFile(response, exportFile) {
        var contentType = response.headers.get("Content-Type");
        if (contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            var downloadedParts = [];
            const reader = response.body.getReader();
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                downloadedParts.push(value);
            }
            const mergedBlob = new Blob(downloadedParts, { type: contentType });
            const mergedUrl = window.URL.createObjectURL(mergedBlob);
            const downloadLink = document.createElement('a');
            downloadLink.href = mergedUrl;
            downloadLink.download = exportFile.filename;
            downloadLink.click();
        }
    }

    return (
        <Box>
            <List title="Source variables"
                perPage={25}
                pagination={<RaPagination />}
                storeKey={false}
                disableSyncWithLocation
                // sort={{ field: 'Upstream', order: 'ASC' }}
                filters={postFilters}
                actions={<PostListActions onCreate={handleCreate} onExport={handleExport} />}
            >
                <Datagrid bulkActionButtons={false} rowClick={handleRowClick}>
                    <TextField label="Upstream" source="id" />
                    <TextField label="Source Type" source="sourceType" />
                    <TextField label="Variable Name" source="variableName" />
                    <TextField label="Is Exact" source="isExact" />
                    <NumberField label="Insert In Hours Ago" source="insertInHoursAgo" />
                    <NumberField label="From Day" source="fromDay" />
                    <NumberField label="ToDay" source="today" />
                    <BooleanField label="Active" source="active" />
                </Datagrid>
            </List>
            <Modal
                open={openEdit}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {openEdit && <SourceVariableModel isEdit={isEdit} onCancel={handleClose} updateModel={updateSubmitModel} id={sourceVariable?.id} />}
                </Box>
            </Modal>
        </Box>
    );
};
const SourceVariablePage = {
    name: 'SourceVariable',
    list: SourceVariableList,
};

export default SourceVariablePage;
