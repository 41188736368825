import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList, Label } from 'recharts';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useColorScheme } from '@mui/material';
import { calculateDomain, changeColor, totalStackedBarChart } from './common';
import PropTypes from 'prop-types';

const RCBarChart = (props) => {
    const { xAxis, yAxis, seriesList, data, width, height, customTooltip, customLabel, legend, margin, showTotal, yGap, barGap, barSize } = props;
    const CustomTooltip = customTooltip || undefined;
    const CustomLabel = customLabel || undefined;

    var { mode } = useColorScheme();

    var totalStack = totalStackedBarChart(seriesList, data, xAxis.dataKey);
    var domain = calculateDomain(totalStack, yGap, height);

    const DefaultLabel = (props) => {
        const { node, totalStack, showTotal, x, y, width, value, height, fill, name } = props;
        var displayTotal = totalStack.find(obj => obj.stackId == node.stackId && node.dataKey == obj.lastDataKey && obj.xDataKey == name)?.sumStack;
        var total = totalStack.find(obj => obj.xDataKey == name)?.total;
        return (
            <>
                {showTotal && displayTotal &&
                    <text
                        x={x + width / 2}
                        y={y - 15}
                        fill={mode == 'dark' ? 'orange' : 'black'}
                        textAnchor="middle"
                        dominantBaseline="middle"
                    >
                        {displayTotal}
                    </text>}

                {value > 0 && total && <text
                    x={x + width / 2}
                    y={y + height / 2}
                    fill={fill}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{ fontSize: '12px' }}
                >
                    {((value / total) * 100).toFixed(2) + '%'}
                </text>}
            </>
        )
    };

    const DefaultTooltip = (props) => {
        const { active, payload, label } = props;
        if (active && payload && payload.length) {
            return (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 50 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={3}>{label}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {payload.map((row) => (
                                <TableRow key={row.dataKey}>
                                    <TableCell align='right'>
                                        <svg height={6 * 2} width={6 * 2}>
                                            <circle cx={6} cy={6} r={6} style={{ fill: row.fill }} />
                                        </svg>
                                    </TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="right">{row.value}</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        }
        return null;
    };

    const renderColorText = (value, entry, index) => {
        return <span style={{ color: changeColor(mode) }}>{value}</span>
    }

    return <BarChart
        width={width}
        height={height}
        data={data}
        barGap={barGap}    
        barSize={barSize}    
        margin={{
            top: margin.top,
            right: margin.right,
            left: margin.left,
            bottom: margin.bottom,
        }}
    >
        <CartesianGrid strokeDasharray="3 3" />
        {/* https://recharts.org/en-US/api/XAxis */}
        <XAxis {...xAxis} tick={{ fill: changeColor(mode) }} style={{ fontSize: '12px' }}>
            <Label {...xAxis.label} fill={changeColor(mode)} />
        </XAxis>     
        <YAxis {...yAxis} tick={{ fill: changeColor(mode) }} tickCount={domain.length} interval={0} style={{ fontSize: '12px' }}>        
            <Label {...yAxis.label} fill={changeColor(mode)} />
        </YAxis>
        <Tooltip content={CustomTooltip ? <CustomTooltip /> : <DefaultTooltip />} cursor={{ fill: mode == 'dark' ? '#4c4b4c97' : '#ededed97' }} />
        {legend != 'hide' && <Legend {...legend} formatter={renderColorText} />}
        {seriesList.map((obj, index) => {
            return <Bar key={`bar-${index}`} {...obj}>
                <LabelList dataKey={obj.dataKey} {...obj.labelList}
                    content={CustomLabel ? <CustomLabel /> : <DefaultLabel node={obj} totalStack={totalStack} showTotal={showTotal} />}
                />
            </Bar>
        })}
    </BarChart>
}
RCBarChart.defaultProps = {
    xAxis: {},
    yAxis: {},
    seriesList: [],
    data: [],
    width: 1100,
    height: 600,
    legend: { layout: 'horizontal', iconType: 'square' },
    margin: { top: 5, right: 30, left: 20, bottom: 18 },
    showTotal: false,
    yGap: 5,
    barGap: 12,
    barSize: 70
}
RCBarChart.propTypes = {
    /**xAxis contains props for itselft and a prop object contains definition for label
     * Example: xAxis={{ dataKey: 'name', label: { value: 'USER', dy: 10} }}
     * DOC: https://recharts.org/en-US/api/XAxis
     */
    xAxis: PropTypes.object,
    /**yAxis contains props for itselft and a prop object contains definition for label
    * Example: yAxis={{ dataKey: 'name', label: { value: 'USER', dy: 10} }}
    * DOC: https://recharts.org/en-US/api/YAxis
    */
    yAxis: PropTypes.object,
    /**Array contains definition of node will be render in chart 
     * Including props of <Bar> and <LabelList>
     * Ex: seriesList={[
                      { dataKey: 'processingFailed', name: 'Processing Failed', fill: '#f44336', maxBarSize: 60, stackId: 'a', labelList: { fill: 'white' } },
                      { dataKey: 'processed', name: 'Processed', fill: '#4caf50', maxBarSize: 60, stackId: 'a', labelList: { fill: 'white' } },                     ]}
    */
    seriesList: PropTypes.array,
    /**Array of data will be used for render */
    data: PropTypes.array,
    width: PropTypes.number,
    height: PropTypes.number,
    legend: PropTypes.object,
    margin: PropTypes.object,
    /**show or hide total in stacked bar chart */
    showTotal: PropTypes.bool,
    /**gap value between 2 */
    yGap: PropTypes.number
}
export default RCBarChart