import React, { useCallback } from 'react'
import { useListFilterContext } from 'react-admin';
import { highlightWords, getSearchWords } from './helper';

interface Props {
    html: string;
}
/**
 * Highlights specified words within a container element.
 * @param {HTMLElement} container - The container element to search for text nodes.
 * @param {string[]} words - An array of words to highlight.
 * @returns {string} - The updated HTML content of the container element.
 */
function highlightWords(container, words) {
    /** @type {Document} */
    let document = container.getRootNode();
    const walker = document.createTreeWalker(container, NodeFilter.SHOW_TEXT);

    /** @type {Array<{ node: Node, highlightedText: string }>} */
    let replacements = []; // Array to store replacement information

    let currentNode = walker.nextNode();
    while (currentNode) {
        const textContent = currentNode.textContent;
        let highlightedText = textContent;

        // Perform highlighting for each word
        words.forEach((word) => {
            const regex = new RegExp(`\\b(${word})\\b`, 'gi');
            highlightedText = highlightedText.replace(regex, '<mark>$&</mark>');
        });

        if (highlightedText !== textContent) {
            // Store the replacement information
            replacements.push({
                node: currentNode,
                highlightedText: highlightedText
            });
        }

        currentNode = walker.nextNode();
    }

    // Perform DOM updates using the replacement information
    replacements.forEach((replacement) => {
        const parentNode = replacement.node.parentNode;
        const wrapper = document.createElement('div');
        wrapper.innerHTML = replacement.highlightedText;

        const replacementNodes = Array.from(wrapper.childNodes);

        // Insert the replacement nodes before the original text node
        replacementNodes.forEach((replacementNode) =>
            parentNode.insertBefore(replacementNode, replacement.node)
        );

        // Remove the original text node from the DOM
        parentNode.removeChild(replacement.node);
    });

    return container.innerHTML;
}



export const MessageViewer = ({ html, ...props }: Props) => {
    const { filterValues } = useListFilterContext();
    // if (html) {
    //     html = highlightWords(getSearchWords(filterValues), html);
    // }
    const onRefChange = useCallback(iframe => {
        if (!iframe) return;
        iframe.style.height = `${window.screen.height - iframe.offsetTop + 20}px`;
        iframe.onload = function () {
            for (const a of [...iframe.contentDocument.querySelectorAll("a")]) {
                a.setAttribute('rel', 'noopener noreferrer');
                a.setAttribute('target', '_blank');
            }
            var words = getSearchWords(filterValues);
            highlightWords(iframe.contentDocument.body, words);
            setTimeout(() => { // run in next tick
                let h = iframe.contentDocument.body.scrollHeight;
                iframe.style.height = `${h + 30}px`;
            }, 0);
        };

        iframe.srcdoc = html;
    }, [html]);
    return (
        <iframe title='Email Viewer' {...props} ref={onRefChange} style={{ width: "100%", border: "none", overflow: "hidden", background: "white" }}>
        </iframe >
    )//sandbox="allow-same-origin" 
};