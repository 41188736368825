import React, { useState, useRef } from 'react';
import RaList from '../../components/ra-list/RaList';
import RenderControls from '../../components/ra-list/RenderControls';
import { FunctionField, NumberField, TextField, Datagrid, useDataProvider, useListController } from 'react-admin';
import { dayjs, formatLocalTime } from '../../components/ex_dayjs';
import TnCustomBody from './components/tn-custom-body';
import TNOrderDetails from './components/details-view';
import CompetitorChart from './components/competitor-chart';
const TnCompetitiveAnalysis = () => {

    const dataProvider = useDataProvider();
    const { perPage } = useListController();
    const [lastUpdate, setLastUpdate] = useState(undefined);
    const [defaultFilter, setDefaultFilter] = useState({
        data_to_show_eq: 'sales',
        sortMode_eq: 'normal'
    });
    const [defaultSort, setDefaultSort] = useState(['SevenDayDiff desc']);
    const sortArr = ["ThirtyMin", "OneHour", "ThreeHour", "SixHour", "TwelveHour", "Yesterday", "TwoDay", "ThreeDay", "SevenDay", "AvgSale", "TotalSales"];

    React.useEffect(() => {
        dataProvider.fetchData('TnCompetitiveAnalysis', 'LastUpdate').then((response) => {
            if (response.status !== 200 && response.data) {
                setLastUpdate(dayjs.utc(response.data).fromNow());
            }
        }).catch((error) => console.log(error));
    }, [])

    const handleSortModeChange = (ev) => {
        let sortField = defaultSort[0]?.split(' ')[0];
        if (ev.value === 'extended' && defaultSort !== null && sortArr.includes(sortField)) {
            sortField += 'Ex desc';
            setDefaultSort([sortField]);
        }
    }

    const controls = [
        { component: 'text', name: 'event_name_contains', label: 'Event Name' },
        { component: 'text', name: 'venue_name_contains', label: 'Venue Name' },
        { component: 'checkbox', name: 'exclude_last_minute_sales_eq', label: 'Exclude Last Minute Sales', xs: 1.6 },
        { component: 'checkbox', name: 'remove_sports_eq', label: 'Exclude Sports', xs: 1.1 },
        { component: 'radio', name: 'data_to_show_eq', label: 'Show Data', choices: [{ id: 'sales', name: 'Sales' }, { id: 'revenue', name: 'Revenue' }], defaultValue: 'sales', xs: 1.4 },
        {
            component: 'select', name: 'sortMode_eq', label: 'Sort by', onChange: handleSortModeChange,
            choices: [{ id: 'normal', name: 'Normal data' }, { id: 'extended', name: 'Competitor data' }], isEmptyOption: false, defaultValue: 'normal'
        }
    ]

    return (
        <>
            {lastUpdate && <p>Last updated {lastUpdate}</p>}
            <RaList
                sortby={defaultSort}
                perPage={50}
                rowsPerPageOptions={[10, 25, 50, 100, 200]}
                title={'TN Competitive Analysis'}
                filters={
                    <RenderControls
                        controls={controls}
                        defaultValues={defaultFilter}
                        exportFile={{
                            filename: `TnCompetiveAnalysis-${formatLocalTime(new Date(), 'YY-MM-DD hh:mm:ss')}`,
                            columns: ['event_id', 'event_name', 'venue_name', 'event_date', 'Yesterday', 'TwoDay', 'ThreeDay', 'FiveDay', 'SevenDay', 'SevenDayDiff',
                                'TotalSales', 'AvgSale', 'TnInventory', 'RecordType'],
                            limit: perPage,
                            label: 'Export data in current page'
                        }}
                    />
                }
            >
                <Datagrid
                    bulkActionButtons={false}
                    body={<TnCustomBody />}
                >
                    <TNOrderDetails source='event_id' label='Event ID' dataProvider={dataProvider} />
                    <CompetitorChart source="event_name" label={'Event Name'} dataProvider={dataProvider} />
                    <TextField source="venue_name" label={'Venue'} />
                    <FunctionField label='Event Date' source='event_date' render={record => (
                        <><b>{dayjs(record.event_date).format('MM/DD/YYYY')}</b><br />{dayjs(record.event_date).format('hh:mm A')}</>)}
                    />
                    <NumberField source="yesterday" label={'1 Day'} />
                    <NumberField source="twoDay" label={'2 Day'} />
                    <NumberField source="threeDay" label={'3 Day'} />
                    <NumberField source="fiveDay" label={'5 Day'} />
                    <NumberField source="sevenDay" label={'7 Day'} />
                    <NumberField source="sevenDayDiff" label={'Difference'} />
                    <FunctionField source="avgSale" label={'Avg Sale'} render={record => <span>{(Math.round(record.avgSale))}</span>} />
                    <NumberField source="totalSales" label={'Total Sales'} />
                    <NumberField source="tnInventory" label={'TN Inventory'} />
                </Datagrid>
            </RaList >
        </>

    )
}
export default TnCompetitiveAnalysis