import React, { useState } from 'react';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import { useRecordContext } from 'react-admin';
import { LinearProgress } from '@mui/material';
import { formatDateTime } from '../../../components/ex_dayjs';
import MDialog from '../../../components/controls/MDialog';
import RCBarChart from '../../../components/charts/recharts/rcBarChart';
const CompetitorChart = (props) => {
    const { dataProvider } = props;
    const record = useRecordContext();
    const [isLoading, setIsLoading] = useState(false);
    const [openChart, setOpenChart] = useState(false);
    const [chartData, setChartData] = useState(null);
    const [chartWidth, setChartWidth] = useState(1100);
    const handleShowChart = () => {
        setOpenChart(true);
        setIsLoading(true);
        dataProvider.fetchData('TnCompetitiveAnalysis', `TnVersusCompetitorSales/?event_id=${record.event_id}`)
            .then(rs => {
                setIsLoading(false);
                if (rs.data) {
                    if (rs.data.length > 90) {
                        setChartWidth(1800);
                    } else if (rs.data.length > 180) {
                        setChartWidth(2700);
                    }
                    setChartData(rs.data.map(x => ({ date_start: formatDateTime(x.date_start, 'MM/DD/YYYY'), "tn_sales": x.tn_sales, "competitor_sales": x.competitor_sales })));
                }
            })
            .catch(error => {
                setIsLoading(false);
                notify(`Load Chart failed: Status ${error.status}, Error ${error.body}`, { type: 'error', autoHideDuration: 2000 })
            })
    }
    const handleCloseChart = () => {
        setOpenChart(false);
    }
    return (
        <>
            <div style={{ display: 'flex', cursor: 'pointer' }} onClick={handleShowChart}>
                <BarChartRoundedIcon style={{ display: 'inline-block' }} />
                <span style={{ display: 'inline-block', marginTop: 3 }}>{record.event_name}</span>
            </div>
            <MDialog
                maxWidth='lg'
                open={openChart}
                handleClose={handleCloseChart}
                title={record.event_name}
                name={'tn-competitive-chart'}
                children={
                    <div style={{ height: 530, textAlign: 'center' }}>
                        <b style={{ fontSize: 18 }}>{record.venue_name + ' - ' + formatDateTime(record.event_date, 'MM/DD/YYYY')}</b>
                        {isLoading && <LinearProgress color='secondary' />}
                        {chartData && <RCBarChart
                            width={chartWidth}
                            height={500}
                            data={chartData}
                            xAxis={{ dataKey: 'date_start' }}
                            yAxis={{ position: 'insideLeft', label: { value: '#SALES', angle: -90, dx: -10 } }}
                            legend={{ verticalAlign: 'bottom' }}
                            seriesList={[
                                { dataKey: 'tn_sales', name: 'TN Sales', fill: '#038ffb', maxBarSize: 40, stackId: 'a', labelList: { fill: 'white' } },
                                { dataKey: 'competitor_sales', name: 'Competitor Sales', fill: '#fd4560', maxBarSize: 40, stackId: 'a', labelList: { fill: 'white' } }
                            ]}
                        >
                        </RCBarChart>}
                    </div>
                }
            />
        </>
    )
}
export default CompetitorChart