import { RaKeys, getRaStore, setRaStore } from "./utils/common";

// Using Caching the response data to avoid making redundant requests to the server
export const cachedDatas = new Map();

export function setCachedData(resource, data) {
    var storeKey = `${resource}.${RaKeys.isSearch}`;
    var isSearch = getRaStore(storeKey);
    if (isSearch == null) return;
    setRaStore(storeKey, false);
    setRaStore(`${resource}.${RaKeys.isCompleteSearch}`, true);
    cachedDatas.set(resource, data);
}

export function getCachedData(resource) {
    var cachedData = cachedDatas.get(resource);
    if (cachedData) return cachedData;
    return {
        data: [],
        total: 0
    };
}