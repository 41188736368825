import React, { useEffect, useState } from "react";
import { useFormContext } from 'react-hook-form';
import { Create, SaveButton, SelectInput, SimpleForm, TextInput, required } from "react-admin";
import { Button, Chip, Grid, Typography } from "@mui/material";
import MDialog from "../../../../../components/controls/MDialog";

const tagsTracking = ['Processing Mistake', 'Shipping Mistake', 'Mapping Error'];

function getTagsTracking(order, oldTags) {
    var newTags = order.tags;
    const arrCurrentTags = (oldTags || ",").split(",");
    const arrTags = (newTags || ",").split(",");
    const addedTags = arrTags.filter(tag => !arrCurrentTags.includes(tag));
    return addedTags;
}

export const onConfigTrackingMapping = (order, oldTags) => {
    const addedTags = getTagsTracking(order, oldTags);
    return tagsTracking.some(x => addedTags.includes(x));
}

const FormContent = ({ data, handleClose }) => {
    const { record } = data;
    const { setValue } = useFormContext();
    const [otherRequired, setOtherRequired] = useState({});

    const defaultErrors = [
        'Incorrect event name',
        'Incorrect event time',
        'Incorrect event date',
        'Incorrect event type (not actual event)',
        'Other'
    ];

    const trimmedRequired = [
        required(),
        (value) => {
            if (value && typeof value === 'string' && value.trim() === '') {
                return 'This field cannot be null or contain only whitespace.';
            }
            return undefined;
        }
    ];

    useEffect(() => {
        if (!record.isMapping) setOtherRequired({ validate: trimmedRequired });
    }, [record]);

    const onChangeError = (e) => {
        if (e) {
            const { value } = e.target;
            if (value == 'Other') {
                setValue('otherError', '');
                setOtherRequired({ validate: trimmedRequired });
                return;
            }
        }
        setValue('otherError', '');
        setOtherRequired({});
    }

    return (
        <>
            <Grid item container xs={12} style={{ marginTop: -20 }}>
                <span><b>Tag:&nbsp;</b>
                    {record.tag?.split(',').map(m => <Chip label={m} color={m.includes('Error') ? 'error' : 'warning'} />)}
                </span>
            </Grid>
            {record.isMapping && (
                <Grid item container>
                    <SelectInput source="error" validate={required()} choices={defaultErrors.map(x => ({ id: x, name: x }))} onChange={onChangeError} translateChoice={false} resettable />
                </Grid>
            )}
            <Grid item container style={{ marginTop: record.isMapping ? -10 : 0 }}>
                <TextInput source="otherError" {...otherRequired} multiline minRows={2} resettable />
            </Grid>
            {!record.isMapping && (
                <Grid item container style={{ marginTop: -25 }}>
                    <Grid item xs={4} style={{ alignContent: 'center' }}>
                        <Typography>Link with Order: </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextInput source="orderNumber" resettable />
                    </Grid>
                </Grid>
            )}
            <Grid container justifyContent={"flex-end"} spacing={1} style={{ marginBottom: -30 }}>
                <Grid item>
                    <SaveButton variant="contained" color="primary" label={'Save Order'} />
                </Grid>
                <Grid item>
                    <Button onClick={handleClose} variant='outlined' aria-label={'m_isOpen'}>Close</Button>
                </Grid>
            </Grid>
        </>
    );
}

export default TrackingMappingPopup = ({ data, onClose }) => {
    const { order } = data;
    const { m_isOpen, m_data, callback } = data.dlgState;
    const [trackingItem, setTrackingItem] = useState({});

    useEffect(() => {
        if (m_isOpen && order) {
            let addedTags = getTagsTracking(order, m_data.oldTags);
            setTrackingItem({
                error: '',
                otherError: '',
                orderNumber: '',
                tag: addedTags.filter(x => tagsTracking.find(y => y == x)).join(","),
                isMapping: addedTags.some(x => x == 'Mapping Error')
            })
        }
    }, [m_isOpen, order]);

    const onSubmit = (data) => {
        let defaultError = data.error?.trim();
        let msgError = data.otherError?.trim();
        if (trackingItem.isMapping) {
            msgError = defaultError;
            if (data.otherError) msgError += ` - Detail: ${data.otherError}.`;
        } else { // for mistaken
            if (data.orderNumber) {
                msgError += ` (Link with order: ${data.orderNumber})`;
            }
        }
        callback(msgError);
        onClose(null, 'm_isOpen', false);
    }

    return (
        <>
            {m_isOpen && (
                <Create record={trackingItem}>
                    <MDialog
                        title={`TRACKING ORDER #${order.order_number}`}
                        maxWidth={'sm'}
                        open={m_isOpen}
                        handleClose={onClose}
                        name={'m_isOpen'}
                        footer={false}
                        msgLoading={'Saving...'}
                        className={'order-dialog'}
                    >
                        <SimpleForm defaultValues={order.orderProcessTracking || {}} toolbar={null} mode="onBlur" reValidateMode="onBlur" onSubmit={onSubmit}>
                            <FormContent
                                handleClose={onClose}
                                data={{ record: trackingItem }}
                            />
                        </SimpleForm>
                    </MDialog>
                </Create>
            )}
        </>
    )
}