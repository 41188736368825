import React from 'react';
import {
    useColorScheme
} from "@mui/material/styles";

// Icons
import DarkMode from "@mui/icons-material/DarkMode";
import LightMode from "@mui/icons-material/LightMode";

import IconButton from '@mui/material/IconButton';

const ModeToggle = () => {
    const { mode, setMode } = useColorScheme();
    const [mounted, setMounted] = React.useState(false);
    React.useEffect(() => {
        setMounted(true);
    }, []);
    if (!mounted) return null;

    return (
        <IconButton onClick={() => setMode(mode === "dark" ? "light" : "dark")}>
            {mode === "dark" ? <DarkMode /> : <LightMode />}
        </IconButton>
    );
};

export default ModeToggle;