function totalStackedBarChart(seriesList, data, xDataKey) {
    var stackNames = [...new Set(seriesList.map(x => x.stackId))];
    var rs = [];
    stackNames.forEach(name => {
        let stackKeyList = seriesList.filter(x => x.stackId == name).map(x => x.dataKey);
        let keyList = seriesList.map(x => x.dataKey);
        rs.push(data.reduce((final, obj) => {
            let sumStack = 0, total = 0;
            Object.keys(obj).forEach(key => {
                if (stackKeyList.some(x => x == key)) {
                    sumStack += obj[key];
                }
                if (keyList.some(x => x == key)) {
                    total += obj[key];
                }
            });
            final.push({ xDataKey: obj[xDataKey], stackId: name, total, sumStack, lastDataKey: stackKeyList[stackKeyList.length - 1] });
            return final;
        }, []));
    });
    return rs.flatMap(x => x);
}
function calculateDomain(totalStack, yGap, height) {
    var maxYAxis = totalStack.reduce((maxNum, item) => (item.sumStack > maxNum ? item.sumStack : maxNum), totalStack[0].sumStack)
    var domain = [];
    //chart height = 600px => max 16 gaps, so depend on height, we calculate the gap size
    var calGap = yGap;
    while ((maxYAxis / calGap) > (16 * (height / 600))) { calGap += 5; }
    while (calGap % 5 != 0) { calGap++; }
    for (let nodeValue = 0; nodeValue <= maxYAxis + calGap; nodeValue += calGap) {
        domain.push(nodeValue);
    }
    return domain;
}

function changeColor(mode) {
    return mode == 'dark' ? 'white' : 'black';
}

export { totalStackedBarChart, calculateDomain, changeColor }