import React from 'react';
import { Toolbar, AppBar, Box, Typography, Breadcrumbs, Link } from '@mui/material';
import AccountMenu from './AccountMenu';
import TopMenu from './Menu';
import ModeToggle from './ModeToggle';

const Header = () => {
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/">
            Analysis System
        </Link>,
        <Typography key="2" color="text.primary"
            id="react-admin-title">
        </Typography>
    ];
    return (
        <Box component="nav" sx={{ flexGrow: 1 }}>
            <AppBar position="static" color="primary" >
                <Toolbar variant="dense">
                    <Box flex={1} display="flex" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                            <Box
                                component="img"
                                sx={{ marginRight: '1em', height: 30 }}
                                src={
                                    'https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg'
                                }
                                alt="Analysis System"
                            />
                            <Breadcrumbs separator="›" aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>

                        </Box>
                        <Box>
                            {/* <Button /> */}
                            <TopMenu />
                        </Box>
                        <Box display="flex">
                            <ModeToggle />
                            <AccountMenu />
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default Header;
