import * as React from "react";
import { useRecordContext } from 'react-admin';
import { Link } from "@mui/material";

export const EventFormatter = (props) => {
    const { dataProvider } = props;
    const record = useRecordContext(props);
    var params = `RedirectTo?listingId=${record.src_listing_id}&market_id=${record.market_id}`;

    return (
        <Link style={{ minWidth: 100 }} target="_blank" onClick={(e) => {
            e.preventDefault();
            const newTab = window.open('', '_blank');
            dataProvider
                .fetchData('Listings', params)
                .then((response) => {
                    if (response.data) {
                        newTab.location.href = response.data;
                    }
                })
                .catch((error) => console.log(error));
        }} href={`/Listings/${params}`}>
            {record.event}
        </Link>
    )
}

EventFormatter.defaultProps = { label: 'Event' };