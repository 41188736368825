import * as React from "react";
import { useRecordContext } from 'react-admin';
import { formatDateTime, dayjs } from "../../ex_dayjs";

export default DateTimeDiffFormatter = ({ source, record, ...props }) => {
    const _record = record || useRecordContext(props);
    if(_record.dateTime && _record.smDateTime){        
        let _date = dayjs(_record.dateTime);
        let _smdate = dayjs(_record.smDateTime);
        let isSameDay = _date.isSame(_smdate, 'day');
        let isSameTime = _date.isSame(_smdate, 'hour') && _date.isSame(_smdate, 'minute');
        let validRange = Math.abs(_date.diff(_smdate, 'hour')) == 1        

        var cellColor = (isSameDay && isSameTime) ? '' : ((isSameDay && validRange ) ? 'orange' : 'red');
    }
   
    return (
        <span style={{ backgroundColor: cellColor }}>
            {formatDateTime(_record[source])}
        </span>
    )
}