import React, { useState } from 'react';
import { useRecordContext, useDataProvider, useNotify } from 'react-admin';
import { errorLevel } from '../common';
import { LinearProgress, MenuItem, FormControl, Select } from '@mui/material';

const StatusField = (props) => {
    const { isAdmin, setForRender } = props;
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [isLoading, setIsLoading] = useState(false);    

    var record = useRecordContext();

    if (!record) return null;

    const handleChange = (props) => {
        let changedValue = props.target.value;
        if(changedValue == -1 && record.error_level == null) return;
               
        updateEL(props.target.value)
    }
    const updateEL = (value) => {
        let mistakeItem = record;
        mistakeItem.error_level = value == -1 ? null : value;
        setIsLoading(true);

        dataProvider.postData('OrderMistakeReport', 'UpdateErrorLevel', mistakeItem)
            .then(rs => {                
                if (rs.data) {
                    record.error_level = rs.data.error_level;
                    record.modified = rs.data.modified;
                    record.modified_by = rs.data.modified_by;                   
                    setForRender(record);
                    notify('Change error level successfully', { type: 'success', autoHideDuration: 2000 });
                }
                else if (rs.status == 400) {
                    notify(`Change error level failed(Bad request): ${rs.body}`, { type: 'error', autoHideDuration: 3000 });
                }
            })
            .catch(error => {               
                console.log('Error level change failed:', error);
                notify(`Change status failed: Status ${error.status}, Error ${error}`, { type: 'error', autoHideDuration: 3000 });
            })
            .finally(_ => {
                setIsLoading(false);                
            });
            
    }

    return (
        <FormControl sx={{ m: 1, minWidth: 120, padding: 0 }} size="small" variant='standard'>
            {isLoading && <LinearProgress color="secondary" />}
            <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={record.error_level}
                defaultValue={record.error_level}
                disableUnderline={true}
                onChange={handleChange}
                sx={{ padding: 0 }}
                disabled={!isAdmin}
            >
                {errorLevel.filter(err => err.id != -2).map((m, index) => {
                    return <MenuItem key={index} id={m.id} value={m.id}>{m.name}</MenuItem>
                })}
            </Select>                    
        </FormControl>
    )
}
export default React.memo(StatusField);