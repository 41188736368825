import * as React from 'react';
import { useRecordContext } from 'react-admin';
import BoltTwoToneIcon from '@mui/icons-material/BoltTwoTone';
import StubHubDialog from './stubhub-dialog';
import { Tooltip, IconButton } from '@mui/material';

export const StubHubSearch = (props) => {
    const record = useRecordContext(props);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }
    
    return (
        <>
            <Tooltip title='Click here to get tickets from www.ticketstatictis.com'>
            <IconButton aria-label='openStubhubSearch' onClick={handleOpen} color='warning'>
                <BoltTwoToneIcon />
            </IconButton>
            </Tooltip>
            {open && <StubHubDialog recordContext={record} onClose={handleClose} source='SingleEventAnalysis'/>}
        </>

    )
}