import React, { useState, useRef, useEffect } from 'react';
import { useRecordContext, useDataProvider, useNotify, Confirm } from 'react-admin';
import { LinearProgress, FormControl, IconButton, Stack, Button } from '@mui/material';
import MAutoComplete from '../../../components/controls/MAutoComplete';
import EditIcon from '@mui/icons-material/Edit';

const ReceiverField = (props) => {
    const { listUser, setForRender, rowInfo } = props;
    const refInput = useRef(null);  
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [openCfrm, setOpenCfrm] = useState(false);
    const [user, setUser] = useState(null);
    
    var record = useRecordContext();
    if (!record) return null;

    useEffect(() => {
        if(isEdit === true & rowInfo?.rowId != record.id) {
            setIsEdit(false);
        }
    }, [rowInfo]);

    const handleConfirm = () => {
        updateReceiver(user)
    }
    const handleUserChange = (user) => {
        setUser(user);
    }
    const showEdit = () => {
        setIsEdit(true);        
        setTimeout(() => refInput.current?.click(), 100); // To show options of autocomplete
    }
    const updateReceiver = (user) => {
        let mistakeItem = record;
        mistakeItem.receiver = user.id;
        setIsLoading(true);

        dataProvider.postData('OrderMistakeReport', 'UpdateReceiver', mistakeItem)
            .then(rs => {
                if (rs.data) {
                    record.receiver = rs.data.receiver;
                    record.modified = rs.data.modified;
                    record.modified_by = rs.data.modified_by;
                    notify('Change receiver successfully', { type: 'success', autoHideDuration: 2000 });
                }
                else if (rs.status == 400) {
                    notify(`Change receiver failed(Bad request): ${rs.body}`, { type: 'error', autoHideDuration: 3000 });
                } else notify(`Error: ${rs?.message}`, { type: 'error', autoHideDuration: 3000 });
            })
            .catch(error => {
                console.log('Receiver change failed:', error);
                notify(`Change status failed: Status ${error.status}, Error ${error}`, { type: 'error', autoHideDuration: 3000 });
            })
            .finally(_ => {
                setIsLoading(false);
                setForRender(record);
                setOpenCfrm(false);
                setIsEdit(false);
            });

    }

    return (
        <FormControl sx={{ m: 1, minWidth: 250, padding: 0 }} size="small" variant='standard'>
            {isLoading && <LinearProgress color="secondary" />}
            {isEdit
                && <div><MAutoComplete
                    refInput={refInput}
                    label={"Select user (*)"}
                    isMulti={false}
                    choices={listUser}
                    value={user}
                    defaultValue={record.receiver}
                    onChange={handleUserChange}
                    error={!user}
                    displayField="name"
                    valueField="id"
                />
                    <Stack sx={{ mt: 2 }} direction="row" spacing={2}>
                        <Button variant='contained' color='primary' onClick={() => setOpenCfrm(true)} disabled={!user}>Save</Button>
                        <Button variant='outlined' onClick={() => setIsEdit(false)}>Cancel</Button>
                    </Stack>
                </div>
                || <span onClick={showEdit}>{record.receiver}<IconButton ><EditIcon sx={{ fontSize: 13 }} /></IconButton></span>
            }
            <Confirm isOpen={openCfrm} title='Change Mistaken User' content={`Are you sure want to change mistake to user: ${user?.nick} - "${user?.id}"?`}
                onConfirm={handleConfirm}
                onClose={() => setOpenCfrm(false)} />
        </FormControl>
    )
}
export default React.memo(ReceiverField);