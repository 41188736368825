import React from 'react';
import { useRecordContext } from 'react-admin';
import { Chip, Stack } from '@mui/material';
const ApplyFor = (props) => {
    const { exchanges } = props;
    const record = useRecordContext();
    var exchangeValue = record.applyfor?.split(',')?.filter(e => e != '');

    if (exchangeValue.length == 0 || !!!exchangeValue)
        return <></>
    else return (
        <Stack direction='row' spacing={1}>
            {exchangeValue.map(e => (
                <Chip label={exchanges.find(ex => ex.id == e)?.name} />
            ))}
        </Stack>
    );
}

export default React.memo(ApplyFor);