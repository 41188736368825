import { useRefresh, useNotify, useCreate, useUpdate, SimpleForm, TextInput, NumberInput, BooleanInput, SelectInput, required, minValue, ReferenceInput, useRecordContext } from 'react-admin';
import * as React from 'react';

import MyToolbar from '../../../components/Toolbar';
import { Box } from '@mui/material';

const Form = ({ onCancel, updateModel, isEdit }) => {
    const [updating] = isEdit ? useUpdate() : useCreate();
    const notify = useNotify();
    const refresh = useRefresh();
    const record = useRecordContext();
    const [load, setLoad] = React.useState(false);
    const handleSubmit = (data) => {
        setLoad(true);
        //Set data for edit (disabled)
        data.id ??= record.id;
        data.sourceType ??= record.sourceType;
        updating('SourceVariable', { id: data.id, data: data }, {
            onSuccess: () => {
                updateModel();
                refresh();
                notify('Save success', { type: 'success' });
            },
            onError: (error) => {
                notify(`Save fail: ${error.message}`, { type: 'error' });
            }
        });
    };

    return (

        <SimpleForm toolbar={<MyToolbar onCancel={onCancel} load={load} key={load} />} onSubmit={handleSubmit}>
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput
                        disabled={isEdit}
                        validate={[required('Invalid Upstream')]}
                        resettable
                        label="Upstream"
                        source="id"
                        fullWidth
                    />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <ReferenceInput source='sourceType' reference='Common/SoureTypes' sort={{ field: 'name', order: 'ASC' }} >
                        <SelectInput optionText="name" disabled={isEdit}
                            validate={[required('Invalid Source Type')]}
                            resettable
                            label="Source Type" fullWidth />
                    </ReferenceInput>
                </Box>
            </Box>

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput
                        resettable
                        validate={[required('Invalid Variable Name')]}
                        label="Variable Name"
                        source="variableName"
                        fullWidth
                    />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <NumberInput label="Insert In Hours Ago" source="insertInHoursAgo" fullWidth />
                </Box>
            </Box>

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <NumberInput
                        min={0}
                        label="From Day"
                        source="fromDay"
                        fullWidth
                        validate={[minValue(0, 'Invalid Number')]}
                    />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <NumberInput
                        min={0}
                        label="ToDay"
                        source="today"
                        fullWidth
                        validate={[minValue(0, 'Invalid Number')]}
                    />
                </Box>
            </Box>

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <SelectInput
                        fullWidth
                        emptyValue=""
                        label="Is Exact"
                        source="isExact"
                        choices={[
                            { id: 'Y', name: 'Yes' },
                            { id: 'N', name: 'No' },
                        ]}
                    />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <BooleanInput label="Active" source="active" fullWidth />
                </Box>
            </Box>
        </SimpleForm>

    );
};

export default Form;
