import * as React from 'react';
import { Create, Edit, useRecordContext } from 'react-admin';
import Form from './components/Form';
import FormHeader from '../../components/FormHeader';

const SourceVariableModel = ({ isEdit, onCancel, updateModel, ...props }) => {
    return (

        isEdit && (
            <Edit {...props} title=" ">
                <>
                    <FormHeader onCancel={onCancel} isEdit={isEdit} />
                    <Form onCancel={onCancel} updateModel={updateModel} isEdit={isEdit} />
                </>
            </Edit>
        ) ||
        (
            <Create title=" ">
                <>

                    <FormHeader onCancel={onCancel} isEdit={isEdit} />
                    <Form onCancel={onCancel} updateModel={updateModel} isEdit={isEdit} />
                </>
            </Create>
        )

    );
};
export default SourceVariableModel;
