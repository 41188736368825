import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Divider from '@mui/material/Divider';
import { Stack, IconButton, Typography, TextField, Box, LinearProgress, useColorScheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const EventActionPopUp = ({ open, handleClose, title, children }) => {
    return (
        <Dialog open={open} onClose={handleClose} TransitionComponent={Transition} fullWidth={true} maxWidth={'md'}>
            <DialogTitle>
                <Stack direction="row">
                    <Typography variant="h6" flex="1">
                        {title}
                    </Typography>
                    <IconButton onClick={handleClose} size="small">
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <Divider variant="middle" />
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default EventActionPopUp;