import React from "react";
import { useRecordContext } from "react-admin";
import { dayjs } from "../../../../components/ex_dayjs";
import { Tooltip } from "@mui/material";
const OrderNotes = () => {
    const record = useRecordContext();
    if (!record.order_notes) return;
    var title = record.order_notes.reduce((final, note) => {
        let item = `${note.author}: ${note.post} - ${dayjs.utc(note.utc_posted_on).fromNow()}`
        final.push(item);
        return final;
    }, []);
    return (
        <>
            <Tooltip title={title.join('. ')}>
                <span>
                    {record.order_notes.map((note, index) => {
                        return <span key={`quicknote-${record.order_number}-${index}`}>
                            <span>
                                <b>{note.author}: </b>
                                {note.post} - {dayjs.utc(note.utc_posted_on).fromNow()}
                            </span>
                            <br />
                        </span>;
                    })}
                </span>
            </Tooltip>
        </>

    );
};
export default OrderNotes;