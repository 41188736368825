import { useState } from "react";
import React from "react";
import Checkbox from '@mui/material/Checkbox';



const ExactMatch = (props) =>{
    return (
      <div className="custom-group-header" >
        <span>Exact Matches Only</span> 
        <Checkbox inputRef={props.exactRef} sx={{padding:0}}/>
      </div>
    );
  }

  export default ExactMatch;