import React, { useEffect, useRef, useState } from "react";
import { useLocation } from 'react-router-dom';
import { BooleanField, Datagrid, FunctionField, NumberField, TextField, useDataProvider, useListController, useUnselectAll } from "react-admin";
import { Box, Tooltip } from '@mui/material';
import { formatLocalTime } from "../../components/ex_dayjs";
import RaList from "../../components/ra-list/RaList";
import RenderControls from "../../components/ra-list/RenderControls";
import { getDefaultUtcOffset, loadSourceType, reloadPage, setDefaultValuesFromURL } from "../../utils/common";
import { ExchangeMarket, MappingOptions, Countries } from "../../utils/enum";
import MappingPopup from "./components/MappingPopup";
import SyncButton from "./components/SyncButton";
import SyncEventPopup from "./components/SyncEventPopup";
import UnmapAllButon from "./components/UnmapAllButon";
import UrlFormatter from "../../components/ra-list/formatters/UrlFormatter";
import SkipPricerAllButon from "./components/SkipPricerAllButon";
import DateTimeDiffFormatter from "../../components/ra-list/formatters/DateTimeDiffFormatter";

const defaultOrderby = 'dateTime asc';

const ListingsMapping = () => {
    const location = useLocation();
    const dataProvider = useDataProvider();
    const refbtnFilter = useRef(null);
    const [rowItem, setRowItem] = React.useState(null);
    const [sortModel, setSortModel] = React.useState([defaultOrderby]);
    const { resource, data, selectedIds } = useListController();
    const unselectAll = useUnselectAll(resource);
    // for dialogs
    const [state, setState] = React.useState({
        m_isOpen: false,
        m_isLoading: false,
        m_item: {},
        m_events: [],
        // for sync dialog
        s_isOpen: false,
        s_title: 'DETAIL',
        s_data: {},
        // for unmap all
        unmap_isLoading: false,
        unmap_isOpen: false,
        unmap_alert: false,
        unmap_sync_isLoading: false,
        // for skip pricer
        skipBtn: 'skip pricer',
        smEventId: '', // For sync button
    });

    const [selection, setSelection] = React.useState({
        selectedIds: [],
        isSelection: false,
        MarketId: 1, // VS
        Mapped: -1 // Any
    });
    const [marketType, setMarketType] = React.useState([]);

    const [defaultValues, setDefaultValues] = useState({
        MarketId: 1,
        Mapped: -1,
        Score: [2, 5],
        adminonly: false,
        exactDateTime: null,
        utc_offset_eq: getDefaultUtcOffset()
    });

    const controls = [
        { component: 'number', name: 'TicketId', label: 'Ticket ID' },
        { component: 'number', name: 'SmOriginalID', label: 'Sm EventID' },
        { component: 'select', name: 'Type_eq', label: 'Type', choices: marketType },
        { component: 'select', name: 'Country', choices: Countries },
        { component: 'text', name: 'Name', label: 'Event', xs: 2 },
        { component: 'text', name: 'Venue', xs: 2 },
        { component: 'daterange', name: 'DateTime', label: 'Date Time' },
        { component: 'numberrange', name: 'Score', label: 'Score', xs: 2, decimal: 3, rangeMin: [0, 5], rangeMax: [0, 5] },
        { component: 'select', name: 'MarketId', required: true, label: 'Exchange market', choices: ExchangeMarket, isEmptyOption: false, onChange: ((e) => handleSelection(e)) },
        { component: 'select', name: 'Mapped', choices: MappingOptions, isEmptyOption: false, onChange: ((e) => handleSelection(e)) },
        { component: 'daterange', name: 'Mapped_Time', label: 'Mapped Time', isUTC: true },
        { component: 'text', name: 'Mapped_By_contains', label: 'By', xs: 1 },
        { component: 'checkbox', name: 'adminonly', label: 'Admin only' },
        { component: 'checkbox', name: 'exactDateTime', label: 'Exact datetime matches', xs: 1.5 },
        { component: 'checkbox', name: 'isduplicated', label: 'Duplicated', onChange: (e) => handleDuplicated(e) },
        { component: 'checkbox', name: 'notfound', label: 'Not found' },
        { component: 'text', name: 'utc_offset_eq', isHide: true }
    ];

    useEffect(() => {
        loadSourceType(dataProvider, (response) => {
            setMarketType(response);
        });
        unselectAll();

        if (location.search) {
            var newDefaultValue = { ...defaultValues };
            setDefaultValuesFromURL(location.search, newDefaultValue, controls);
            setDefaultValues(newDefaultValue);
            setSelection((prev) => ({ ...prev, MarketId: newDefaultValue.MarketId }));
        }
    }, []);

    useEffect(() => {
        if (data?.length > 0) {
            var isEnablePricer = data.every(x => x.isSkipPricer === true);
            setState((prev) => ({ ...prev, skipBtn: isEnablePricer ? 'enable pricer' : 'skip pricer' }));
            // Show/hide checkbox to mapping/skip 
            const MARKETS_ALLOW = [1, 2, 4, 13]; // For VS, TN, SG, Go Tickets
            var isSelection = MARKETS_ALLOW.some(x => x == selection.MarketId) && selection.Mapped == 1;
            setSelection((prev) => ({ ...prev, isSelection }));
        }
    }, [data]);

    const handleDuplicated = (e) => {
        const { value } = e;
        if (value) setSortModel(['smEventID asc', defaultOrderby]);
    };

    /**
     * Allow select multiple checkbox to unmap or not
     * @param {*} name 
     * @param {*} value 
     * @returns 
     */
    function handleSelection(e) {
        const { name, value } = e;
        setSelection((prev) => ({ ...prev, [name]: value }));
        unselectAll();
    }

    const handleRowClick = (id, resource, record) => {
        setRowItem({ id, resource, record });
    };

    const handleDoubleClick = () => {
        if (rowItem?.record) {
            var _newRecord = { ...rowItem.record, MarketId: selection.MarketId };
            setState((prev) => ({ ...prev, m_isOpen: true, m_item: _newRecord, m_events: [], m_isLoading: true }));
            if (rowItem.id) {
                dataProvider.postData(resource, `FindEvents`, _newRecord)
                    .then((response) => {
                        if (response.data) {
                            setState((prev) => ({ ...prev, m_events: response.data, m_isLoading: false }));
                        }
                    })
                    .catch((error) => console.error(error));
            }
        }
    };

    const handleCloseDialog = (event, dialogName = null) => {
        let name = event?.currentTarget?.ariaLabel;
        if (!name) name = dialogName;
        if (name) setState((prev) => ({ ...prev, [name]: false }));
    };

    return (
        <Box>
            <RaList
                title={"Event Mapping"}
                sortby={sortModel}
                storeKey={true}
                perPage={25}
                filters={
                    <RenderControls
                        exportFile={{
                            columns: ['Type', 'Name', 'SmEvent', 'Venue', 'SmVenue', 'City', 'SmCity', 'State', 'SmState', 'DateTime', 'SmDateTime', 'IsSkipPricer',
                                'Mapped', 'Mapped_By', 'Mapped_Time', 'Score', 'ListingCount', 'Rename_Event', 'Rename_Venue', 'Rename_City', 'Rename_State']
                        }}
                        useHashUrl
                        refFilter={refbtnFilter}
                        filterInline={false}
                        actions={
                            <SyncButton
                                dataProvider={dataProvider}
                                marketId={selection.MarketId}
                                smEventId={state.smEventId}
                                setSmEventId={(id) => setState((prev) => ({ ...prev, smEventId: id }))}
                                callback={(resp) => setState((prev) => ({ ...prev, s_isOpen: true, s_data: resp }))} />
                        }
                        controls={controls}
                        defaultValues={defaultValues}
                    />
                }
            >
                <Datagrid
                    key={new Date()}
                    optimized
                    bulkActionButtons={
                        selection.isSelection && (
                            <>
                                <UnmapAllButon
                                    dataProvider={dataProvider}
                                    selectedIds={selectedIds}
                                    params={{ resource, data }}
                                    reloadPage={() => {
                                        unselectAll();
                                        reloadPage(resource, refbtnFilter);
                                    }} />
                                <SkipPricerAllButon
                                    dataProvider={dataProvider}
                                    selectedIds={selectedIds}
                                    params={{ resource, data, skipBtn: state.skipBtn }}
                                    reloadPage={() => {
                                        unselectAll();
                                        reloadPage(resource, refbtnFilter);
                                    }} />
                            </>
                        )
                    }
                    onDoubleClick={handleDoubleClick}
                    rowClick={handleRowClick}
                    isRowSelectable={(record) => record.mapped && !record.isUnmap}
                >
                    <TextField source="type" />
                    <UrlFormatter source="name" href="url" />
                    <UrlFormatter label="SmEvent" source="smEvent" href="smUrl" />
                    <FunctionField source="venue" render={record =>
                        <Tooltip title={record.rename_Venue != '' ? `Renamed from "${record.venue}"` : ''} ><span style={{ color: record.rename_Venue != '' ? 'orange' : '' }}>{record.rename_Venue || record.venue}</span></Tooltip>}
                    />
                    <TextField source="smVenue" label="SmVenue" />
                    <FunctionField source="city" render={record =>
                        <Tooltip title={record.rename_City != '' ? `Renamed from "${record.city}"` : ''} ><span style={{ color: record.rename_City != '' ? 'orange' : '' }}>{record.rename_City || record.city}</span></Tooltip>}
                    />
                    <TextField source="smCity" label="SmCity" />
                    <FunctionField source="state" render={record =>
                        <Tooltip title={record.rename_State != '' ? `Renamed from "${record.state}"` : ''} ><span style={{ color: record.rename_State != '' ? 'orange' : '' }}>{record.rename_State || record.state}</span></Tooltip>}
                    />
                    <TextField source="smState" label="SmState" />
                    <FunctionField label="DateTime" source="dateTime" render={record => <DateTimeDiffFormatter source={'dateTime'} record={record} />} />
                    <FunctionField label="SmDateTime" source="smDateTime" render={record => <DateTimeDiffFormatter source={'smDateTime'} record={record} />} />
                    <FunctionField label="Skip Pricer" sortBy="isSkipPricer" render={(record) => <BooleanField source="isSkipPricer" looseValue={record.isSkipPricer === false} />} />
                    <FunctionField
                        label="Mapped"
                        sortBy="mapped"
                        render={(record) => {
                            if (record.mapped > 0) {
                                return <BooleanField source="mapped" record={{ mapped: true }} />;
                            } else if (record.isUnmap === true) {
                                return <BooleanField source="mapped" record={{ mapped: false }} valueLabelFalse="Unmap" style={{ color: 'orange' }} />;
                            } else return null;
                        }}
                    />
                    <TextField source="mapped_By" label="By" />
                    <FunctionField label="Time" source="mapped_Time" render={record => record.mapped_Time ? formatLocalTime(record.mapped_Time) : null} />
                    <NumberField source="score" />
                    <NumberField source="listingCount" label="Volume" />
                </Datagrid>
            </RaList >

            <MappingPopup dataProvider={dataProvider} data={state} smMarket={selection.MarketId} handleClose={handleCloseDialog} reloadPage={() => reloadPage(resource, refbtnFilter)} />
            <SyncEventPopup dataProvider={dataProvider} data={state} handleClose={handleCloseDialog} />
        </Box >
    );
};

const ListingsMappingPage = {
    name: 'ListingsMapping',
    list: ListingsMapping
};
export default ListingsMappingPage;