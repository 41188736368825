import * as React from 'react';
import { Box } from '@mui/material';
import Icon from '@mui/icons-material/Stars';
import { FieldProps, useRecordContext } from 'react-admin';
import KnownDomains, { categorize } from './Util';
import * as Favicons from '../../decoration/favicon';

interface OwnProps {
    size?: 'large' | 'small';
}

const CategoryField = ({ size = 'large' }: FieldProps & OwnProps) => {
    const record = useRecordContext();
    if (!record) return null;
    let category = categorize(record),
        icon = Favicons[category];

    return (
        <Box
            component="span"
            display="flex"
            sx={{
                opacity: 0.87,
                whiteSpace: 'nowrap',
            }}
        >
            {icon && <img src={icon} alt={category} style={{
                width: size === 'large' ? 20 : 15,
                height: size === 'large' ? 20 : 15,
                marginRight: '5px'
            }} />} {category}
            {/* {Array(record.rating)
                .fill(true)
                .map((_, i) => (
                    <Icon
                        key={i}
                        sx={{
                            width: size === 'large' ? 20 : 15,
                            height: size === 'large' ? 20 : 15,
                        }}
                    />
                ))} */}
        </Box>
    );
};

CategoryField.defaultProps = {
    label: 'Type',
    source: 'type',
};

export default CategoryField;
