import React, { useCallback } from 'react'
import { useListFilterContext, useRecordContext } from 'react-admin';
import get from 'lodash/get';
import { highlightWords, getSearchWords } from './helper';



const HighlightedTextField = ({ source }) => {
    const record = useRecordContext();
    const { filterValues } = useListFilterContext();
    if (!record) return;
    let fieldValue = get(record, source); // Replace 'title' with the actual field name
    if (!fieldValue) {
        source = source.split('.')[1].split('[')[0];
        fieldValue = get(record, source);
    }

    var highlightedHTML = highlightWords(getSearchWords(filterValues), fieldValue);

    return <div dangerouslySetInnerHTML={{ __html: highlightedHTML }} />;
};

export default HighlightedTextField;