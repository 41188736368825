import React, { useState } from "react";
import { Popover, TableBody, Table, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { formatLocalTime, toDateTime } from "../../../components/ex_dayjs";

const History = (props) => {

    const { open, data, btnId } = props.info;
    const setHistoryPopover = props.setHistoryPopover;
    var btnPosition;
    if (btnId != null)
        btnPosition = document.getElementById(btnId).getBoundingClientRect();

    const id = open ? 'simple-popover' : undefined;

    const handleClose = (params) => {
        setHistoryPopover(prev => ({ ...prev, open: false }));
    }
    return (
        <Popover
            id={id}
            open={open}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={{ top: btnPosition?.top + 45, left: btnPosition?.left }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <TableContainer component={Paper} sx={{
                pt: 1,
                borderRadius: 'sm',
                transition: '0.3s',
                background: (theme) =>
                    `linear-gradient(45deg, ${theme.vars.palette.primary[500]}, ${theme.vars.palette.primary[400]})`,
                '& tr:last-child': {
                    '& td:first-child': {
                        borderBottomLeftRadius: '8px',
                    },
                    '& td:last-child': {
                        borderBottomRightRadius: '8px',
                    },
                },
            }}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>BID</TableCell>
                            <TableCell align="right">ExchangeId</TableCell>
                            {/* <TableCell align="right">Section</TableCell>
                            <TableCell align="right">Row</TableCell> */}
                            <TableCell align="right">Qty</TableCell>
                            <TableCell align="right">Our map_price</TableCell>    
                            <TableCell align="right">Competitor price</TableCell>
                            <TableCell align="right">Updated date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data != null && data.length > 0 && JSON.parse(data).sort((x, y) => toDateTime(y.Updated_on) - toDateTime(x.Updated_on)).map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">{row.BID}</TableCell>
                                <TableCell align="right">{row.ExchangeId}</TableCell>
                                {/* <TableCell align="right">{row.LevelName}</TableCell>
                                <TableCell align="right">{row.Row}</TableCell> */}
                                <TableCell align="right">{row.Qty}</TableCell>
                                <TableCell align="right">{row.Map_Price}</TableCell>
                                {/* OriginalPrice <> Price in Ticketnetwork*/}
                                <TableCell align="right">{row.OriginalPrice}</TableCell>
                                <TableCell align="right">{formatLocalTime(row.Updated_on)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Popover>
    )
}
export default React.memo(History);