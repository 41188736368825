import React from 'react'
import { useDataProvider, Loading } from 'react-admin';
import { useQuery } from '@tanstack/react-query';
import LaunchForm from './Launcher';
import SetupInstruction from './Setup';

const serviceUrl = 'http://127.0.0.1:3032/';

export const StealthPage: React.FC = () => {
    const dataProvider = useDataProvider();
    let displayError = '';
    let { data, isLoading, error } = useQuery({
        queryKey: ['stealth', 'hello'],
        queryFn: () => dataProvider.fetch(new URL('hello', serviceUrl).toString()),
        ...{
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 0,
            retryDelay: 3000
        }
    });

    if (isLoading) return <Loading />;

    if (data?.json.name === "Devsoft.Browser.StealthService") {
        const requiredVersion = "2.2";
        if (data?.json.version === requiredVersion) {
            return <LaunchForm serviceUrl={serviceUrl} />;
        }
        displayError = `Mismatched version.Your version: ${data.json.version}. Required: ${requiredVersion}. Please redownload and run the Stealth Service again.`;
    }
    if (!!error && typeof error === "object" && !!error.message) {
        displayError = error.message;
        console.error(error);
    }

    return <SetupInstruction error={displayError} />;
}