import * as React from 'react';
import MDialog from '../../../components/controls/MDialog';
import { Button } from '@mui/material';
import MSelect from '../../../components/controls/MSelect';

const EntryDatePopup = ({ open, dates, onClose, onSelect }) => {
    const [entryDate, setEntryDate] = React.useState(dates[0].id);

    return (
        <MDialog open={open}
            handleClose={onClose}
            title={`The condition contains [EntryDate] rules so that you have to select expiring dates.`}
            action={<Button variant="contained" onClick={() => onSelect(entryDate)}>Select</Button>}>
            <MSelect
                defaultValue={dates[0].id}
                choices={dates}
                isEmptyOption={false}
                onChange={setEntryDate} />
        </MDialog>
    )
}

export default EntryDatePopup;