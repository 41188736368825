import dayjs from "dayjs";

//https://day.js.org/docs/en/installation/installation
//dayjs with the plugins: utc, ...
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc).extend(relativeTime);
dayjs.extend(timezone);

const toLocalTime = function (utc = null) {
    var localdayjs = (utc ? dayjs.utc(utc) : dayjs.utc()).local();
    return localdayjs;
}

const formatLocalTime = function (utc = null) {
    return formatDateTime(toLocalTime(utc));
}

const toDateTime = function (date) {
    return dayjs(date);
}

const formatDateTime = function (date, format = 'MM-DD-YYYY hh:mm A') {
    if (!date) return null;
    return toDateTime(date).format(format);
}

const formatDate = function (date, format = 'MM-DD-YYYY') {
    if (!date) return null;
    return toDateTime(date).format(format);
}

const formatTime = (input, inputFormats, outputFormat) => {
    let dateObj = input;

    if (typeof input === 'string') {
        for (let i = 0; i < inputFormats.length; i++) {
            const format = inputFormats[i];
            dateObj = dayjs.utc(input, format);
            if (dateObj.isValid()) {
                break;
            }
        }
    }

    return dateObj.isValid() ? dateObj.format(outputFormat) : '';
}

function isValidTime(value) {
    const formats = ['hh:mm A', 'HH:mm:ss', 'HH:mm'];
    for (let i = 0; i < formats.length; i++) {
        const format = formats[i];
        if (dayjs(value, format, true).isValid()) {
            return true;
        }
    }
    return false;
}

export { dayjs, toDateTime, formatDateTime, formatDate, toLocalTime, formatLocalTime, formatTime, isValidTime };