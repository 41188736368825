import * as React from 'react';
import { Stack, Chip } from '@mui/material';
import { defaultExcludeForString } from '../../../components/query-builder/querybuilder.config';
import { useDynamicRule } from './rule';

export default AdjustRule;

function AdjustRule(marketType) {
    const { validator, showMarket, getRuleInfo } = useDynamicRule();

    const initialState = {
        sortModel: [{ field: 'score', sort: 'desc' }]
    };

    return getRuleInfo(getName, getColumnDefs, getConditionFields, getActionFields, initialState);

    function getName() {
        return 'DynamicAdjustRule';
    }

    function getConditionFields() {
        const excludeConditionOperator = [...defaultExcludeForString, 'is_duplicated'];
        return [
            { id: 'EventRule', name: 'EventRule', label: 'Event & SmEvent', input: 'text', type: 'string', validator, excludes: excludeConditionOperator },
            { id: 'VenueRule', name: 'VenueRule', label: 'Venue & SmVenue', input: 'text', type: 'string', validator, excludes: excludeConditionOperator },
            { id: 'EventUrl', name: 'EventUrl', label: 'Event URL', input: 'text', type: 'string', validator, excludes: excludeConditionOperator },
            { id: 'Event_Description', name: 'Event_Description', label: 'Event Description', input: 'text', type: 'string', validator, excludes: excludeConditionOperator },
            { id: 'MarketType', name: 'MarketType', label: 'Event Market', defaultValue: 'accesso', values: marketType, input: 'select', type: 'string', validator, valueEditorType: 'select', excludes: excludeConditionOperator },
            { id: 'Venue', name: 'Venue', label: 'Venue', input: 'text', type: 'string', validator, excludes: excludeConditionOperator },
            { id: 'City', name: 'City', label: 'City', input: 'text', type: 'string', validator, excludes: excludeConditionOperator },
            { id: 'State', name: 'State', label: 'State', input: 'text', type: 'string', validator, excludes: excludeConditionOperator },
        ];
    }

    function getActionFields() {
        const validator = (r) => !!r.value || r.value.lenght > 0;
        return [
            {
                id: 'Tags', name: 'Tags', label: 'Tags', input: 'text', type: 'string', valueEditorType: 'autocomplete', operators: [{ name: 'assign', label: 'assign' }], validator, defaultValue: [], values: ['Skip', 'Manual Review', 'Low value']
            },
            {
                id: 'Rename_Event', name: 'Rename_Event', label: 'Name', placeholder: '=input.Name.Replace("X", "Y")', input: 'text', type: 'string', operators: [{ name: 'assign', label: 'assign' }], validator, defaultValue: []
            },
            {
                id: 'Rename_Venue', name: 'Rename_Venue', label: 'Venue', placeholder: '=input.Venue.Replace("X", "Y")', input: 'text', type: 'string', operators: [{ name: 'assign', label: 'assign' }], validator, defaultValue: []
            },
            {
                id: 'Rename_City', name: 'Rename_City', label: 'City', placeholder: '=input.City.Replace("X", "Y")', input: 'text', type: 'string', operators: [{ name: 'assign', label: 'assign' }], validator, defaultValue: []
            },
            {
                id: 'Rename_State', name: 'Rename_State', label: 'State', placeholder: '=input.State.Replace("X", "Y")', input: 'text', type: 'string', operators: [{ name: 'assign', label: 'assign' }], validator, defaultValue: []
            }
        ]
    }

    function getColumnDefs() {
        const showTags = (params) => {
            let tags = params.value?.split(',') || [];
            return (
                <Stack direction='row' spacing={1}>
                    {tags.filter(x => x != '').map(x => <Chip label={x} color={x !== 'Skip' ? 'warning' : 'default'} />)}
                </Stack>
            )
        }

        return [
            { field: 'marketId', headerName: 'Exchange', width: 80, valueFormatter: ({ value }) => showMarket(value) },
            { field: 'name', headerName: 'Event', width: 250 },
            { field: 'venue', headerName: 'Venue', width: 250 },
            { field: 'city', headerName: 'City', width: 150 },
            { field: 'state', headerName: 'State', width: 80 },
            { field: 'score', headerName: 'Score', width: 100, type: 'number' },
            { field: 'tags', headerName: 'Tags', width: 200, renderCell: value => showTags(value) }
        ];
    }
}