import { dayjs } from "../../components/ex_dayjs";
import isoWeek from 'dayjs/plugin/isoWeek';
dayjs.extend(isoWeek);

const calcMonthRender = (lengthOfTime) => {
    let startDate = dayjs(lengthOfTime.startDate);
    let endDate = dayjs(lengthOfTime.endDate);

    let startMonth = startDate.month() + 1;
    let startYear = startDate.year();
    let startDay = startDate.get('date');

    let endMonth = endDate.month() + 1;
    let endYear = endDate.year();
    let endDay = endDate.get('date');

    var monthArr = [];
    var monthDiff = endDate.diff(startDate, 'month');
    for (let index = 0; index <= monthDiff; index++) {
        let _month = (startMonth + index) <= 12 ? (startMonth + index) : (startMonth + index - 12);
        let _year = monthArr.find(m => m.month == 12) ? endYear : startYear
        monthArr.push({ month: _month, year: _year, day: index == 0 ? startDay : index == monthDiff ? endDay : null });
    }
    monthArr.isFilter = lengthOfTime.isFilter;
    return monthArr;
}

function mappingSchedule(scheduleDay, data) {
    var originMonthYear = `${scheduleDay.year}-${getZero(scheduleDay.month)}`;
    var originData = data.filter(x => x.MonthYear == originMonthYear);
    var workDaysStatus = originData.reduce((workDaysStatus, user) => {
        let workData = { UserEmail: user.UserEmail, Status: user.Status, workStatus: [] }
        let originUser = originData.find(x => x.UserEmail == user.UserEmail);
        scheduleDay.weekDays.forEach(element => {
            workData.workStatus.push({
                Name: element.name,
                Status: originUser[element.day],
                Shift: originUser.ShiftWorkDateDetails.length > 0 ? originUser.ShiftWorkDateDetails.find(x => x.DayInMonth == element.day)?.TimeRangeString : '',
                WeekIndex: dayjs(`${originMonthYear}-${element.day.slice(4)}`).isoWeek(),
                Order: workData.workStatus.length < 7 ? 1 : 2
            })

        });
        workDaysStatus.push(workData);
        return workDaysStatus
    }, []);
    var monthArr = calcMonthRender({
        startDate: scheduleDay.destFrom ? scheduleDay.destFrom : scheduleDay.destTo,
        endDate: scheduleDay.destTo
    })
    monthArr.forEach(mon => {
        var m_y = mon.year + '-' + getZero(mon.month);
        var newData = data.filter(x => x.MonthYear == m_y);
        newData.forEach(user => {
            Object.keys(user).forEach(key => {
                if (key.includes('Day_')) {
                    let dayIndex = key.slice(4);
                    let dayName = dayjs(`${m_y}-${dayIndex}`).format('ddd');
                    let currentDate = `${m_y}-${getZero(dayIndex)}`;
                    
                    //1. only import available days for current month
                    //2. catch exception likes '2024-02-30'
                    if (dayjs(currentDate).diff(dayjs(), 'day') >= 0 && checkDateExist(currentDate, 'YYYY-MM-DD')) {
                        let matchData = workDaysStatus.find(u => u.UserEmail == user.UserEmail)
                            .workStatus.find(s => s.Name == dayName && s.Order == findMatchWeek(scheduleDay, currentDate));
                        user[key] = matchData.Status;
                        if (matchData.Shift) {
                            let existTime = user.ShiftWorkDateDetails.find(u => u.DayInMonth == key);
                            if (existTime) {
                                existTime.TimeRangeString = matchData.Shift;
                            } else {
                                user.ShiftWorkDateDetails.push({ DayInMonth: key, TimeRangeString: matchData.Shift });
                            }
                        }
                    }
                }
            });
            user['ChangedList'] = [];
            user.Preview = true;
        })
    })

    return data;
}

function findMatchWeek(schedule, currDateString) {
    let startDate = dayjs(`${schedule.year}-${schedule.month}-${schedule.weekDays[0]?.day.slice(4)}`);
    let endDate = dayjs(`${schedule.year}-${schedule.month}-${schedule.weekDays[schedule.weekDays.length - 1]?.day.slice(4)}`);
    let currentDate = dayjs(currDateString);

    let week1 = startDate.isoWeek();
    let week2 = endDate.isoWeek();
    let currentWeek = currentDate.isoWeek();

    if (currentWeek < week2) {// different year
        let yearDiff = currentDate.$y - endDate.$y;
        for (let i = 0; i < yearDiff; i++) {
            let weekNum = dayjs(`${schedule.year + i}-12-31`).isoWeek();
            currentWeek += weekNum;
        }
    }
    let matchWeek1 = (currentWeek - week1) % 4 == 0 || (currentWeek + 1 - week1) % 4 == 0;

    return matchWeek1 ? 1 : 2;
}

// catch exception likes '2024-02-30'
// dayjs('2024-02-30').format('YYYY-MM-DD') => '2024-03-01'
function checkDateExist(dateString, format) {
    return dayjs(dateString).format(format) === dateString;
}

function getNotification(response) {
    let rsStatus = response.status;
    let messgage = response.message;
    switch (rsStatus) {
        case 400: messgage = 'Invalid Request: 400'; break;
        case 403: messgage = 'Need Permission: 403'; break;
        case 500: messgage = 'Server Issue: 500'; break;
        case undefined: messgage = 'ERROR: ' + messgage; break;
    }
    return messgage;
}

//check valid input for datepicket/timepicker mui 6.18.5
function validDateTime(timeList, setTimeList) {
    let valid = true;
    let nullDatesFrom = timeList.filter(x => x.from == null);
    let nullDatesTo = timeList.filter(x => x.to == null);

    if (nullDatesFrom.length > 0) {
        let indexs = nullDatesFrom.reduce((rs, obj) => {
            rs.push(timeList.indexOf(obj));
            return rs;
        }, [])
        setTimeList(prev => {
            return prev.map(obj => {
                if (indexs.some(x => x == prev.indexOf(obj))) {
                    return { ...obj, from: dayjs('abc') };//set invalidDate for timepicker
                }
                return obj;
            })
        });
        valid = false;
    }
    if (nullDatesTo.length > 0) {
        let indexs = nullDatesTo.reduce((rs, obj) => {
            rs.push(timeList.indexOf(obj));
            return rs;
        }, [])
        setTimeList(prev => {
            return prev.map(obj => {
                if (indexs.some(x => x == prev.indexOf(obj))) {
                    return { ...obj, to: dayjs('abc') };//set invalidDate for timepicker
                }
                return obj;
            })
        });
        valid = false;
    }

    valid = timeList.filter(x => !x.from?.isValid() || !x.to?.isValid()).length == 0;
    return valid;
}

function isWeekend(dayName) {
    return ["Sun", "Sat"].some(name => dayName == name);
}

function getZero(value) {
    return value < 10 ? `0${value}` : value;
}
const leaveTypes = [
    { id: 0, name: "OFF" },
    { id: 3, name: "AL" },
    // { id: 1, name: "WORK" },  
    { id: 2, name: "1HT" },
    { id: 4, name: "2HT" },
]
export { calcMonthRender, isWeekend, leaveTypes, mappingSchedule, getZero, validDateTime, getNotification };