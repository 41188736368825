import React from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useColorScheme } from '@mui/material';
import { changeColor } from './common';
import PropTypes from 'prop-types';

const RCPieChart = (props) => {
    const { data, dataKey, seriesList, width, height, customLabel, customTooltip, legend, elevation, chartSize } = props;
    const CustomLabel = customLabel || undefined;
    const CustomTooltip = customTooltip || undefined;
    const RADIAN = Math.PI / 180;
    var { mode } = useColorScheme();

    const convertData = (item) => {      
        return seriesList.reduce((final,serie) => {
            final.push({ name: serie.name, value: item[serie.dataKey], fill: serie.fill })
            return final;
        }, [])
    }

    const DefaultLabel = (props) => {
        const { cx, cy, midAngle, innerRadius, outerRadius, percent, index } = props;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (<>
            {percent && <text
                x={x}
                y={y}
                fill="white"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
                style={{ fontSize: '13px' }}
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>}
        </>
        );
    }

    const DefaultTooltip = (props) => {
        const { active, payload } = props;
        if (active && payload && payload.length) {
            return (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 40 }} size="small" aria-label="a dense table">
                        <TableBody>
                            {payload.map((row) => (
                                <TableRow key={`pietooltip-${row.name}`}>
                                    <TableCell align='right'>
                                        <svg height={6 * 2} width={6 * 2}>
                                            <circle cx={6} cy={6} r={6} style={{ fill: row.payload.fill }} />
                                        </svg>
                                    </TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="right">{row.value}</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        } else return null;
    }

    const renderColorText = (value, entry, index) => {
        return <span style={{ color: changeColor(mode) }}>{value}</span>
    }

    return (
        <>
            {data.map((item, dIndex) => {
                var newItem = convertData(item);
                return <Grid item  key={`piechar-${dIndex}`}>
                    <Paper elevation={elevation} style={{ textAlign: 'center', padding: '10px' }}>
                        <Typography variant="h5" textAlign={'center'}>{item[dataKey]}</Typography>
                        <PieChart width={width} height={height}>
                            <Legend {...legend} formatter={renderColorText} />
                            <Tooltip content={CustomTooltip ? <CustomTooltip /> : <DefaultTooltip item={item} />} />
                            <Pie
                                data={newItem}
                                dataKey={'value'}
                                cx="50%"
                                cy="50%"
                                outerRadius={chartSize}
                                label={CustomLabel ? <CustomLabel /> : <DefaultLabel />}//https://recharts.org/en-US/examples/PieChartWithCustomizedLabel                                          
                                isAnimationActive={false}
                                labelLine={false}
                            >
                                {newItem.map((obj, index) => (
                                    <Cell key={`cell-${index}`} fill={obj.fill} />
                                ))}
                            </Pie>
                        </PieChart>
                    </Paper>
                </Grid>
            })}
        </>
    )
}

RCPieChart.defaultProps = {
    seriesList: [],
    data: [],
    width: 450,
    height: 450,
    legend: { layout: 'vertical', align: 'right', verticalAlign: 'middle', iconType: 'square' },
    elevation: 0,
    chartSize: 80
}
RCPieChart.propTypes = {   
    /**Array contains definition of cell will be render in chart 
     * Ex: seriesList={[
                      { dataKey: 'processingFailed', name: 'Processing Failed', fill: '#f44336'},
                      { dataKey: 'processed', name: 'Processed', fill: '#4caf50' }]}
    **dataKey: match for value of each cell in circle
    **fill: cell color
    **name: cell name which is displayed at Legend
    */
    seriesList: PropTypes.array,
    /**Array of data will be used for render */
    data: PropTypes.array,
    /**key for getting name of pie chart */
    dataKey: PropTypes.string,
    /**container width */
    width: PropTypes.number,
    /**container height */
    height: PropTypes.number,
    legend: PropTypes.object,
    /**background color weight: read [Paper] of MUI */
    elevation: PropTypes.number,
    /**Size of chart, default value is 80 */
    chartSize: PropTypes.number
}
export default RCPieChart;