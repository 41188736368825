import React, { useEffect, useState, useRef } from "react";
import { formatLocalTime, dayjs, toLocalTime, formatDateTime } from "../../../components/ex_dayjs";
import { DatagridConfigurable, FunctionField, TextField } from "react-admin";
import RenderControls from "../../../components/ra-list/RenderControls";
import RaList from "../../../components/ra-list/RaList";
import { autoLoad, getRaStore, loadSourceType, setDefaultValuesFromURL, setRaStore } from "../../../utils/common";
import { dataProvider } from "../../../DataProvider";
import ChartView from "./component/chart-view";
import { FilterListProvider } from "../../../context/FilterListProvider";
import ChartButton from "./component/chart-button";
import { FormatDate } from "./component/formatter";



const MappingErrorReport = () => {
    const refbtnFilter = useRef(null);
    const [defaultSort] = useState(['reported_date desc']);
    const [defaultValueFilter, setDefaultValuesFilter] = useState({
        reported_date: [dayjs().startOf('M'), dayjs()]
    });
    const [marketType, setMarketType] = useState([]);
    const [chart, setChart] = useState({ open: false, type: 'bar' });

    useEffect(() => {
        loadSourceType(dataProvider, (response) => {
            setMarketType(response);
        });

        if (location.search) {
            var newDefaultValue = { ...defaultValueFilter };
            setDefaultValuesFromURL(location.search, newDefaultValue, controlsFilter);
            setDefaultValuesFilter(newDefaultValue);
        }
        autoLoad(refbtnFilter);

    }, []);

    const controlsFilter = [
        { component: 'select', name: 'market_type_eq', label: 'Type', choices: marketType || [] },
        { component: 'text', name: 'receiver_contains', label: 'Mapped By' },
        { component: 'daterange', name: 'reported_date', label: 'Date', xs: 2.3 },
        { component: 'text', name: 'note_contains', label: 'Error' },
        { component: 'number', name: 'order_number_eq', label: 'Order Number' },
    ]

    const openChartView = (filterList) => {
        setChart(prev => ({ ...prev, open: true, filterList: filterList }));
    };

    return <>
        <RaList
            sortby={defaultSort}
            perPage={25}
            title={'Mapping Error Report'}            
            filters={
                <FilterListProvider>
                    <RenderControls
                        refFilter={refbtnFilter}
                        controls={controlsFilter}
                        defaultValues={defaultValueFilter}
                        useHashUrl
                        selectColumn={true}
                        actions={<ChartButton callback={openChartView} />}
                        exportFile={{
                            filename: `MappingErrorReport-${formatLocalTime(new Date(), 'YY-MM-DD')}`,
                            columns: ['order_number', 'receiver', 'occured_date', 'note', 'market_type', 'primary_event', 'sm_event', 'primary_venue', 'sm_venue',
                                'primary_city', 'sm_city', 'primary_state', 'sm_state', 'primary_datetime', 'sm_datetime', 'score'],
                            limit: 1000000
                        }}
                    />
                </FilterListProvider>
            }
        >
            <DatagridConfigurable omit={['reported_by', 'reported_date']}
                bulkActionButtons={false}
            >
                <TextField source="reported_by" label={'Reported By'} />
                <FunctionField source="reported_date" label={'Reported Date'} render={record => <FormatDate formatFunc={toLocalTime} datetime={record.reported_date} useFormat />} />
                <TextField source="order_number" label={'Order Number'} />
                <TextField source="receiver" label={'Mapped By'} />
                <FunctionField source="occured_date" label={'Mapped Time'} render={record => <FormatDate formatFunc={toLocalTime} datetime={record.occured_date} useFormat />} />
                <TextField source="note" label={'Error'} />
                <TextField source="market_type" label={'Type'} />
                <FunctionField source="primary_event" label={'Name'} render={record =>
                    <a href={record.primary_url == "" ? null : record.primary_url} target="_blank" style={{ color: record.primary_url ? 'orange' : '' }}>{record.primary_event}</a>}
                />
                <FunctionField source="sm_event" label={'SmEvent'} render={record =>
                    <a href={record.sm_url == "" ? null : record.sm_url} target="_blank" style={{ color: record.sm_url ? 'orange' : '' }}>{record.sm_event}</a>}
                />
                <TextField source="primary_venue" label={'Venue'} />
                <TextField source="sm_venue" label={'SmVenue'} />
                <TextField source="primary_city" label={'City'} />
                <TextField source="sm_city" label={'SmCity'} />
                <TextField source="primary_state" label={'State'} />
                <TextField source="sm_state" label={'SmState'} />
                <FunctionField source="primary_datetime" label={'DateTime'} render={record => <FormatDate formatFunc={formatDateTime} datetime={record.primary_datetime} />} />
                <FunctionField source="sm_datetime" label={'SmDateTime'} render={record => <FormatDate formatFunc={formatDateTime} datetime={record.sm_datetime} />} />
                <TextField source="score" label={'Score'} />
            </DatagridConfigurable>
            {chart.open && <ChartView chart={chart} setChart={setChart} />}
        </RaList >
    </>
}
const MappingErrorReportPage = {
    name: 'MappingErrorReport',
    list: MappingErrorReport
}
export default MappingErrorReportPage;