import * as React from 'react';
import RenderControls from '../../../components/ra-list/RenderControls';
import { Button } from '@mui/material';
import RaList from '../../../components/ra-list/RaList';
import { Link } from "@mui/material";
import UrlField from './components/UrlField';
import {
    FunctionField,
    TextField,
    NumberField,
    Datagrid,
} from 'react-admin';
import { formatDateTime, dayjs } from "../../../components/ex_dayjs";
const URL = `http://www.ticketclub.com/ResultsTicket.aspx?evtid=`;

const LMUnderservedEvents = () => {
    const fromTime = dayjs(dayjs.utc().tz("America/Los_Angeles")).format('YYYY-MM-DD') + ' 00:00:00Z';
    const toTime = dayjs(fromTime).add(7, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss') + 'Z';
    const [sortModel] = React.useState(["totalSales desc"]);
    const filters = [
        { component: 'daterange', name: 'sm_event_date', label: 'Event Date', xs: 2.5 },
        { component: 'numberrange', name: 'totalSales', label: 'Total sales', xs: 2 },
        { component: 'numberrange', name: 'TicketAvailable', label: 'Tickets available', xs: 2.5 }
    ];
    const [defaultValues] = React.useState({
        sm_event_date: [fromTime, toTime],
        totalSales: [null, 50]
    });
    return (
        <RaList
            perPage={50}
            title={`Last Minute Underserved Events`}
            sortby={sortModel}
            filters={
                < RenderControls
                    exportFile={{
                        filename: `LastMinuteUnderservedEvents-${formatDateTime(new Date(), 'YY-MM-DD')}`,
                        columns: ['sm_original_id', 'sm_event_date', 'sm_event_name', 'sm_event_venue', 'TotalSales', 'TicketAvailable']
                    }}
                    controls={filters}
                    defaultValues={defaultValues}
                />
            }
        >
            <Datagrid bulkActionButtons={false}>
                <UrlField source="sm_original_id" attachUrl={URL} label='Id' />
                <FunctionField label="Event Date" render={record => formatDateTime(record.sm_event_date)} />
                <TextField label="Event" source="sm_event_name" />
                <TextField label="Venue" source="sm_event_venue" />
                <NumberField label="Total Sales" source="totalSales" />
                <NumberField label="Ticket Available" source="ticketAvailable" />
            </Datagrid>
        </RaList >
    );
}

export default LMUnderservedEvents;