import * as React from 'react';
import { matchPath } from 'react-router-dom';
import {
    Create, Edit, useNotify, useRefresh, useRedirect, TabbedForm, TextInput, required,
    Toolbar, SaveButton, ListButton,
    useCreate, useUpdate, DeleteWithConfirmButton,
    useRecordContext,
    BooleanInput,
    SelectInput,
    AutocompleteArrayInput
} from 'react-admin';
import { Grid } from '@mui/material';
import { getRaStore } from '../../utils/common';
import { sharedTags } from '../orders/order-base/vendor';

const PostForm = () => {
    var dictionaries = {
        category: getRaStore('GetAAT_Category'),
        subCategory: getRaStore('GetAAT_SubCategory'),
        tags: ['Tickets On Sale', 'Rejected', 'Mutual Cancellation', "Preconfirm Rejected", "Auto-Rejected"].concat(sharedTags)
    };
    const isNew = matchPath('/aat_primaryaccount/create/*', location.pathname);
    const Form = () => {
        const record = useRecordContext();
        const redirect = useRedirect();
        const notify = useNotify();
        const refresh = useRefresh();
        const [updating] = isNew ? useCreate() : useUpdate();

        const handleSubmit = (data) => {
            updating('AAT_PrimaryAccount', { id: data.id, data: data },
                {
                    onSuccess: () => {
                        notify('Changes updated', { type: 'success' });
                        redirect('/AAT_PrimaryAccount');
                        refresh();
                    },
                    onError: (error) => {
                        notify(`Save fail: ${error.message}`, { type: 'error' });
                    }
                }
            );
        };

        const transformTagsToArray = (tags) => {
            if (typeof tags === 'string') {
                return tags.split(',').filter(x => x);
            }
            return tags;
        };

        const MyToolbar = () => {
            return (
                <Toolbar>
                    <SaveButton />
                    <ListButton />
                    {
                        !isNew && <DeleteWithConfirmButton translateOptions={{ name: record.email }} />
                    }
                </Toolbar>
            );
        };
        return (
            <TabbedForm defaultValues={({ status: true })} toolbar={<MyToolbar />} mode="onBlur" reValidateMode="onBlur" onSubmit={handleSubmit}>
                <TabbedForm.Tab label="Account">
                    <Grid container columnSpacing={2}>
                        <Grid item xs={6}>
                            <TextInput source="email" validate={required()} resettable />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput source="name" validate={required()} resettable />
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2} alignItems={"end"}>
                        <Grid item xs={4}>
                            <TextInput label="Pwd TM" source="pwd_TM" multiline minRows={2} resettable />
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput label="Pwd 3TM" source="pwd_3TM" multiline minRows={2} resettable />
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput label="CC Type" source="cC_Type" resettable />
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={6}>
                            <SelectInput label="Category" source="category" validate={required()} choices={dictionaries.category} emptyText={false} emptyValue={false} translateChoice={false} resettable />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectInput label="Sub Category" source="sub_Category" choices={dictionaries.subCategory} translateChoice={false} resettable />
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={4}>
                            <TextInput source="address" resettable />
                        </Grid>
                        <Grid item xs={2}>
                            <TextInput source="city" resettable />
                        </Grid>
                        <Grid item xs={2}>
                            <TextInput source="state" resettable />
                        </Grid>
                        <Grid item xs={2}>
                            <TextInput source="zip" resettable />
                        </Grid>
                        <Grid item xs={2}>
                            <TextInput source="phone" resettable />
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2} alignItems={"end"}>
                        <Grid item xs={4}>
                            <AutocompleteArrayInput
                                label="Tags"
                                source="tags"
                                format={transformTagsToArray}
                                parse={(value) => value.join(',')}
                                choices={dictionaries.tags}
                                translateChoice={false}
                                resettable />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput source="notes" resettable multiline minRows={2} />
                        </Grid>
                        <Grid item xs={2}>
                            <BooleanInput label="Active" source="status" />
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
            </TabbedForm>
        );
    };

    return isNew ? (<Create ><Form /></Create>) : (<Edit ><Form /></Edit>);
};

export default PostForm;