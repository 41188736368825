import * as React from 'react';
import { TextField, Grid } from '@mui/material';

const MTextRange = ({ name, onChange, defaultValue, ...props }) => {
    const [state, setState] = React.useState({ from: defaultValue?.[0], to: defaultValue?.[1] });

    React.useEffect(() => {
        setState({ ...state, from: defaultValue?.[0] ?? null, to: defaultValue?.[1] ?? null });
    }, [defaultValue])

    const handleChange = (e, label, op) => {
        const { value } = e.target;
        setState((prev) => ({ ...prev, [label]: value }));
        onChange(value, `${name}_${op}`);
    }

    return (
        <Grid container>
            <Grid item xs={6}>
                <TextField {...props} defaultValue={state.from} onChange={(e) => handleChange(e, 'from', 'ge')} type="search" inputProps={{ autoComplete: 'on' }} />
            </Grid>
            <Grid item xs={6}>
                <TextField {...props} label="to" defaultValue={state.to} onChange={(e) => handleChange(e, 'to', 'le')} type="search" inputProps={{ autoComplete: 'on' }} />
            </Grid>
        </Grid>
    )
}


export default React.memo(MTextRange);