import { Email } from '../../types';
import { default as GeneratedKnownDomains } from './KnownDomains';
const OverrideDomains = {
    'ticketmaster': /(ticketmaster|livenation)/,
    'ticketnetwork': /(ticketnetwork)/,
    'vividseats': /(vividseats)/,
    'seatgeek': /(seatgeek)/,
    'paciolan': /(paciolanmail|ticketsalberta)/,
    'tdc': /(mlb\.com)/,
};
const knownDomains = Object.entries(OverrideDomains).concat(Object.entries(GeneratedKnownDomains));
const KnownDomains = Object.assign({}, GeneratedKnownDomains, OverrideDomains);

export function categorize(email: Email): string {
    for (const [k, v] of knownDomains) {
        if (v.test(email.from)) {
            return k;
        }
    }
    return email.from.split('@')[1].split('.')[0];
}
export default KnownDomains;
// export default OverrideDomains;
// export default GeneratedKnownDomains;
