import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Slide,
    Divider,
    Stack
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MBackdrop from './MBackdrop';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CDialog = styled(Dialog)(({ theme, height }) => ({
    '& .MuiDialog-paper': {
        maxHeight: 'calc(100% - 64px)', // 64px is the height of the AppBar in MUI
        width: '100%',
        height: 'auto',
        margin: 0,
        borderRadius: 0,
        boxShadow: 'none'
    },
    '& .MuiDialog-scrollPaper': {
        overflow: 'auto'
    },
    '& .MuiDialog-paperScrollPaper': {
        height: height
    }
}));


const MDialog = ({ children, height, name, title, action, open, handleClose, disabled, msgLoading, footer = true, ...props }) => {
    return (
        <CDialog
            {...props}
            fullWidth
            height={height}
            disabled={disabled}
            aria-label={name}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}>
            <DialogTitle>{title}</DialogTitle>
            <Divider />
            <DialogContent>
                <DialogContentText>
                    {children}
                </DialogContentText>
            </DialogContent>
            <Divider />
            {footer && (
                <DialogActions>
                    {action}
                    <Stack direction="row" spacing={1}>
                        <Button onClick={handleClose} variant='outlined' aria-label={name}>Close</Button>
                    </Stack>
                </DialogActions>
            )}
            <MBackdrop isOpen={disabled} message={msgLoading} />
        </CDialog>
    );
}

export default React.memo(MDialog);