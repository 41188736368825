import * as React from 'react';
import { Box } from '@mui/material';
function ActionColumn(params) {
  let editingCells = params.api.getEditingCells();
  // checks if the rowIndex matches in at least one of the editing cells
  let isCurrentRowEditing = editingCells.some((cell) => {
    return cell.rowIndex === params.node.rowIndex;
  });

  if (isCurrentRowEditing) {
    return (
      <Box component="form" sx={{ '& > :not(style)': { m: 1 }, }}>
        <button type='button' data-action="update"> Update  </button>
        <button type='button' data-action="cancel" > Cancel </button>
      </Box>
    );
  } else {
    return (
      <Box component="form" sx={{ '& > :not(style)': { m: 1 }, }}>
        <button type='button' data-action="edit" > Edit  </button>
        <button type='button' data-action="delete" > Delete </button>
      </Box>
    );
  }
};

export { ActionColumn };