import * as React from 'react';
import { useCallback } from 'react';
import { Identifier, List, DateInput, TextInput, TextField, DateField, Datagrid } from 'react-admin';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { Box, Drawer } from '@mui/material';
import EmailView from './EmailView';
import rowStyle from './RowStyle';
import CategoryField from './CategoryField';
import HighlightedTextField from './HighlightedTextField';
import { RaPagination } from '../../components/ra-list/RaList';
export interface EmailListDesktopProps {
    selectedRow?: Identifier;
}


const EmailList = ({ selectedRow }: EmailListDesktopProps) => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleClose = useCallback(() => {
        navigate('/email');
    }, [navigate]);

    const match = matchPath('/email/:id', decodeURIComponent(location.pathname));
    const emailFilters = [
        <TextInput label="Search" source="q" alwaysOn />,
        // <SelectInput source="mailbox" choices={} />,
        <TextInput source="to" alwaysOn />,
        <TextInput source="from" alwaysOn />,
        <TextInput source="subject" alwaysOn />,
        <TextInput label="Body" source="bodyText" alwaysOn />,
        <DateInput label="After" source="date_ge" alwaysOn />, //alwaysOn
        <DateInput label="Before" source="date_le" alwaysOn />,
    ];

    return (
        <Box display="flex">
            <List
                storeKey={false}
                disableSyncWithLocation
                // filterDefaultValues={{ date_ge: new Date((new Date().getTime() - 15 * 24 * 60 * 60 * 1000)).toISOString() }}
                filterDefaultValues={{ date_ge: new Date(Date.parse('2020-01-01')).toISOString() }}
                exporter={false}
                perPage={25}
                pagination={<RaPagination />}
                sort={{
                    field: 'date',
                    order: 'DESC'
                }}
                filters={emailFilters}
                queryOptions={{ meta: { q1: ['bodyText', 'subject'], '$_select': ['date', 'subject', 'from', 'to'] } }}
                sx={{
                    flexGrow: 1,
                    transition: (theme) =>
                        theme.transitions.create(['all'], {
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                    marginRight: !!match ? '800px' : 0,
                }}>
                <Datagrid
                    bulkActionButtons={false}
                    rowClick="edit"
                    rowStyle={rowStyle(selectedRow)}
                    sx={{
                        // '& .column-date': {
                        //     display: !!match ? 'none' : 'table-cell'
                        // },
                        '& .column-to': {
                            display: !!match ? 'none' : 'table-cell'
                        },
                        '& .column-from': {
                            display: !!match ? 'none' : 'table-cell'
                        },
                    }}>
                    <CategoryField size="small" />
                    <DateField source="date" options={{ year: 'numeric', month: 'short', day: 'numeric' }} />
                    {/* <TextField source="highlight.subject[0]" label="Subject" cellRenderer={highlightText} /> */}
                    <HighlightedTextField source="highlight.subject[0]" label="Subject" />
                    <TextField source="to" />
                    <TextField source="from" />
                </Datagrid>
                <Drawer
                    variant="persistent"
                    open={!!match}
                    anchor="right"
                    onClose={handleClose}
                    sx={{ zIndex: 100 }}
                >
                    {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                    {!!match && (
                        <EmailView
                            id={(match).params.id}
                            onCancel={handleClose}
                        />
                    )}
                </Drawer>
            </List>

        </Box>
    );
};

export default EmailList;
