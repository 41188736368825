const state = [
    {
        label: 'US',
        options: [
            { id: 'AL', name: 'Alabama' },
            { id: 'AK', name: 'Alaska' },
            { id: 'AZ', name: 'Arizona' },
            { id: 'AR', name: 'Arkansas' },
            { id: 'CA', name: 'California' },
            { id: 'CO', name: 'Colorado' },
            { id: 'CT', name: 'Connecticut' },
            { id: 'DE', name: 'Delaware' },
            { id: 'DC', name: 'District of Columbia' },
            { id: 'FL', name: 'Florida' },
            { id: 'GA', name: 'Georgia' },
            { id: 'HI', name: 'Hawaii' },
            { id: 'ID', name: 'Idaho' },
            { id: 'IL', name: 'Illinois' },
            { id: 'IN', name: 'Indiana' },
            { id: 'IA', name: 'Iowa' },
            { id: 'KS', name: 'Kansas' },
            { id: 'KY', name: 'Kentucky' },
            { id: 'LA', name: 'Louisiana' },
            { id: 'ME', name: 'Maine' },
            { id: 'MD', name: 'Maryland' },
            { id: 'MA', name: 'Massachusetts' },
            { id: 'MI', name: 'Michigan' },
            { id: 'MN', name: 'Minnesota' },
            { id: 'MS', name: 'Mississippi' },
            { id: 'MO', name: 'Missouri' },
            { id: 'MT', name: 'Montana' },
            { id: 'NE', name: 'Nebraska' },
            { id: 'NV', name: 'Nevada' },
            { id: 'NH', name: 'New Hampshire' },
            { id: 'NJ', name: 'New Jersey' },
            { id: 'NM', name: 'New Mexico' },
            { id: 'NY', name: 'New York' },
            { id: 'NC', name: 'North Carolina' },
            { id: 'ND', name: 'North Dakota' },
            { id: 'OH', name: 'Ohio' },
            { id: 'OK', name: 'Oklahoma' },
            { id: 'OR', name: 'Oregon' },
            { id: 'PA', name: 'Pennsylvania' },
            { id: 'RI', name: 'Rhode Island' },
            { id: 'SC', name: 'South Carolina' },
            { id: 'SD', name: 'South Dakota' },
            { id: 'TN', name: 'Tennessee' },
            { id: 'TX', name: 'Texas' },
            { id: 'UT', name: 'Utah' },
            { id: 'VT', name: 'Vermont' },
            { id: 'VA', name: 'Virginia' },
            { id: 'WA', name: 'Washington' },
            { id: 'WV', name: 'West Virginia' },
            { id: 'WI', name: 'Wisconsin' },
            { id: 'WY', name: 'Wyoming' },
        ]
    },
    {
        label: 'CA',
        options: [
            { id: 'AB', name: 'Alberta' },
            { id: 'BC', name: 'British Columbia' },
            { id: 'MB', name: 'Manitoba' },
            { id: 'NB', name: 'New Brunswick' },
            { id: 'NL', name: 'Newfoundland and Labrador' },
            { id: 'NT', name: 'Northwest Territories' },
            { id: 'NS', name: 'Nova Scotia' },
            { id: 'NU', name: 'Nunavut' },
            { id: 'ON', name: 'Ontario' },
            { id: 'PE', name: 'Prince Edward Island' },
            { id: 'QC', name: 'Quebec' },
            { id: 'SK', name: 'Saskatchewan' },
            { id: 'YT', name: 'Yukon' }
        ]
    }
]

const lmstatus = [
    { id: 0, name: "Not specified" },
    { id: 1, name: "Purchased" },
    { id: 2, name: "Sold Out" },
    { id: 3, name: "Cheap" },
    { id: 4, name: "Check Back" },
    { id: 5, name: "Blacklist Venue" },
    { id: 6, name: "Not On Sale" }
]
const normalstatus = [
    { id: 0, name: "UNKNOW" },
    { id: 1, name: "SOLD OUT" },
    { id: 2, name: "TOO CHEAP" },
    { id: 3, name: "PURCHASED" },
]
const tnMode = { normal: 0, normalwithstatus: 1, lastminute: 2 }
export { state, lmstatus, normalstatus, tnMode };