import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import RenderControls from '../../components/ra-list/RenderControls';
import { Button, Stack } from '@mui/material';
import RaList from '../../components/ra-list/RaList';
import { dataProvider } from '../../DataProvider';
import SearchEvent from './components/SearchEvent'
import InsertEvent from './components/InsertEvent';
import EventActionPopUp from './components/EventActionPopUp';
import {
    FunctionField,
    TextField,
    Datagrid,
    useNotify
} from 'react-admin';
import { formatDateTime, dayjs } from "../../components/ex_dayjs";


const ReceiptsCounter = () => {
    const [sortModel] = React.useState(["EventDate desc"]);
    const [defaultValueFilter, setDefaultValueFilter] = useState({
        site_id_eq: '',
        // EventDate: [fromTime, toTime]
    });
    const filters = [
        { component: 'text', name: 'site_id_eq', label: 'Event ID', xs: 1.5 },
        { component: 'text', name: 'EventName_eq', label: 'Event Name', xs: 1.5, },
        { component: 'date', name: 'EventDate_eq', label: 'Event Date', xs: 1.5 }
    ];

    // const refFilter = useRef();
    // const notify = useNotify();
    // const fromTime = dayjs(dayjs.utc().tz("America/Los_Angeles")).format('YYYY-MM-DD') + ' 00:00:00Z';
    // const toTime = dayjs(fromTime).add(7, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss') + 'Z';
    // const [open, setOpen] = useState(false);
    // const [search, setSearch] = useState(true);
    // const [viewSearch, setViewSearch] = useState(false);

    // const handleUpdate = (e, param, temp) => {
    //     const fromTime = dayjs(e.value).format('YYYY-MM-DD') + ' 00:00:00Z';
    //     const toTime = dayjs(fromTime).endOf('day').format('YYYY-MM-DD HH:mm:ss') + 'Z';
    //     setDefaultValueFilter(prev => ({ ...prev, EventDate_lt: toTime, EventDate_gt: fromTime }));
    // }

    // const handleViewClick = (id) => {
    //     setDefaultValueFilter(() => ({ id_eq: id }));
    //     setOpen(false);
    //     setViewSearch(true);
    // }

    // const handleInsert = (link) => {
    //     dataProvider.postData('ReceiptsCounter', 'InsertEvent', { link: link })
    //         .then((response) => {
    //             if (response && response.data) {
    //                 notify('Update Success', { type: 'success', autoHideDuration: 5000 });
    //                 // setOpenConfirm(false);
    //                 // onClose();
    //             } else {
    //                 notify("Fail to load", { type: 'error', autoHideDuration: 5000 });
    //             }
    //         })
    //         .catch(e => {
    //             console.log(e);
    //             notify("Something went wrong", { type: 'error', autoHideDuration: 5000 });
    //         });
    // }
    // const handleSearchReceipts = (filterList, a, b, c) => {
    //     console.log(recepit);
    //     refFilter.current.click()
    //     dataProvider.getList('ReceiptsCounter', recepit)
    //         .then((response) => {
    //             if (response && response.data) {
    //                 notify('Update Success', { type: 'success', autoHideDuration: 5000 });
    //                 // setOpenConfirm(false);
    //                 // onClose();
    //             } else {
    //                 notify("Fail to load", { type: 'error', autoHideDuration: 5000 });
    //             }
    //         })
    //         .catch(_ => {
    //             notify("Something went wrong", { type: 'error', autoHideDuration: 5000 });
    //         });
    // }
    // const handleActionClick = (isSearch) => {
    //     setOpen(true);
    //     setSearch(isSearch);
    // }

    // useEffect(() => {
    //     if (viewSearch) {
    //         setTimeout(() => {
    //             refFilter.current.click();
    //             setViewSearch(false);
    //         }, 100);
    //     }
    // }, [viewSearch]);

    return (
        <>
            <RaList
                perPage={25}
                title={`Receipts Counter`}
                sortby={sortModel}
                filters={
                    < RenderControls
                        filterInline={true}
                        // refFilter={refFilter}
                        actions={
                            <>
                                {/* <Button onClick={() => handleActionClick(true)} variant="contained">Search events</Button>
                                <Button onClick={() => handleActionClick(false)} variant="contained">Insert event</Button> */}
                            </>
                        }
                        controls={filters}
                        defaultValues={defaultValueFilter}
                    />
                }
            >
                <Datagrid bulkActionButtons={false}>
                    <TextField label="User email" source="mailUser" />
                    <TextField label="Tix number" source="count" />
                    <TextField label="Receipt numbers" source="orderNumbers" />
                    <TextField label="Event name" source="eventName" />
                    <FunctionField label="Event date" source="eventDate" render={record => formatDateTime(record.eventDate)} />
                </Datagrid>
            </RaList >
            {/* <EventActionPopUp open={open} handleClose={() => setOpen(false)} title={search ? 'Search Events' : 'Insert New Event'}>
                {search ? <SearchEvent handleViewClick={handleViewClick} /> :
                    <InsertEvent handleInsert={handleInsert} />}
            </EventActionPopUp> */}

        </>
    );
}

export default ReceiptsCounter;