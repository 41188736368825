function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

function log(...args) {
    // Retrieve the stored output array from local storage
    const storedOutput = JSON.parse(localStorage.getItem("consoleOutput")) || [];

    // Add the new output (args array) to the array
    storedOutput.push({
        args,
        url: document.location.href,
        timestamp: new Date().getTime()
    });

    // Store the updated output array in local storage
    localStorage.setItem("consoleOutput", JSON.stringify(storedOutput));

    // Log the output to the console
    console.log(...args);
}


const getProfileFromToken = (tokenJson) => {
    const token = JSON.parse(tokenJson);
    const jwt = parseJwt(token.jwt);
    return { id: 'my-profile', ...jwt }
}

async function exchangeOTPforJWT(otp) {
    const response = await fetch('https://identity.songtek.net/jwt/exchange', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ otp })
    });
    if (!response.ok) {
        throw new Error(await response.text());
    }
    let data = await response.json();
    return data;
}
const authProvider = {
    login: async ({ otp }) => {
        if (!otp) {
            throw new Error('Unset OTP');
        }
        log('login: exchangeOTPforJWT and store token into local storage');
        // Transform the code to a token via the API        
        const token = await exchangeOTPforJWT(otp);
        localStorage.setItem('token', JSON.stringify(token));
        // cleanup();
        return Promise.resolve({ redirectTo: '/' });
    },
    logout: () => {
        log('logout: remove token from local storage');
        localStorage.removeItem('token');
        sessionStorage.clear();
        debugger;
        return Promise.resolve('');
        // return Promise.resolve('https://hello2.songtek.net/Logout');
    },
    checkAuth: () => {
        const token = localStorage.getItem('token');

        if (!token) {
            log('checkAuth: false. Reason: token not found in local storage');
            return Promise.reject(/*{ redirectTo: '/no-access' }*/);
        }

        // This is specific to the Google authentication implementation
        const jwt = getProfileFromToken(token);
        const now = new Date();
        const hasExpired = now.getTime() > (jwt.exp * 1000);
        log(`checkAuth: ${hasExpired}. Reason: check hasExpired=${hasExpired}`);
        return hasExpired ? Promise.reject() : Promise.resolve()
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 /*|| status === 403*/) {
            log('checkError: remove token from local storage. Reason: ' + status);
            localStorage.removeItem('token');
            return Promise.reject();
        }
        if (status === 403) {
            log('checkError: redirect to /error/403. Reason: ' + status);
            return Promise.reject({ redirectTo: '/error/403', logoutUser: false });
        }
        log('checkError: other error code (404, 500, etc): no need to log out. Reason: ' + status);
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getIdentity: () =>
        Promise.resolve({
            id: 'user',
            fullName: 'John Doe',
        }),
    getPermissions: () => Promise.resolve(''),
    getJWT: () => {
        const tokenJson = localStorage.getItem('token');
        if (!tokenJson) {
            throw new Error("Not logged in");
        }
        const token = JSON.parse(tokenJson);
        return token.jwt;
    },
};

export default authProvider;