import React, {
  forwardRef,
  memo, 
  useImperativeHandle, 
  useRef,
  useState,
} from 'react';
import { useNotify } from 'react-admin';
const NumericEditor = memo(forwardRef((props, ref) => {

  const [value, setValue] = useState(parseFloat(props.value));
  const [inValidColor, setInValidColor] = useState('');
  const refInput = useRef(null); 
  const notify = useNotify();

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      focus(){
        refInput.current.focus();
      },
      // the final value to send to the grid, on completion of editing
      getValue() {
        // this simple editor doubles any value entered into the input
        return value;
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart() {
        return false;
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        // our editor will reject any value greater than 10000000
        return value > 1000000;
      },
    };
  });
  return (
    <input
      type="number"
      ref={refInput}
      value={value}
      onChange={(event) => setValue(event.target.value)}     
      onKeyDownCapture={(event) => {
        if(event && event.key == 'Enter'){
          if(value != props.value){
            notify("You have unsaved data! Please press Update button.", { type: 'warning'})
            setInValidColor('#ffcc00');
            event.preventDefault();
          } else {
            setInValidColor('');
          }          
        }
      }}
      
      className="doubling-input" 
      style={{ width: '100%', height: "100%", color: inValidColor }}       
    />
  );
})
);
export default NumericEditor;