import * as React from 'react';
import { SaveButton, Toolbar } from 'react-admin';
import { Stack, Button } from '@mui/material';

const style = {
    float: 'right',
    right: '20px',
};

const MyToolbar = ({ onCancel, load }) => {
    return (
        <Toolbar sx={style}>
            <Stack direction="row" spacing={2}>
                <SaveButton disabled={load} label="Save" size="small" />
                <Button onClick={onCancel} variant="outlined" size="small">
                    Close
                </Button>
            </Stack>
        </Toolbar>
    );
};

export default MyToolbar;
