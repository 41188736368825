import React, { useState } from "react";
import { Popover, TableBody, Table, TableCell, TableContainer, TableHead, TableRow, Paper, LinearProgress } from "@mui/material";
import { formatLocalTime } from "../../../components/ex_dayjs";
import { useDataProvider } from "react-admin";

const History = (props) => {
    const { open, btnId, rowData } = props.info;
    const setHistoryPopover = props.setHistoryPopover;
    const gridApi = props.api;
    const dataProvider = useDataProvider();
    const [isLoading, setIsLoading] = useState(false);
    const [historyData, setHistoryData] = useState(null)

    var btnPosition;
    if (btnId != null)
        btnPosition = document.getElementById(btnId).getBoundingClientRect();

    const id = open ? 'simple-popover' : undefined;

    const handleClose = (params) => {
        setHistoryPopover(prev => ({ ...prev, open: false }));
    }

    React.useEffect(() => {
        setIsLoading(true);
        dataProvider.postData('NgaVenueConfig', 'GetHistory', rowData)
            .then(response => {
                if (response.status != 200 && response.data) {                   
                    setHistoryData(response.data);                   
                }
            }).catch(err => console.log('Somthing went wrong: ', err)).finally(_ => setIsLoading(false))
    }, []) 

    return (
        <Popover
            id={id}
            open={open}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={{ top: btnPosition?.top + 45, left: btnPosition?.left }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <TableContainer component={Paper} sx={{
                pt: 1,
                borderRadius: 'sm',
                transition: '0.3s',
                background: (theme) =>
                    `linear-gradient(45deg, ${theme.vars.palette.primary[500]}, ${theme.vars.palette.primary[400]})`,
                '& tr:last-child': {
                    '& td:first-child': {
                        borderBottomLeftRadius: '8px',
                    },
                    '& td:last-child': {
                        borderBottomRightRadius: '8px',
                    },
                },
            }}>               
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Section</TableCell>
                            <TableCell>First Row</TableCell>                          
                            <TableCell>Last Row</TableCell>
                            <TableCell>By</TableCell>    
                            <TableCell>Date</TableCell>                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {isLoading && <TableRow><TableCell colSpan={5}><LinearProgress color="secondary" /></TableCell></TableRow>}
                        {historyData?.length > 0 && historyData.map((row) => {
                            let rowJson = JSON.parse(row.jsonInfo);
                            return (<TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">{row.description}</TableCell>
                                <TableCell align="left">{rowJson.OldFirstRow != rowJson.NewFirstRow ? rowJson.OldFirstRow + " \u2192 " + rowJson.NewFirstRow : rowJson.OldFirstRow}</TableCell>
                                <TableCell align="left">{rowJson.OldLastRow != rowJson.NewLastRow ? rowJson.OldLastRow + " \u2192 " + rowJson.NewLastRow : rowJson.OldLastRow}</TableCell>                                
                                <TableCell align="right">{row.insertedBy}</TableCell>                                                                
                                <TableCell align="right">{formatLocalTime(row.insertedOn)}</TableCell>
                            </TableRow>
                        )})
                        ||
                            (<TableRow><TableCell colSpan={5}>{historyData == null ? 'Waiting ...' : 'No history yet'}</TableCell></TableRow>)
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Popover>
    )
}
export default React.memo(History);