import * as React from "react";
import { useRecordContext } from 'react-admin';
import { Link, Tooltip } from "@mui/material";

export default UrlFormatter = ({ href, source, record, ...props }) => {
    const _record = record || useRecordContext(props);
    return (
        <Tooltip title={source == 'name' && _record.rename_Event != '' ? `Renamed from "${_record.name}"` : ''}>
            <Link style={{ minWidth: 100, color: source == 'name' && _record.rename_Event != '' ? 'orange' : '' }}
                underline="hover" href={_record[href]} target="_blank">
                {source !== 'name' ? _record[source] : (_record.rename_Event || _record.name)}
            </Link>
        </Tooltip>
    )
}