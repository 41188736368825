import React, { useEffect } from 'react';
import { Chip } from '@mui/material';
import { useState } from 'react';
import { useNotify, Confirm, useDelete } from 'react-admin';
import { refreshCeilsAGrid } from '../../../utils/common';

const Assign = (props) => {
  const [open, setOpen] = useState(false);//react-admin confirm
  const [assignedTo, setAssignedTo] = useState();
  const [handleDelete] = useDelete();
  const notify = useNotify();

  useEffect(() => {
    if (props.data?.assignedTo)
      setAssignedTo(props.data.assignedTo);
  }, [props]);

  const hanldeDelete = (_) => {
    props.api.stopEditing();
    setOpen(true);
  };

  const handleConfirm = () => {
    let order = props.data;
    handleDelete('OrderAssignments', { id: order.id }, {
      onSuccess: (_) => {
        notify(`Delete successfully`, { type: 'success', autoHideDuration: 2000 });
        props.api.stopEditing();
        props.data['assignedDate'] = null;
        props.data['assignedTo'] = null;
        refreshCeilsAGrid(props, ["assignedDate", "assignedTo"]);
        setAssignedTo(null);
      },
      onError: (error) => {
        notify(`Delete error: ${error.message}`, { type: 'error', autoHideDuration: 5000 });
        props.api.stopEditing();
      },
    });
    setOpen(false);
  };

  return (
    assignedTo ? (
      <React.Fragment>
        <Chip
          size="small"
          onDelete={hanldeDelete}
          key={assignedTo} label={assignedTo} />
        <Confirm isOpen={open} title='Remove Order Assignment' content='Are you sure want to remove this order assignment?' onConfirm={handleConfirm} onClose={() => setOpen(false)} />
      </React.Fragment>
    ) : null
  );
};

export default Assign;