import React from "react";
import { Datagrid, NumberField, TextField } from "react-admin";
import RaList from "../../components/ra-list/RaList";
import RenderControls from "../../components/ra-list/RenderControls";

const VVPerformanceTracking = () => {
    const [defaultValues, setDefaultValues] = React.useState();
    React.useEffect(() => {
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        setDefaultValues({ report_date: [firstDay, date] });
    }, []);

    return (
        <RaList
            title={"Performance Tracking For Vivid Page"}
            sortby={['id desc']}
            perPage={25}
            filters={
                <RenderControls
                    defaultValues={defaultValues}
                    controls={[
                        { component: 'daterange', name: 'report_date', label: 'Event Date' }
                    ]}
                />
            }
        >
            <Datagrid
                optimized
                bulkActionButtons={false}
            >
                <TextField source="id" label="Employee" />
                <NumberField source="process" label="Process" />
                <NumberField source="ship" label="Ship" />
                <NumberField source="total" label="Total" />
            </Datagrid>
        </RaList>
    );
}

const VVPerformanceTrackingPage = {
    name: 'VividPerformance',
    list: VVPerformanceTracking
}
export default VVPerformanceTrackingPage;