import { io } from "socket.io-client";

class SocketIO {
    constructor(options) {
        this.options = options;
        this.socket = null;

        // Define methods within the constructor function
        this.connect = function (url) {
            const self = this;
            self.url = url;
            const connectionOptions = {
                forceNew: true,
                reconnectionAttempts: "Infinity", // avoid having user reconnect manually to prevent dead clients after a server restart
                timeout: 20000, // before connect_error and connect_timeout are emitted
                transports: ["websocket"],
            };

            return new Promise(function (resolve, reject) {
                self.socket = io(url, connectionOptions);
                self.socket.once("connect", resolve);
                self.socket.on("connect", function () {
                    self.socket.emit("info", { by: self.options.identity, nickName: self.options.nickName });
                });
                self.socket.once("error", reject);
                self.on = self.socket.on.bind(self.socket);
                self.once = self.socket.once.bind(self.socket);
                self.emit = self.socket.emit.bind(self.socket);
            });
        };

        // Private method, not exposed
        const emitAsync = function () {
            const self = this;
            const args = Array.prototype.slice.apply(arguments);
            return new Promise(function (resolve, reject) {
                self.socket.emit.apply(self.socket, args.concat([resolve]));
                self.socket.once("error", reject);
            });
        };

        this.open = function (orderNumber, status) {
            const self = this;
            self.socket.emit("open_order", {
                by: self.options.identity,
                nickName: self.options.nickName,
                orderNumber: orderNumber,
                status: status
            });
        };

        this.refresh = function () {
            const self = this;
            self.socket.emit("refresh");
        };

        this.updateStatus = function (request) {
            const self = this;
            request.by = self.options.identity;
            return emitAsync.call(self, "order_status_changing", request);
        };
    }
}

export default SocketIO;