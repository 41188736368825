import * as React from 'react';
import { Stack, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const FormHeader = ({ onCancel , isEdit}) => {
    return (
        <Stack direction="row" p={2}>
            <Typography variant="h6" gutterBottom flex="1">
            {isEdit && 'Update Source Variable' || 'Create Source Variable'}
            </Typography>
            <IconButton onClick={onCancel} size="small">
                <CloseIcon />
            </IconButton>
        </Stack>
    );
};

export default FormHeader;
