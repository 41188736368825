import * as React from "react";
import _exporter from "../../pages/exporter";
import PropTypes from 'prop-types';
import { List, ListView, Pagination, useListController } from "react-admin";
import { RaKeys, setRaStore } from "../../utils/common";

const defaultProps = {
    empty: false,
    pagination: [5, 10, 25, 50, 1000]
};

/**
 * @param {*} props 
 * @returns 
 */
const RaList = (props) => {
    const {
        empty = defaultProps.empty,
        pagination = defaultProps.pagination,
        children, filters, queryOptions, sortby, exporter
    } = props;

    const { setSort, resource, perPage, sort } = useListController({ ...props, storeKey: false, disableSyncWithLocation: true });

    React.useEffect(() => {
        setRaStore(`${resource}.${RaKeys.isRefresh}`, true);
    }, []);

    React.useEffect(() => {
        if (Array.isArray(sortby)) {
            if (sortby.length == 1) {
                let _p = sortby[0].split(' ');
                setSort({ field: _p[0], order: _p[1].toUpperCase() });
            } else setSort({ field: sortby });
        }
    }, [sortby]);

    return (
        <List {...props}
            storeKey={props.storeKey || false} // For selection Ids
            debounce={0}
            actions={null}
            empty={empty}
            perPage={perPage}
            sort={sort}
            pagination={<RaPagination pagination={pagination} />}
            emptyWhileLoading={empty}
            disableSyncWithLocation={true}
            queryOptions={{ meta: { q: queryOptions } }}
            filters={filters ? React.cloneElement(filters) : null}
            exporter={exporter ? (records) => {
                _exporter(records, exporter.columnsExport, exporter.filename);
            } : null}
        >
            {children}
        </List>
    );
};

RaList.propTypes = {
    ...List.propTypes,
    ...ListView.propTypes,
    /** sortby is a state: ['id asc', 'date desc'] */
    sortby: PropTypes.array,
    /** array fields to query: ['event', 'venue'] */
    queryOptions: PropTypes.array,
    /** exporter is a object { columnsExport: ["...array list column"], filename: 'download or default Export' } */
    exporter: PropTypes.object,
    /** pagination is array number, default: [5, 10, 25, 50, 1000] */
    pagination: PropTypes.array
};

export const RaPagination = ({ pagination, ...props }) =>
    <Pagination {...props}
        rowsPerPageOptions={pagination || defaultProps.pagination}
        labelDisplayedRows={(o) => `${o.from}-${o.to} of ${new Intl.NumberFormat().format((o.count))}`}
    />

export default RaList;