import React, { useRef, useState } from 'react';
import MDialog from '../../../components/controls/MDialog';
import { Grid, Stack, LinearProgress, Button, FormControl, Select, MenuItem, Checkbox, TextField } from '@mui/material';
import { Confirm, useNotify } from 'react-admin';
import AgGrid from '../../../components/ag-grid/ag-grid';
import { buildQueryString, dataProvider } from '../../../DataProvider';
import style from '../style.css';
import MSelect from '../../../components/controls/MSelect';

const ApplyVenuesDialog = (props) => {
    const { applyVenues, setApplyVenues, deliveryMethod, marketType } = props;
    const notify = useNotify();
    const refInput = useRef(null);
    const [filterList, setFilterList] = useState(applyVenues.filterList);
    const [venueFilter, setVenueFilter] = useState({ type: applyVenues.filterList?.type_eq, venue: applyVenues.filterList?.venue });
    const [isLoading, setIsLoading] = React.useState(false);
    const [data, setData] = useState([]);
    const [gridData, setGridData] = useState([])
    const [method, setMethod] = useState(0);
    const [openConfirm, setOpenConfirm] = useState(false);

    React.useEffect(() => {
        if (!filterList.venue) {
            console.log('Venue is null => Break Filter');
            return;
        }
        setIsLoading(true);
        dataProvider.fetchData('EventDelivery', `Filter${convertFilter(filterList)}`)
            .then((result) => {
                if (result.data) {
                    let gridDataTemp = convertData(result.data?.items);
                    notify(`Found ${gridDataTemp.length} venue(s) with ${result.data?.items?.length} events.`, { type: 'success', autoHideDuration: 2000 });

                    setData(result.data?.items)
                    setGridData(gridDataTemp);
                } else {
                    notify(`Fail to fetch data ...`, { type: 'error', autoHideDuration: 2000 });
                    setApplyVenues({ open: false, filterList: null });
                }
            })
            .catch(err => {
                notify(`Fail to fetch data: ${err}`, { type: 'error', autoHideDuration: 2000 });
                setApplyVenues({ open: false, filterList: null });
            })
            .finally(_ => setIsLoading(false));
    }, [filterList]);

    const handleFilterVenue = () => {
        if (!venueFilter.venue) {
            notify(`FILTER: Please insert venue ...`, { type: 'error', autoHideDuration: 2000 });
            return;
        }
        setFilterList(prev => ({ ...prev, type_eq: !venueFilter.type ? undefined : venueFilter.type, venue: venueFilter.venue }));
    }

    const convertFilter = (filter) => {
        let _filter = buildQueryString({ filter: filter }, { top: 1000000, skip: 0, orderBy: 'id asc' });
        return _filter;
    }

    const convertData = (data) => {
        let newData = [];
        data?.forEach(element => {
            if (newData.find(x => x.venue === element.venue) == null) {
                newData.push({
                    type: element.type,
                    venue: element.venue,
                    venueDisplay: element.venue + `  (${data.filter(x => x.venue == element.venue).length} events)`,
                    count: data.filter(x => x.venue == element.venue).length,
                    applyAllMarket: false
                });
            }
        });
        return newData;
    }

    const handleApply = () => {
        let selectedRows = refInput.current.getAgGridInstance().api.getSelectedRows();
        if (selectedRows?.length > 0) {
            setOpenConfirm(true);
        } else {
            notify(`Please select venue(s) for applying`, { type: 'error', autoHideDuration: 2000 });
        }
    }

    const handleConfirm = () => {
        setOpenConfirm(false);
        setIsLoading(true)

        let selectedRows = refInput.current.getAgGridInstance().api.getSelectedRows();
        let dataPut = data
            .filter(ev => selectedRows.some(row => ev.venue == row.venue))
            .reduce((final, item) => {//get sample one event for each specific venue name
                if (final.find(x => x.venue == item.venue) == null) {
                    final.push(item);
                }
                return final;
            }, [])
            .map(event => {
                let dmEdit = {
                    apply: 2,//2: for same venue name              
                    deliveryMethod: method,
                    eventId: event.id,
                    id: 0,
                    name: event.name,
                    notes: [],
                    originDeliveryMethod: event.originDeliveryMethod,
                    type: selectedRows.find(row => row.venue == event.venue)?.applyAllMarket ? null : event.type,
                    venue: event.venue
                }
                event.deliveryMethodEdit = dmEdit;
                return event;
            })

        dataProvider.postData('EventDelivery', 'ApplySameVenues', dataPut, 'PUT')
            .then((response) => {
                if (response.data) {
                    notify('Update Success ...', { type: 'success', autoHideDuration: 2000 });
                } else {
                    notify(`Update Failed: ${response?.message}`, { type: 'error', autoHideDuration: 2000 });
                }
            })
            .catch(err => {
                notify(`Update Failed: ${err}`, { type: 'error', autoHideDuration: 2000 });
            })
            .finally(_ => {
                setIsLoading(false);
                setApplyVenues({ open: false, filterList: null })
            });
    }

    var colDefs = [
        {
            field: 'type',
            headerName: 'Type',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            maxWidth: 100,
        },
        {
            field: 'venueDisplay',
            tooltipField: 'venue',
            headerName: 'Venue',
            minWidth: 560
        },
        {
            field: 'applyAllMarket',
            headerName: 'Apply All Market',
            cellRenderer: (params) => {
                return <Checkbox checked={params.data.applyAllMarket}
                    onChange={(event) => {
                        let isChecked = event.target.checked;
                        let updatedGridData = gridData.map(item => {
                            if (item.venue == params.data.venue) {
                                return { ...item, applyAllMarket: isChecked };
                            }
                            return item;
                        })
                        let selectedRows = params.api.getSelectedRows();
                        const selectedVenues = selectedRows.map(node => node.venue);
                        setGridData(updatedGridData);

                        //update selected rows again after re-render
                        setTimeout(() => {
                            params.api.forEachNode((node) => {
                                if (selectedVenues.some(venue => venue == node.data.venue)) {
                                    node.setSelected(true);
                                }
                            });
                        }, 0);

                    }} />
            }
        }
    ]

    return (
        <>
            <MDialog
                title={'Apply Same Venue'}
                maxWidth={'md'}
                open={applyVenues.open}
                handleClose={() => setApplyVenues({ open: false, filterList: null })}
                name={'apply-multiple-venues-dialog'}
                action={
                    <Button variant='contained' color='primary' disabled={isLoading || gridData.length == 0} onClick={handleApply}>Apply</Button>
                }
                children={
                    <>
                        {isLoading == true && <LinearProgress color='secondary' />}
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <MSelect choices={marketType} label={'Type'} defaultValue={filterList.type_eq}
                                    onChange={(ev) => setVenueFilter(prev => ({ ...prev, type: ev }))}
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <TextField defaultValue={filterList.venue} onChange={(ev) => setVenueFilter(prev => ({ ...prev, venue: ev.target.value }))} placeholder='Venue' />
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant='contained' color='primary' onClick={handleFilterVenue} sx={{ marginTop: '20px' }}>Filter</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <AgGrid id={'aggrid-applyvenue'}
                                    ref={refInput}
                                    gridHeight={'40vh'}
                                    columnDefs={colDefs}
                                    hidePaging={true}
                                    rowData={gridData}
                                    rowSelection={'multiple'}
                                    suppressRowClickSelection={true}
                                >
                                </AgGrid>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row">
                                    <FormControl key={1} variant="standard">
                                        <p>Please select a delivery method:</p>
                                        <Select defaultValue={method} name={'delivery-method2'}
                                            onChange={(event) => { setMethod(event.target.value) }}
                                        >
                                            {deliveryMethod.map(m => (m.id !== '') &&
                                                <MenuItem key={m.id} value={m.id}>{m.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Stack>
                            </Grid>
                        </Grid>
                    </>
                }
            >
            </MDialog>
            <Confirm isOpen={openConfirm} title='Update Delivery Method'
                content={
                    <p>Are you sure to update the delivery method to <span style={{ color: 'orange' }}> {deliveryMethod.find(d => d.id == method)?.name}</span></p>
                }
                onConfirm={handleConfirm} onClose={() => setOpenConfirm(false)}
            />
        </>
    );
}
export default ApplyVenuesDialog