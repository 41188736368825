import React, { useState } from "react";
import { TextField, InputAdornment, Button, CircularProgress } from '@mui/material';
import { GetApp } from '@mui/icons-material';
import { useNotify } from "react-admin";
import { ExchangeMarket } from "../../../utils/enum";

const SyncButton = ({ dataProvider, marketId, callback, smEventId, setSmEventId, ...props }) => {
    const notify = useNotify();
    const [state, setState] = useState({
        isLoading: false,
        s_open: false, // sync dialog,
        smEventId: smEventId || ''
    });

    const previewSync = (event) => {
        if (event.which === 13 || event.keyCode === 13 || event.type === "click") {
            if (!state.smEventId.trim()) notify(`Please enter SmEventID`, { type: 'warning' });
            else {
                setState((prev) => ({ ...prev, isLoading: true }));
                dataProvider.fetchData('ListingsMapping', `FetchEvent?eventId=${state.smEventId}&marketId=${marketId}`)
                    .then((response) => {
                        let msgNotFound = `Event #${state.smEventId} does not exist on ${ExchangeMarket.find(x => x.id == marketId).name} Portal!`;
                        if (response && response.data) {
                            callback(response.data);
                            if (!response.data.isPortal) notify(msgNotFound, { type: 'warning' });
                        } else notify(msgNotFound, { type: 'warning' });
                        setState((prev) => ({ ...prev, isLoading: false }));
                        setSmEventId(state.smEventId);
                    })
                    .catch((error) => {
                        notify(`Error: ${error}`, { type: 'error' });
                        setState((prev) => ({ ...prev, isLoading: false }));
                        setSmEventId(state.smEventId);
                    });
            }
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === '.' || event.key === 'e') {
            event.preventDefault(); // prevent dot character from being entered
        }
    };

    return (
        <TextField {...props}
            id="syncEvent"
            placeholder="Sm EventID"
            autoComplete="search"
            style={{
                maxWidth: 300,
                marginTop: -15
            }}
            value={state.smEventId}
            InputProps={{
                inputProps: {
                    type: 'number',
                    onKeyPress: handleKeyPress
                },
                endAdornment: (
                    <InputAdornment position="end">
                        <Button size='small'
                            onClick={previewSync}
                            onMouseDown={(e) => e.preventDefault()}
                            style={{ marginTop: 10 }}
                            startIcon={state.isLoading ? (<CircularProgress size={20} />) : (<GetApp />)}>
                            Sync Event
                        </Button>
                    </InputAdornment>
                )
            }}
            onChange={(e) => setState((prev) => ({ ...prev, smEventId: e.target.value }))}
            onBlur={(e) => setSmEventId(e.target.value)}
            onKeyPress={previewSync}
        />
    );
};

export default React.memo(SyncButton);