import React, { memo, useState, forwardRef } from 'react';
import { Stack, Button, TextField, Checkbox, FormControlLabel, Divider, Box, LinearProgress, IconButton } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import { useDataProvider, useNotify, Confirm } from 'react-admin';
import MDialog from '../../../components/controls/MDialog';
import CheckIcon from '@mui/icons-material/Check';
import SendIcon from '@mui/icons-material/Send';
import { dayjs } from '../../../components/ex_dayjs';
import HistoryIcon from '@mui/icons-material/History';
import History from './history';

const NgaVenueConfigEditor = memo(forwardRef((props, ref) => {
    const dataProvider = useDataProvider();   
    const [range, setRange] = useState({ firstRow: props.data.firstrow, lastRow: props.data.lastrow });
    const [applyVenue, setApplyVenue] = useState(props.data.apply_to_venue);
    const [reverseRange, setReverseRange] = useState(false);
    const notify = useNotify();
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [disable, setDisable] = useState(false);
    const [overrideInfo, setOverrideInfo] = useState(null);
    const [updateData, setUpdateData] = useState(null);
    const [waitCheck, setWaitCheck] = useState(false);
    const [historyPopover, setHistoryPopover] = useState({ open: false, btnId: null, rowData: null });   
    const [isSaving, setIsSaving] = useState(false);

    const handleDialogClose = () => {
        setOpen(false);
        setWaitCheck(false);
    }
    const saveEdit = () => {     
        if(waitCheck) return;
        setWaitCheck(true);

        let rowrange = props.data;
        let rrConfigOverride = {
            type: rowrange.type,
            venue_id: rowrange.venue_id,
            section: rowrange.section,
            firstrow: range.firstRow,
            lastrow: range.lastRow,
            tmid: rowrange.tmid,
            by: rowrange.by,
            apply_to_venue: applyVenue,
            reverse_range: reverseRange
        }
        rowrange.specVenueConfigOverride = rrConfigOverride;

        setUpdateData(rowrange);
        if (applyVenue) {
            checkOverride(rowrange);
        } else {
            setOpen(true)
        }

    };
    const handleConfirm = (params) => {
        if(isSaving) return;

        setIsSaving(true);
        update(updateData);
    }
    const update = (updateData, removeConfig, removeList = []) => {
        if (removeList.length > 0) {
            var rmList = removeList.map(x => x.siteId);
        }
        let data = updateData;
        data.removeConfig = removeConfig ?? false;
        data.siteIds = rmList ?? [];

        dataProvider.postData('NgaVenueConfig', 'UpdateRowRange', data)
            .then(response => {
                if (response.status != 200 && response.data) {
                    let mess = response.data.message;
                    let data = response.data.data;
                    if (mess == 'success') {
                        notify('Update successfully', { type: 'success', autoHideDuration: 2000 });
                        UpdateGrid(props);
                        props.api.stopEditing();
                    } else {
                        notify(`Update Failed: ${response}`, { type: 'error', autoHideDuration: 2000 });
                    }
                }
            }).catch(err => console.log('Somthing went wrong: ', err)).finally(_ => setIsSaving(false))
    }
    const checkOverride = (rowrange) => {
        dataProvider.postData('NgaVenueConfig', 'CheckConfigOverride', rowrange)
            .then(response => {
                if (response.status != 200 && response.data) {
                    let isOverride = response.data.isOverride;
                    let data = response.data.data;
                    if (isOverride) {
                        let mess = `Selected section was updated Row Range for some specifice events with site ID as below:\n[${data.map(x => x.firstrow).join(',')}]. Do you want to override them too?`;
                        setOverrideInfo({ mess, data });
                        setOpenDialog(true);
                    } else {
                        setOverrideInfo({ mess: `Are you sure with this apply?`, data });
                        setOpen(true);
                    }
                }
            }).catch(err => console.log('Somthing went wrong', err)).finally(_ => setWaitCheck(false));
    }
    const UpdateGrid = (props) => {
        props.data.firstrow = range.firstRow;
        props.data.lastrow = range.lastRow;
        props.data.apply_to_venue = applyVenue;
        props.api.refreshCells({
            columns: ["firstrow", "lastrow", "apply_to_venue"],
            rowNodes: [props.node],
            force: true
        });
    }

    const columnDefs = [
        { field: 'type', headerName: 'Type', width: 120 },
        { field: 'siteId', headerName: 'Site ID', width: 200 },
        { field: 'firstrow', headerName: 'First Row', width: 150 },
        { field: 'lastrow', headerName: 'Last Row', width: 150 },
        { field: 'section', headerName: 'Section', width: 200 },
        { field: 'by', headerName: 'By', width: 200 },
        { field: 'last_update', headerName: 'Last updated', width: 150, renderCell: params => { return dayjs.utc(params.value).fromNow(); } }
    ];

    const reserseRange = (value) => {
        setRange({ firstRow: range.lastRow, lastRow: range.firstRow })
        setReverseRange(value);
    }
    return (
        <div style={{
            width: props.columnApi.getColumnState().filter(col => col.colId == 'firstrow' || col.colId == 'lastrow')
                .reduce((total, current) => total + current.width, 0)
        }}>
            <Stack direction="row" sx={{ '& > :not(style)': { marginLeft: 1 } }}>
                <TextField
                    required
                    id="outlined-required"
                    label="First Row"
                    value={range.firstRow}
                    onChange={(event) => setRange(prev => ({ ...prev, firstRow: event.target.value }))}
                />
                <Divider orientation="vertical" variant="middle" flexItem />
                <TextField
                    required
                    id="outlined-required"
                    label="Last Row"
                    value={range.lastRow}
                    onChange={(event) => setRange(prev => ({ ...prev, lastRow: event.target.value }))}
                />
            </Stack>
            {props.data.venue_id != 0 &&
                <FormControlLabel control={<Checkbox defaultChecked={applyVenue} onChange={(event) => setApplyVenue(event.target.checked)} />}
                    label="Apply to Venue" sx={{ marginLeft: 1 }} />}
            <FormControlLabel control={<Checkbox defaultChecked={false} onChange={(event) => reserseRange(event.target.checked)} />}
                label="Reverse Range" sx={{ marginLeft: 1 }} />
            {waitCheck && <LinearProgress color='secondary' />}
            <Stack direction='row' spacing={1} sx={{ margin: 1 }}>

                <Button variant="contained" onClick={saveEdit} size="small" >Save</Button>
                <Button variant="outlined" onClick={() => { props.api.stopEditing() }} size="small">Cancel</Button>
                {props.data.by && props.data.by != 'Computer' && <IconButton onClick={() => setHistoryPopover({ open: true, btnId: `history-${props.data.id}`, rowData: props.data })}
                    id={`history-${props.data.id}`} data-action='history'><HistoryIcon /></IconButton>}
            </Stack>
            <Confirm isOpen={open} title='Update Row Range SPEC' content={'Are you sure with this apply?'} onConfirm={handleConfirm} onClose={handleDialogClose} />
            {Array.isArray(overrideInfo?.data) && <MDialog
                maxWidth='lg'
                open={openDialog}
                handleClose={() => setOpenDialog(false)}
                title={`Update Row Range SPEC`}
                name={'edit-row-range'}
                disabled={disable}
                action={
                    <Stack direction="row" justifyContent={"space-between"} width="100%">
                        <Stack direction="row" spacing={1} justifyContent={'flex-end'}>
                            <Button startIcon={<CheckIcon />} color='info' variant='contained'
                                onClick={() => {
                                    let cfrm = confirm('Existing overridden config will be deleted when applying Row Range.\nContinue?')
                                    if (cfrm) {
                                        update(updateData, true, overrideInfo?.data)
                                    }
                                }}
                            >Update All</Button>
                            <Button startIcon={<SendIcon />} color={'secondary'} variant='contained'
                                onClick={() => {
                                    let cfrm = confirm('Are you sure with this apply?');
                                    if (cfrm) {
                                        update(updateData, false, [])
                                    }
                                }}
                            >Limit Update</Button>
                        </Stack>
                    </Stack>
                }
                children={
                    <div>
                        <p>The Row Range of this section was manually updated for certain events as below: </p>
                        <Box sx={{ height: "40vh", width: '100%' }}>
                            <DataGrid
                                rows={overrideInfo?.data.map(obj => { return { ...obj, id: obj.siteId } }) || []}
                                columns={columnDefs}
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'siteId', sort: 'asc' }],
                                    },
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 25,
                                        },
                                    },
                                }}
                            />
                        </Box>
                        <p>"UPDATE ALL": This will apply Row Range to all events, including these mentioned events</p>
                        <p>"LIMIT UPDATE": Only apply Row Range for events that have not been updated manually yet (by Computer) </p>
                    </div>
                }
            />}
            {historyPopover.open && <History info={historyPopover} setHistoryPopover={setHistoryPopover} api={props.api} />}
        </div>
    );
})
);
export default NgaVenueConfigEditor;