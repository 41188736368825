import React, { useEffect, useState, useRef } from "react";
import { formatLocalTime, dayjs, formatDateTime } from "../../components/ex_dayjs";
import { Datagrid, FunctionField, NumberField, TextField } from "react-admin";
import RenderControls from "../../components/ra-list/RenderControls";
import RaList from "../../components/ra-list/RaList";
import { autoLoad, loadAccountMaps, loadSourceType } from "../../utils/common";
import { dataProvider } from "../../DataProvider";
import ChartView from "./component/chart-view";
import { FilterListProvider } from "../../context/FilterListProvider";
import ChartButton from "./component/chart-button";
import { STATUS_ENUM, getStatuses } from "../../utils/enum";

const OrderAutoPriceReport = () => {
    const refbtnFilter = useRef(null);
    const [defaultSort] = useState(['order_date desc']);
    const [defaultValueFilter] = useState({
        order_date: [dayjs().startOf('M'), dayjs()],
        is_auto_price_eq: null
    });
    const [accountMaps, setAccountMaps] = useState([]);
    const [marketType, setMarketType] = useState([]);    
    const [chart, setChart] = useState({ open: false, type: 'bar' });

    const allowStatuses = getStatuses([STATUS_ENUM.Unprocessed, STATUS_ENUM.PendingShipment, STATUS_ENUM.Processing, STATUS_ENUM.Completed]);

    useEffect(() => {       
        loadAccountMaps(dataProvider, (response) => {
            let convertedData = response.map((item) => ({ id: item.account_id, name: item.listing_account }));
            setAccountMaps(convertedData);
        });
        loadSourceType(dataProvider, (response) => {
            setMarketType(response);
        });       
        autoLoad(refbtnFilter);
    }, []);

    const controlsFilter = [
        { component: 'select', name: 'vivid_account_id_eq', label: 'Account', choices: accountMaps || [] },
        { component: 'select', name: 'type', label: 'Market', choices: marketType || [] },
        { component: 'number', name: 'order_number_eq', label: 'Order Number' },
        { component: 'daterange', name: 'order_date', label: 'Order Date' },
        { component: 'text', name: 'event_contains', label: 'Event', xs: 1.5 },
        { component: 'text', name: 'venue_contains', label: 'Venue', xs: 1.5 },
        { component: 'select', name: 'status', label: 'Order Status', choices: allowStatuses },
        { component: 'checkbox', name: 'is_auto_price_eq', label: 'Auto-Priced', nullable: true },        
    ]

    const openChartView = (filterList) => {
        setChart(prev => ({ ...prev, open: true, filterList: filterList }));
    };

    return <>
        <RaList
            sortby={defaultSort}
            perPage={25}
            title={'Order Auto Price Report'}
            filters={
                <FilterListProvider>
                    <RenderControls
                        refFilter={refbtnFilter}
                        controls={controlsFilter}
                        defaultValues={defaultValueFilter}
                        actions={<ChartButton callback={openChartView} />}
                        exportFile={{
                            filename: `OrderAutoPriceReport-${formatLocalTime(new Date(), 'YY-MM-DD')}`,
                            columns: ['account', 'Type', 'order_number', 'order_date', 'event', 'venue', 'status_name', 'quantity', 'unit_price', 'OriginalPrice', 'AutoPrice'],
                            limit: 1000000
                        }}
                    />
                </FilterListProvider>
            }
        >            
            <Datagrid
                bulkActionButtons={false}
            >
                <TextField source="account" label={'Account'} />
                <TextField source="type" label={'Market'} />
                <TextField source="order_number" label={'Order Number'} />
                <FunctionField source="order_date" label={'Order Date'} render={record =>
                    <><span>{formatDateTime(record.order_date,'MM/DD/YYYY')}</span><br /><span>{formatDateTime(record.order_date,'hh:mm A')}</span></>
                } />
                <TextField source="event" label={'Event'} />
                <TextField source="venue" label={'Venue'} />
                <TextField source="status_name" label={'Order Status'} />                
                <NumberField source="quantity" label={'Qty'} />
                <NumberField source="unit_price" label={'Unit Price'} />
                <NumberField source="originalPrice" label={'Original Price'} />
                <NumberField source="autoPrice" label={'Auto Price'} />                                
            </Datagrid>
            {chart.open && <ChartView chart={chart} setChart={setChart} />}
        </RaList >
    </>
}
const OrderAutoPriceReportPage = {
    name: 'OrderAutoPriceReport',
    list: OrderAutoPriceReport
}
export default OrderAutoPriceReportPage;