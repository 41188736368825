import * as React from "react";
import { useRecordContext } from 'react-admin';
import { Link } from "@mui/material";

const UrlField = ({ href, source, attachUrl, ...props }) => {
    const record = useRecordContext(props);
    return (
        <Link style={{ minWidth: 100 }} underline="hover" href={`${attachUrl}${record[source]}`} target="_blank">
            {record[source]}
        </Link>
    )
}

export default UrlField;