import * as React from "react";
import { BooleanField, SelectArrayInput, SelectField, List, DateInput, TextInput, TextField, DateField, NumberField, Datagrid, NumberInput, SelectInput } from 'react-admin';
import { RaPagination } from "../../components/ra-list/RaList";
import ConditionalNumberField from "./ConditionalNumberField";
import BulkUpdateStatusButton from "./BulkUpdateStatusButton";
const DivvyCompanies = [
    { id: "Q29tcGFueTo2MDMwMQ==", name: "Song Technologies LLC" },
    { id: "Q29tcGFueTo2NjIyNQ==", name: "Song Tech Prepay" },
];
const Status = [
    { id: 0, name: "Pending" },
    { id: 1, name: "Completed" },
];
const TransactionTypes = [
    'purchase',
    'transfer',
    'refund',
    'fee'
];
const postFilters = [
    <SelectInput label="Status" source="status" choices={Status} placeholder="ANY" alwaysOn />,
    <SelectInput source="type" label="Type" choices={TransactionTypes} alwaysOn />,
    // <SelectArrayInput label="Company" source="companyId_in" choices={DivvyCompanies} placeholder="ANY" alwaysOn />,
    // //<SelectArrayInput label="Exclude Company" source="companyId_nin" choices={DivvyCompanies} alwaysOn />, // demo 'not in'
    <DateInput label="After" source="time_ge" alwaysOn />,
    <DateInput label="Before" source="time_le" alwaysOn />,
    <TextInput source="cardLastFour" />,
    <TextInput source="cardDescription" />,
    <TextInput source="merchantName" />,
    <NumberInput label="Amount from" source="amount_ge" alwaysOn />,
    <NumberInput label="Amount to" source="amount_le" alwaysOn />,
];

const EmburseTransactionList = () => (
    <List
        storeKey={false}
        disableSyncWithLocation
        exporter={false}
        pagination={<RaPagination />}
        perPage={25}
        sort={{ field: 'time', order: 'DESC' }}
        filters={postFilters}
        filterDefaultValues={{ 'time_ge': new Date((new Date().getTime() - 15 * 24 * 60 * 60 * 1000)).toISOString() }}>

        <Datagrid bulkActionButtons={<BulkUpdateStatusButton />}>
            <DateField source="time" label="Transaction Time" />
            {/* <DateField source="postedAt" label="Posted At" /> */}
            {/* <TextField source="id" label="ID" /> */}
            <ConditionalNumberField source="amount" label="Amount" />
            <TextField source="currencyCode" label="Currency Code" />
            <TextField source="state" label="State" />
            {/* <NumberField source="merchantMid" label="Merchant ID" /> */}
            {/* <NumberField source="merchantMcc" label="Merchant MCC" /> */}
            <TextField source="merchantName" label="Merchant Name" />
            {/* <TextField source="merchantCity" label="Merchant City" /> */}
            {/* <TextField source="merchantState" label="Merchant State" /> */}
            {/* <TextField source="merchantZip" label="Merchant Zip" /> */}
            {/* <TextField source="merchantCountry" label="Merchant Country" /> */}
            {/* <TextField source="cardId" label="Card ID" /> */}
            {/* <TextField source="cardState" label="Card State" /> */}
            <TextField source="cardDescription" label="Card Description" />
            <TextField source="cardLastFour" label="Card Last Four" />
            {/* <BooleanField source="isOnline" label="Is Online?" /> */}
            {/* <TextField source="note" label="Note" /> */}

            {/* <DateField source="createdAt" label="Created At" /> */}

            <TextField source="type" label="Type" />
            <SelectField source="status" choices={Status} translateChoice={false} />
        </Datagrid>
    </List>
);
const EmbursePage = {
    name: 'EmburseTransaction',
    list: EmburseTransactionList
};
export default EmbursePage;