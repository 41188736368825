import * as React from 'react';
import { Typography, Skeleton } from '@mui/material';
import MQueryBuilder from '../../../components/query-builder/MQueryBuilder';
import { QueryBuilderMaterial } from '@react-querybuilder/material';

const RuleCreator = ({ isEdit, editData, handleChange, query, actionQueryRef, conditionQueryRef, conditionFields, actionFields }) => {
    const isLoad = isEdit && !editData;
    let formatedCondition = formatedAction = {};

    if (!isLoad && isEdit) {
        formatedCondition = JSON.parse(editData.condition);
        formatedAction = JSON.parse(editData.action);
    }

    const conditionInitData = {
        combinator: 'AND',
        rules: [
        ]
    }
    const actionInitData = {
        combinator: 'modify',
        rules: [
        ]
    }

    const combinators = [
        { name: 'modify', label: '' }
    ];
    return (
        <>
            <Typography variant="h6" gutterBottom flex="1" key='math'>
                For those orders match ...
            </Typography>
            <QueryBuilderMaterial>
                <>
                    {isLoad ? <Skeleton /> :
                        <MQueryBuilder
                            key='condition'
                            fields={conditionFields}
                            initialData={conditionInitData}
                            ref={conditionQueryRef}
                            editData={formatedCondition}
                            defaultOperator='and'
                            query={query}
                            handleChange={handleChange}
                            hideNotButton />}
                </>
                <Typography variant="h6" flex="1" key='adjust'>
                    Then adjust
                </Typography>
                <>
                    {isLoad ? <Skeleton /> :
                        <MQueryBuilder
                            key='action'
                            fields={actionFields}
                            initialData={actionInitData}
                            ref={actionQueryRef}
                            editData={formatedAction}
                            defaultOperator='modify'
                            query={query}
                            hideGroupButton
                            hideLockButtons
                            hideNotButton
                            useCustomEditor
                            handleChange={handleChange}
                            combinators={combinators}
                            combinatorName='method'
                            combinatorSelector={null}
                        />}
                </>
            </QueryBuilderMaterial>
        </>
    );
};
export default React.memo(RuleCreator);