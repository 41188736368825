import * as React from "react";
import { useLocation } from 'react-router-dom';
import {
    TextField, NumberField, DatagridConfigurable, useDataProvider, FunctionField, useStore
} from 'react-admin';
import './style.css';
import { loadAccountMaps, loadResourceCSV, loadSourceType, setDefaultValuesFromURL } from "../../utils/common";
import { ActionEnum, DeliveryMethodEnum } from "../../utils/enum";
import RenderControls from "../../components/ra-list/RenderControls";
import RaList from "../../components/ra-list/RaList";
import {
    EventFormatter,
    ActionFormatter,
    DeliveryMethodFormatter,
    RuleFormatter,
    TicketFormatter,
    NewRuleButton
} from "./formatters";
import UrlFormatter from "../../components/ra-list/formatters/UrlFormatter";
import { formatDateTime, formatLocalTime } from "../../components/ex_dayjs";
import RuleEditPopup from "../csv-merge/components/RuleEditPopup";
import { FilterListProvider } from "../../context/FilterListProvider";

const OntarioCA = 'Ontario, CA';
const LasVegasNV = 'Las Vegas, NV';
const excludeCities = [OntarioCA, LasVegasNV];

const cellColsCss = {
    venue: 'listing-col-venue',
    rule: 'listing-col-rule'
};

const defaultOrderby = 'refresh_on desc';
const storeListingKey = 'preferences.Listings.datagrid';
const HIDE_AUTO_PRICER_COLS = ['sm_original_id', 'map_price', 'competitor_price', 'pricer_updated_on'];
const HIDE_COLS = ['sm_event', 'updated_on', 'affected_by_rule_IDs', 'delivery_method'];
const EXPORT_COLS = [
    'sm_original_id', 'broker_id', 'src_listing_id', 'market_type', 'event', 'sm_event',
    'venue', 'event_date', 'quantity', 'section', 'row', 'price', 'floor_price',
    'map_price', 'competitor_price', 'pricer_updated_on', 'refresh_on', 'is_shown', 'account'
];

const Listing = () => {
    const location = useLocation();
    const dataProvider = useDataProvider();
    const refbtnFilter = React.useRef(null);
    const [defaultValues, setDefaultValues] = React.useState({
        exclude_cities_ne: excludeCities,
        action: -1,
        is_shown_eq: true,
        isLowestPrice: null,
        haveCompetitor: null,
        autoPriced: null,
        refreshAgo: 24
    });
    const [refData, setRefData] = React.useState({
        sourceType: [],
        accountMaps: []
    });
    const [rulePopup, setRulePopup] = React.useState({
        isOpenRule: false,
        rowSelected: null,
        sources: []
    });
    const [sortModel, setSortModel] = React.useState([defaultOrderby]);

    const [omitCols] = useStore(`${storeListingKey}.omit`, HIDE_AUTO_PRICER_COLS);
    const [availableColumns] = useStore(`${storeListingKey}.availableColumns`, []);
    const [columns, setColums] = useStore(`${storeListingKey}.columns`, availableColumns.map(x => x.index));

    const handleAutoPriced = (e) => {
        const { value } = e;
        let hideCols = omitCols;
        if (columns.length) {
            hideCols = availableColumns.filter(x => !columns.some(y => y == x.index)).map(x => x.source);
        }
        if (value) {
            setSortModel(['pricer_updated_on desc']);
            if (columns.length == 0) setTimeout(() => refbtnFilter.current.click(), 100);
            hideCols = hideCols.filter(x => !HIDE_AUTO_PRICER_COLS.includes(x));
            HIDE_COLS.forEach((col) => {
                if (!hideCols.some(x => x == col)) hideCols.push(col);
            });
        } else {
            setSortModel([defaultOrderby, '']); // add '' to prevent auto sort after click
            hideCols = hideCols.filter(x => !HIDE_COLS.includes(x));
            HIDE_AUTO_PRICER_COLS.forEach((col) => {
                if (!hideCols.some(x => x == col)) hideCols.push(col);
            });
        }
        let avaiCols = availableColumns.filter(x => !hideCols.some(y => y == x.source)).map(x => x.index);
        setColums(avaiCols);
    };

    const controls = [
        { component: 'number', name: 'sm_original_id_eq', label: 'Sm EventID' },
        { component: 'number', name: 'broker_id_eq', label: 'ExchangeId' },
        { component: 'number', name: 'src_listing_id_eq', label: 'Listing #' },
        { component: 'select', name: 'market_type_eq', label: 'Market type', choices: refData.sourceType },
        { component: 'select', name: 'delivery_method_eq', label: 'Delivery method', choices: DeliveryMethodEnum.filter(x => x.id != 2) /**Ignore Fedex */ },
        { component: 'text', name: 'event' },
        { component: 'text', name: 'venue' },
        { component: 'daterange', name: 'event_date', label: 'Event date' },
        { component: 'text', name: 'section', xs: 1 },
        { component: 'number', name: 'refreshAgo', label: 'Refresh ago (h)' },
        { component: 'daterange', name: 'updated_on', label: 'Updated on', isUTC: true },
        { component: 'text', name: 'affected_by_rule_IDs_contains', label: 'Rule ID', xs: 1 },
        { component: 'select', name: 'action', choices: ActionEnum, isEmptyOption: false },
        { component: 'select', name: 'account_id_eq', label: 'Account', choices: refData.accountMaps, xs: 1.5 },
        { component: 'autocomplete', name: 'exclude_cities_ne', label: 'Exclude cities', choices: excludeCities },
        { component: 'checkbox', name: 'is_shown_eq', label: 'SHOWN', nullable: true, xs: 0.8 },
        { component: 'checkbox', name: 'isLowestPrice', label: 'Lowest Price', xs: 0.8 },
        { component: 'checkbox', name: 'haveCompetitor', label: 'have a competitor', xs: 0.8 },
        { component: 'checkbox', name: 'autoPriced', label: 'Auto Priced', onChange: (e) => handleAutoPriced(e) },
    ];

    React.useEffect(() => {
        loadSourceType(dataProvider, (response) => {
            setRefData((prev) => ({ ...prev, sourceType: response }));
        });
        loadAccountMaps(dataProvider, (response) => {
            setRefData((prev) => ({ ...prev, accountMaps: response.map((item) => ({ id: item.account_id, name: item.listing_account })) }));
        });
        loadResourceCSV(dataProvider, (response) => setRulePopup((prev) => ({ ...prev, sources: response })));

        if (location.search) {
            var newDefaultValue = { ...defaultValues };
            setDefaultValuesFromURL(location.search, newDefaultValue, controls);
            if (newDefaultValue.ListingId) {
                newDefaultValue.src_listing_id_eq = parseInt(newDefaultValue.ListingId);
                delete newDefaultValue.ListingId;
                if (typeof newDefaultValue.action === 'string') {
                    newDefaultValue.action = ' ' // any
                }
            }
            setDefaultValues(newDefaultValue);
        }
    }, []);


    const onRuleClick = (id, rule) => {
        let account = refData.accountMaps.find(x => x.id == rule?.sources);
        if (account) rule.sources = account.name;
        setRulePopup((prev) => ({
            ...prev,
            isOpenRule: true,
            ruleId: id > 0 ? id : [id, rule]
        }));
    };

    return (
        <>
            <RaList
                perPage={25}
                sortby={sortModel}                
                filters={
                    <FilterListProvider>
                        <RenderControls
                            exportFile={{
                                filename: `Listings-${formatDateTime(new Date(), 'YY-MM-DD')}.csv`,
                                columns: EXPORT_COLS.filter(x => {
                                    return availableColumns.findIndex(y => y.source == x && columns.some(z => z == y.index)) >= 0;
                                })
                            }}
                            useHashUrl
                            selectColumn
                            refFilter={refbtnFilter}
                            filterInline={false}
                            actions={<NewRuleButton callback={onRuleClick} />}
                            controls={controls}
                            defaultValues={defaultValues}
                        />
                    </FilterListProvider>
                }
            >
                <DatagridConfigurable bulkActionButtons={false} omit={omitCols}>
                    <TextField source="sm_original_id" label="SmEventID" />
                    <TicketFormatter source="broker_id" />
                    {/* <TextField source="id" sortable={false} /> */}
                    <TextField source="src_listing_id" label="Listing #" />
                    <TextField source="market_type" label="Market type" />
                    <EventFormatter source="event" dataProvider={dataProvider} />
                    <UrlFormatter source="sm_event" href="sm_event_url" />
                    <TextField source="venue" cellClassName={cellColsCss.venue} />
                    <FunctionField label="Event date" source="event_date" render={record => formatDateTime(record.event_date)} cellClassName={cellColsCss.venue} />
                    <NumberField source="quantity" label="Qty" />
                    <TextField source="section" />
                    <TextField source="row" />
                    <NumberField source="price" />
                    <NumberField source="floor_price" />
                    <NumberField source="map_price" />
                    <NumberField source="competitor_price" />
                    <FunctionField label="Pricer Updated On" source="pricer_updated_on" render={record => record.pricer_updated_on && formatLocalTime(record.pricer_updated_on)} />
                    <FunctionField label="Updated on" source="updated_on" render={record => record.updated_on && formatLocalTime(record.updated_on)} />
                    <FunctionField label="Refresh Ago" source="refresh_on" render={record => record.refresh_on && formatLocalTime(record.refresh_on)} />
                    <FunctionField source="affected_by_rule_IDs" label="Rule ID" render={(record) => <RuleFormatter record={record} callback={onRuleClick} />} cellClassName={cellColsCss.rule} />
                    <ActionFormatter source="action" label="Action" />
                    <DeliveryMethodFormatter source="delivery_method" />
                    <TextField source="account" />
                </DatagridConfigurable>
            </RaList>
            {rulePopup.isOpenRule &&
                <RuleEditPopup
                    data={rulePopup}
                    dataProvider={dataProvider}
                    onClose={() => setRulePopup((prev) => ({ ...prev, isOpenRule: false }))} />}
        </>
    );
};

const ListingsPage = {
    name: 'Listings',
    list: Listing
};
export default ListingsPage;