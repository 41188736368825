import { Button, Grid, Stack, TextField } from "@mui/material";
import React, { useState, useRef } from "react";
import { MultipleStop, Upload } from '@mui/icons-material';
import MDialog from "../../../../components/controls/MDialog";
import { useRecordContext } from "react-admin";
import { formatDateTime } from "../../../../components/ex_dayjs";
import html2pdf from '../../lib/html2pdf.min.js';
import LoadingButton from "../../../../components/controls/LoadingButton.js";

const ProofAcceptance = (props) => {
    const { notify, dataProvider, vendor } = props;
    const order = useRecordContext();
    const controller = vendor.controller;
    if (!order) return;

    const [proofPopup, setProofPopup] = useState({ open: false, acceptedName: order?.customer?.name, acceptedEmail: order?.customer?.email, rePDF: false });
    const [previewPDF, setPreviewPDF] = useState({ width: '100%', height: '500px', blobURL: null, html: null });
    const fileInputRef = useRef(null);

    React.useEffect(() => {
        if (!proofPopup.open) return;
        if (order) {
            let proofHTML = proofAcceptanceHTML(order, proofPopup.acceptedName, proofPopup.acceptedEmail);
            html2pdf()
                .from(proofHTML)
                .set({
                    margin: 0,
                    filename: 'document.pdf',
                    html2canvas: { scale: 4 },
                    jsPDF: { unit: 'cm', format: 'letter', orientation: 'portrait' }
                })
                // .save();
                .outputPdf('blob')
                .then((pdfblob) => {
                    fileInputRef.current = pdfblob;
                    let blobUrl = URL.createObjectURL(pdfblob) + '#zoom=FitWidth';
                    setPreviewPDF(prev => ({ ...prev, blobURL: blobUrl, html: proofHTML }));
                });
        }
    }, [proofPopup.open, proofPopup.rePDF])

    const closeProofPopup = () => {
        setPreviewPDF(prev => ({ ...prev, blobURL: null }));
        setProofPopup(prev => ({ ...prev, open: false }));
    };

    async function uploadProofAcceptance() {
        const file = fileInputRef.current;
        if (!file) {
            notify('Please select a file first');
            return;
        }

        let orderNumber = order.order_number;
        const formData = new FormData();
        formData.append('file', file);

        if (previewPDF.blobURL) {
            await dataProvider.postData(controller, `UploadProofOfAcceptance?orderNumber=${orderNumber}`, formData)
                .then(response => {
                    if (response?.data) {
                        notify(`${response.data.message}`, { type: response.data.success ? 'success' : 'error', autoHideDuration: 2000 });
                    } else {
                        notify(`ERROR: ${response?.message}`, { type: 'error', autoHideDuration: 2000 });
                    }
                })
                .finally(_ => closeProofPopup());
        }
    }

    const onChangeAccepted = (target, value) => {
        setProofPopup(prev => ({ ...prev, [target]: value }));
    }

    const updateAcceptedPDF = (props) => {
        if (props.key == 'Enter') {
            setProofPopup(prev => ({ ...prev, rePDF: !proofPopup.rePDF }));
            notify('[Accepted by] updated', { type: 'success' });
        }
    }

    return (
        <>
            <Button variant="contained" size="small" startIcon={<MultipleStop />} style={{ backgroundColor: '#5cc0de' }}
                onClick={() => setProofPopup(prev => ({ ...prev, open: true }))} >Upload Proof</Button>
            <MDialog
                title={`#${order.order_number} - Proof of Acceptance`}
                maxWidth={'md'}
                open={proofPopup.open}
                handleClose={closeProofPopup}
                name={'proof-acceptance-dialog'}
                action={
                    <Stack direction="row">
                        {/* <Button onClick={uploadProofAcceptance} variant="contained" color="primary" startIcon={<Upload />}>Upload</Button> */}
                        <LoadingButton onClick={uploadProofAcceptance} color="primary" startIcon={<Upload />} children={"Upload"} />
                    </Stack>
                }
                className={'order-dialog'}
                children={
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={5} sx={{ textAlign: "right" }}>
                                    <b>Event</b>
                                </Grid>
                                <Grid item xs={7}>
                                    <span>{order?.event}</span>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={5} sx={{ textAlign: "right" }}>
                                    <b>Venue</b>
                                </Grid>
                                <Grid item xs={7} >
                                    <span>{order?.venue}</span>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={5} sx={{ textAlign: "right" }}>
                                    <b>Date</b>
                                </Grid>
                                <Grid item xs={7}>
                                    <span>{formatDateTime(order?.date, 'MMM DD YYYY hh:mm A')}</span>
                                </Grid>
                            </Grid>
                            {order?.customer?.name.trim() && <Grid container spacing={2}>
                                <Grid item xs={5} sx={{ textAlign: "right" }}>
                                    <b>Customer Name</b>
                                </Grid>
                                <Grid item xs={7}>
                                    <span>{`${order?.customer?.name}`}</span>
                                </Grid>
                            </Grid>}
                            {order?.customer?.email.trim() && <Grid container spacing={2}>
                                <Grid item xs={5} sx={{ textAlign: "right" }}>
                                    <b>Customer Email</b>
                                </Grid>
                                <Grid item xs={7}>
                                    <span>{order?.customer?.email}</span>
                                </Grid>
                            </Grid>}
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={5} sx={{ textAlign: "right" }}>
                                    <b>Section</b>
                                </Grid>
                                <Grid item xs={7}>
                                    <span>{order?.section}</span>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={5} sx={{ textAlign: "right" }}>
                                    <b>Row</b>
                                </Grid>
                                <Grid item xs={7} >
                                    <span>{order?.row}</span>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={5} sx={{ textAlign: "right" }}>
                                    <b>Seat</b>
                                </Grid>
                                <Grid item xs={7}>
                                    <span>{order?.lo_seat ? `${order.lo_seat} - ${order.hi_seat}` : order?.seat}</span>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={5} sx={{ textAlign: "right" }}>
                                    <b>Qty</b>
                                </Grid>
                                <Grid item xs={7}>
                                    <span>{order?.quantity}</span>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ marginTop: '-30px' }}>
                                <Grid item xs={5} sx={{ textAlign: "right", alignContent: 'center' }}>
                                    <b>Accepted by(Name)</b>
                                </Grid>
                                <Grid item xs={7}>
                                    <TextField hiddenLabel margin="normal" variant="standard" size="small" value={proofPopup.acceptedName}
                                        onKeyUp={(props) => updateAcceptedPDF(props)}
                                        onChange={(props) => onChangeAccepted('acceptedName', props.target.value)} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ marginTop: '-30px' }}>
                                <Grid item xs={5} sx={{ textAlign: "right", alignContent: 'center' }}>
                                    <b>Accepted by(Email)</b>
                                </Grid>
                                <Grid item xs={7} >
                                    <TextField hiddenLabel margin="normal" variant="standard" size="small" value={proofPopup.acceptedEmail}
                                        onKeyUp={(props) => updateAcceptedPDF(props)}
                                        onChange={(props) => onChangeAccepted('acceptedEmail', props.target.value)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        {previewPDF.blobURL && <Grid item xs={12}>
                            <embed src={previewPDF.blobURL} type={'application/pdf'} width={previewPDF.width} height={previewPDF.height}></embed>
                            {/* <div dangerouslySetInnerHTML={{ __html: previewPDF.html }} />;//for test display html */}
                        </Grid>
                        }
                    </Grid>

                }
            >
            </MDialog>
        </>
    )
}

const proofAcceptanceHTML = (order, name, email) => {
    var htmlStr = `<div style="white-space: nowrap; width: 100%;font-size: 16px;color: #333333">
                <div style="margin-top: 58px;width: 100%;">
                    <div style="background-color: #333333; height: 22px;width: 40%;position: absolute;"></div>
                    <div style="background-color: lightgray; height: 22px;width: 100%;"></div>
                </div>
                <p style="text-align: center;font-family: Haettenschweiler,sans-serif;font-size: 76px">Proof of acceptance</p>
                <div style="margin-left: 30px;">
                    <table style="border-spacing: 0 10px;border-collapse: inherit;font-family: Arial;">
                        <tr >
                            <td style="font-weight: bold;">Event ID:</td>
                            <td>&nbsp;&nbsp;${order.event_site_id}<br /></td>
                        </tr>
                        <tr >
                            <td style="font-weight: bold;">Event Name:</td>
                            <td>&nbsp;&nbsp;${order.event}</td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold;">Venue Name:</td>
                            <td>&nbsp;&nbsp;${order.venue}</td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold;">Event Date:</td>                           
                            <td>&nbsp;&nbsp;${order.date}</td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold;">Quantity:</td>
                            <td>&nbsp;&nbsp;${order.quantity}</td>
                        </tr>
                    </table>
                    <br />
                    <br />
                    <table style="width:95%;font-family: Arial;border-collapse: inherit">
                        <thead style="height:45px;background-color: lightgray;">
                            <tr>
                                <th style="border: 1px solid darkgray;text-align: center;">Section</th>
                                <th style="border: 1px solid darkgray;text-align: center;width: 33%;">Row</th>
                                <th style="border: 1px solid darkgray;text-align: center;width: 33%;">Seat(s)</th>
                            </tr>
                        </thead>
                        <tbody style="height:45px">
                            <tr >
                                <td style="text-align: center;border: 1px solid darkgray;">${order.section}</td>
                                <td style="text-align: center;border: 1px solid darkgray;">${order.row}</td>
                                <td style="text-align: center;border: 1px solid darkgray;">${order.lo_seat ? (order.lo_seat + ' - ' + order.hi_seat) : order.seat}</td>
                            </tr>
                        </tbody>
                    </table>
                    <hr style="width:95%; margin-left:0px;" />
                        <p style="font-weight: bold;">Accepted On:</p>
                        <p style="font-weight: bold;">Send To:</p>
                        <table>
                            <tr >
                                <td style="font-weight: bold;">&#8226; Name:</td>
                                <td >&nbsp;${order.customer?.name}</td>
                            </tr>
                            <tr >
                                <td style="font-weight: bold;">&#8226; Email:</td>
                                <td >&nbsp;${order.customer?.email}</td>
                            </tr>
                        </table>                        
                        <p style="font-weight: bold;">Accepted By:</p>
                        <table>
                            <tr >
                                <td style="font-weight: bold;">&#8226; Name:</td>
                                <td >&nbsp;${name}</td>
                            </tr>
                            <tr >
                                <td style="font-weight: bold;">&#8226; Email:</td>
                                <td >&nbsp;${email}</td>
                            </tr>
                        </table>                        
                        <p><b>Send email matches accepted by email:</b>     true</p>
                        <p style="margin-left: 100px">TM</p>
                </div >
            </div >`
    return htmlStr;
}
export default ProofAcceptance;