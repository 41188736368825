import * as React from 'react';
import { InputAdornment, Select, MenuItem, TextField } from '@mui/material'
import PropTypes from 'prop-types';

const MInputFilter = ({ label, name, choices, isEmpty, defaultValue, onChange, ...props }) => {
    const [searchText, setSearchText] = React.useState('');
    let _operator = '_contains';
    const handleFilter = (ev) => {
        var { value } = ev.target;
      
        switch (value) {
            case 1:
                _operator = '_eq';
                break;
            case 2:
                _operator = '_startswith';
                break;
            default:
                _operator = '_contains'
                break;
        }
        onChange(searchText, name + _operator);
    }
    const handleTextChange = (ev) => {
        var { value } = ev.target
        onChange(value, name + _operator);
        setSearchText(value);
    }
    return (
        <TextField
            {...props}
            type='search'
            label={label}
            variant="standard"
            onChange={handleTextChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Select defaultValue={0}
                            disableUnderline={true}
                            variant="standard"
                            onChange={handleFilter}
                        >
                            <MenuItem value={0}>INCLUDES</MenuItem>
                            <MenuItem value={1}>EXACT MATCH</MenuItem>
                            <MenuItem value={2}>STARTS WITH</MenuItem>
                        </Select>
                    </InputAdornment>
                ),
            }}
        />
    )
}

MInputFilter.propTypes = {
    setFilters: PropTypes.func,
    setIsOpenSearch: PropTypes.func,
    isOpenSearch: PropTypes.bool,
    inputs: PropTypes.array,
    onSearch: PropTypes.func
};

export default React.memo(MInputFilter);