import AdjustRule from "./adjust-rule";
import OrderRule from "./order-rule";

function getRule(info) {
    const { categoryId, marketType, aatPAccounts, aatCategoryAcct, delivery } = info;
    switch (categoryId) {
        case 1: return AdjustRule(marketType);
        case 2: return OrderRule(marketType, aatPAccounts, aatCategoryAcct, delivery);
        default: return null;
    }
}

function useDynamicRule() {
    const validator = (r) => !!r.value || r.value.lenght > 0;

    const getRuleInfo = (getName, getColumnDefs, getConditionFields, getActionFields, initialState, dlgStyles) => ({
        name: getName(),
        columnDefs: getColumnDefs(),
        conditionFields: getConditionFields(),
        actionFields: getActionFields(),
        initialState: initialState || {}, // For dataGrid
        dlgStyles: dlgStyles || {}
    });

    const showMarket = (value) => {
        switch (value) {
            case 1: return 'VV';
            case 2: return 'TN';
            case 3: return 'SH';
            case 4: return 'SG';
            default: return 'TP';
        }
    }

    return { validator, showMarket, getRuleInfo };
}

export {
    useDynamicRule,
    getRule
};