import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useDataProvider } from "react-admin";
import Filter from './components/filter';
import { Box } from '@mui/system';
import LinearProgress from '@mui/material/LinearProgress';
import { getDefaultUtcOffset } from "../../utils/common";
import { useState, useEffect } from 'react';
import { useNotify } from 'react-admin';
import RCBarChart from '../../components/charts/recharts/rcBarChart';
import RCPieChart from '../../components/charts/recharts/rcPieChart';

const UserDashboard = () => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [listUsers, setListUsers] = useState([]);
  const [chartType, setChartType] = useState('bar');
  const [teamId, setTeamId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = (filter) => {
    setIsLoading(true);
    const startDate = filter.period_ge;
    const endDate = filter.period_le;
    const teamId = filter.teamId;
    const utcOffset = getDefaultUtcOffset();

    dataProvider.fetchData('UserDashboard', `GetDataDashboard?startDate=${startDate}&endDate=${endDate}&teamId=${teamId}&utcOffset=${utcOffset}`)
      .then((response) => {
        if (response && response?.data) {
          response.data.forEach(item => {
            item.name += ` (Total: ${item.unprocessed + item.processingFailed + item.a_ProcessingFailed + item.processed + item.ihd + item.general + item.any})`;
          });
          setListUsers(response.data);
        } else {
          notify(`Not found any data.`, { type: 'success', autoHideDuration: 5000 })
        }
      })
      .catch((err) =>
        notify(`Fail to fetch GetDataDashboard with Filter. Reason::: ${err?.message}`, { type: 'error', autoHideDuration: 5000 })
      )
      .finally(_ => setIsLoading(false));
  };
 
  useEffect(() => {
    dataProvider.fetchData('User', 'GetTeam')
      .then((response) => {
        if (response.status !== 200 && response.data) {
          response.data !== 0 && setTeamId(response.data);
        }
      }).catch(err => {
        notify(`Fail to fetch Team. Reason::: ${err}`, { type: 'error', autoHideDuration: 5000 });
      });
  }, []);

  return (
    <>
      <Box sx={{ width: '100%', mb: 5 }}>
        <Paper elevation={1} sx={{ pl: 1 }} >
          <Filter type={chartType} setType={setChartType} handleSearch={handleSearch} teamId={teamId} />
        </Paper>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        {isLoading &&
          <LinearProgress color='secondary' />
          || (
            listUsers?.length !== 0 &&
            (chartType === 'pie' &&
              (
                <Grid xs={12} display="flex" justifyContent="center" alignItems="center">          
                  <Paper elevation={10} sx={{ padding: '0px 20px 30px 20px', marginBottom: '30px', width: '83%' }}>
                    <Typography sx={{ marginTop: '20px', marginBottom: '25px' }} variant="h3" textAlign={'center'} mb={2}>User Dashboard</Typography>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} >
                      <RCPieChart
                        data={listUsers}
                        dataKey={'name'}
                        height={220}
                        width={460}
                        chartSize={100}
                        elevation={20}
                        seriesList={[
                          { dataKey: 'processed', name: 'Processed', fill: '#4caf50' },
                          { dataKey: 'a_ProcessingFailed', name: 'Assigned Processing Failed', fill: '#f44336' },                                                                                                
                          { dataKey: 'unprocessed', name: 'UnProcessed', fill: '#9e9e9e' },
                          { dataKey: 'ihd', name: 'IHD', fill: '#ffeb3b' },
                          { dataKey: 'general', name: 'General', fill: '#2196f3' },
                          { dataKey: 'any', name: 'Any', fill: '#9c27b0' },
                          { dataKey: 'processingFailed', name: 'Processing Failed', fill: '#f7554e' },  
                        ]}
                      >
                      </RCPieChart>
                    </Grid>
                  </Paper>
                </Grid>
              )
              ||
              <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
                <Paper elevation={24} sx={{ width: '90%' }}>
                  <Typography sx={{ marginTop: '20px', marginBottom: '25px' }} variant="h3" textAlign={'center'}>User Dashboard</Typography>
                  <RCBarChart
                    width={1600}
                    data={listUsers}
                    yGap={5}
                    barSize={listUsers.length > 8 ? 50 : 70}
                    xAxis={{ dataKey: 'name', label: { value: 'USER', dy: 12 } }}
                    yAxis={{ position: 'insideLeft', label: { value: 'Orders', angle: -90, dx: -10 } }}
                    legend={{ verticalAlign: 'top', height: 60, iconType: 'square' }}
                    showTotal={true}
                    seriesList={[
                      { dataKey: 'processed', name: 'Processed', fill: '#4caf50', stackId: 'a', labelList: { fill: 'white' } },
                      { dataKey: 'a_ProcessingFailed', name: 'Assigned Processing Failed', fill: '#f44336', stackId: 'a', labelList: { fill: 'white' } },                                         
                      { dataKey: 'unprocessed', name: 'UnProcessed', fill: '#9e9e9e', stackId: 'a', labelList: { fill: 'white' } },
                      { dataKey: 'ihd', name: 'IHD', fill: '#eddb42', stackId: 'b', labelList: { fill: 'white' } },
                      { dataKey: 'general', name: 'General', fill: '#2196f3', stackId: 'b', labelList: { fill: 'white' } },
                      { dataKey: 'any', name: 'Any', fill: '#9c27b0', stackId: 'b', labelList: { fill: 'white' } },
                      { dataKey: 'processingFailed', name: 'Processing Failed', fill: '#f7554e', stackId: 'b', labelList: { fill: 'white' } },    
                    ]}
                  >
                  </RCBarChart>
                </Paper>
              </Grid>
            ))}
      </Box>
    </>
  );
};


const UserDashboardPage = {
  name: 'UserDashboard',
  list: UserDashboard
};

export default UserDashboardPage;