import * as React from 'react';
const Error403 = () => {
    return (
        <div>
            <div class="text-center">
                <h2 class="without-margin">Don't worry. It's <span class="text-warning"><big>403</big></span> error only.</h2>
                <h4 class="text-warning">Access denied</h4>
            </div>
            <div class="text-center">
                <h3><small>Please choose an option from the menu bar or contact your administrator to request more access to the system.</small></h3>
            </div>
        </div>
    )
}
export default Error403;