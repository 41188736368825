import * as React from 'react';
import { styled } from '@mui/material/styles';
import { memo } from 'react';
import clsx from 'clsx';
import { TableCell, TableSortLabel, Tooltip } from '@mui/material';
import { TableCellProps } from '@mui/material/TableCell';
import {
    FieldTitle,
    useTranslate,
    SortPayload,
    useResourceContext,
    useTranslateLabel,
} from 'ra-core';

export const ODatagridHeaderCell = (
    props: ODatagridHeaderCellProps
): JSX.Element => {
    const { className, field, sort, updateSort, isSorting, vendor, ...rest } = props;
    const resource = useResourceContext();

    const translate = useTranslate();
    const translateLabel = useTranslateLabel();

    const isTN = vendor?.controller == 'TicketNetwork';
    const renderCustomSort = (source: string) => ['event', 'primary_order_number', 'internal_status'].some(x => source.includes(x));
    const styleTNEvent = { display: 'flex', justifyContent: 'space-between' };
    const [selectedSortField, setSelectedSortField] = React.useState('');
    const sortLabel = translate('ra.sort.sort_by', {
        field: sort?.field || selectedSortField,
        order: translate(sort?.order || 'ASC'),
        _: translate('ra.action.sort'),
    });

    const MultipleSordHeader = (props: any) => {
        const { source } = props;
        switch (source) {
            case 'event-venue-order_total':
                return (
                    <select onChange={(props) => handleChange(props.target.value, 'ASC')} onClick={_ => _} value={selectedSortField}>
                        <option value={'event'}>Event</option>
                        <option value={'venue'}>Venue</option>
                        <option value={'order_total'}>Order Total</option>
                    </select>
                );
            case 'primary_order_number-primary_account':
                return (
                    <select onChange={(props) => handleChange(props.target.value, 'ASC')} value={selectedSortField}>
                        <option value={'primary_order_number'}>Primary #</option>
                        <option value={'primary_account'}>Primary account</option>
                    </select>
                );
            case 'internal_status-delivery_method':
                return (
                    <select onChange={(props) => handleChange(props.target.value, 'ASC')} value={selectedSortField}>
                        <option value={'internal_status'}>Status</option>
                        <option value={'delivery_method'}>Delivery</option>
                    </select>
                );
        }
    }   

    const setDataField = (source: string, sortBy?: string,) => {
        if (source.split('-').length > 1) {
            if (sort?.field) {
                if (source.includes(sort.field)) return sort.field;
                else source.split('-')[0];
            } else { return sortBy }
        } else return sortBy || source;
    }

    const handleChange = (field: string, order: string = '') => {
        console.log('change to sort:', field);
        setSelectedSortField(field);
        updateSort?.(field, order)
    };
  
    const clickonHeaderCell = (params: any, field: any) => {
        //disable click on header cell for header when disable sort OR it is "select" || "svg" tag
        if(['select', 'svg'].some(tag => tag == params.target.localName)
            || field.props?.sortable == false) return;

        let data_value = field.props.sortBy || field.props.source.split('-')[0];       
        if(data_value){            
            updateSort?.(data_value, 'ASC')
        }
    }
    
    return (
        <StyledTableCell
            className={clsx(className, field?.props.headerClassName)}
            align={field?.props.textAlign || field?.type.textAlign}
            variant="head"
            onClick={(params) => clickonHeaderCell(params, field)}
            {...rest}
        >
            {updateSort &&
                sort &&
                field &&
                field.props.sortable !== false &&
                field.type.sortable !== false &&
                (field.props.sortBy || field.props.source) ? (
                <Tooltip
                    title={sortLabel}
                    placement={
                        field.props.textAlign === 'right' ||
                            field.type.textAlign === 'right'
                            ? 'bottom-end'
                            : 'bottom-start'
                    }
                    enterDelay={300}
                >
                    <span style={isTN && field.props.source == 'event' ? styleTNEvent : {}}>
                        <TableSortLabel
                            active={field.props?.source.split('-').some((x: string) => x === sort.field)}
                            direction={sort.order === 'ASC' ? 'asc' : 'desc'}
                            data-field={setDataField(field.props.source, field.props.sortBy)}
                            data-order={field.props.sortByOrder || 'ASC'}
                            //onClick={clickOnMultipleSort}
                            classes={ODatagridHeaderCellClasses}

                        >
                            {renderCustomSort(field.props.source) && <MultipleSordHeader source={field.props.source} /> || <span>{field.props.label}</span>}
                        </TableSortLabel>
                        {isTN && field.props.source == 'event' && <span>Wholesale/Total</span>}
                    </span>
                </Tooltip>
            ) : (
                <FieldTitle
                    label={field?.props.label}
                    source={field?.props.source}
                    resource={resource}
                />
            )}
        </StyledTableCell>
    );
};

export interface ODatagridHeaderCellProps
    extends Omit<TableCellProps, 'classes' | 'resource'> {
    className?: string;
    field?: JSX.Element;
    isSorting?: boolean;
    sort?: SortPayload;
    updateSort?: (field: string, order: any) => void;
    vendor?: { controller: string };
}

export default memo(
    ODatagridHeaderCell,
    (props, nextProps) =>
        props.updateSort === nextProps.updateSort &&
        props.sort?.field === nextProps.sort?.field &&
        props.sort?.order === nextProps.sort?.order &&
        props.isSorting === nextProps.isSorting
);

const PREFIX = 'RaDatagridHeaderCell';

export const ODatagridHeaderCellClasses = {
    icon: `${PREFIX}-icon`,
};

// Remove the sort icons when not active
const StyledTableCell = styled(TableCell, {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})({
    [`& .MuiTableSortLabel-icon`]: {
        display: 'none',
    },
    [`& .Mui-active .MuiTableSortLabel-icon`]: {
        display: 'inline',
    },
});
