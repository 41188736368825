import React, { useRef } from 'react';
import { useState } from 'react';
import { useUpdate, useNotify, Confirm } from 'react-admin';
import { Stack, FormControl, Button } from '@mui/material';
import { UserStatusObj } from '../../../utils/enum';
import MAutoComplete from '../../../components/controls/MAutoComplete';
import { refreshCeilsAGrid } from '../../../utils/common';

const AssignmentUser = (props) => {
  const { listUser } = props;
  const refInput = useRef(null);
  const [user, setUser] = useState();
  const [open, setOpen] = useState(false);//react-admin confirm
  const saveEdit = () => setOpen(true);
  const [handleUpdate] = useUpdate();
  const notify = useNotify();
  const handleDialogClose = () => setOpen(false);
  const onUserChange = (value) => {
    setUser(value);
  };

  React.useEffect(() => {
    setTimeout(() => refInput.current.click(), 100); // To show options of autocomplete
  }, [listUser]);

  React.useEffect(() => {
    initUser(props.value);
  }, [props.value]);

  const handleConfirm = () => {
    let order = props.data;
    order.assignedTo = user.id;
    order.status = UserStatusObj.Actived;
    order.aliasName = user.value;
    handleUpdate('OrderAssignments', { id: order.id, data: order }, {
      onSuccess: (response) => {
        notify('Update successfully', { type: 'success', autoHideDuration: 2000 });
        props.api.stopEditing();
        props.data['assignedDate'] = response?.assignedDate;
        props.data['assignedTo'] = response?.assignedTo;
        refreshCeilsAGrid(props, ["assignedDate", "assignedTo"]);
      },
      onError: (error) => {
        notify(`Update error: ${error.message}`, { type: 'error', autoHideDuration: 5000 });
        props.data.tags = props.value;
        props.api.stopEditing();
      },
    });
    setOpen(false);
  };

  function initUser(value) {
    let _user = listUser.find(x => x.id == value);
    setUser(_user);
  }

  return (
    <FormControl sx={{ width: 250 }}>
      <MAutoComplete
        label={"Select user (*)"}
        refInput={refInput}
        isMulti={false}
        choices={listUser}
        value={user}
        defaultValue={props.value}
        onChange={onUserChange}
        error={!user}
      />
      <Stack sx={{ mt: 2 }} direction="row" spacing={2}>
        <Button onClick={saveEdit} variant="contained" disabled={!user}>Save</Button>
        <Button onClick={() => { props.api.stopEditing(); }} variant="outlined">Cancel</Button>
      </Stack>
      <Confirm isOpen={open} title='Assign Order' content='Are you sure want to assign to this user?' onConfirm={handleConfirm} onClose={handleDialogClose} />
    </FormControl>
  );
};

export default AssignmentUser;