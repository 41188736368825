import * as React from 'react';
import { useMemo, useState, useRef } from 'react';
import Button from '@mui/material/Button';
// import LoadingButton from '@mui/lab/LoadingButton';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import { TextField, Box, useColorScheme } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { dayjs, formatDateTime } from "../../../components/ex_dayjs";
import { AgGridReact } from 'ag-grid-react';
import { dataProvider } from '../../../DataProvider';
import EventActionPopUp from './EventActionPopUp';


const InsertEvent = ({ handleInsert, value, onChange }) => {
    const [link, setLink] = React.useState();
    const [loading, setLoading] = React.useState(false);
    return (
        // <EventActionPopUp open={open} handleClose={handleClose} title='Insert New Event'>
        <DialogContentText>
            <Typography variant="h6">
                Event Link
            </Typography>
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={3} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextField
                        onChange={(e) => setLink(e.target.value)}
                        variant="filled"
                        value={link}
                        autoFocus
                        type="text"
                        fullWidth
                        placeholder='Cirque du Soleil Crystal'
                    />

                </Box>
                <Box flex={1} sx={{ pt: 2 }} mr={{ xs: 0, sm: '0.2em' }}>
                    {/* <Button startIcon={<SendIcon />} variant="contained" onClick={() => handleInsert(link)}>Insert</Button> */}
                    {/* <LoadingButton
                        onClick={() => { setLoading(true); handleInsert(link) }}
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<SendIcon />}
                        variant="contained"
                    >
                        <span>Insert</span>
                    </LoadingButton> */}
                </Box>
            </Box>

        </DialogContentText>

        // </EventActionPopUp>
    )
}

export default InsertEvent;