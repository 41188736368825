import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/material/CssBaseline';

import * as React from "react";
import { Route } from 'react-router';
import { Admin, Resource, CustomRoutes, memoryStore } from 'react-admin';
import { QueryClient } from '@tanstack/react-query';
import { createBrowserHistory } from 'history';
import pages from './pages';
import Layout from './layout';
import authProvider from './auth/authProvider';
import LoginPage from './auth/LoginPage';
import { dataProvider } from './DataProvider';
import Home from "./pages/dashboard/Home";
import Error403 from "./pages/dashboard/403";
import { StealthPage } from "./pages/stealth";
import { MATERIAL_THEME_ID, MATERIAL_THEME, MaterialCssVarsProvider } from './theme';
import AAT_AccountAssignmentPage from './pages/aat-account-assigment';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  },
});

const history = createBrowserHistory();
const Ready = () => (
  <div>
    <h1>Admin ready</h1>
    <p>You can now add resources</p>
  </div>
);

export default function App() {
  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: MATERIAL_THEME }} defaultMode="dark">
      <JoyCssVarsProvider>
        <CssBaseline enableColorScheme />
        <Admin
          authProvider={authProvider}
          queryClient={queryClient}
          loginPage={LoginPage}
          layout={Layout}
          requireAuth
          ready={Ready}
          history={history}
          dataProvider={dataProvider}
          store={memoryStore()} //don’t want the store to be persisted between sessions
        >
          <CustomRoutes>
            <Route path="/error/403" element={<Error403 />} />
            <Route path="/" element={<Home />} />
            <Route path="/stealth" element={<StealthPage />} />
            <Route path="/AAT_AccountAssignment" element={<AAT_AccountAssignmentPage />} />
          </CustomRoutes>

          {pages.map((page, i) => (
            <Resource key={i} {...page} />
          ))}
        </Admin>
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
}