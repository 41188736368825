import * as React from 'react';
import { Button } from '@mui/material';
import { useFilterList } from '../../../context/FilterListProvider';

const ApplyVenuesButton = ({ callback }) => {
    const [filterList] = useFilterList();
    const searchVenues = () => callback(filterList);
    return (
        <Button size="small" onClick={searchVenues} >Apply Venues</Button>
    )
}

export default ApplyVenuesButton;