import React from 'react';
import { CssBaseline, Container } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';

import { Error } from 'react-admin';
import Header from './Header';
const Layout = (props) => {
    const { children } = props;
    return (
        <>
            <CssBaseline />
            <Header />
            <Container style={{ margin: "unset", width: "100%", height: "100%", maxWidth: "unset", padding: "unset" }}>
                <main id="main-content" style={{ marginTop: "10px", paddingLeft: "10px", paddingRight: "10px" }}>
                    {/* @ts-ignore */}
                    <ErrorBoundary FallbackComponent={Error}>
                        {children}
                    </ErrorBoundary>
                </main>
            </Container>
        </>
    );
};


export default Layout;
