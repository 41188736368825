import * as React from 'react';
import { Grid } from '@mui/material';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { dayjs } from '../ex_dayjs';
import PropTypes from 'prop-types';
import MDateControl from './MDateControl';

const defaultProps = {
    format: "MM/DD/YYYY",
    isUTC: false
};

const MDateRangePicker = ({
    format = defaultProps.format,
    isUTC = defaultProps.isUTC,
    label, name, onChange, defaultValue, ...props
}) => {
    const [from, setFrom] = React.useState();
    const [to, setTo] = React.useState();

    React.useEffect(() => {
        if (defaultValue?.[0]) setFrom(dayjs(defaultValue[0]));
        if (defaultValue?.[1]) setTo(dayjs(defaultValue[1]));
    }, [defaultValue]);

    const onChangeDateFrom = (newValue) => {
        onChange(newValue, `${name}_ge`);
    };

    const onChangeDateTo = (newValue) => {
        onChange(newValue, `${name}_le`);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container>
                <Grid item xs={6}>
                    <MDateControl
                        {...props}
                        label={label}
                        defaultValue={from}
                        format={format}
                        isUTC={isUTC}
                        onChange={onChangeDateFrom}                        
                    />
                </Grid>
                <Grid item xs={6}>
                    <MDateControl
                        {...props}
                        label={"to"}
                        defaultValue={to}
                        isUTC={isUTC}
                        isEndOfDate={true}
                        format={format}
                        onChange={onChangeDateTo} />
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
};

MDateRangePicker.prototype = {
    label: PropTypes.string,
    name: PropTypes.string,
    defaultValue: PropTypes.any,
    onChange: PropTypes.func,
    format: PropTypes.string,
    isUTC: PropTypes.bool
};

export default React.memo(MDateRangePicker);