import React, { useState, useRef, useMemo, useCallback } from 'react';
import RenderControls from '../../components/ra-list/RenderControls';
import { Title, useDataProvider, useNotify } from 'react-admin';
import { Button, Grid, Tooltip, IconButton, Chip } from '@mui/material';
import { dayjs, formatDateTime } from '../../components/ex_dayjs';
import { loadAccountMaps, loadSourceType } from '../../utils/common';
import { getRaStore, setRaStore } from '../../utils/common';
import AgGrid from '../../components/ag-grid/ag-grid';
import HistoryIcon from '@mui/icons-material/History';
import History from './components/history';

const SmEventTickets = () => {

    const refbtnFilter = useRef(null);
    const dataProvider = useDataProvider();
    const refInput = useRef();
    const refInputDetails = useRef();
    const notify = useNotify();

    const [ticketModel, setTicketModel] = useState([]);
    const [marketType, setMarketType] = useState([]);
    const [accountMaps, setAccountMaps] = useState([])
    const [rowData, setRowData] = useState({ data: null, isSearch: false, prevId: null });
    const [defFilterSmEvent, setDefValSmEvent] = useState({ exchange_market_eq: 1 })
    const [defFilterSmEventDetails, setDefValSmEventDetails] = useState({
        status_shown_eq: true,
        status_not_shown_eq: true,
        have_a_competitor_eq: null,
        isLowestPrice_eq: null
    })
    const [defaultValues, setDefaultValues] = useState({ sortField: { field: 'sm_event_date', order: 'asc' } })
    // const defaultValuesDetails = { sortField: { field: ['section asc', 'row asc'], order: 'asc' } };

    const [historyPopover, setHistoryPopover] = useState({ open: false, data: null, btnId: null });

    React.useEffect(() => {
        loadSourceType(dataProvider, (response) => {
            setMarketType(response);
        });
        loadAccountMaps(dataProvider, (response) => {
            let convertedData = response.map((item) => ({ id: item.account_id, name: item.listing_account }));
            setAccountMaps(convertedData);
        });

        dataProvider.fetchData('SmEventTickets', 'Index')
            .then(response => {
                setTicketModel(response.data);
            }).catch(err => { console.log("load index failed: ", err) })
    }, [])

    React.useEffect(() => {
        if (rowData.isSearch)
            refbtnFilter.current.click();
    }, [rowData.isSearch])

    const smEventFilter = [
        { component: 'number', name: 'id', label: 'Sm Event ID', xs: 3 },//id = sm_original_id
        {
            component: 'select', name: 'exchange_market_eq', label: 'Exchange market',
            choices: [{ id: 1, name: 'Vivid' }, { id: 2, name: 'Ticket Network' }], xs: 3, isEmptyOption: false
        },
        { component: 'text', name: 'sm_event_name_contains', label: 'Event', xs: 6 },
        { component: 'select', name: 'reporter_type_eq', label: 'Market type', xs: 3, choices: marketType },
        { component: 'text', name: 'site_id_eq', label: 'Site Id', xs: 3 },
    ]

    const smEventDetailsFilter = [
        { component: 'text', name: 'section', label: 'Section', xs: 4 },
        { component: 'text', name: 'row', label: 'Row', xs: 4 },
        { component: 'number', name: 'ticket_id_eq', label: 'Sm Listing #', xs: 2 },
        { component: 'number', name: 'src_listing_id_eq', label: 'Listing #', xs: 2 },
        { component: 'select', name: 'account_id_eq', label: 'Account', choices: accountMaps, xs: 2 },
        { component: 'checkbox', name: 'status_shown_eq', label: 'SHOWN', xs: 1.3 },
        { component: 'checkbox', name: 'status_not_shown_eq', label: 'NOT SHOWN', xs: 1.7 },
        { component: 'checkbox', name: 'status_delisted_eq', label: 'DELISTED', xs: 1.5 },
        { component: 'checkbox', name: 'status_removed_eq', label: 'REMOVED', xs: 1.5 },
        { component: 'checkbox', name: 'have_a_competitor_eq', label: 'have a competitor', nullable: true, xs: 2 },
        { component: 'checkbox', name: 'isLowestPrice_eq', label: 'LOWEST PRICE', nullable: true, xs: 2 },
    ]

    const columnDefsSmEvents = [
        { field: 'id', headerName: 'SM EventId', maxWidth: 110 },
        // { field: 'sm_market', headerName: 'Exchange', maxWidth: 100 },
        {
            field: 'sm_event_name', tooltipField: 'sm_event_name', headerName: 'Event',
            cellRenderer: params => {
                if (params.data.marketId == 1)
                    return <a target='_blank' href={`https://vividseats.com/a/production/${params.data.id}`} >{params.value}</a>
                else if (params.data.marketId == 2)
                    return <a target='_blank' href={`https://www.ticketnetwork.com/tickets/${params.data.id}`} >{params.value}</a>
            }
        },
        { field: 'sm_event_venue', tooltipField: 'sm_event_venue', headerName: 'Venue' },
        { field: 'sm_event_date', tooltipField: 'sm_event_date', headerName: 'Date', valueFormatter: ({ value }) => formatDateTime(value, 'YYYY-MM-DD hh:mm A') },
        {
            field: 'reporter_type', headerName: 'Market', maxWidth: 100,
            cellRenderer: params => { return <a target='_blank' href={params.data.url} >{params.value}</a> }
        },
        {
            field: 'all_song_tickets', headerName: 'Volume', type: 'numericColumn', maxWidth: 90,
            cellRenderer: params => {
                return <Tooltip title={params.data.listedCount ? `Not shown: ${params.data.listedCount}` : ""} placement='right'>
                    <span style={{ fontWeight: params.data.listedCount ? 'bold' : '' }}>
                        {params.value}
                    </span>
                </Tooltip>
            }
        },
    ];

    const columnDefsSmEventDetails = useMemo(() => [
        {
            field: 'ticket_id', headerName: 'Sm Listing #', minWidth: 120,
            cellRenderer: params => {
                return <Tooltip title={params.data.haveCompetitor ? 'have a competitor' : "don't have a competitor"} placement='left-start'>
                    <span style={{ color: params.data.haveCompetitor ? 'red' : '' }}>{params.value}</span></Tooltip>
            }
        },
        {
            field: 'account', headerName: 'Account',
            tooltipValueGetter: (params) => {
                return accountMaps.find(x => x.id == params.value)?.name;
            },
            cellRenderer: params => {
                return <a target='_blank' href={`${location.origin}/Listings/?ListingId=${params.data.src_listing_id}&account_id_eq=${params.data.account}`} >
                    {accountMaps.find(x => x.id == params.value).name}</a>
            }
        },
        { field: 'section', headerName: 'Section', minWidth: 100, tooltipField: 'section' },
        { field: 'row', headerName: 'Row', minWidth: 60 },
        { field: 'report_price', headerName: 'Report', type: 'numericColumn' },
        {
            field: 'price', headerName: 'Price', minWidth: 150, type: 'rightAligned',
            cellRenderer: params => {
                return <Tooltip title={params.data.target_price ? `map_price: ${params.data.target_price}` : ""} placement='right'>
                    <span style={{ fontWeight: params.data.target_price ? 'bold' : '' }}>
                        {showHistory(params.data) && <IconButton id={`history-${params.data.id}`} data-action='history'><HistoryIcon /></IconButton>}
                        {params.data.target_price ? `${params.value} ($${params.data.target_price})` : params.value}
                    </span>
                </Tooltip>
            }
        },
        { field: 'original_price', headerName: 'Org', type: 'numericColumn' },
        { field: 'floor_price', headerName: 'Floor', type: 'numericColumn' },
        { field: 'auto_price', headerName: 'Auto', type: 'numericColumn', valueFormatter: ({ value }) => value == 0 ? '-' : value },
        { field: 'quantity', headerName: 'Qty', type: 'numericColumn' },
        {
            field: 'action', headerName: 'Action',
            tooltipValueGetter: (params) => {
                if (params.data.action == 3) {
                    return 'DELISTED';
                } else if (params.data.action == 1) {
                    return 'REMOVED';
                }
                if (!params.data.is_shown) {
                    return 'NOT SHOWN';
                } else {
                    return 'SHOWN';
                }
            },
            cellRenderer: params => {
                if (params.data.action == 3) {
                    return <span style={{ color: 'red' }}>DELISTED</span>;
                } else if (params.data.action == 1) {
                    return <span style={{ color: 'red' }}>REMOVED</span>;
                }
                if (!params.data.is_shown) {
                    return <span style={{ color: 'red' }}>NOT SHOWN</span>;
                } else {
                    return 'SHOWN';
                }
            }
        },
        {
            field: 'updated_date', headerName: 'Updated Date',
            tooltipValueGetter: (params) => {
                return dayjs.utc(params.data.updated_date).fromNow();
            },
            valueFormatter: ({ value }) => dayjs.utc(value).fromNow()
        }
    ]);

    const showHistory = (data) => data.lowestTicketJson && JSON.parse(data.lowestTicketJson).length > 0;

    const defaultColDef = React.useMemo(() => {
        return {
            sortable: true,
            resizable: true,
            flex: 1,
            filter: false,
            suppressMovable: true,
            singleClickEdit: true,
            autoSizeColumns: true,
            cellStyle: { display: 'grid', 'align-items': 'center', }
        };
    }, []);

    const handleSearch = (filterList) => {
        refInput.current.customFilterSearch(filterList);
        setRowData({ ...rowData, prevId: null });
    }

    const handleSearchDetails = (filterList) => {
        if (rowData.isSearch || rowData.data != null) {
            filterList.smEventId = rowData.data.id;
            filterList.eventId = rowData.data.event_id;
        }
        refInputDetails.current.customFilterSearch(filterList);
        setRowData({ ...rowData, isSearch: false });
    }

    const handleRowClick = (params) => {
        if (params.data?.id != rowData.prevId)
            setRowData({ ...rowData, data: params.data, isSearch: true, prevId: params.data?.id });
    }

    const onCellClicked = ((params) => {
        if (params.column.colId === "price" && showHistory(params.data)) {
            setHistoryPopover({ ...historyPopover, open: true, data: params.data.lowestTicketJson, btnId: `history-${params.data.id}` });
        }
    });

    const handleShutdownVV = () => {
        if (confirm(`Are you sure to ${ticketModel.pricerVivid.enabled ? 'disable' : 'enable'} auto pricer for Vivid`)) {
            dataProvider.postData('SmEventTickets', 'EnablePricer', { market: 1, enabled: !ticketModel.pricerVivid.enabled })
                .then(response => {
                    let data = response.data;
                    notify(data.message, { type: data.success ? 'success' : 'error', autoHideDuration: 5000 });
                    if (data.success) {
                        setTicketModel({ ...ticketModel, pricerVivid: { enabled: !ticketModel.pricerVivid.enabled, market: 1 } });
                    }
                }).catch(err => console.log('Fail shutdown VV: ', err))
        }
    }

    const handleShutdownTN = () => {
        if (confirm(`Are you sure to ${ticketModel.pricerTN.enabled ? 'disable' : 'enable'} auto pricer for TicketNetwork`)) {
            dataProvider.postData('SmEventTickets', 'EnablePricer', { market: 2, enabled: !ticketModel.pricerTN.enabled })
                .then(response => {
                    let data = response.data;
                    notify(data.message, { type: data.success ? 'success' : 'error', autoHideDuration: 5000 });
                    if (data.success) {
                        setTicketModel({ ...ticketModel, pricerTN: { enabled: !ticketModel.pricerTN.enabled, market: 2 } });
                    }
                }).catch(err => console.log('Fail shutdown TN: ', err))
        }
    }

    const onDetailsGridReady = ((params) => {
        let defaultSortModel = [
            { colId: 'section', sort: 'asc', sortIndex: 0 },
            { colId: 'row', sort: 'asc', sortIndex: 1 },
        ];
        params.columnApi.applyColumnState({ state: defaultSortModel });
    });

    const onGridReady = ((params) => {
        let defaultSortModel = [
            { colId: 'sm_event_date', sort: 'asc', sortIndex: 0 },
        ];
        params.columnApi.applyColumnState({ state: defaultSortModel });
    });

    return (
        <>
            <Title title='SM Event Tickets' />
            <Grid container spacing={2} className='smeventtickets'>
                <Grid item xs={5}>
                    <RenderControls controls={smEventFilter} defaultValues={defFilterSmEvent} onSearch={handleSearch} />
                    <div style={{marginTop: '42px'}}></div>
                    <AgGrid
                        ref={refInput}
                        list={'SmEventTickets'}
                        columnDefs={columnDefsSmEvents}
                        defaultColDef={defaultColDef}
                        rowHeight={45}
                        rowSelection='single'
                        onRowClicked={handleRowClick}
                        editType={'fullRow'}
                        defaultValues={defaultValues}
                        onGridReady={onGridReady}
                    ></AgGrid>
                </Grid>
                <Grid item xs={7} className='smeventtickets'>
                    <RenderControls controls={smEventDetailsFilter} defaultValues={defFilterSmEventDetails}
                        filterInline={false}
                        refFilter={refbtnFilter}
                        actions={<>
                            <Button variant='contained' color='error' onClick={handleShutdownVV}>{ticketModel?.length == 0 || !!!ticketModel ? 'Loading ...' : ticketModel?.pricerVivid?.enabled ? 'Shutdown VV' : 'Enable VV'}</Button>
                            <Button variant='contained' color='error' onClick={handleShutdownTN}>{ticketModel?.length == 0 || !!!ticketModel ? 'Loading ...' : ticketModel?.pricerTN?.enabled ? 'Shutdown TN' : 'Enable TN'}</Button>
                        </>}
                        onSearch={handleSearchDetails}
                    />                    
                    <AgGrid
                        ref={refInputDetails}
                        list={'SmEventTicketsDetails'}
                        columnDefs={columnDefsSmEventDetails}
                        defaultColDef={defaultColDef}
                        rowHeight={45}
                        rowSelection='single'
                        editType={'fullRow'}
                        onCellClicked={onCellClicked}
                        // defaultValues={defaultValuesDetails}                      
                        onGridReady={onDetailsGridReady}
                    ></AgGrid>
                </Grid>
            </Grid>
            <History info={historyPopover} setHistoryPopover={setHistoryPopover} />
        </>
    );

}
export default SmEventTickets;