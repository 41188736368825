import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import RuleCreator from './RuleCreator';
import { Box, Checkbox, FormControlLabel, Stack, LinearProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle, Chip, Divider } from '@mui/material';
import { useNotify, Confirm } from 'react-admin';
import { dataProvider } from '../../../DataProvider';
import { reloadPage } from '../../../utils/common';
import { Preview, Delete, Send, RemoveCircle, CheckCircle } from '@mui/icons-material';
import { MLabel } from '../../csv-merge/components/RuleEditPopup';
import { DataGrid } from '@mui/x-data-grid';

export default function ActionForm({ isEdit, open, onClose, editData, marketType, delivery, account, refbtnFilter }) {
    const [openConfirm, setOpenConfirm] = useState(false);
    const [valid, setValid] = useState(true);
    const [dataUpdate, setDataUpdate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [confirmData, setConfirmData] = useState(null);
    const [data, setData] = useState(editData);
    const [active, setActive] = useState(null);
    const [previewData, setPreviewData] = useState([]);
    const actionQuery = useRef();
    const conditionQuery = useRef();
    const notify = useNotify();
    const handleClose = () => {
        onClose();
    };
    useEffect(() => {
        setData(editData);
        setActive(isEdit ? editData.active : true);
        return () => {
            setData(null);
        };
    }, [editData]);

    const handleDataChange = (e) => {
        setValid(true);
        setData(preData => ({ ...preData, name: e.target.value }));
    }

    const handleApply = (actionType) => {
        const dataUpdate = getData();
        if (!dataUpdate || !dataUpdate.action || !dataUpdate.condition) return;
        dataUpdate.preferSQLQuery = conditionQuery.current.getValues('sql') || '';
        dataUpdate.isRemove = actionType === 'Clean' ? true : false;
        setLoading(true);
        setData(prev => ({ ...prev, action: dataUpdate.action, condition: dataUpdate.condition }));

        dataProvider.postData('OrderAdjustRule', 'Preview', dataUpdate)
            .then((response) => {
                if (response && response.data.count > 0) {
                    setDataUpdate(dataUpdate);
                    setOpenConfirm(true);
                    setConfirmData({
                        title: 'Update Rule',
                        content: `This rule will affect to ${response.data.count} orders. Are you sure to continue?`,
                        action: actionType
                    })
                } else if (response.name === 'TypeError' || response.status === 500) {
                    notify("Something went wrong", { type: 'error', autoHideDuration: 5000 });
                }
                else {
                    notify('No order affected', { type: 'info', autoHideDuration: 5000 });
                }
            })
            .catch(e => {
                notify(`Something went wrong. \nError_INFO:::${e}`, { type: 'error', autoHideDuration: 5000 });
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleConfirm = (action) => {
        if (action === 'DELETE') {
            const deleteData = {
                id: data?.id || '0'
            }
            setLoading(true);
            dataProvider.postData('OrderAdjustRule', 'Save', deleteData)
                .then((response) => {
                    if (response && response.data) {
                        notify('Save Success', { type: 'success', autoHideDuration: 5000 });
                        onClose();
                        reloadPage('OrderAdjustRule', refbtnFilter);
                    } else {
                        notify("Fail to save", { type: 'error', autoHideDuration: 5000 });
                    }
                })
                .catch(e => {
                    notify(`Fail to save.\nError_INFO:::${e}`, { type: 'error', autoHideDuration: 5000 });
                })
                .finally(() => {
                    setLoading(false);
                    setOpenConfirm(false);
                });
        } else {
            setLoading(true);
            dataProvider.postData('OrderAdjustRule', 'Apply', dataUpdate)
                .then((response) => {
                    if (response && response.data) {
                        notify('Update Success', { type: 'success', autoHideDuration: 5000 });
                    } else {
                        notify(`Fail to update \nError_INFO:::${response}`, { type: 'error', autoHideDuration: 5000 });
                    }
                })
                .catch(e => {
                    notify(`Something went wrong. \nError_INFO:::${e}`, { type: 'error', autoHideDuration: 5000 });
                })
                .finally(() => {
                    setLoading(false);
                    setOpenConfirm(false);
                });
        }
    }

    const handleActive = (ev) => {
        setActive(ev.target.checked);
    }

    const handleSave = () => {
        const data = getData();
        if (data === null) return;
        setLoading(true);
        dataProvider.postData('OrderAdjustRule', 'Save', data)
            .then((response) => {
                if (response && response.data) {
                    notify('Update Success', { type: 'success', autoHideDuration: 5000 });
                    onClose();
                    reloadPage('OrderAdjustRule', refbtnFilter);
                } else {
                    notify("Fail to update", { type: 'error', autoHideDuration: 5000 });
                }
            })
            .catch(_ => {
                notify("Something went wrong", { type: 'error', autoHideDuration: 5000 });
            })
            .finally(() => {
                setLoading(false);
                setOpenConfirm(false);
            });
    }

    const handleDelete = () => {
        setOpenConfirm(true);
        setConfirmData({
            title: 'Delete Rule',
            content: `You are about to delete the rule. Are you sure?`,
            action: 'DELETE'
        })
    }

    const getData = () => {
        if (!data?.name || data?.name === '') {
            setValid(false);//only for name
            return null;
        }

        var _action = actionQuery.current.getValues('json');
        var _condition = conditionQuery.current.getValues('json');

        if (!_action) {
            notify(`Invalid actions`, { type: 'error' });            
            return null;
        }
        if (!_condition){
            notify(`Invalid conditions`, { type: 'error' });            
            return null;
        } 

        return {
            id: data?.id || '0',
            name: data?.name || '',
            active: active,
            action: _action,
            condition: _condition,
            createdby: data?.createdby || '',
            modified: data?.modified
        }
    }

    const showMatchCondition = () => {
        const dataUpdate = getData();
        if (!dataUpdate || !dataUpdate.action || !dataUpdate.condition) return;
        dataUpdate.preferSQLQuery = conditionQuery.current.getValues('sql') || '';

        setData(prev => ({ ...prev, action: dataUpdate.action, condition: dataUpdate.condition }));
        setLoading(true);
        dataProvider.postData('OrderAdjustRule', 'Preview', dataUpdate)
            .then((response) => {
                if (response.data) {
                    notify(`Found ${response.data.count} candidates`, { type: 'success', autoHideDuration: 5000 });
                    setPreviewData(response.data.result);
                }
                else {
                    notify(`Something went wrong. Status: ${response.status || response.message}`, { type: 'error', autoHideDuration: 5000 });
                }
                setLoading(false);
            })
            .catch(err => {
                notify(`Error !!! . Status: ${err.status}`, { type: 'error', autoHideDuration: 5000 });
                setLoading(false);
            });

    }   
    const columnDefs = [
        { field: 'order_number', headerName: 'Order Number', width: 120 },
        { field: 'venue', headerName: 'Venue', width: 250 },        
        { field: 'tags', headerName: 'Tags', width: 350, valueFormatter: ({value}) => value.split(',').filter(x => x).join(', ') },        
        { field: 'notes_by_rules', headerName: 'Notes', width: 600 },
    ];

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth={true}
                scroll={'paper'}
                maxWidth={"lg"}
            >
                <DialogTitle id="scroll-dialog-title">
                    {isEdit ? `Update Rule - #${editData.id}` : 'Create New Rule'}
                </DialogTitle>
                <Divider></Divider>
                <DialogContent dividers={scroll === 'paper'}>
                    <RuleCreator
                        onDataChange={handleDataChange}
                        isEdit={isEdit} editData={data}
                        conditionQueryRef={conditionQuery}
                        actionQueryRef={actionQuery}
                        valid={valid}
                        marketType={marketType}
                        delivery={delivery}
                        account={account}
                    />
                    <br />
                    <Button startIcon={<Preview />} color='info' variant='contained' onClick={showMatchCondition} >Preview</Button>
                    <MLabel key={'math'} name={'Match Candidates (only show 1000 orders)'} h={'h6'} />
                    {loading && <LinearProgress color="secondary" />}
                    <Box sx={{ height: previewData.length > 0 ? "60vh" : 100, width: '100%' }}>
                        <DataGrid
                            rows={previewData}
                            columns={columnDefs}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 25,
                                    },
                                },                              
                                sorting: {
                                    sortModel: [{ field: 'date', sort: 'desc' }],
                                },
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions >
                    <Stack width='100%' direction={{ xs: 'column', sm: 'row' }}
                        justifyContent="space-between">
                        <FormControlLabel label="Active" control={<Checkbox defaultChecked={!isEdit ? true : editData.active} checked={active} onChange={handleActive} />} />
                        <Stack direction={"row"} spacing={1}>
                            <Button disabled={loading} startIcon={<Send />} variant="contained" color='info' onClick={handleSave}>Save</Button>
                            <Button disabled={loading} variant="contained" color='success' startIcon={<CheckCircle />} onClick={() => handleApply('Apply')}>Apply</Button>
                            <Button disabled={loading} variant="contained" color='warning' startIcon={<RemoveCircle />} onClick={() => handleApply('Clean')}>Clean</Button>
                            <Button disabled={loading} variant="contained" color="error" startIcon={<Delete />} onClick={handleDelete}>Delete</Button>
                            <Button variant="outlined" onClick={() => onClose()}>Close</Button>
                        </Stack>
                    </Stack>
                </DialogActions>
            </Dialog>
            <Confirm isOpen={openConfirm} title={confirmData?.title || ''} content={confirmData?.content || ''} onConfirm={() => handleConfirm(confirmData?.action || '')} onClose={() => setOpenConfirm(false)} />
        </div>
    );
}