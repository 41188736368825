import * as React from 'react';
import MDialog from '../../../components/controls/MDialog';
import { Box, Button, Typography, Skeleton, Grid, Stack, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Confirm, useNotify } from 'react-admin';
import { REMOTE_API, convertStringToArray, objectToFormData, } from '../utils';
import MQueryBuilder from '../../../components/query-builder/MQueryBuilder';
import { QueryBuilderMaterial } from '@react-querybuilder/material';
import MAutoComplete from '../../../components/controls/MAutoComplete';
import TagsAutoComplete from './TagsAutoComplete';
import MDatePicker from '../../../components/controls/MDatePicker';
import { Delete, RemoveRedEyeOutlined, Send } from '@mui/icons-material';
import { actionInitData, combinators, conditionInitData, getActionFields, getConditionFields, getExtractDate, getRule } from './QueryBuilderConst';
import { formatDate, formatDateTime } from '../../../components/ex_dayjs';
import EntryDatePopup from './EntryDatePopup';
import MNumber from '../../../components/controls/MNumber';
import MCheckbox from '../../../components/controls/MCheckbox';

const emptyError = `This field cannot be empty`;

export const MLabel = ({ key, name, h, style, ...props }) => {
    return (
        <Typography {...props} variant={h ? h : 'h5'} style={style ? style : { marginTop: 10, marginBottom: 0 }} gutterBottom flex="1" key={key}>
            {name}
        </Typography>
    );
};

const RuleEditPopup = ({ dataProvider, reloadPage, onClose, ...props }) => {
    const { dlgName, isOpenRule, rowSelected, ruleId, sources } = props.data;
    const actionQuery = React.useRef();
    const conditionQuery = React.useRef();
    const notify = useNotify();

    const [state, setState] = React.useState({
        isLoading: true,
        valids: [],
        editData: null,
        formatedCondition: null,
        formatedAction: null,
        applyForMsg: '', // for keep saving
        // for test
        rowsTest: [],
        isLoadingForTest: false,
        isLoadingForSave: false,
        isLoadingForDelete: false,
        // for count confirm
        isCountConfirm: false,
        countMsgConfirm: '',
        countRule: null,
        // for confirm
        isConfirmDelete: false, // for save buton, remove applyFor
        isDelete: false, // for button delete
        // for entry date
        isOpenEntryDate: false,
        entryDates: [],
        addall: false // add all for apply for
    });

    React.useEffect(() => {
        setState((prev) => ({ ...prev, isLoading: false }));
        if (rowSelected) {
            setState((prev) => ({
                ...prev,
                editData: { ...rowSelected, Source: convertStringToArray(rowSelected.Source), Tags: convertStringToArray(rowSelected.Tags) },
                formatedCondition: JSON.parse(rowSelected.Condition),
                formatedAction: JSON.parse(rowSelected.Action),
            }));
        }
    }, [rowSelected]); // select row of csv

    // Call api to get data of rule
    React.useEffect(() => {
        if (ruleId) {
            let _ruleId = ruleId;
            if (_ruleId instanceof Array) {
                let ruleItem = getRule(_ruleId);
                if (ruleItem) {
                    setState((prev) => ({
                        ...prev,
                        editData: { ...ruleItem, Id: ruleItem.Id, Source: convertStringToArray(ruleItem.Source), Tags: convertStringToArray(ruleItem.Tags), Priority: ruleItem.Priority || 0 },
                        formatedCondition: ruleItem.Condition,
                        formatedAction: ruleItem.Action,
                    }));
                }
            } else {
                setState((prev) => ({ ...prev, isLoading: true }));
                const loadRule = async () => {
                    try {
                        const response = await dataProvider.fetchData(REMOTE_API, `rules/${_ruleId}`);
                        let ruleItem = {
                            ...response.data.RuleData,
                            Source: response.data.ApplyFor
                        };
                        setState((prev) => ({
                            ...prev,
                            isLoading: false,
                            editData: { ...ruleItem, Source: convertStringToArray(ruleItem.Source), Tags: convertStringToArray(ruleItem.Tags) },
                            formatedCondition: JSON.parse(response.data.RuleData.Condition),
                            formatedAction: JSON.parse(response.data.RuleData.Action)
                        }));
                    } catch (error) {
                        setState((prev) => ({ ...prev, isLoading: false }));
                        notify(`Load rule error: ${error}`, { type: 'error' });
                    }
                };
                loadRule();
            }
        }
    }, [ruleId]); // For select a rule

    const isError = (field) => state.valids.some(x => x[field] === true);

    const getData = () => {
        var isNullName = !state.editData?.Name?.trim();
        var isNullApplyFor = (!rowSelected || state.editData.Id <= 0) && !state.editData?.Source?.length; // Create New Rule
        setState((prev) => ({ ...prev, valids: [{ name: isNullName }, { applyfor: isNullApplyFor }] }));
        if (isNullName || isNullApplyFor) return null;
        var _action = actionQuery.current.getValues('json');
        var _condition = conditionQuery.current.getValues('json');
        if (!_action) notify(`Invalid actions`, { type: 'error' });
        if (!_condition) notify(`Invalid conditions`, { type: 'error' });
        return {
            action: _action,
            condition: _condition
        };
    };

    const onTest = () => {
        var postData = getData();
        if (!postData || !postData.action || !postData.condition) return;
        let _method = actionQuery.current.method;
        let action = JSON.parse(postData.action);
        if (_method === 'remove') {
            action = {
                method: _method,
                rules: []
            };
        }
        setState((prev) => ({ ...prev, isLoadingForTest: true }));
        dataProvider.postData(REMOTE_API, `test?${getCsvMergeSource()}`, objectToFormData({
            Action: JSON.stringify(action, null, 2),
            Condition: postData.condition
        })).then(function (response) {
            setState((prev) => ({ ...prev, isLoadingForTest: false }));
            if (response.data) {
                setState((prev) => ({ ...prev, rowsTest: response.data }));
            }
        }).catch((error) => {
            setState((prev) => ({ ...prev, isLoadingForTest: false }));
            notify(`Error: ${error}`, { type: 'error' });
        });
    };

    const onSave = () => {
        var postData = getData();
        if (!postData || !postData.action || !postData.condition) return;
        if (!validateRule()) return;
        if (!validateExpiresAt()) return;
        setState((prev) => ({ ...prev, isLoadingForSave: true }));
        var csvMergeSource = getCsvMergeSource();
        var rule = {
            Action: postData.action,
            Condition: postData.condition
        };
        count(csvMergeSource, objectToFormData(rule));
    };

    function deleteRule() {
        setState((prev) => ({ ...prev, isLoadingForDelete: true }));
        dataProvider.delete(REMOTE_API, `rules/${state.editData.Id}`)
            .then(function () {
                setState((prev) => ({ ...prev, isLoadingForDelete: false }));
                notify(`Rule #${state.editData.Id} was deleted.`, { type: 'success' });
                onClose();
                if (reloadPage) reloadPage();
            }).catch((error) => {
                notify(`Error: ${error}`, { type: 'error' });
                setState((prev) => ({ ...prev, isLoadingForDelete: false }));
            });
    }

    function getCsvMergeSource() {
        var _source = state.editData.Source;
        if (_source) {
            return _source.reduce((acc, curr) => `${acc}&names=${curr}`, "").substr(1);
        }
        return '';
    }

    function count(csvMergeSource, rule) {
        dataProvider.postData(REMOTE_API, `count?${csvMergeSource}`, rule)
            .then(function (response) {
                if (response.data != null) {
                    let count = response.data;
                    var msg = "It's estimated that the rule being submitted would affect to " + count + " records. Are you to continue?";
                    if (count === -1) {
                        msg = "Currently there is no data in cache, it's unable to estimate the number of how many records are affected by this rule. Are you to continue submitting this rule? ";
                    }
                    setState((prev) => ({ ...prev, isCountConfirm: true, countRule: rule, countMsgConfirm: msg }));
                } else {
                    setState((prev) => ({ ...prev, isLoadingForSave: false }));
                    notify(`Error: ${response.name}, Status: ${response.status}. `, { type: 'error' });
                }
            }).catch((error) => {
                setState((prev) => ({ ...prev, isLoadingForSave: false }));
                notify(`Error: ${error}`, { type: 'error' });
            });
    }

    /**
     * Process to save rule
     */
    function processSave() {
        dataProvider.currentUser().then((user) => {
            if (user.email) {
                var tags = state.editData.Tags?.join(',') || '';
                var data = {
                    ApplyFor: state.editData.Source,
                    RuleData:
                    {
                        Id: state.editData.Id,
                        Name: state.editData.Name,
                        Action: actionQuery.current.getValues('json'),
                        Condition: conditionQuery.current.getValues('json'),
                        CreatedBy: user.email,
                        Tags: ',' + tags + ',',
                        ExpiredDate: formatDate(state.editData.ExpiredDate, 'YYYY-MM-DD'),
                        IsActive: true,
                        Priority: state.editData.Priority || 0
                    }
                };
                dataProvider.postData(REMOTE_API, `rules`, objectToFormData(data))
                    .then(function () {
                        notify(`Rule was saved.`, { type: 'success' });
                        setState((prev) => ({ ...prev, isLoadingForSave: false }));
                        onClose();
                        if (reloadPage) reloadPage();
                    }).catch((error) => {
                        setState((prev) => ({ ...prev, isLoadingForSave: false }));
                        notify(`Error: ${error}`, { type: 'error' });
                    });
            } else notify(`Not found User`, { type: 'error' });
        }).catch((error) => {
            setState((prev) => ({ ...prev, isLoadingForSave: false }));
            notify(`Error: ${error}`, { type: 'error' });
        });
    }

    /**
     * End get entryDates from condition queryBuilder
     */
    function validateRule() {
        var applyFor = getCsvMergeSource();
        if (!applyFor) {
            setState((prev) => ({ ...prev, isConfirmDelete: true }));
            return false;
        }
        return true;
    }

    /**
     * phuc.nt:
     * When user selects some EntryDate fields in QueryBuilder,
     * We should ask user to select 1 of entry date values.
     */
    function validateExpiresAt() {
        var entryDateRules = getEntryDateRules();
        var expiresDate = state.editData.ExpiredDate;
        if (expiresDate && typeof expiresDate === 'string') expiresDate = new Date(expiresDate);
        if (entryDateRules.length > 0 && (isNaN(expiresDate) || !expiresDate)) {
            var entryDates = getEntryDates(entryDateRules);
            setState((prev) => ({ ...prev, isOpenEntryDate: true, entryDates: entryDates.map(m => ({ id: m, name: m })) }));
            return false;
        }
        return true;

        function getEntryDates(entryDateRules) {
            var dates = entryDateRules.map(rule => {
                if (["not_between", "between"].some(x => x == rule.operator)) {
                    return rule.value[1];
                }
                return rule.value;
            });
            return dates;
        }

        function getEntryDateRules() {
            var entryDateRules = [];
            var condition = JSON.parse(conditionQuery.current.getValues('json'));
            scanEntryDateRules(condition, entryDateRules);
            return entryDateRules;
        }

        function scanEntryDateRules(condition, entryDateRules) {
            if (condition.rules) {
                condition.rules.forEach(function (rule) {
                    if (!rule.hasOwnProperty('condition')) {
                        //operator is_duplicated has no value.
                        if (rule.id === "EventDate" && rule.operator != 'is_duplicated') {
                            entryDateRules.push(rule);
                        }
                    }
                    if (rule.hasOwnProperty('rules')) {//group
                        scanEntryDateRules(rule, entryDateRules);
                    }
                });
            }
        }
    }

    function handleChangeApplyFor(values) {
        setState((prev) => ({ ...prev, editData: { ...prev.editData, Source: values } }));
    }

    function handleChangeAddAll(checked) {
        if (checked) {
            let allSource = sources.map(m => m.Name);
            handleChangeApplyFor(allSource);
        }
    }

    return (
        <>
            <MDialog
                name={dlgName}
                open={isOpenRule}
                title={state.editData?.Id > 0 ? `#${state.editData.Id}` : 'New rule'}
                handleClose={onClose}
                maxWidth="xl"
                msgLoading={state.isLoadingForSave ? 'Saving...' : (state.isLoadingForDelete ? 'Deleting...' : 'Loading...')}
                disabled={state.isLoadingForTest || state.isLoadingForSave || state.isLoadingForDelete}
            >
                <MLabel key={'name'} name={'Rule Name'} style={{}} />
                {state.isLoading && <Skeleton />}
                {state.editData &&
                    <TextField
                        value={state.editData?.Name || ''}
                        error={isError('name')}
                        helperText={isError('name') ? emptyError : null}
                        fullWidth
                        required
                        label="Rule"
                        onChange={(e) => setState((prev) => ({ ...prev, editData: { ...prev.editData, Name: e.target.value } }))}
                        placeholder="eg. Ban venue abc..." variant="outlined" />}
                <Stack width='100%' direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Stack>
                        <MLabel key={'applyFor'} name={'Apply For (*)'} />
                    </Stack>
                    <Stack style={{ marginTop: 5 }}>
                        <MCheckbox label={"add all"} sources={state.addall} onChange={handleChangeAddAll} />
                    </Stack>
                </Stack>
                {state.isLoading && <Skeleton />}
                {state.editData &&
                    <MAutoComplete
                        name="applyFors"
                        variant='outlined'
                        error={isError('applyfor')}
                        helperText={isError('applyfor') ? emptyError : null}
                        value={state.editData?.Source}
                        choices={sources.map(m => m.Name)}
                        onChange={(values) => handleChangeApplyFor(values)} />
                }
                {state.applyForMsg && <MLabel key={'applyForMsg'} name={state.applyForMsg} />}
                <Grid container spacing={2} >
                    <Grid item xs={8}>
                        <MLabel key={'tags'} name={'Tags'} />
                        {state.isLoading && <Skeleton />}
                        {state.editData &&
                            <TagsAutoComplete
                                variant='outlined'
                                value={state.editData?.Tags}
                                onChange={(e) => setState((prev) => ({ ...prev, editData: { ...prev.editData, Tags: e } }))} />}
                    </Grid>
                    <Grid item xs={4}>
                        <MLabel key={'priority'} name={'Priority'} />
                        {state.isLoading && <Skeleton />}
                        {state.editData &&
                            <MNumber
                                variant="outlined"
                                value={state.editData?.Priority || 0}
                                onChange={(e) => setState((prev) => ({ ...prev, editData: { ...prev.editData, Priority: e } }))} />}
                    </Grid>
                </Grid>
                <MLabel key={'match'} name={'For those records match ...'} h={'h6'} />
                <QueryBuilderMaterial>
                    {state.isLoading ? <Skeleton /> :
                        <MQueryBuilder
                            key='condition'
                            fields={getConditionFields()}
                            initialData={conditionInitData}
                            ref={conditionQuery}
                            editData={state.formatedCondition}
                            defaultOperator='and'
                            hideNotButton />}
                    <MLabel key={'adjust'} name={'Then adjust'} h={'h6'} />
                    {state.isLoading ? <Skeleton /> :
                        <MQueryBuilder
                            key='action'
                            fields={getActionFields()}
                            initialData={actionInitData}
                            ref={actionQuery}
                            editData={state.formatedAction}
                            defaultOperator='modify'
                            hideGroupButton
                            hideLockButtons
                            hideNotButton
                            showRemoveCheckbox
                            combinators={combinators}
                            combinatorName='method'
                        />}
                </QueryBuilderMaterial>
                <Stack width='100%' direction={{ xs: 'column', sm: 'row' }} style={{ marginTop: 10 }} spacing={2}>
                    <Stack style={{ marginTop: 5 }}>
                        <MLabel key={'Expires'} name={'Expires at (optional)'} />
                    </Stack>
                    <Stack style={{ marginTop: -10 }}>
                        <MDatePicker name='ExpiredDate' value={state.editData?.ExpiredDate} onChange={(value) => setState((prev) => ({ ...prev, editData: { ...prev.editData, ExpiredDate: value } }))} />
                    </Stack>
                </Stack>
                <Stack width='100%' justifyContent="space-between" direction={{ xs: 'column', sm: 'row' }} style={{ marginTop: 10 }}>
                    <Stack width='70%' direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Button startIcon={<RemoveRedEyeOutlined />} variant="contained" onClick={onTest}>Test </Button>
                        <Button startIcon={<Send />} variant="contained" onClick={onSave}>Save</Button>
                    </Stack>
                    <Stack>
                        {state.editData?.Id > 0 && <Button startIcon={<Delete />} variant="contained" color="error" onClick={() => setState((prev) => ({ ...prev, isDelete: true }))}>Delete</Button>}
                    </Stack>
                </Stack>
                <MLabel key={'MatchedRecords'} name={'Matched Records'} />
                <Box sx={{ maxHeight: 400, height: state.rowsTest.length ? 400 : 100, width: '100%' }}>
                    <DataGrid
                        rows={state.rowsTest}
                        getRowId={(r) => r.TicketID}
                        columns={[
                            { field: 'Event', headerName: 'Event', flex: 1 },
                            { field: 'Venue', headerName: 'Venue', flex: 1 },
                            { field: 'EventDate', headerName: 'Event Date', flex: 0.4, valueFormatter: ({ value }) => formatDate(value) },
                            { field: 'EventTime', headerName: 'Time', flex: 0.4, valueFormatter: ({ value }) => formatDateTime(value, 'hh:mm A') },
                            { field: 'Quantity', headerName: 'Qty', flex: 0.3 },
                            { field: 'Section', headerName: 'Section', flex: 0.3 },
                            { field: 'Row', headerName: 'Row', flex: 0.3 },
                            { field: 'Cost', headerName: 'Cost', flex: 0.3 },
                            { field: 'FloorPrice', headerName: 'Floor Price', flex: 0.4 },
                            { field: 'PrimaryCost', headerName: 'Primary Cost', flex: 0.4 },
                            { field: 'InHandDate', headerName: 'IHD', flex: 0.4, valueFormatter: ({ value }) => formatDate(value) },
                            { field: 'EDelivery', headerName: 'EDelivery', flex: 0.3 },
                            { field: 'TicketID', headerName: 'TicketID', flex: 0.4 }
                        ]}
                    />
                </Box>
            </MDialog>
            <Confirm isOpen={state.isConfirmDelete}
                title='Delete rule'
                content='Apply For is empty, do you want to delete this rule?'
                onClose={() => setState((prev) => ({ ...prev, isConfirmDelete: false }))}
                onConfirm={() => {
                    setState((prev) => ({ ...prev, isConfirmDelete: false }));
                    deleteRule();
                }} />
            <Confirm isOpen={state.isDelete}
                title='Delete rule'
                content='You are about to delete the rule. Are you sure?'
                onClose={() => setState((prev) => ({ ...prev, isDelete: false }))}
                onConfirm={() => {
                    setState((prev) => ({ ...prev, isDelete: false }));
                    deleteRule();
                }} />
            <Confirm isOpen={state.isCountConfirm}
                title='Confirm'
                content={state.countMsgConfirm}
                onClose={() => setState((prev) => ({ ...prev, isCountConfirm: false, isLoadingForSave: false }))}
                onConfirm={() => {
                    setState((prev) => ({ ...prev, isCountConfirm: false }));
                    processSave();
                }} />
            {state.isOpenEntryDate && (
                <EntryDatePopup key={'entryDatePopup'}
                    open={state.isOpenEntryDate}
                    dates={state.entryDates}
                    onSelect={(value) => {
                        setState((prev) => ({ ...prev, editData: { ...prev.editData, ExpiredDate: getExtractDate(value) }, isOpenEntryDate: false }));
                    }}
                    onClose={() => setState((prev) => ({ ...prev, isOpenEntryDate: false }))} />
            )}
        </>
    );
};

export default RuleEditPopup;