import React from 'react';
import { RecordContextProvider, DatagridBody } from 'react-admin';
import { TableRow, TableCell } from '@mui/material';

const MyDatagridRow = ({ record, id, onToggleItem, children, ...props }) => {
    const colsColor = ['sold_1_hour', 'sold_2_hours', 'sold_4_hours', 'sold_8_hours', 'sold_16_hours']

    const isColor = (source) => {
        if (colsColor.includes(source) && record[source] > 0)
            return '#5EA47D';
        else
            return '';
    }

    const isCenter = (source) => {
        if (typeof(record[source]) === 'number')
            return 'center';
        else
            return '';
    }

    return (
        <RecordContextProvider value={record}>
            <TableRow hover={true} >
                {React.Children.map(children, field => (
                    <TableCell key={`${id}-${field.props.source}`}
                        style={{
                            backgroundColor: isColor(field.props.source),
                            textAlign: isCenter(field.props.source)
                        }}
                    >
                        {field}
                    </TableCell>
                ))}
            </TableRow>
        </RecordContextProvider>
    )
};
const CustomCellColor = (props) => {
    return (
        <DatagridBody {...props} row={<MyDatagridRow grouping={props.grouping} />} key={new Date()} />
    )
}
export default React.memo(CustomCellColor);