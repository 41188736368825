/**
 * This is an example validation function you can pass to QueryBuilder in the
 * `validator` prop. It assumes that you want to validate groups, and has a no-op
 * for validating rules which you should replace with your own implementation.
 */
const defaultCombinators = [
    { name: 'AND', label: 'AND' },
    { name: 'OR', label: 'OR' },
];
export const defaultValidator = query => {
    const result = {}

    const validateRule = (rule) => {
        if (rule.validator && !rule.validator(rule)) {
            result[rule.id] = { valid: false, reasons: ['false'] };
        }
        else if (!(!!rule.value || rule.value.length > 0)) {
            result[rule.id] = { valid: false, reasons: ['empty'] };
        }
    };

    const validateGroup = (rg) => {
        const reasons = [];
        if (rg.rules.length === 0) {
            reasons.push('empty group');
        }

        if (rg.id) {
            if (reasons.length) {
                result[rg.id] = { valid: false, reasons };
            } else {
                result[rg.id] = true;
            }
        }
        rg.rules.forEach(r => {
            if (typeof r === 'string') {
                // Validation for this case was done earlier
            } else if ('rules' in r) {
                validateGroup(r);
            } else {
                validateRule(r);
            }
        });
    };
    validateGroup(query);
    return result;
    // You can return the result object itself like above, or if you just
    // want the entire query to be marked invalid if _any_ rules/groups are
    // invalid, return a boolean like this:
    //   return Object.values(result).map(rv => (typeof rv !== 'boolean')).includes(true);
    // That will return `true` if no errors were found.
};