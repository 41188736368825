import React from 'react';
import { NumberField, NumberFieldProps, useRecordContext } from 'react-admin';

/**
 * ConditionalNumberField component that extends the functionality of the NumberField.
 * It hides the value when it is zero and displays it otherwise.
 *
 * @param {NumberFieldProps} props - The properties passed to the NumberField.
 * @returns {JSX.Element | null} - Returns a NumberField or null if the value is zero.
 */
const ConditionalNumberField: React.FC<NumberFieldProps> = (props) => {
    // Get the current record context using the useRecordContext hook
    const record = useRecordContext(props);

    // If there's no record, don't render anything
    if (!record) return null;

    // Get the value from the record using the source property
    const value = record[props.source];

    // If the value is 0, return null to hide the field
    if (!value) {
        return null;
    }

    // Otherwise, render the default NumberField with all the passed props
    return (<NumberField {...props} />);
};

export default ConditionalNumberField;
