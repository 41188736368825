// in src/MyLoginPage.js
import * as React from "react";
import { useEffect, useState, } from 'react';
import { useNavigate, } from 'react-router-dom'
import { useAuthProvider, useNotify } from 'react-admin';
const PUBLIC_URL = (new URL("/login/", document.location)).href;// 'http://localhost:3000/login/';
let lastOTP = '';
const MyLoginPage = () => {
    const authProvider = useAuthProvider();
    const notify = useNotify();
    const navigate = useNavigate();
    const [state = { notLoaded: true }, setState] = useState(null);
    const { searchParams } = new URL(window.location.href);
    const otp = searchParams.get('otp');
    useEffect(() => {
        if (!otp || lastOTP === otp)
            return;
        lastOTP = otp;
        authProvider.login({ otp })
            .then(({ redirectTo }) => {
                navigate(redirectTo || "/", { replace: true });
            })
            .catch((e) => {
                notify(e.message);
                window.location.href = 'https://hello2.songtek.net/auth/?redirectToPath=/Goto/' + btoa(PUBLIC_URL);
            });
    }, []);
    if (!otp) {
        window.location.href = 'https://hello2.songtek.net/auth/?redirectToPath=/Goto/' + btoa(PUBLIC_URL);
        return <p>Redirecting ... </p>;
    }
    return (
        <p>Authenticating ... </p>
    );



};

export default MyLoginPage;