import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

//https://marmelab.com/react-admin/List.html#exporter
//https://www.npmjs.com/package/jsonexport#typehandlers
export default _exporter = (records, fields, filename = "Export") => {
    const data = records.map(obj => {
        return Object
            .fromEntries(
                Object
                    .entries(obj)
                    .filter(([key]) => fields.some(f => f.toLowerCase() == key.toLowerCase()))
            );
    });

    jsonExport(data, {
        rename: fields
    }, (err, csv) => {
        downloadCSV(csv, filename); // download as 'csv` file
    });
};