import { sqlOperators, OPERATORS, sqlStatements, escapeString, fmt } from './querybuilder.config'
import { changeType } from '../../utils/common';


const getOperatorByType = (type) => {
    if (type == '-1') {
        return null;
    }
    const operators = Object.values(OPERATORS);
    for (var i = 0, l = operators.length; i < l; i++) {
        if (operators[i].type == type) {
            return operators[i];
        }
    }
}

function getStmtConfig(stmt) {
    var config = stmt.match(/(question_mark|numbered|named)(?:\((.)\))?/);
    if (!config) config = [null, 'question_mark', undefined];
    return config;
}

const getSQL = (data, stmt = false, nl = false) => {
    if (data === undefined) return null;
    nl = (nl === true) ? '\n' : ' ';

    if (stmt === true) stmt = 'question_mark';
    if (typeof stmt == 'string') {
        var config = getStmtConfig(stmt);
        stmt = sqlStatements[config[1]](config[2]);
    }

    var sql = (function parse(data) {
        if (!data.condition) {
            return null;
        }
        if (['AND', 'OR'].indexOf(data.condition.toUpperCase()) === -1) {
            console.error('UndefinedSQLCondition', 'Unable to build SQL query with condition "{0}"', data.condition);
            return;
        }
        if (!data.rules) {
            return '';
        }
        var parts = [];
        data.rules.forEach(function (rule) {
            if (rule.rules && rule.rules.length > 0) {
                parts.push('(' + ' ' + parse(rule) + ' ' + ')' + ' ');
            }
            else {
                var sql = sqlOperators[rule.operator];
                var ope = getOperatorByType(rule.operator);
                var value = '';

                if (sql === undefined) {
                    console.error('UndefinedSQLOperator', 'Unknown SQL operation for operator "{0}"', rule.operator);
                    return;
                }

                if (ope.nb_inputs !== 0) {
                    let newRules = rule;
                    if (!(newRules.value instanceof Array)) {
                        newRules.value = [rule.value];
                    }

                    newRules.value.forEach(function (v, i) {
                        if (i > 0) {
                            value += sql.sep;
                        }

                        if (newRules.type == 'integer' || newRules.type == 'double' || newRules.type == 'boolean') {
                            v = changeType(v, rule.type, true);
                        } else if (!stmt) {
                            v = escapeString(v);
                        }

                        if (sql.mod) {
                            v = fmt(sql.mod, v);
                        }

                        if (stmt) {
                            value += stmt.add(newRules, v);
                        }
                        else {
                            if (typeof v == 'string') {
                                v = '\'' + v + '\'';
                            }

                            value += v;
                        }
                    });
                }
                parts.push(rule.field + ' ' + sql.op.replace(/\?/, value));
            }
        });
        return parts.join(' ' + data.condition + ' ');
    }(data));
    return sql;
}

export default getSQL;