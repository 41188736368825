import React, { useState } from 'react';
import { RecordContextProvider, DatagridBody, useRecordContext } from 'react-admin';
import { TableRow, TableCell, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PropTypes from 'prop-types';
import { formatDateTime, dayjs } from '../ex_dayjs';
const MyDatagridRow = ({ id, onToggleItem, children, ...props }) => {
    const { isGroup, isExpand, groupColumn, formattedColumn, anchorColumn } = props.grouping;

    const indentCellCount = children.findIndex(_ => _.props.source == anchorColumn);    
    const indentCells = indentCellCount >= 0 ? children.filter((obj, index) => index < indentCellCount) : [];
    const colSpan = indentCellCount >= 0 ? children.length - indentCellCount : children.length;
    let anchorColumn2 = indentCellCount < 0 ? children[0].props.source : anchorColumn;

    const [expanded, setExpanded] = useState(isExpand);
    const handleToggleSelection = () => {
        if (!expanded)
            document.querySelectorAll(`.${'child' + setChildClass()}`).forEach(e => e.style.display = '');
        else
            document.querySelectorAll(`.${'child' + setChildClass()}`).forEach(e => e.style.display = 'none');
        setExpanded(!expanded);
    }

    const record = useRecordContext();
    const setChildClass = () => {
        let rs = '';
        groupColumn.forEach(col => rs += record[col])
        return rs.replace(/\s/g, '').replace(/[^\w\s]/gi, "");
    }
    const isValidDate = (dateString) => {
        return dayjs(dateString, "YYYY-MM-DDTHH:mm:ss", true).isValid();// Strick check for our datetime format
    }
    const formatDateCell = (value) => {
        if(isValidDate(value))
            return formatDateTime(value, 'ddd MMM DD YYYY hh:mm A')
        else 
            return value;
    }
    const formatCell = (value, index) => {
        let seperateStr = ' - ';
        if (index > 1)
            return seperateStr + formatDateCell(value);
        else
            return formatDateCell(value);
    }
    return (
        <RecordContextProvider value={record}>
            <TableRow hover={true}
                style={isGroup ? (!record.parent ? (!expanded ? { display: 'none' } : {}) : {}) : {}} //hide child
                className={!record.parent ? ('child' + setChildClass()) : ''}
            >
                {isGroup && <TableCell padding="none" >
                    {record.parent && <IconButton aria-label="expand row" size="small" onClick={handleToggleSelection}>
                        {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                    </IconButton>}
                </TableCell>}
                {/* fill data for child-row */}
                {!record.parent && React.Children.map(children, field => (
                    <TableCell sx={{textAlign: field.props.textAlign || ''}}>
                        {formattedColumn.some(col => col == field.props.source) ? (isGroup ? record[field.props.source] : field) : field}
                    </TableCell>
                ))}
                {/* format parent-row */}
                {record.parent && isGroup && React.Children.map(children, field => {
                    if (field.props.source == anchorColumn2)
                        return (
                            <>
                                {indentCells.map(obj => (<TableCell/>))}
                                <TableCell colSpan={colSpan} style={{ fontWeight: 'bold' }}>
                                    {groupColumn.map((col, index) => (
                                        <div style={{ display: 'inline-block', marginLeft: index == 1 ? '12px' : '', whiteSpace: 'pre' }}>
                                            {formattedColumn.some(fcol => fcol == col) ? children.find(f => f.props.source === col) : formatCell(record[col], index)}
                                        </div>
                                    ))}
                                </TableCell>
                            </>
                        )
                })}
            </TableRow>
        </RecordContextProvider>
    )
};
const RaBodyGrouping = (props) => {
    return (
        <DatagridBody {...props} row={<MyDatagridRow grouping={props.grouping} />} key={new Date()} />
    )
}
export default React.memo(RaBodyGrouping);

RaBodyGrouping.propTypes = {
    /**Props for body grouping */
    grouping: {
        /** True: group rows | False: un-group rows */
        isGroup: PropTypes.bool,
        /** True: expand all columns (grouped) | False: collapse all columns (grouped) */
        isExpand: PropTypes.bool,
        /**Columns for grouping*/
        groupColumn: PropTypes.array,
        /**Custom columns component */
        formattedColumn: PropTypes.array,
        /**Col index in DataGrid for display group row. If null, get the first column*/
        anchorColumn: PropTypes.string
    }
};
RaBodyGrouping.defaultProps = {
    grouping: {
        isGroup: true,
        isExpand: true,
        groupColumn: [],
        formattedColumn: [],
        /**If not declare, get the first col */
        anchorColumn: '' 
    }
}