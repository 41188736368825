import React from "react";
import { Button } from "@mui/material";
import { useNotify, Confirm } from 'react-admin';
import { SaveAs } from "@mui/icons-material";
import { dataProvider } from "../../../DataProvider";
import { getNotification } from "../common";

const SaveReviewButton = (props) => {
  const { monthYear, workTime, setIsLoading, setHideSavePreview } = props;
  const notify = useNotify();
  const [open, setOpen] = React.useState(false);
  const handleDialogClose = () => setOpen(false);

  const handleSave = async (param) => {
    setOpen(true);
  };
  
  const handleConfirm = async (params) => {
    var pushData = workTime.filter(x => monthYear.some(my => x.MonthYear == my));
    setOpen(false);
    setIsLoading(true);
    await dataProvider.postData('WeeklyWorkTime', 'SaveAllMonth/?isImport=true', pushData)
      .then((response) => {
        if (response.data) {
          //set essential data for inserted record
          let arrData = JSON.parse(response.data);
          for (let index = 0; index < arrData.length; index++) {
            let newRecord = workTime.find(g => g.UserEmail == arrData[index].UserEmail && g.MonthYear == arrData[index].MonthYear);
            if (newRecord) {
              newRecord.id = arrData[index].id;
              newRecord.Created = arrData[index].Created;
              newRecord.CreatedBy = arrData[index].CreatedBy;
              newRecord.Status = arrData[index].Status;
            }
          }
          workTime.forEach(r => {r['ChangedList'] = [];}); //reset

          notify(`SAVED !!!`, { type: 'success', autoHideDuration: 2000 });
          setHideSavePreview(true);
        } else {
          let mess = getNotification(response);
          notify(mess, { type: 'error', autoHideDuration: 2000 });
        }
      }).catch(console.error).finally(_ => setIsLoading(false));

  };

  return (
    <>
      <Button variant="contained" size="small" onClick={handleSave} startIcon={<SaveAs />}>SAVE PREVIEW</Button>
      <Confirm isOpen={open} title={`Save MONTH: ${monthYear}`} content='Are you sure with this apply?' onConfirm={handleConfirm} onClose={handleDialogClose} />
    </>

  );
};

export default SaveReviewButton;