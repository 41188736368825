import React, { useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import {
    TextField, Datagrid, FunctionField, useDataProvider, useListController, NumberField
} from 'react-admin';
import RenderControls from "../../components/ra-list/RenderControls";
import { dayjs, formatLocalTime } from '../../components/ex_dayjs';
import { TicketNetWorkSearch } from "./component/ticketnetwork-search";
import CommentField from "./component/comment-field";
import { StubHubSearch } from "./component/stubhub-search";
import SportModeButton from "./component/sportmode-button";
import RaList from "../../components/ra-list/RaList";
import RaBodyGrouping from "../../components/ra-list/RaBodyGrouping";

const OrderDetail = () => {
    const location = useLocation();
    const refFilter = useRef();
    const dataProvider = useDataProvider();
    const [sortBy] = useState(['order_date desc'])
    const { data, total, perPage, filterValues } = useListController();
    const [message, setMessage] = useState('');
    const [key, setKey] = useState(new Date());
    const [info, setInfo] = useState({
        title: 'Order Detail Analysis',
        sportmode: false,
        firstLoad: false
    });
    const [defaultValues, setDefaultValues] = useState({
        haveCompetitor: null,
        event_date: [dayjs().add(5, 'day')],
        wholesale_price: [null, 1000],
        isgrouping_eq: info.sportmode
    });

    React.useEffect(() => {
        if (location.pathname.includes('SingleEventAnalysis/Sport')) {
            setInfo((prev) => ({ ...prev, sportmode: true, title: 'Sports Analysis Page' }));
        }
    }, [location.pathname]);

    var queryString = location.search ?? location.hash;
    React.useEffect(() => {
        if (queryString.includes('?event_id=')) {
            var eventId = new URLSearchParams(queryString).get("event_id");
            if (eventId) {
                setDefaultValues((prev) => ({ ...prev, event_id_eq: parseInt(eventId) }));
            }
        }
    }, []);

    React.useEffect(() => {
        setInfo({ ...info, firstLoad: true });
    }, [])

    React.useEffect(() => {
        if (info.firstLoad) {
            setTimeout(() => {
                refFilter.current.click();
                setKey(new Date());
            }, 100);
        }

    }, [info.sportmode]);

    React.useEffect(() => {
        setMessage(() => {
            let percentage = 0;
            let allrecord;
            if (total > 0) {
                let totalRest = Math.ceil(total / perPage);
                if (totalRest > 2) {
                    allrecord = totalRest + total;
                    percentage = ((total / allrecord) * 100).toFixed(2);
                }
                if (filterValues.wholesale_price_ge > 0 && filterValues.wholesale_price_le == null) {
                    return `${total} of ${allrecord} sales (${percentage} %) were priced above $${filterValues.wholesale_price_ge}`;
                }
                else if (filterValues.wholesale_price_ge == null && filterValues.wholesale_price_le > 0) {
                    return `${total} of ${allrecord} sales (${percentage} %) were priced below $${filterValues.wholesale_price_le}`;
                }
                else if (filterValues.wholesale_price_ge > 0 && filterValues.wholesale_price_le > 0) {
                    return `${total} of ${allrecord} sales (${percentage} %) were priced between $${filterValues.wholesale_price_ge} and $${filterValues.wholesale_price_le}`;
                } else return ''
            }

        })
    }, [data]);

    const handleShowPassRecordChange = (ev) => {
        let dataValue = ev.value ? null : dayjs().add(5, 'day');
        setDefaultValues({ ...defaultValues, event_date: [dataValue] })
    }
    const controls = [
        { component: 'daterange', name: 'event_date', label: 'Event Date' },
        { component: 'daterange', name: 'order_date', label: 'Order Date' },
        { component: 'numberrange', name: 'wholesale_price', label: 'Whole Sale Price', xs: 2 },
        { component: 'autocomplete', name: 'section_in', label: 'Section', choices: [] },
        { component: 'textrange', name: 'row', label: 'Row' },
        { component: 'number', name: 'event_id_eq', label: 'Event ID' },
        { component: 'textfilter', name: 'event_name', label: 'Event Name', xs: 2 },
        { component: 'textfilter', name: 'venue_name', label: 'Venue Name', xs: 2 },
        { component: 'text', name: 'owner_company_name_contains', label: 'Owner Company Name', xs: 2 },

        { component: 'checkbox', name: 'show_pass_record_eq', label: 'Show pass records', xs: 1, onChange: (handleShowPassRecordChange) },
        { component: 'checkbox', name: 'category_ticket_only_eq', label: 'Category Tickets Only', xs: 1 },
        { component: 'checkbox', name: 'exclude_supplemental_eq', label: 'Exclude supplemental records', xs: 1.5 },
        //for grouping
        { component: 'checkbox', name: 'isgrouping_eq', label: 'Is Grouped', isHide: true }
    ]
    if (!info.sportmode) {
        controls.push({ component: 'checkbox', name: 'exclude_zone_eq', label: 'Exclude zone' });
        controls.push({ component: 'checkbox', name: 'exclude_sport_eq', label: 'Exclude sport' });
    }
    else {
        controls.splice(13, 1);
        controls.splice(12, 1);
    }

    return (
        <RaList
            perPage={25}
            title={info.title}
            sortby={sortBy}
            filters={
                <RenderControls
                    filterInline={false}
                    refFilter={refFilter}
                    actions={
                        <>
                            <TicketNetWorkSearch source={OrderDetailPage.name} />
                            <SportModeButton setInfo={setInfo} info={info} setDefaultValues={setDefaultValues} />
                        </>
                    }
                    useHashUrl
                    controls={controls}
                    defaultValues={defaultValues}
                    exportFile={{
                        filename: `OrderDetail-${formatLocalTime(new Date(), 'YY-MM-DD')}`,
                        columns: ['order_date', 'event_id', 'event_name', 'venue_name', 'event_date', 'ticket_qty', 'section', 'row', 'wholesale_price', 'cost', 'revenue',
                            'owner_customer_id', 'owner_company_name', 'website_name', 'ticket_type'],
                        limit: 1000000
                    }}
                />
            }
        >
            {total > 0 && <p>{message}</p>}
            <Datagrid bulkActionButtons={false}
                expand={info.sportmode ? true : false}
                key={key}
                body={<RaBodyGrouping
                    grouping={{
                        isGroup: info.sportmode,
                        isExpand: true,
                        groupColumn: ['event_id', 'event_name', 'venue_name', 'event_date'],
                        formattedColumn: ['event_id'],
                    }}
                />}
            >
                <FunctionField label='Order Date' source='order_date' render={record => (
                    <><b>{dayjs(record.order_date).format('MM/DD/YYYY')}</b><br />{dayjs(record.order_date).format('hh:mm A')}</>)}
                />
                <CommentField source="event_id" label='Event ID' dataProvider={dataProvider} headerClassName={'event_id'} />
                <TextField source="event_name" label='Event Name' />
                <TextField source="venue_name" label='Venue Name' />
                <FunctionField label='Event Date' source='event_date' render={record => (
                    <><b>{dayjs(record.event_date).format('MM/DD/YYYY')}</b><br />{dayjs(record.event_date).format('hh:mm A')}</>)}
                />
                <FunctionField source="ticket_qty" render={record => record.qty_desc == "" ? record.ticket_qty : record.qty_desc} />
                <TextField source="section" />
                <TextField source="row" />
                <NumberField source="wholesale_price" />
                <NumberField source="cost" />
                <NumberField source="revenue" />
                <NumberField source="owner_customer_id" label='Company ID' />
                <TextField source="owner_company_name" />
                <TextField source="website_name" />
                <FunctionField source="ticket_type" render={record => record.website_name == 'MERCURY' ? record.ticket_type + '(M)' : (
                    record.website_name == 'Supplemental' ? record.ticket_type + '(S)' : record.ticket_type
                )} headerClassName={'ticket_type'} />

                {/* order_id:  Hide due to ticketstatistic is down */}
                <StubHubSearch source="id" label='TS' />
            </Datagrid>
        </RaList >
    );
}

const OrderDetailPage = {
    name: 'SingleEventAnalysis',
    list: OrderDetail
}
export default OrderDetailPage;