import * as React from 'react';
import { TextField, Tooltip } from '@mui/material';

const MTextField = ({ onChange, defaultValue, tooltip, ...props }) => {
    const [textValue, setTextValue] = React.useState();
    const [focused, setFocused] = React.useState(false);

    React.useEffect(() => {
        setTextValue(defaultValue);
    }, [defaultValue])

    const handleChange = (e) => {
        const { value } = e.target;
        setTextValue(value);
        onChange(value);
    }
    const handleFocus = () => {
        setFocused(true);
    }

    const handleBlur = () => {
        setFocused(false);
    }

    return (
        <Tooltip title={tooltip ?? ''}>
            <TextField {...props}
                fullWidth={props.fullWidth !== undefined ? props.fullWidth : true}
                value={textValue}
                onChange={(e) => handleChange(e)}
                inputProps={{
                    type: 'text',
                    autoComplete: 'on',
                    onFocus: handleFocus,
                    onBlur: handleBlur
                }}
                InputLabelProps={{
                    shrink: focused || textValue !== undefined
                }} />
        </Tooltip>
    )
}


export default React.memo(MTextField);