import * as React from 'react';
import { FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';


const defaultProps = {
    isDisplayRow: true
}

const MRadio = ({
    label, choices, defaultValue, value, onChange,
    isDisplayRow = defaultProps.isDisplayRow,
    ...props }) => {
    const [mValue, setMValue] = React.useState('');
    const [mDefaultValue, setMDefaultValue] = React.useState('');
    React.useEffect(() => {
        if (defaultValue !== undefined) {
            setMDefaultValue(defaultValue);
            setMValue(defaultValue);
        }
    }, [defaultValue]);

    React.useEffect(() => {
        if (value !== undefined) setMValue(value);
    }, [value]);

    const handleChange = (e) => {
        let newValue = e?.target?.value;
        setMValue(newValue);
        onChange(newValue);
    }

    return (
        <FormControl sx={{ minWidth: '100%' }} size="small">
            <FormLabel id={`${label}-radio-buttons-group-label`}>{label}</FormLabel >
            <RadioGroup
                {...props}
                aria-labelledby={`${label}-radio-buttons-group-label`}
                defaultValue={mDefaultValue}
                value={mValue}
                name={`${label}-radio-buttons-group`}
                row={isDisplayRow}
                onChange={handleChange}
            >
                {choices.map((m, index) => {
                    return <FormControlLabel key={index} id={m.id} value={m.id} control={<Radio />} label={m.name} />
                })}
            </RadioGroup>
        </FormControl>
    );
}

MRadio.prototype = {
    /**Array choice: [{id: 'id', name: 'name'}, {...}] */
    choices: PropTypes.array,
    /**Direction display item */
    isDisplayRow: PropTypes.bool,
    defaultValue: PropTypes.any,
    value: PropTypes.any,
    onChange: PropTypes.func
}

export default React.memo(MRadio);