import React, { useEffect, useMemo, useRef } from "react";
import { Link, Tooltip } from '@mui/material';
import { Title, useDataProvider } from "react-admin";
import { getDefaultUtcOffset, loadSourceType } from "../../utils/common";
import { dayjs, formatDate, formatDateTime, formatLocalTime } from "../../components/ex_dayjs";
import { ExchangeMarket, STATUS_ENUM, TeamOptions, getStatuses } from "../../utils/enum";
import RenderControls from "../../components/ra-list/RenderControls";
import AgGrid from "../../components/ag-grid/ag-grid";
import AssignmentUser from "./components/AssignmentUser";
import Assign from "./components/Assign";

const _resource = 'OrderAssignments';
const allowMarketId = [1, 2, 13];
const allowStatuses = [STATUS_ENUM.Unprocessed, STATUS_ENUM.PendingShipment];
const allowVividStatus = getStatuses([...allowStatuses, STATUS_ENUM.Processing, STATUS_ENUM.Completed]);
const allowTNStatus = getStatuses([...allowStatuses, STATUS_ENUM.TNProcessing, STATUS_ENUM.Completed]);

const OrderAssignments = () => {
    const dataProvider = useDataProvider();
    const refInput = useRef(null);
    const refbtnFilter = useRef(null);
    const [marketType, setMarketType] = React.useState([]);
    const [listUser, setListUser] = React.useState([]);
    const [orderStatuses, setOrderStatuses] = React.useState(allowVividStatus);
    const [defaultValue] = React.useState({
        IsAssigned: null,
        AssignedDate_eq: new Date(),
        OrderStatus: STATUS_ENUM.Unprocessed,
        utc_offset_eq: getDefaultUtcOffset()
    });

    function handleSelection(e) {
        const { value } = e;
        if (value == 2) setOrderStatuses(allowTNStatus);
        else setOrderStatuses(allowVividStatus);
    }
    const controls = [
        { component: 'select', name: 'Market', label: 'Exchange', choices: ExchangeMarket.filter(x => allowMarketId.find(y => y == x.id)), onChange: (e) => handleSelection(e), xs: 1.5 },
        { component: 'select', name: 'MarketType_eq', label: 'Type', choices: marketType },
        { component: 'number', name: 'OrderNumber', label: 'Order #', xs: 1 },
        { component: 'select', name: 'OrderStatus', label: 'Order Status', choices: orderStatuses, xs: 1.5 },
        { component: 'select', name: 'TeamId', choices: TeamOptions, label: 'Team' },
        { component: 'autocomplete', name: 'AssignedTo_eq', label: 'Assigned to', choices: listUser.map(x => x.name), isMulti: false },
        { component: 'date', name: 'AssignedDate_eq', label: 'Assigned Date', isUTC: true, xs: 1.2 },
        { component: 'checkbox', name: 'IsAssigned', label: 'Assigned' },
        { component: 'checkbox', name: 'IsInhandDate', label: 'IHD', xs: 0.5 },
        { component: 'text', name: 'utc_offset', isHide: true }
    ];

    const ragRenderer = (params) => {
        switch (params.column?.colId) {
            case 'market':
                let marketName = ExchangeMarket.find(x => x.id == params.value)?.name;
                return (<span>{marketName}</span>);
            case 'orderDate':
            case 'eventDate':
                return <span>{formatDateTime(params.value)}</span>;
            case 'inhandDate':
                let color = '';
                let daysToInhand = params.data.daysToInhand;
                if (daysToInhand != null) {
                    if (daysToInhand < 6) color = 'yellow';
                    if (daysToInhand < 3) color = 'red';
                }
                return (
                    <Tooltip arrow title={daysToInhand != null ? `Processed at ${formatLocalTime(params.data.utcPostedOn)}` : null}>
                        <span style={{ color: color }}>{formatDate(params.value)}</span>
                    </Tooltip>
                );
            case 'assignedDate': // for utc
                return <span>{params.value ? formatLocalTime(params.value) : null}</span>;
            case 'eventName':
                return (
                    <Link href={params.data.eventUrl} underline="hover" target="_blank">
                        <span>{params.value}</span>
                    </Link>
                );
            case 'orderStatus':
                let vividStatus = allowVividStatus.find(x => x.id == params.value)?.name;
                let tnStatus = allowTNStatus.find(x => x.id == params.value)?.name;
                return (
                    <span>{vividStatus || tnStatus}</span>
                );
            default:
                return <span>{params.value}</span>;
        }
    };

    var colDefs = [
        { field: 'market', headerName: 'Market', cellRenderer: ragRenderer, maxWidth: 120 },
        { field: 'orderNumber', headerName: 'Order #', maxWidth: 100 },
        { field: 'orderStatus', headerName: 'Order Status', cellRenderer: ragRenderer, maxWidth: 150 },
        { field: 'orderDate', headerName: 'Order Date', cellRenderer: ragRenderer, maxWidth: 150 },
        { field: 'marketType', headerName: 'Type', maxWidth: 100 },
        { field: 'eventName', headerName: 'Event', cellRenderer: ragRenderer, maxWidth: 300 },
        { field: 'venue', headerName: 'Venue', cellRenderer: ragRenderer, maxWidth: 300 },
        { field: 'eventDate', headerName: 'Event Date', cellRenderer: ragRenderer, maxWidth: 150 },
        { field: 'inhandDate', headerName: 'IHD', cellRenderer: ragRenderer, maxWidth: 100 },
        { field: 'assignedDate', headerName: 'Assigned Date', cellRenderer: ragRenderer, maxWidth: 150 },
        {
            field: 'assignedTo', headerName: 'Processor', editable: true, xs: 1,
            cellEditor: (props) => <AssignmentUser {...props} listUser={listUser} />,
            cellRenderer: (props) => <Assign {...props} listUser={listUser} />,
            cellEditorPopup: true,
            colId: "assignedTo",
            cellEditorPopupPosition: 'over'
        }
    ];

    const defaultColDef = useMemo(() => {
        return {
            cellStyle: { wordBreak: 'normal', fontSize: '14px' },
            singleClickEdit: true,
            sortable: true,
            wrapText: true,
            filter: false,
            autoHeight: true,
            flex: 1,
            suppressRowHoverHighlight: true,
            suppressHorizontalScroll: true,
            suppressMovable: true,//block move columns
            resizable: true,
            autoHeaderHeight: true,
        };
    }, []);

    useEffect(() => {
        loadSourceType(dataProvider, (response) => {
            setMarketType(response);
        });
        dataProvider
            .fetchData(_resource, `GetTeam`)
            .then((response) => {
                if (response.data) {
                    let newData = response.data.map(x => ({ id: x.id, name: x.email, value: x.aliasName }));
                    setListUser(newData);
                }
            })
            .catch((error) => console.log(error));
    }, []);

    const handleSearch = (filterList) => {
        refInput.current.customFilterSearch(filterList);
    };

    const onGridReady = ((params) => {
        var defaultSortModel = [
            { colId: 'inhandDate', sort: 'asc', sortIndex: 0 }
        ];
        params.columnApi.applyColumnState({ state: defaultSortModel });
    });

    return (
        <>
            <Title title={'Order Assignments'}></Title>
            <RenderControls
                controls={controls}
                onSearch={handleSearch}
                defaultValues={defaultValue}
                refFilter={refbtnFilter}
                agSource={_resource}
                agSort={{ field: 'inhandDate', order: 'asc' }}
                exportFile={{
                    filename: `${_resource}-${formatDateTime(new Date(), 'YY-MM-DD')}`,
                    columns: ['Market', 'OrderNumber', 'OrderStatus', 'OrderDate', 'MarketType', 'EventName', 'Venue', 'EventDate', 'InhandDate', 'AssignedDate', 'AssignedTo']
                }}
            />
            <AgGrid
                ref={refInput}
                list={_resource}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}
                rowHeight={45}
                rowSelection='single'
                onGridReady={onGridReady}
            ></AgGrid>
        </>
    );
};

const OrderAssignmentsPage = {
    name: _resource,
    list: OrderAssignments
};
export default OrderAssignmentsPage;