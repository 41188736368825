import React from "react";
import { Button } from "@mui/material";
import { dataProvider } from "../../../DataProvider";
import { useNotify, Confirm } from 'react-admin';
import { SaveAs } from "@mui/icons-material";
import { dayjs, formatLocalTime } from "../../../components/ex_dayjs";
import RaExporter from "../../../components/ra-list/RaExporter";
import { getNotification } from "../common";

const SaveAllButton = (props) => {
  const { api, columnGroup, showSaveAll, monthYear, setIsLoading, lengthOfTime } = props;
  const notify = useNotify();
  const [open, setOpen] = React.useState(false);
  const handleDialogClose = () => setOpen(false);

  const handleSave = async (param) => {
    setOpen(true);
  };
  //SAVE ALL
  const handleConfirm = async (params) => {
    var grid_data = api.getModel().rowsToDisplay?.reduce((flatArr, obj) => {
      flatArr.push(obj.data);
      return flatArr;
    }, []);

    var pushData = grid_data.filter(x => x.id == 0 || (x.id > 0 && x.ChangedList.length > 0));
    if (pushData.length == 0) {
      notify(`Nothing changes!!`, { type: 'warning', autoHideDuration: 2000 });
      setOpen(false);
      return;
    }

    setIsLoading(true);
    await dataProvider.postData('WeeklyWorkTime', 'SaveAllMonth', pushData)
      .then((response) => {
        if (response.data) {
          //set essential data for inserted record
          let arrData = JSON.parse(response.data);
          for (let index = 0; index < arrData.length; index++) {
            let newRecord = grid_data.find(g => g.UserEmail == arrData[index].UserEmail);
            if (newRecord) {
              newRecord.id = arrData[index].id;
              newRecord.Created = arrData[index].Created;
              newRecord.CreatedBy = arrData[index].CreatedBy;
              newRecord.Status = arrData[index].Status;
            }
          }
          grid_data.forEach(r => {
            r['ChangedList'] = [];//reset changedlist
          });
          api.setRowData(grid_data);
          //
          notify(`SAVED !!!`, { type: 'success', autoHideDuration: 2000 });
        } else {
          let mess = getNotification(response);
          notify(mess, { type: 'error', autoHideDuration: 2000 });
        }
      }).catch(console.error).finally(_ => setIsLoading(false));
    setOpen(false);
  };
  //EXPORT DATA 
  const handleExport = () => {
    var daysInMonth = dayjs(`${monthYear}-01`).daysInMonth();
    var daysList = api.getModel().rowsToDisplay?.reduce((days, obj) => {
      Object.keys(obj.data).forEach(dayCol => {
        if (dayCol.includes('Day_') && days.length < daysInMonth) {
          days.push(dayCol);
        }
      });

      return days;
    }, []);

    return {
      resource: 'WeeklyWorkTime',
      params: {
        filter: { MonthYear: monthYear, TeamId: lengthOfTime.teamId },
        sort: { field: 'id', order: 'asc' }
      },
      exportFile: {
        filename: `WeeklyWorkTime-${formatLocalTime(new Date(), 'YY-MM-DD')}`,
        columns: ['AliasName'].concat(daysList),
        limit: 1000000,
        position: "3-2"
      }
    };
  };

  return (
    <>
      {showSaveAll && <Button variant="contained" size="small" onClick={handleSave} startIcon={<SaveAs />}>SAVE</Button>}
      <RaExporter onPrepare={handleExport} />
      <Confirm isOpen={open} title={`Save MONTH: ${monthYear}`} content='Are you sure with this apply?' onConfirm={handleConfirm} onClose={handleDialogClose} />
    </>

  );
};

export default SaveAllButton;