import React, { useState, useRef } from 'react';
import RaList from '../../components/ra-list/RaList';
import RenderControls from '../../components/ra-list/RenderControls';
import { FunctionField, NumberField, TextField, Datagrid } from 'react-admin';
import { dayjs, formatLocalTime } from '../../components/ex_dayjs';
import { state, lmstatus, tnMode } from './common';
import LastMinuteButton from './component/lm-button';
import SaleNote from './component/sale-note';
import NoteField from './component/note-field';
import StatusField from './component/status-field';
import EventIdField from './component/eventid-field';
import NormalWithStatusButton from './component/nws-button';

const TNSalesAnalysis = () => {
    const refFilter = useRef();
    const [sortBy] = useState(['thecount desc'])
    const [notesData, setNotesData] = useState({ data: null, recordId: null })
    const [key, setKey] = useState('');
    const [info, setInfo] = useState({
        mode: tnMode.normal,
        title: 'TN Sale Analysis',
        firstLoad: false
    })
    const [defaultValues, setDefaultValues] = useState({
        haveCompetitor: null,
        event_date: [dayjs()],
        user_mode_eq: tnMode.normal
    });
    React.useEffect(() => {
        setInfo({ ...info, firstLoad: true });      
    }, [])
    React.useEffect(() => {
        if (info.firstLoad) {
            setTimeout(() => {
                refFilter.current.click();
                setKey(new Date());
            }, 100);
        }
    }, [info.mode])

    React.useEffect(() => {
        if (location.href.toLowerCase().includes('exchangeticketcount/lastminute')) {
            setInfo({ ...info, mode: tnMode.lastminute, title: 'TN Sale Analysis (LM)' })
            setDefaultValues((prev) => ({ ...prev, user_mode_eq: tnMode.lastminute }))
        }
        if (location.href.toLowerCase().includes('exchangeticketcount/statuslist')) {
            setInfo({ ...info, mode: tnMode.normalwithstatus, title: 'TN Sale Analysis (With Status)' })
            setDefaultValues((prev) => ({ ...prev, user_mode_eq: tnMode.normalwithstatus }))
        }
    }, [location.href])

    const handleRowStyle = (record, index) => {
        let bgColor = '';
        if (info.mode != tnMode.normal) {
            switch (record.status) {
                case 1: bgColor = '#00bbbb33'; break;
                case 2: bgColor = 'darkgray'; break;
                case 3: bgColor = '#00ff0066'; break;
                case 4: bgColor = '#ffff0066'; break;
            }
            return {
                backgroundColor: bgColor
            }
        }
        else return {};
    }

    const controls = [
        { component: 'daterange', name: 'event_date', label: 'Event Date' },
        { component: 'text', name: 'owner_company_name_contains', label: 'Owner Company Name' },
        { component: 'textfilter', name: 'event_name', label: 'Event Name', xs: 2 },
        { component: 'textfilter', name: 'venue_name', label: 'Venue Name', xs: 2 },
        { component: 'select', name: 'status', label: 'Status', choices: lmstatus, isHide: info.mode != 2, defaultValue: 0 },
        { component: 'selectgrouping', name: 'event_state_eq', label: 'State', choices: state },
        { component: 'number', name: 'recent_sales_eq', label: 'Recent sales', placeholder: "#days past", focused: true },
        { component: 'numberrange', name: 'thecount', label: 'TN Sales', xs: 1.5 },
        { component: 'numberrange', name: 'posnext_count', label: 'POS_All', xs: 1.5 },
        { component: 'numberrange', name: 'shcount', label: 'SH Sales', xs: 1.5 },
        { component: 'checkbox', name: 'posted_market_eq', label: '3rd Tier' },
        { component: 'text', name: 'user_mode_eq', label: 'UserMode', isHide: true },
    ]
    return (
        <RaList
            sortby={sortBy}
            perPage={25}
            title={info.title}
            filters={
                <RenderControls
                    refFilter={refFilter}
                    actions={
                        <>
                            <LastMinuteButton info={info} setInfo={setInfo} setDefaultValues={setDefaultValues} />
                            <NormalWithStatusButton info={info} setInfo={setInfo} setDefaultValues={setDefaultValues} />
                        </>
                    }

                    controls={controls}
                    defaultValues={defaultValues}
                    exportFile={{
                        filename: `TnSalesAnalysis-${formatLocalTime(new Date(), 'YY-MM-DD')}`,
                        columns: ['id', 'event_name', 'venue_name', 'event_date', 'event_state', 'thecount', 'shcount', 'allsales', 'posnext_count', 'Status'],
                        limit: 200000
                    }}
                />

            }
        >
            <Datagrid bulkActionButtons={false} expand={<SaleNote />} expandSingle rowStyle={handleRowStyle} key={key}>
                <EventIdField label='Event ID' source='id' />
                <TextField source="event_name" label={'Event Name'} />
                <TextField source="venue_name" label={'Venue'} />
                <TextField source="event_state" label={'State'} />
                <FunctionField label='Event Date' source='event_date' render={record => (
                    <><b>{dayjs(record.event_date).format('MMM DD, YYYY')}</b><br />{dayjs(record.event_date).format('hh:mm A')}</>)}
                />
                <NumberField source="thecount" label={'TN Sales'} />
                <NumberField source="shcount" label={'SH Sales'} />
                <NumberField source="allsales" label={'All Sales'} />

                <NumberField source="count_grey" label={'Grey'} />
                <NumberField source="posnext_count" label={'POSNext'} />
                <NumberField source="exchange_count" label={'All POS'} />
                {info.mode != 0 && <StatusField label='Status' info={info} setNotesData={setNotesData} />}
                {info.mode == 2 && <NoteField label='Notes' notesData={notesData} />}
            </Datagrid>
        </RaList >
    );
}
const TNSalesAnalysisPage = {
    name: 'ExchangeTicketCount',
    list: TNSalesAnalysis
}
export default TNSalesAnalysisPage;