import React from 'react';
import { Autocomplete, Stack, FormControl, TextField, Button, Chip } from '@mui/material';
import { useState } from 'react';
import { useUpdate, useNotify, Confirm } from 'react-admin';
import { blue, grey, red, green, deepOrange } from "@mui/material/colors";


const MultiSelectTags = (props) => {
  const [tagName, setTagName] = useState([props.value]);
  const [open, setOpen] = useState(false);//react-admin confirm
  const saveEdit = () => setOpen(true);
  const [handleUpdate] = useUpdate();
  const notify = useNotify();
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    let event = props.data;
    event.tags = tagName.join(',');
    handleUpdate('EventMatcher', { id: event.id, data: event }, {
      onSuccess: (_) => {
        notify('Update successfully', { type: 'success', autoHideDuration: 2000 });
        props.api.stopEditing();
        props.reloadPage();
      },
      onError: (error) => {
        notify(`Update error: ${error.message}`, { type: 'error', autoHideDuration: 5000 });
        props.data.tags = props.value;
        props.api.stopEditing();
      },
    });
    setOpen(false);
  }

  const onTagsChange = (_, value) => {
    setTagName(value)
  };
  const defaultTags = ['New', 'Procesing', 'High value', 'Low value', 'Request from exchange', 'Support Ticket', 'Skip', 'Manual Review', 'Unmapped', 'Ontario', 'Vegas' ,'Request Again'];
  const getStyles = (tagName) => {
    switch (tagName.trim()) {
      case 'high value':
        return green[500];
      case 'low value':
        return red[600];
      case 'support ticket':
        return blue[500];
      case 'skip':
      case 'unmapped':
      case 'manual review':
        return grey[500];
      default:
        return deepOrange[400];
    }
  }
  const tags = props.value !== true && props.value.split(",").filter(value => value !== "");
  return (
    <>
      <FormControl sx={{ m: 1, width: 200 }}>
        <Autocomplete
          multiple
          id="size-small-filled-multi"
          size="small"
          onChange={(event, value) => onTagsChange(event, value)}
          options={defaultTags}
          defaultValue={tags}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                label={option}
                size="small"
                key={option}
                style={{ backgroundColor: getStyles(option.toString().toLowerCase()) }}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label="Select tags"
            />
          )}
        />
        <Stack sx={{ mt: 2 }} direction="row" spacing={2}>
          <Button onClick={saveEdit} variant="contained">Save</Button>
          <Button onClick={() => { props.api.stopEditing() }} variant="outlined">Cancel</Button>
        </Stack>
      </FormControl>
      <Confirm isOpen={open} title='Update Tags' content='Are you sure want to update tags?' onConfirm={handleConfirm} onClose={handleDialogClose} />
    </>
  );
};

export default MultiSelectTags;