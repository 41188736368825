import React, { useEffect, useState } from "react";
import { Datagrid, FunctionField, TextField, useDataProvider, useRecordContext } from "react-admin";
import { formatDateTime, formatLocalTime, formatDate } from "../../components/ex_dayjs";
import RaList from "../../components/ra-list/RaList";
import RenderControls from "../../components/ra-list/RenderControls";
import { getDefaultUtcOffset, loadSourceType } from "../../utils/common";
import { DeliveryMethodEnum } from "../../utils/enum";
import { Tooltip } from "@mui/material";
import './style.css';

const colsCss = {
    orderNumber: 'shipping-report-col-orderNumber',
    date: 'shipping-report-col-date',
    deliveryMethod: 'shipping-report-delivery-method',
    notes: 'shipping-report-notes',
    flex: 'shipping-report-col-flex',
    in_hand_date_warn: 'shipping-report-col-inhand-warn',
    in_hand_date_danger: 'shipping-report-col-inhand-danger'
};

const TASKS = [
    { id: 'Ship', name: 'Ship' },
    { id: 'Shipped', name: 'Shipped' },
    { id: 'Shipping', name: 'Shipping' },
    { id: 'Process', name: 'Process' },
    { id: 'Processed', name: 'Processed' },
    { id: 'Processing', name: 'Processing' }
];

const InHandField = ({ source }) => {
    const record = useRecordContext();
    let daysToInhand = record['daysToInhand'];
    const style = daysToInhand < 3 ? colsCss.in_hand_date_danger : daysToInhand < 6 ? colsCss.in_hand_date_warn : undefined;
    return (
        <Tooltip arrow title={daysToInhand != null && style ? `Processed at ${formatLocalTime(record.utc_posted_on)}` : null}>
            <span className={style}>{formatDate(record[source])}</span>
        </Tooltip>
    );
};

const ShippingReport = () => {
    const dataProvider = useDataProvider();
    const [marketType, setMarketType] = React.useState([]);
    const [sortModel] = React.useState(["date desc"]);
    const [defaultValues, setDefaultValues] = useState({});

    useEffect(() => {
        loadSourceType(dataProvider, (response) => {
            setMarketType(response);
        });
        const currentDate = new Date();
        setDefaultValues({
            date: [currentDate, currentDate],
            utc_offset_eq: getDefaultUtcOffset()
        });
    }, []);

    return (
        <RaList
            emptyWhileLoading
            title={"Shipping Report"}
            sortby={sortModel}
            storeKey={false}
            perPage={200}
            pagination={[200, 250, 500, 1000]}
            filters={
                <RenderControls
                    exportFile={{
                        columns: ['account', 'order_number', 'order_date', 'primary_market', 'event_date', 'delivery_method', 'notes', 'in_hand_date', 'date', 'DaysToInhand']
                    }}
                    controls={[
                        { component: 'text', name: 'user_eq', label: 'User' },
                        { component: 'daterange', name: 'date', isUTC: true },
                        { component: 'select', name: 'primary_market_eq', label: 'Type', choices: marketType },
                        { component: 'select', name: 'task_eq', label: 'Task', choices: TASKS },
                        { component: 'select', name: 'delivery_method_eq', label: 'Delivery method', choices: DeliveryMethodEnum },
                        { component: 'text', name: 'account_eq', label: 'Account', xs: 1 },
                        { component: 'text', name: 'order_number_eq', label: 'Order Number', xs: 1 },
                        { component: 'text', name: 'utc_offset_eq', isHide: true },
                        { component: 'checkbox', name: 'IHD_eq', label: "IHD", xs: 0.5 },
                        { component: 'checkbox', name: 'LM_eq', label: "LM", xs: 0.5 }

                    ]}
                    defaultValues={defaultValues}
                />
            }
        >
            <Datagrid
                bulkActionButtons={false}
            >
                <TextField source="user" cellClassName={colsCss.flex} />
                <TextField source="task" cellClassName={colsCss.flex} />
                <TextField source="account" cellClassName={colsCss.flex} />
                <TextField source="order_number" label="Order Number" cellClassName={colsCss.orderNumber} />
                <FunctionField label="Order Date" source="order_date" render={record => formatDateTime(record.order_date)} cellClassName={colsCss.date} />
                <TextField source="primary_market" label="Market" cellClassName={colsCss.flex} />
                <FunctionField label="Event Date" source="event_date" render={record => formatDateTime(record.event_date)} cellClassName={colsCss.date} />
                <TextField source="delivery_method" label="Delivery Method" cellClassName={colsCss.deliveryMethod} />
                <TextField source="notes" cellClassName={colsCss.notes} />
                {/* <FunctionField source="in_hand_date" render={record => formatLocalTime(record.in_hand_date)} width={200} cellClassName={(record) => {
                    return formatLocalTime(record.in_hand_date) - curDate < 3 ? colsCss.in_hand_date_danger : colsCss.in_hand_date_warn}
                }  /> */}
                <InHandField label="Inhand date" source="in_hand_date"></InHandField>
                <FunctionField source="date" render={record => formatLocalTime(record.date)} width={200} cellClassName={colsCss.date} />
            </Datagrid>
        </RaList>
    );
};

const ShippingReportPage = {
    name: 'ShippingReport',
    list: ShippingReport
};
export default ShippingReportPage;