import React, { useState } from "react";
import { Chip, LinearProgress, Box} from "@mui/material";
import { useRecordContext, useNotify } from "react-admin";
import MDialog from "../../../components/controls/MDialog";
import { DataGrid } from "@mui/x-data-grid";
import { toLocalTime } from "../../../components/ex_dayjs";
import style from '../style.css';

const TNOrderDetails = (props) => {
    const { dataProvider } = props;
    const notify = useNotify();
    const record = useRecordContext();
    const [openDetails, setOpenDetails] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [orderDetailsList, setOrderDetailsList] = useState([]);
    
    const handleOpenOrderDetails = () =>{       
        setOpenDetails(true);
        setIsLoading(true);
        dataProvider.fetchData('TnCompetitiveAnalysis', `TnOrderDetails/?event_id=${record.event_id}`)
        .then(rs => {
            setIsLoading(false);
            if (rs.data) {
                setOrderDetailsList(rs.data);
            }
        })
        .catch(error => {
            setIsLoading(false);
            notify(`Load Details failed: Status ${error.status}, Error ${error.body}`, { type: 'error', autoHideDuration: 2000 })
        })
     
    }

    const handleCloseOrderDetails = () => {
        setOpenDetails(false);        
    }
   
    const getRowClassName = (params) => {
        let website = params.row.website_name.toLowerCase();
        if (website === 'supplemental' || website === 'autohold')
            return 'turnredforcompetitor';       
    }

    const columnDefs = [
        { field: 'order_date', headerName: 'Order Date', width: 200, valueFormatter: ({ value }) => toLocalTime(value).format('MMM DD, YYYY hh:mm A') },
        { field: 'event_id', headerName: 'Event ID', width: 100 },
        { field: 'event_name', headerName: 'Event Name', width: 250 },        
        { field: 'venue_name', headerName: 'Venue Name', width: 350 },        
        { field: 'event_date', headerName: 'Event Date', width: 200, valueFormatter: ({ value }) => toLocalTime(value).format('MMM DD, YYYY hh:mm A') },
        { field: 'ticket_qty', headerName: 'Ticket Qty', width: 100 },
        { field: 'section', headerName: 'Section', width: 200 },
        { field: 'row', headerName: 'Row', width: 100 },
        { field: 'wholesale_price', headerName: 'Whole_Sale_Price', width: 100 },
        { field: 'cost', headerName: 'Cost', width: 100 },
        { field: 'revenue', headerName: 'Revenue', width: 100 },
    ];
    return record ? (
        <div>
            <Chip label={record.event_id} onClick={handleOpenOrderDetails}/>
            <MDialog
                maxWidth='xl'                
                open={openDetails}
                handleClose={handleCloseOrderDetails}               
                name={'tn-competitive-details'}
                children={
                    <div>                       
                        <h2><b>{record.event_id + ' - ' + record.event_name}</b></h2>
                        <h5 style={{textAlign: 'end'}}><b>Black|White = TN Sales / </b><b style={{color: 'red'}}>Red Text = Competitor Sales</b></h5>                      
                        <Box sx={{ height: "60vh", width: '100%' }}>                            
                            {isLoading && <LinearProgress color='secondary' />}
                            <DataGrid
                                rows={orderDetailsList}
                                columns={columnDefs}
                                //getRowId={(r) => r.event_id}
                                getRowClassName={getRowClassName}
                                
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 25,
                                        },
                                    },
                                }}
                            />
                        </Box>
                    </div>
                }
            />
        </div>
    ) : null;
}
export default React.memo(TNOrderDetails)