import React, { useState } from 'react';
import MDialog from '../../../components/controls/MDialog';
import { FormControlLabel, Grid, Stack, Switch, Typography, LinearProgress } from '@mui/material';
import RCBarChart from '../../../components/charts/recharts/rcBarChart';
import RCPieChart from '../../../components/charts/recharts/rcPieChart';
import { buildQueryString, dataProvider } from '../../../DataProvider';

const ChartView = (props) => {
    const { chart, setChart } = props;
    const filter = chart.filterList;
    const [isLoading, setIsLoading] = React.useState(false);
    const [data, setData] = useState([]);

    React.useEffect(() => {
        setIsLoading(true);
        dataProvider.fetchData('OrderAutoPriceReport', `ViewChart/Filter${convertFilter(filter)}`
        ).then((result) => {
            setData(convertData(result.data));
        }).catch(err => {
            console.log(`Fail to fetch data: ${err}`, { type: 'error', autoHideDuration: 2000 });
        }).finally(_ => setIsLoading(false));
    }, []);

    const convertFilter = (filter) => {
        let _filter = buildQueryString({ filter: filter }, { orderBy: 'order_date desc' });
        return _filter;
    }

    const convertData = (data) => {
        let newData = [];
        data = data?.sort((a, b) => a.marketId - b.marketId).sort((a, b) => a.vivid_account_id - b.vivid_account_id);
        let accounts = Object.groupBy(data, ({account}) => account);
                
        Object.keys(accounts).forEach(item => {
            let total = accounts[item].length;
            let autoPrice = accounts[item].filter(x => x.is_auto_price).length;
            newData.push({
                name: item,
                nonauto: total - autoPrice,
                auto: autoPrice
            });
        });
        return newData;
    }
    return (
        <>
            <MDialog
                maxWidth={'xl'}
                open={chart.open}
                handleClose={() => setChart(prev => ({ ...prev, open: false, type: 'bar' }))}
                name={'order-autoprice-dialog'}
                action={
                    <Stack direction="row" justifyContent={"space-between"} width="100%">
                        <FormControlLabel
                            value="pie"
                            control={<Switch color="primary" onChange={(ev) => setChart(prev => ({ ...prev, type: ev.target.checked ? 'pie' : 'bar' }))} />}
                            label="Pie chart"
                            labelPlacement="start"
                        />
                    </Stack>
                }
                children={
                    <>
                        {isLoading == true && <LinearProgress color='secondary' />}
                        <Typography sx={{ marginBottom: '10px' }} variant="h3" textAlign={'center'}>Order Auto Price Chart</Typography>
                        {data.length && chart.type == 'bar' &&
                            (<RCBarChart
                                width={1500}
                                data={data}
                                barSize={data.length > 18 ? 50 : ((data.length > 8) ? 70 : 90)}
                                yGap={5}
                                xAxis={{ dataKey: 'name', label: { value: 'ACCOUNT', dy: 20 } }}
                                yAxis={{ position: 'insideLeft', label: { value: 'ORDERS', angle: -90, dx: -10 } }}
                                legend={{ verticalAlign: 'top', height: 60, iconType: 'square' }}
                                showTotal={true}
                                seriesList={[
                                    { dataKey: 'auto', name: 'Auto Price', fill: '#4caf50', stackId: 'a', labelList: { fill: 'white' } },
                                    { dataKey: 'nonauto', name: 'None', fill: '#9e9e9e', stackId: 'a', labelList: { fill: 'white' } }
                                ]}
                            >
                            </RCBarChart>)
                            ||
                            (
                                <Grid container alignItems="center" sx={{ minHeight: '65vh' }}
                                    justifyContent="center" spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} >
                                    <RCPieChart
                                        data={data}
                                        dataKey={'name'}
                                        height={220}
                                        width={450}
                                        chartSize={80}
                                        elevation={20}
                                        seriesList={[
                                            { dataKey: 'auto', name: 'Auto Price', fill: '#4caf50' },
                                            { dataKey: 'nonauto', name: 'None', fill: '#9e9e9e' }
                                        ]}
                                    >
                                    </RCPieChart>
                                </Grid>
                            )
                        }
                    </>
                }
            >
            </MDialog>
        </>
    );
}
export default ChartView