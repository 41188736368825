import * as React from 'react';
import { Typography, Link } from '@mui/material';
import { formatDate, formatDateTime } from '../../../../components/ex_dayjs';

export const MTypography = ({ label, name, href, date, datetime, bold = '' }) => {
    return (
        <Typography>
            <label style={{ fontWeight: 'bold' }}>{label + ': '}</label>
            {href && (
                <Link target='_blank' href={href} >{name}</Link>
            )}
            {date && (
                <label style={{ fontWeight: bold }}>{formatDate(date)}</label>
            )}
            {datetime && (
                <label>{formatDateTime(datetime)}</label>
            )}
            {!href && !datetime && !date && name && (
                <label>{name}</label>
            )}
        </Typography>
    )
}