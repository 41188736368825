import { Button, ButtonGroup, Chip, IconButton, Stack, TextField, Tooltip, Typography } from "@mui/material";
import React from "react";
import { Confirm, useNotify, useRecordContext } from "react-admin";
import { STATUS_ENUM, STATUS_NAME, getTagsColor, ProblemList } from "../../../../utils/enum";
import { Send, Mail, CloudUpload, Done, Clear, Print, Check } from "@mui/icons-material";
import MDialog from "../../../../components/controls/MDialog";
import MSelect from "../../../../components/controls/MSelect";

const StatusField = (props) => {
    const { mydayjs, currUser, dataProvider, appIO } = props;
    const { vendor, bus, bus_events } = appIO;

    const record = useRecordContext();
    if (!record) return;

    const notify = useNotify();
    const [confirmStateUpPs, setConfirmStateUpPs] = React.useState({ open: false, message: '' });
    const [confirmStateUc, setConfirmStateUc] = React.useState({ open: false, rejectReason: '' });//unconfirm
    const [problemState, setProblemState] = React.useState({ open: false });

    const [tags, setTags] = React.useState(record?.tags.split(',')?.filter(x => x));
    let isHardTicket = record?.delivery_method == 1;
    let airBill = isHardTicket && record.airBill && record.airBill == true;

    const handleTagDelete = (props, tag) => {
        let currTags = record.tags?.split(',')?.filter(x => x);
        let newTags = currTags?.filter(x => x != tag);
        record.tags = ',' + newTags.join(',') + ',';

        dataProvider.postData(vendor.controller, 'Update', {
            ID: record.id,
            tags: record.tags
        }).then(response => {
            if (response.data) {

                setTags(newTags);
            }
            // return broadcastChangedOrder(result.order, KEYS_TAG_CHANGED_ORDER);
        });
    }

    //#region Unconfirm Order
    const clickConfirmOrder = () => {
        let order_number = record.order_number;
        dataProvider.postData(vendor.controller, 'Confirm', { orderNumber: order_number, seatNumbers: '' })
            .then(response => {
                if (response.data) {
                    updateStatus({
                        orderNumber: order_number,
                        currentStatus: STATUS_ENUM.Unconfirmed,
                        newStatus: STATUS_ENUM.Unprocessed
                    });
                }
            })
    }
    const clickRejectOrder = () => {
        setConfirmStateUc(prev => ({ ...prev, open: true }));

    }
    const handleRejectOrder = () => {
        setConfirmStateUc(prev => ({ ...prev, open: false }));

        let order_number = record.order_number;
        dataProvider.postData(vendor.controller, 'Recject', { orderNumber: order_number })
            .then(response => {
                if (response.data) {
                    updateStatus({
                        orderNumber: order_number,
                        currentStatus: STATUS_ENUM.Unconfirmed,
                        newStatus: STATUS_ENUM.Canceled,
                        problem: confirmStateUc.rejectReason
                    });
                    setConfirmStateUc(prev => ({ ...prev, rejectReason: '' }));
                }
            })
    }
    //#endregion

    //#region UnProcess + Shipping
    function isProblemExist() {
        let proplem = record.tags.split(',').filter(x => x).find(x => x == 'Problem');
        return !!proplem;
    }
    var currentStatus = null;
    var currentOrderNumber = null;

    const handleconfirmUpPs = () => {
        setConfirmStateUpPs(prev => ({ ...prev, open: false, message: '' }));
        if (typeof currentStatus === 'undefined' || currentStatus === null) {
            return;
        }
        changeStatus();
    }
    //Update Internal_status State => re-render
    function updateStatus(options) {
        options.shipping = options.shipping || record.shipping;

        return bus.emitAsync(bus_events.order_status_changing, options)
            .then((result) => {
                if (result && result.length > 0 && result[0].status !== 'Accepted') {
                    notify(
                        <Alert severity="error">
                            <h4>Your command is rejected.</h4> <br />
                            {result[0].reason}<br />
                        </Alert>
                    );
                }
                else {
                    dataProvider.fetchData(vendor.controller, 'Get?isWithFullNotes=true&orderNumber=' + options.orderNumber)
                        .then((response) => {
                            if (response.data) {
                                let response = res.data;
                                record.str_utc_last_updated_on = response.order.str_utc_last_updated_on;

                                var notes = response.order.order_notes;
                                record.order_notes = notes.reduce(function (prev, cur) { if (prev.length < 3 && cur.note_type == 0) prev.push(cur); return prev; }, []);
                                record.order_fullnotes = notes;
                                bus.broadcast(bus_events.order_saved, record);
                            }
                            notify('Get Order failed', { type: 'error' });
                        });
                }
            });
    }

    function changeStatus() {
        var newStatus;
        switch (currentStatus) {
            case STATUS_ENUM.Unprocessed:
                newStatus = STATUS_ENUM.Processing;
                break;
            case STATUS_ENUM.PendingShipment:
                newStatus = STATUS_ENUM.Shipping;
                break;
            default:
                return;
        }

        updateStatus({
            orderNumber: currentOrderNumber,
            currentStatus: currentStatus,
            newStatus: newStatus,
        }).then(function () {
            currentStatus = null;
            currentOrderNumber = null;
        });
    }
    async function confirmIfAny(message, isShipping = false) {
        currentOrderNumber = record.order_number
        if (!isProblemExist()) {
            setConfirmStateUpPs(prev => ({ ...prev, open: true, message: message }));
        }
        else {
            if (isShipping) {
                if (vendor.shouldConfirmShippingStatus && !tags.some(tag => tag.toLowerCase() == 'E-Ticket : Not Uploaded')) {
                    setConfirmStateUpPs(prev => ({ ...prev, open: true, message: 'This order shipping-status is not valid, ARE YOU SURE TO CONTINUE SHIPPING?' }));
                } else {
                    changeStatus();
                }
            } else {
                let orderNumber = currentOrderNumber;
                Promise.race([
                    fetch('https://ipinfo.io/ip').then(res => res.status == 200 ? res.text() : ''),
                    fetch('https://icanhazip.com/').then(res => res.status == 200 ? res.text() : ''),
                    fetch('https://api.ipify.org?format=json').then(res => res.status == 200 ? res.json() : '')
                ])
                    .then(info => {
                        let post = `Clicked process button, IP: ${info.ip ? info.ip : info}`;
                        dataProvider.postData(vendor.controller, `NoteAdminIP?order_number=${orderNumber}&post=${post}`)
                            .then(res => {
                                if (res.data) {
                                    console.log('Add admin ip log success');
                                }
                            })
                    });
                changeStatus();
            }

        }
    }
    const clickProcess = (params) => {
        currentStatus = record.internal_status;
        if (!record.info) return;

        confirmIfAny('There are problem in order. Would you want continue to process?');
    }
    const handlePendingShipment = (params) => {
        currentStatus = record.internal_status;

        confirmIfAny('There are problem(s) in order. Would you want continue to ship?', true);
    }
    //#endregion

    //#region Processing
    //#region Problem report        
    const clickProblem = () => {
        setProblemState(prev => ({ ...prev, open: true }));
    }
    const changeProblemList = (reason) => {
        setProblemState(prev => ({ ...prev, open: false }));
        console.log(props);
        if (reason === '#') {
            return updateStatus({
                orderNumber: record.order_number,
                currentStatus: record.internal_status,
                newStatus: record.internal_status == STATUS_ENUM.Processing ? STATUS_ENUM.Unprocessed : STATUS_ENUM.PendingShipment
            });
        }

        let newTags = tags.filter(tag => tag != 'Problem Resolved');//cause have problem => remove "Problem resolved"
        if (!newTags.some(tag => tag == "Problem")) {
            newTags.push("Problem");
        }
        if (!newTags.some(tag => tag == reason)) {//if not exist => add
            newTags.push(reason);
        }
        record.tags = ',' + newTags.join(',') + ',';

        dataProvider.postData(vendor.controller, 'Update', {
            ID: record.id,
            tags: record.tags
        }).then(response => {
            if (response.data) {
                setTags(newTags);
            }
            // return broadcastChangedOrder(result.order, KEYS_TAG_CHANGED_ORDER);
        });
    }
    //#endregion
    //#region DONE
    const clickDone = () => {

        function doUpdate(options) {
            var order = {
                order_number: record.order_number,
                ID: record.id,
                primary_order_number: record.primary_order_number,
                primary_account: record.primary_account,
                primary_cost: record.primary_cost,
                penalty_cost: record.penalty_cost,
                internal_status: validShipment() ? STATUS_ENUM.PendingShipment : STATUS_ENUM.Unprocessed
            };
            for (var k in options) order[k] = options[k];

            dataProvider.postData(vendor.controller, 'Update', order)
                .then(response => {
                    if (response.data) {

                    }
                    // return broadcastChangedOrder(result.order, KEYS_TAG_CHANGED_ORDER.concat(["primary_order_number", "primary_account", "primary_cost", "pelnalty_cost"]));
                });

            function validShipment() {
                return !!record.primary_order_number || !!record.primary_account || !!record.primary_cost || !!record.penalty_cost;
            }
        }

        var needUpdate = false;
        var tagLabels = tags.map((tag, index) => {
            if (tag == 'Problem') {
                needUpdate = true;
                return 'Problem Resolved';
            }

            return tag;
        }).join(',');

        if (needUpdate) {
            doUpdate({ tags: tagLabels });
        }
        else {
            doUpdate();
        }
    }
    //#endregion
    //#endregion

    //#region component
    const StatusTitle = (props) => {
        const { statusText, subStatus } = props;
        const shipping = record.shipping;
        return (<Tooltip title={`${statusText} ${subStatus || ''} ${shipping || ''}`}>
            <span>
                <Typography>{statusText}</Typography>
                <Typography sx={{ fontWeight: 'bold' }}>{subStatus || ''}</Typography>
                <Typography sx={{ overflow: 'hidden', whiteSpace: 'pre', fontWeight: 'bold' }}>{shipping || ''}</Typography>
            </span>
        </Tooltip>)
    }

    const ProblemDialog = (props) => {
        return (
            <MDialog
                maxWidth={"xs"}
                open={problemState.open}
                handleClose={() => setProblemState(prev => ({ ...prev, open: false }))}
                title={'Select a problem'}
                name={'problem-list'}
                action={<></>}
                children={
                    <MSelect choices={ProblemList} onChange={changeProblemList} ></MSelect>
                }
            />
        )
    }
    //#endregion
    const renderStatusComp = (status) => {//uiGrid line 99
        switch (status) {
            case STATUS_ENUM.Unconfirmed:
                return <>
                    <StatusTitle statusText={'Unconfirmed'} />
                    <Stack direction='row' className="smallInput">
                        <TextField variant="outlined" placeholder="Confirm seat#" title="confirm seat" size="small" hiddenLabel margin="none" />
                        <ButtonGroup size="small" sx={{ fontSize: '12px' }}>
                            <Button variant="contained" size="small" color="error" id='rejectOrder' onClick={clickRejectOrder}><Clear /></Button>
                            <Button variant="contained" size="small" color="success" id='confirmOrder' onClick={clickConfirmOrder}><Check /></Button>
                        </ButtonGroup>
                    </Stack>
                </>;
            case STATUS_ENUM.Canceled:
                return <><StatusTitle statusText={'Canceled'} /></>
            case STATUS_ENUM.Unprocessed:
                return <>
                    <StatusTitle statusText={'Unprocessed'} />
                    <Button variant="contained" size="small" color="success" onClick={clickProcess}>Process</Button>
                </>;
            case STATUS_ENUM.Processing:
                return <><StatusTitle statusText={'Processing'} subStatus={record.processor} />
                    {record.processor == currUser &&
                        // {record.processor == record.processor && 
                        <Stack direction='row' spacing={1} style={{ width: '100%' }}>
                            <Button variant="contained" size="small" color="error" startIcon={<Clear />} style={{ fontSize: '10px' }} onClick={clickProblem}>Prob.</Button>
                            <Button variant="contained" size="small" color="success" startIcon={<Done />} style={{ fontSize: '10px' }}
                                disabled={!record.primary_account} onClick={clickDone}>Done</Button>
                        </Stack>
                    }
                </>;
            case STATUS_ENUM.PendingShipment:
                return <><StatusTitle statusText={'Available Unshipped'} />
                    <Button variant="contained" size="small" color="success" startIcon={isHardTicket ? <Mail /> : <CloudUpload />} onClick={handlePendingShipment}>Ship</Button>
                </>;
            case -1:
                return <><StatusTitle statusText={'Available Unshipped'} />
                    <Button variant="contained" size="small" color="success" startIcon={isHardTicket ? <Mail /> : <CloudUpload />} onClick={handlePendingShipment}>Ship</Button>
                </>;
            case STATUS_ENUM.Shipping:
                //sub_status = shipper
                return <><StatusTitle statusText={'Shipping'} />
                    {record.processor == currUser &&
                        // {record.processor == record.processor && 
                        <Stack direction='row' spacing={1} style={{ width: '100%' }}>
                            <Button variant="contained" size="small" color="error" startIcon={<Clear />} style={{ fontSize: '10px' }} onClick={clickProblem}>Prob.</Button>
                            <Button variant="contained" size="small" color="success" startIcon={<Done />} style={{ fontSize: '10px' }}
                                disabled={!record.primary_account} onClick={clickDone}>Done</Button>
                        </Stack>
                    }
                </>;
            case STATUS_ENUM.DelayedUnshippedOrders:
                return <>
                    <StatusTitle statusText={'Delayed Unshipped'} subStatus={record.utc_delivery_date ? `Avail ${mydayjs.utc(record.utc_delivery_date).fromNow()}` : ''} />
                </>;
            case STATUS_ENUM.Completed:
                return <StatusTitle statusText={'Completed'}
                    subStatus={airBill ? <a style={{ fontSize: '12px' }} target="_blank" href={`AirBill?orderNumber=${record.order_number}`}>Get Air-bill</a> : ''} />;
            case -4:
                return <StatusTitle statusText={'Completed'}
                    subStatus={airBill ? <a style={{ fontSize: '12px' }} target="_blank" href={`AirBill?orderNumber=${record.order_number}`}>Get Air-bill</a> : ''} />;
            default:
                return <StatusTitle statusText={STATUS_NAME[status]} />
        }
    };
    return (
        <div style={{ display: 'block', widht: '100px' }}>
            {tags.map(tag => {
                return <Chip key={`${record.order_number}-${tag}-nguyenthanhngu`} label={tag} onDelete={(props) => handleTagDelete(props, tag)} size='small' id=""
                    style={{ backgroundColor: getTagsColor(tag?.toLowerCase()) }} className="statusChip"
                />
            })}

            {
                renderStatusComp(record.internal_status)
            }
            <Confirm isOpen={confirmStateUpPs.open}
                title='Confirm'
                content={confirmStateUpPs.message}
                onClose={() => setConfirmStateUpPs((prev) => ({ ...prev, open: false, message: '' }))}
                onConfirm={handleconfirmUpPs} />
            <Confirm isOpen={confirmStateUc.open}
                title='Reject'
                content={<TextField variant="outlined"
                    placeholder="Please input reject reason"
                    value={confirmStateUc.rejectReason}
                    onChange={(props) => setConfirmStateUc(prev => ({ ...prev, rejectReason: props.target.value }))} />}
                onClose={() => setConfirmStateUc((prev) => ({ ...prev, open: false, message: '' }))}
                onConfirm={handleRejectOrder}
                ConfirmIcon={() => <Send />}
                confirm="Send"
                confirmColor="warning" />
            <ProblemDialog />
        </div>
    )
}
export default StatusField;