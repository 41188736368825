import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { MenuItem, ListItemText, ListItemIcon, Button, TextField, Grid } from '@mui/material';
import { Edit, Delete, StarBorder } from '@mui/icons-material';
import SourceEditPopup from './SourceEditPopup';
import { REMOTE_API, getDataUpstream } from '../utils';
import { Confirm, useNotify } from 'react-admin';

const SOURCE_LINK = 'https://api.songtek.net/csv/merge/filter/';

const SourceSelect = ({ label, choices, marketType, sourceVariable, dataProvider, onSelect, onDeleted }) => {
    const notify = useNotify();
    const [state, setState] = React.useState({
        link: `${SOURCE_LINK}_`,
        hidden: true,
        isEdit: false,
        isConfirm: false,
        sourceNameDel: '', // for delete
        source: null
    });

    const handleChange = (e) => {
        const { value } = e.target;
        setState((prev) => ({ ...prev, link: `${SOURCE_LINK}${value}` }));
        onSelect(value);
    };

    const onEdit = (item) => {
        // Fetch data source
        dataProvider.fetchData(REMOTE_API, `sources/${item.Name}`)
            .then(function (response) {
                setState((prev) => ({ ...prev, isEdit: true, source: { ...response.data, ...getDataUpstream(response.data.Url) } }));
            }).catch((error) => {
                setState((prev) => ({ ...prev, isEdit: false }));
                notify(`Get source was error: ${error}`, { type: 'error' });
            });
    };

    const onDelete = (item) => {
        setState((prev) => ({ ...prev, isConfirm: true, sourceNameDel: item.Name }));
    };

    const handleDelete = () => {
        setState((prev) => ({ ...prev, isConfirm: false }));
        dataProvider.delete(REMOTE_API, `sources/${state.sourceNameDel}`)
            .then(function () {
                notify(`source deleted`, { type: 'success' });
                onDeleted(); // To reload source
            }).catch((error) => {
                console.error(error);
            });
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={3}>
                <FormControl sx={{ minWidth: '100%' }} size="small">
                    <InputLabel id="demo-select-small">{label}</InputLabel>
                    <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        defaultValue={'View All'}
                        label={label}
                        onChange={handleChange}
                        renderValue={(selected) => {
                            const selectedChoice = choices.find((choice) => choice.Name === selected);
                            return selectedChoice ? selectedChoice.Name : 'View All';
                        }}
                    >
                        <MenuItem key={-1} id={'_'} value={'_'}>
                            <ListItemIcon>
                                <StarBorder />
                            </ListItemIcon>
                            <ListItemText primary={'View All'} />
                        </MenuItem>
                        {choices.map((m, index) => {
                            return (
                                <MenuItem key={index} id={m.Id} value={m.Name}>
                                    <ListItemText primary={m.Name} />
                                    <ListItemIcon>
                                        <Button startIcon={<Edit />} sx={{ marginRight: 1 }} onClick={() => onEdit(m)}>Edit</Button>
                                        <Button startIcon={<Delete />} onClick={() => onDelete(m)}>Delete</Button>
                                    </ListItemIcon>
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3} sx={{ display: state.hidden ? 'none' : '' }}>
                <TextField
                    autoComplete="link"
                    value={state.link}
                    sx={{ width: '100%' }}
                    onChange={(e) => setState((prev) => ({ ...prev, link: `${SOURCE_LINK}${e.target.value}` }))}
                />
            </Grid>
            <Grid item xs={3}>
                <Button size='small'
                    sx={{ marginTop: 4 }}
                    onClick={() => setState((prev) => ({ ...prev, hidden: false }))}>
                    Show Link
                </Button>
            </Grid>
            {state.isEdit && <SourceEditPopup data={state} sourceVariable={sourceVariable} marketType={marketType} dataProvider={dataProvider} handleClose={() => setState((prev) => ({ ...prev, isEdit: false }))} />}
            <Confirm isOpen={state.isConfirm} title={`You are about to delete source "${state.sourceNameDel}". Are you sure?`} onConfirm={handleDelete} onClose={() => setState((prev) => ({ ...prev, isConfirm: false }))} />
        </Grid>
    );
};

export default SourceSelect;