import React, { useState, useEffect } from 'react';
import { Grid, Stack } from '@mui/material';
import Filters, { SearchResult } from './components/Filters';
import DropdownTextEditor from './components/DropdownTextEditor';
import Diff from './components/Diff';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

// Extend dayjs with the necessary plugins
dayjs.extend(relativeTime);
dayjs.extend(utc);

/**
 * Formats a datetime string into a readable format.
 * 
 * @param {string} dateTimeStr - The datetime string to format.
 * @returns {string} The formatted datetime string.
 */
const formatDateTime = (dateTimeStr: string): string => {
    const dateTime = dayjs.utc(dateTimeStr);
    const relativeTimeStr = dateTime.fromNow(); // Relative time (e.g., "Yesterday", "2 days ago")
    const formattedTime = dateTime.local().format('h:mm A'); // Local time, e.g., 2:56 PM
    const formattedDate = dateTime.local().format('HH:mm MMMM D'); // Local date, e.g., 14:56 June 16

    return `${relativeTimeStr} ${formattedTime} (${formattedDate})`;
};

/**
 * IndexPage component
 * 
 * This component serves as the main page for the Availability resource.
 * It includes filters, dropdown editors, and handles search results.
 * 
 * @returns {JSX.Element} The IndexPage component.
 */
const IndexPage: React.FC = () => {
    const [bucketName, setBucketName] = useState<string>('');
    const [prefix, setPrefix] = useState<string>('');
    const [keys, setKeys] = useState<{ id: string; name: string; dateTimeStr: string; }[]>([]);
    const [leftSideData, setLeftSideData] = useState<any>(null);
    const [rightSideData, setRightSideData] = useState<any>(null);

    /**
     * Handles the search results and formats them for display in dropdown editors.
     * 
     * @param {SearchResult[]} result - The search results to handle.
     */
    const onSearchResult = (result: SearchResult[]) => {
        const availableKeys = result.map(x => {
            const keyParts = x.key.split('/');
            const dateTimeStr = keyParts[keyParts.length - 1].split('.')[0]; // Extract the datetime part
            const formattedName = formatDateTime(dateTimeStr);

            return { id: x.key, name: formattedName, dateTimeStr };
        });

        // Sort the availableKeys array in descending order by the extracted datetime
        availableKeys.sort((a, b) => dayjs(b.dateTimeStr).diff(dayjs(a.dateTimeStr)));
        setKeys(availableKeys);
    };

    return (
        <Stack sx={{ width: '100%' }}>
            <Filters setBucketName={setBucketName} setPrefix={setPrefix} handleSearchResult={onSearchResult} />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <DropdownTextEditor onDataLoaded={setLeftSideData} bucketName={bucketName} choices={keys} />
                </Grid>
                <Grid item xs={6}>
                    <DropdownTextEditor onDataLoaded={setRightSideData} bucketName={bucketName} choices={keys} />
                </Grid>
            </Grid>
            <Diff a={leftSideData} b={rightSideData} />
        </Stack>
    );
}

/**
 * AvailabilityPage configuration
 */
const AvailabilityPage = {
    name: 'Availability',
    list: IndexPage
};

export default AvailabilityPage;
