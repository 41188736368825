import React, { memo, useEffect, useState, forwardRef } from 'react';
import { Stack, Select, MenuItem, FormControl, Button, Chip, LinearProgress, Divider } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { useUpdate, useNotify, Confirm } from 'react-admin';
import { dataProvider } from '../../../DataProvider';
import { formatLocalTime } from '../../../components/ex_dayjs';

const DeliveryMethodEdit = memo(
    forwardRef((props, ref) => {
        const { deliveryMethod } = props;               
        const [method, setMethod] = useState({ newMethod: props.data.finalDeliveryMethod, applyFor: 0, applyType: props.data.type })
        const [handleUpdate] = useUpdate();
        const notify = useNotify();
        const [editData, setEditData] = useState(null);
        const [open, setOpen] = useState(false);//react-admin confirm
        let isLoadEditComplete = false;
        let isFirstEdit = true;

        const saveEdit = () => setOpen(true);
        const handleDialogClose = () => setOpen(false);
        const handleConfirm = (params) => {
            setOpen(false);
            let event = props.data;
            let dmEdit = {
                apply: method.applyFor,              
                deliveryMethod: method.newMethod,
                eventId: event.id,
                id: 0,
                name: event.name,
                notes: [],
                originDeliveryMethod: event.originDeliveryMethod,
                type: method.applyType != 'all' ? event.type.trim() : null,
                venue: event.venue
            }
            event.deliveryMethodEdit = dmEdit;

            handleUpdate('EventDelivery', { id: event.id, data: event }, {
                onSuccess: (result) => {
                    notify('Update successfully', { type: 'success', autoHideDuration: 2000 });
                    UpdateGrid(props);
                    props.api.stopEditing();
                },
                onError: (error) => {
                    notify(`Update error: ${error.message}`, { type: 'error', autoHideDuration: 5000 });
                    props.api.stopEditing();
                },
            });            
        }
        useEffect(() => {
            if (!isLoadEditComplete) {
                dataProvider
                    .fetchData('EventDelivery', `GetEdits/?id=${props.data.id}`)
                    .then((response) => {
                        if (response.status !== 200 && response.data) {
                            let arrData = JSON.parse(response.data);
                            // let rowHeight = 260 + arrData.length * 90;                
                            setEditData(arrData);
                            console.log(`Loading editor complete`);
                        }
                    }).catch(console.error);
                isLoadEditComplete = true;
            }
        }, []);
        function UpdateGrid(props) {
            props.data.finalDeliveryMethod = method.newMethod;
            props.api.refreshCells({
                columns: ["finalDeliveryMethod"],
                rowNodes: [props.node],
                force: true
            });
        }
        function GetDeliveryMethod(methodValue) {
            return deliveryMethod.find(m => m.id !== '' && m.id == methodValue)?.name;
        }
        const getEditDescription = (edit) => {
            isFirstEdit = false;
            let isType = edit.Type ? ` and Type = "${edit.Type.trim()}"` : '';
            if (edit.EventId)
                return `Event of ID '${edit.EventId}' will be delivered as ${GetDeliveryMethod(edit.DeliveryMethod)}${isType} (RuleID= ${edit.Id}).`;
            if (((edit.Name || '').length > 0) && (edit.Venue || '').length > 0)
                return `Events with name like '${edit.Name}' and venue like '${edit.Venue}'${isType} will be delivered as ${GetDeliveryMethod(edit.DeliveryMethod)} (RuleID= ${edit.Id}).`;
            if ((edit.Name || '').length > 0)
                return `Events with name like '${edit.Name}'${isType} will be delivered as ${GetDeliveryMethod(edit.DeliveryMethod)} (RuleID= ${edit.Id}).`;
            if ((edit.Venue || '').length > 0)
                return `Events with venue name like '${edit.Venue}'${isType} will be delivered as ${GetDeliveryMethod(edit.DeliveryMethod)} (RuleID= ${edit.Id}).`;
            return '';
        }
        const getQuoteOfEditNotes = (edit) => {
            if (edit.Notes.length == 0) return "";

            let notes = "";
            edit.Notes.forEach(n => {
                // notes += `by ${n.EditBy} at ${n.InsertedDate.format('MM-DD-YYYY hh:mm A')}\n`;
                notes += `by ${n.EditBy} at ${formatLocalTime(n.InsertedDate)}\n`;
            });
            return notes;
        }

        return (
            <div style={{ width: 370 }}>
                <Stack direction="row" sx={{ '& > :not(style)': { m: 1, width: '10ch' } }}>
                    <FormControl key={1} variant="standard" sx={{ m: 1, minWidth: 150 }}>
                        <Select defaultValue={method.newMethod} name={'delivery-method'} sx={{ fontSize: 13 }} onChange={(event) => { setMethod(prevState => ({ ...prevState, newMethod: event.target.value })) }}>
                            {deliveryMethod.map(m => (m.id !== '') &&
                                <MenuItem key={m.id} value={m.id}>{m.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl key={2} variant="standard" sx={{ m: 1, minWidth: 180 }}>
                        <Select defaultValue={0} name={'target-apply'} sx={{ fontSize: 13 }} onChange={(event) => { setMethod(prevState => ({ ...prevState, applyFor: event.target.value })) }}>
                            <MenuItem key={0} value={0}>{'Apply to this event only'}</MenuItem>
                            <MenuItem key={1} value={1}>{'Apply to same event name'}</MenuItem>
                            <MenuItem key={2} value={2}>{'Apply to same venue name'}</MenuItem>
                            <MenuItem key={3} value={3}>{'Apply to this event + venue only'}</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction='row' sx={{ '& > :not(style)': { m: 1, width: '10ch' } }}>
                    <FormControl key={3} variant="standard" sx={{ m: 1, minWidth: 150 }}>
                        <InputLabel id="demo-simple-select-standard-label">Apply type</InputLabel>
                        <Select defaultValue={props.data.type} name={'target-apply'} sx={{ fontSize: 14 }} onChange={(event) => { setMethod(prevState => ({ ...prevState, applyType: event.target.value })) }}>
                            <MenuItem key={0} value={props.data.type}>{props.data.type}</MenuItem>
                            <MenuItem key={1} value={'all'}>{'Apply all market'}</MenuItem>
                        </Select>
                    </FormControl>
                    <Button variant="contained" onClick={saveEdit} sx={{height: 35 }}>Save</Button>
                    <Button variant="outlined" onClick={() => { props.api.stopEditing() }} sx={{height: 35 }}>Cancel</Button>
                </Stack>
                <Divider />
                <Stack sx={{ '& > :not(style)': { m: 1 } }}>
                    <h4><b>History</b></h4>
                    {editData == null && <LinearProgress color='secondary' />}
                    {editData != null && editData.map(e =>
                        <>
                            <p style={{ color: isFirstEdit ? "orange" : 'text.primary' }}>{getEditDescription(e)}</p>
                            <Chip label={getQuoteOfEditNotes(e)} sx={{ height: 'auto', '& .MuiChip-label': { display: 'block', whiteSpace: 'normal', margin: '8px 0 8px 0' } }} />
                        </>
                    )
                    }
                    {props.data.deliveryMethod != null &&
                        <>
                            <p style={{ color: isFirstEdit ? "orange" : null, width: "100%" }}>-This event will be delivered as ${GetDeliveryMethod(props.data.deliveryMethod)}</p>
                            <Chip label="by AP/EXT" />
                        </>
                    }
                    <div>
                        <p style={{ color: isFirstEdit ? "orange" : null }}>-Original delivery method of this event is {GetDeliveryMethod(props.data.originDeliveryMethod)}</p>
                    </div>
                </Stack>
                <Confirm isOpen={open} title='Update Delivery Method' content='Are you sure with this apply?' onConfirm={handleConfirm} onClose={handleDialogClose} />
            </div>
        );
    })
);
export default DeliveryMethodEdit;