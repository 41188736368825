'use strict';

import React, { useMemo, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Title } from 'react-admin';
import AgGrid from '../../components/ag-grid/ag-grid';
import { dataProvider } from '../../DataProvider';
import DeliveryMethodEditPopUp from './component/delivery-method-edit';
import { formatDateTime, formatLocalTime } from "../../components/ex_dayjs";
import { loadSourceType, loadDeliveryMethod, setDefaultValuesFromURL, autoLoad } from '../../utils/common';
import RenderControls from '../../components/ra-list/RenderControls';
import { FilterListProvider } from '../../context/FilterListProvider';
import ApplyVenuesButton from './component/apply-venue-button';
import ApplyVenuesDialog from './component/apply-venue-dialog';

const EventDeliveryList = () => {
    const location = useLocation();
    const refInput = useRef(null);
    const refFilter = useRef(null);
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const [marketType, setMarketType] = useState([]);
    const [deliveryMethod, setDeliveryMethod] = useState([]);
    const [defaultValues, setDefaultValues] = useState({});
    const [defaultValuesAgGrid, setDefaultValuesAgGrid] = useState({
        sortField: { field: 'id', order: 'asc' }, // default sort field       
    })
    const [applyVenues, setApplyVenues] = useState({ open: false, filterList: null });

    let doLoadData = true;
    useEffect(() => {
        if (doLoadData) {
            loadSourceType(dataProvider, (response) => {
                setMarketType(response);
            });
            loadDeliveryMethod(dataProvider, (response) => {
                setDeliveryMethod(response);
            });
            doLoadData = false;
        }
        if (location.search) {
            var newDefaultValue = { ...defaultValues };
            setDefaultValuesFromURL(location.search.replace('SiteId', 'SiteId_eq'), newDefaultValue, controls);
            setDefaultValues(newDefaultValue);
            autoLoad(refFilter);
        }
    }, []);

    React.useEffect(() => {
        if (defaultValues.SiteId)
            handleSearch(defaultValues);
    }, []);

    const defaultColDef = useMemo(() => {
        return {
            sortable: true,
            resizable: true,
            flex: 1,
            minWidth: 50,
            filter: false,
            suppressMovable: true,
            singleClickEdit: true,
        };
    }, []);

    var colDefs = [
        { field: 'type', tooltipField: 'type', headerName: 'Type', maxWidth: 100 },
        { field: 'siteId', tooltipField: 'siteId', headerName: 'Site ID', minWidth: 110, maxWidth: 150 },
        {
            field: 'name', tooltipField: 'name', headerName: 'Event',
            cellRenderer: params => {
                return <a target='_blank' href={`${params.data.url}`} >{params.data.name}</a>
            }
        },
        { field: 'venue', tooltipField: 'venue', headerName: 'Venue' },
        { field: 'isEdited', tooltipField: 'isEdited', headerName: 'Edited', hide: true },
        {
            field: 'dateTime', tooltipField: 'dateTime', headerName: 'Event Date', maxWidth: 150,
            valueFormatter: params => { return formatDateTime(params.value); },
        },
        {
            field: 'finalDeliveryMethod', headerName: 'Delivery Method', editable: true, minWidth: 300,
            valueFormatter: params => { return deliveryMethod.find(i => (i.id !== '') && i.id == params.value).name },
            cellStyle: params => {
                if (params.data.isEdited) return { color: 'orange' };
            },
            cellEditor: DeliveryMethodEditPopUp,
            cellEditorParams: { deliveryMethod: deliveryMethod },
            cellEditorPopup: true,
            cellEditorPopupPosition: 'over',
        },
        {
            field: 'updatedDMBy', tooltipField: 'updatedDMBy', headerName: 'Updated Rule By', maxWidth: 170,
        },
        {
            field: 'updatedDMOn', headerName: 'Updated Rule On', maxWidth: 150,
            valueFormatter: params => { return params.value != null ? formatLocalTime(params.value) : null; },
            tooltipValueGetter: (params) => { return params.value != null ? formatLocalTime(params.value) : null; },
        },
    ]
    const controls = [
        { component: 'select', name: 'type_eq', label: 'Type', choices: marketType },
        { component: 'text', name: 'SiteId_eq', label: 'Site ID', xs: 2 },
        { component: 'text', name: 'name', label: 'Event', xs: 2 },
        { component: 'text', name: 'venue', label: 'Venue', xs: 2 },
        { component: 'daterange', name: 'dateTime', label: 'Event Date' },
        { component: 'select', name: 'finalDeliveryMethod_eq', label: 'Delivery Method', choices: deliveryMethod, xs: 1.5 },
        { component: '', name: 'empty', xs: 1.5 },
        {
            component: 'select', name: 'isEdited_eq', label: 'Edited',
            choices: [
                { id: false, name: 'Not Edited' },
                { id: true, name: 'Edited' }
            ]
        },
        { component: 'number', name: 'preferredDeliveryMethodEditID', label: 'Rule ID', xs: 1 },
        { component: 'text', name: 'updatedDMBy', label: 'Updated Rule By' },
        { component: 'daterange', name: 'updatedDMOn', label: 'Updated Rule On', xs: 2.3 }
    ]

    const handleSearch = (filterList) => {
        refInput.current.customFilterSearch(filterList);
    }

    const openApplyVenues = (filterList) => {
        setApplyVenues({ open: true, filterList: filterList });
    };

    return (
        <div style={containerStyle}>
            <Title title={'Event Delivery'}></Title>
            <FilterListProvider>
                <RenderControls
                    // useHashUrl                  
                    refFilter={refFilter}
                    defaultValues={defaultValues}
                    controls={controls}
                    onSearch={handleSearch}
                    agSource='EventDelivery'
                    agSort={defaultValuesAgGrid.sortField}
                    actions={<ApplyVenuesButton callback={openApplyVenues} />}
                    exportFile={{
                        filename: `EventDelivery-${formatLocalTime(new Date(), 'YY-MM-DD')}`,
                        columns: ['Type', 'SiteId', 'Name', 'Venue', 'DateTime', "IsEdited", 'FinalDeliveryMethodName', 'FinalRule', 'UpdatedDMBy', 'UpdatedDMOn', 'Url'],
                        limit: 1000000
                    }}
                />
            </FilterListProvider>

            <AgGrid
                ref={refInput}
                list={'EventDelivery'}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}
                rowHeight={45}
                rowSelection='single'
                // editType={'fullRow'}
                setDefaultValues={setDefaultValuesAgGrid}
            ></AgGrid>
            {applyVenues.open && <ApplyVenuesDialog applyVenues={applyVenues} setApplyVenues={setApplyVenues} deliveryMethod={deliveryMethod} marketType={marketType} />}
        </div>
    );
};

export default EventDeliveryList;