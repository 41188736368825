import React, { useEffect, useState } from "react";
import { Typography, Stack, Divider, Box, Button, LinearProgress } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import { Confirm, useNotify } from "react-admin";
import { MTypography } from "../../controls/MTypography";
import MDialog from "../../../../../components/controls/MDialog";
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { formatDate, formatDateTime } from "../../../../../components/ex_dayjs";

//# 65657385
const DeliveryDelayPopup = ({ vendor, dataProvider, data, onClose }) => {
    const { order } = data;
    const { d_isOpen, d_data } = data.dlgState;
    const [state, setState] = useState({
        d_title: '',
        d_name: 'd_isOpen',
        d_isLoading: false,
        c_isConfirm: false,
        c_title: ''
    });
    const [sortModel, setSortModel] = React.useState([{ field: 'order_number', sort: 'desc' }]);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [rows, setRows] = useState([]);
    const notify = useNotify();

    useEffect(() => {
        if (d_isOpen && order) {
            setSelectionModel([]);
            setState((prev) => ({ ...prev, d_isLoading: true, d_title: `#${order.order_number} - APPLY THE DELAY DATE` }));
            dataProvider.postData(vendor.controller, `GetSimilarEvents`, order)
                .then((response) => {
                    setState((prev) => ({ ...prev, d_isLoading: false }));
                    if (response && response.data) {
                        setRows(response.data);
                        notify(`Found ${response.data.length} rows`, { type: 'success', autoHideDuration: 1000 });
                    }
                });
        }
    }, [d_isOpen, order]);

    const handleSelectionModelChange = (newSelection) => {
        setSelectionModel(newSelection);
    }

    const handleSortModelChange = (newModel) => {
        setSortModel(newModel);
    };

    const columnDefs = [
        { field: 'order_number', headerName: 'Order', flex: 1 },
        { field: 'event', headerName: 'Event', flex: 1 },
        { field: 'venue', headerName: 'Venue', flex: 2 },
        { field: 'date', headerName: 'Event date', width: 200, valueFormatter: ({ value }) => formatDateTime(value), flex: 2 },
        { field: 'delay_date', headerName: 'Delay date', width: 200, valueFormatter: ({ value }) => formatDateTime(value), flex: 2 }
    ];

    const onCloseConfirm = () => setState((prev) => ({ ...prev, c_isConfirm: false }));

    const handleApply = () => {
        if (selectionModel.length == 0) {
            if (order.delay_date) {
                let title = `This order has a delay date: ${formatDate(order.delay_date)}. Are you sure to apply the change to ${d_data.delay_date}?`;
                setState((prev) => ({ ...prev, c_isConfirm: true, c_title: title }));
            } else onConfirmApply(order.id);
        } else {
            let orders = rows.filter(x => selectionModel.find(y => y == x.id)).map(x => x.order_number);
            let title = `Are you sure to apply delay date ${d_data.delay_date} to order ${order.order_number} and ${orders.toString()} ?`;
            setState((prev) => ({ ...prev, c_isConfirm: true, c_title: title }));
        }
    }

    const onConfirmApply = (orderId) => {
        let postData = [];
        if (orderId) postData.push(orderId);
        else { // For cases confirm
            if (selectionModel.length == 0) {
                postData.push(order.id);
            } else {
                let dataSelected = selectionModel.map((m) => ({ ...m, delay_date: d_data.delay_date }));
                let applySelected = rows.filter(x => dataSelected.find(y => y.id == x.id)).map(m => m.id);
                applySelected.unshift(order.id);
                postData = applySelected;
            }
        }
        onCloseConfirm();
        if (postData.length) applyDelayDate(postData);

        function applyDelayDate(data) {
            dataProvider.postData(vendor.controller, `ApplyDelayDate`, { orders: data, delayDate: formatDate(d_data.delay_date, 'YYYY-MM-DD'), days: d_data.days })
                .then((response) => {
                    setState((prev) => ({ ...prev, d_isLoading: false }));
                    notify(`There are ${response.data} orders updated.`, { type: 'success' });
                    onClose(null, state.d_name);
                    //reload order
                }).catch((error) => {
                    notify(`Apply has error: ${error}`, { type: 'error' });
                });
        }
    }

    return (
        <>
            <MDialog name={state.d_name}
                open={d_isOpen}
                title={state.d_title}
                handleClose={onClose}
                action={<Button startIcon={<PlaylistAddCheckIcon />} onClick={handleApply}>Apply</Button>}
                maxWidth="lg"
                className={'order-dialog'}
            >
                <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                    style={{ paddingTop: 10 }}
                >
                    <MTypography label='Delay Date' bold="bold" date={d_data?.delay_date || order?.delay_date} />
                </Stack>
                <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                >
                    <MTypography label='Event Name' name={order?.event} />
                    <MTypography label='Venue' name={order?.venue} />
                    <MTypography label='Date' datetime={order?.date} />
                </Stack>
                {state.d_isLoading && <LinearProgress color='secondary' />}
                <Typography variant="h4">Similar events</Typography>
                <br />
                <Box sx={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columnDefs}
                        getRowId={(r) => r.id}
                        sortModel={sortModel}
                        autoPageSize={true}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 25,
                                },
                            },
                        }}
                        checkboxSelection
                        rowSelectionModel={selectionModel}
                        onRowSelectionModelChange={handleSelectionModelChange}
                        onSortModelChange={handleSortModelChange}
                    />
                </Box>
            </MDialog>
            <Confirm
                isOpen={state.c_isConfirm}
                title={state.c_title}
                onClose={onCloseConfirm}
                onConfirm={() => onConfirmApply()}
            />
        </>
    )
}

export default React.memo(DeliveryDelayPopup);