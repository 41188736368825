export { default as ticketmaster } from './ticketmaster.ico';
export { default as ticketnetwork } from './ticketnetwork.ico';
export { default as vividseats } from './vividseats.ico';
export { default as paciolan } from './paciolan.ico';
export { default as ticketswest } from './ticketswest.ico';
export { default as etix } from './etix.ico';
export { default as eventbrite } from './eventbrite.ico';
export { default as axs } from './axs.ico';
export { default as tdc } from './tdc.png';
export { default as seatgeek } from './seatgeek.png';
