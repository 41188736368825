import * as React from 'react';
import { Typography, Link } from '@mui/material';
import { formatDateTime } from '../../../components/ex_dayjs';

export const MTypography = ({ label, name, href, datetime }) => {
    return (
        <Typography>
            <label style={{ fontWeight: 'bold' }}>{label + ': '}</label>
            {href && (
                <Link target='_blank' href={href} >{name}</Link>
            )}
            {datetime && (
                <label>{formatDateTime(datetime)}</label>
            )}
            {!href && !datetime && name && (
                <label>{name}</label>
            )}
        </Typography>
    )
}