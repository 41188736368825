import { dayjs } from "../../components/ex_dayjs";
const defaultOperators = [
    { name: 'equal', label: 'equal' },
    { name: 'not_equal', label: 'not equal' },
    { name: 'contains', label: 'contains' },
    { name: 'begins_with', label: 'begins with' },
    { name: 'not_begins_with', label: 'does not begin with' },
    { name: 'ends_with', label: 'ends with' },
    { name: 'not_contains', label: 'does not contain' },
    { name: 'not_ends_with', label: 'does not end with' },
    { name: 'is_null', label: 'is null' },
    { name: 'is_not_null', label: 'is not null' },
    { name: 'in', label: 'in' },
    { name: 'notIn', label: 'not in', query_name: 'not_in' }, // Allow input: 12,34,56,...
    { name: 'less', label: 'less' },
    { name: 'less_or_equal', label: 'less or equal' },
    { name: 'greater', label: 'greater' },
    { name: 'greater_or_equal', label: 'greater or equal' },
    { name: 'between', label: 'between' },
    { name: 'not_between', label: 'not between' },
    { name: 'is_empty', label: 'is empty' },
    { name: 'is_not_empty', label: 'is not empty' },
    { name: 'is_duplicated', label: 'is duplicated' }
]

const OPERATORS = {
    equal: { type: 'equal', nb_inputs: 1, multiple: false, apply_to: ['string', 'number', 'datetime', 'boolean'] },
    not_equal: { type: 'not_equal', nb_inputs: 1, multiple: false, apply_to: ['string', 'number', 'datetime', 'boolean'] },
    in: { type: 'in', nb_inputs: 1, multiple: true, apply_to: ['string', 'number', 'datetime'] },
    not_in: { type: 'not_in', nb_inputs: 1, multiple: true, apply_to: ['string', 'number', 'datetime'] },
    less: { type: 'less', nb_inputs: 1, multiple: false, apply_to: ['number', 'datetime'] },
    less_or_equal: { type: 'less_or_equal', nb_inputs: 1, multiple: false, apply_to: ['number', 'datetime'] },
    greater: { type: 'greater', nb_inputs: 1, multiple: false, apply_to: ['number', 'datetime'] },
    greater_or_equal: { type: 'greater_or_equal', nb_inputs: 1, multiple: false, apply_to: ['number', 'datetime'] },
    between: { type: 'between', nb_inputs: 2, multiple: false, apply_to: ['number', 'datetime'] },
    not_between: { type: 'not_between', nb_inputs: 2, multiple: false, apply_to: ['number', 'datetime'] },
    begins_with: { type: 'begins_with', nb_inputs: 1, multiple: false, apply_to: ['string'] },
    not_begins_with: { type: 'not_begins_with', nb_inputs: 1, multiple: false, apply_to: ['string'] },
    contains: { type: 'contains', nb_inputs: 1, multiple: false, apply_to: ['string'] },
    not_contains: { type: 'not_contains', nb_inputs: 1, multiple: false, apply_to: ['string'] },
    ends_with: { type: 'ends_with', nb_inputs: 1, multiple: false, apply_to: ['string'] },
    not_ends_with: { type: 'not_ends_with', nb_inputs: 1, multiple: false, apply_to: ['string'] },
    is_empty: { type: 'is_empty', nb_inputs: 0, multiple: false, apply_to: ['string'] },
    is_not_empty: { type: 'is_not_empty', nb_inputs: 0, multiple: false, apply_to: ['string'] },
    is_null: { type: 'is_null', nb_inputs: 0, multiple: false, apply_to: ['string', 'number', 'datetime', 'boolean'] },
    is_not_null: { type: 'is_not_null', nb_inputs: 0, multiple: false, apply_to: ['string', 'number', 'datetime', 'boolean'] }
};

const sqlOperators = {
    equal: { op: '= ?' },
    not_equal: { op: '!= ?' },
    in: { op: 'IN(?)', sep: ', ' },
    not_in: { op: 'NOT IN(?)', sep: ', ' },
    less: { op: '< ?' },
    less_or_equal: { op: '<= ?' },
    greater: { op: '> ?' },
    greater_or_equal: { op: '>= ?' },
    between: { op: 'BETWEEN ?', sep: ' AND ' },
    not_between: { op: 'NOT BETWEEN ?', sep: ' AND ' },
    begins_with: { op: 'LIKE(?)', mod: '{0}%' },
    not_begins_with: { op: 'NOT LIKE(?)', mod: '{0}%' },
    contains: { op: 'LIKE(?)', mod: '%{0}%' },
    not_contains: { op: 'NOT LIKE(?)', mod: '%{0}%' },
    ends_with: { op: 'LIKE(?)', mod: '%{0}' },
    not_ends_with: { op: 'NOT LIKE(?)', mod: '%{0}' },
    is_empty: { op: '= \'\'' },
    is_not_empty: { op: '!= \'\'' },
    is_null: { op: 'IS NULL' },
    is_not_null: { op: 'IS NOT NULL' }
}
const defaultExcludeForString = ['less', 'less_or_equal', 'greater', 'greater_or_equal', 'between', 'not_between'];
const defaultExcludeForNumber = ['contains', 'not_contains', 'begins_with', 'not_begins_with', 'ends_with', 'not_ends_with'];
const QUERYFORMATTYPE = {
    Json: 'json_without_ids',
    Sql: 'sql'
}

const nullValueOperator = [
    'is_null',
    'is_not_null',
    'is_empty',
    'is_not_empty',
    'is_duplicated',
]
const sqlStatements = {
    'question_mark': function () {
        var params = [];
        return {
            add: function (rule, value) {
                params.push(value);
                return '?';
            },
            run: function () {
                return params;
            }
        };
    },

    'numbered': function (char) {
        if (!char || char.length > 1) char = '$';
        var index = 0;
        var params = [];
        return {
            add: function (rule, value) {
                params.push(value);
                index++;
                return char + index;
            },
            run: function () {
                return params;
            }
        };
    },

    'named': function (char) {
        if (!char || char.length > 1) char = ':';
        var indexes = {};
        var params = {};
        return {
            add: function (rule, value) {
                if (!indexes[rule.field]) indexes[rule.field] = 1;
                var key = rule.field + '_' + (indexes[rule.field]++);
                params[key] = value;
                return char + key;
            },
            run: function () {
                return params;
            }
        };
    }
};

/**
 * Escape string like mysql_real_escape_string
 * @param value {string}
 * @return {string}
 */
function escapeString(value) {
    if (typeof value != 'string') {
        return value;
    }

    return value
        .replace(/[\0\n\r\b\\\'\"]/g, function (s) {
            switch (s) {
                case '\0': return '\\0';
                case '\n': return '\\n';
                case '\r': return '\\r';
                case '\b': return '\\b';
                default: return '\\' + s;
            }
        })
        // uglify compliant
        .replace(/\t/g, '\\t')
        .replace(/\x1a/g, '\\Z');
};

/**
 * Replaces {0}, {1}, ... in a string
 * @param str {string}
 * @param args,... {mixed}
 * @return {string}
 */
function fmt(str/*, args*/) {
    var args = Array.prototype.slice.call(arguments, 1);
    return str.replace(/{([0-9]+)}/g, function (m, i) {
        return args[parseInt(i)];
    });
};

function getExtractDate(value) {
    let inputDate = new Date(value);
    let days = extractRegex('AddDays');
    let months = extractRegex('AddMonths');
    let years = extractRegex('AddYears');
    let isFormatDate = /\d{4}\-\d{0,2}\-\d{0,2}/.test(value);
    if (isFormatDate && !isNaN(inputDate)) return inputDate;
    if (!isNaN(days) || !isNaN(months) || !isNaN(years)) {
        let currentDate = dayjs();
        if (!isNaN(days)) return currentDate.add(days, 'day');
        if (!isNaN(months)) return currentDate.add(months, 'month');
        if (!isNaN(years)) return currentDate.add(years, 'year');
    }
    return null;

    function extractRegex(text) {
        let regex = new RegExp(`Now.${text}\\(([+-]?[\\d]+)\\)$`);
        let num = parseFloat(value.match(regex)?.[1]);
        return num;
    }
}

export { defaultOperators, sqlOperators, OPERATORS, QUERYFORMATTYPE, defaultExcludeForString, defaultExcludeForNumber, sqlStatements, nullValueOperator, escapeString, fmt, getExtractDate };