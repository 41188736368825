import './query.css';
import { QueryBuilderDnD } from '@react-querybuilder/dnd';
import _ from 'lodash';
import * as ReactDnD from 'react-dnd';
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useImperativeHandle } from 'react';
import QueryBuilder, { formatQuery, transformQuery } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
import { defaultOperators, nullValueOperator } from './querybuilder.config';
import getSQL from './common';
import MValueEditor from './MValueEditor';
import TagSelector from './TagSelector';
import { formatTime, isValidTime } from '../ex_dayjs';
import { defaultValidator } from './validate';


const defaultCombinators = [
    { name: 'AND', label: 'AND' },
    { name: 'OR', label: 'OR' },
];

const getDefaultField = (field) => {
    //https://react-querybuilder.js.org/docs/components/querybuilder#getDefaultField
}
const getDefaultValue = (field) => {
    //https://react-querybuilder.js.org/docs/components/querybuilder#getDefaultValue
}
const getValues = (field, opera) => {
    //https://react-querybuilder.js.org/docs/components/querybuilder#getValues
}
const validator = r => !!r.value || r.value.lenght > 0;
const NullComponent = () => null;

const queryDataParser = (data, defaultOperator) => {
    if (Object.keys(data).length !== 0) {
        initialQuery = {
            combinator: data?.condition || defaultOperator,
            rules: data.rules.map((rule) => {
                let op = defaultOperators.find(x => x.query_name == rule.operator);
                if (op) rule.operator = op.name;
                if ('rules' in rule) {
                    rule = queryDataParser(rule); return rule;
                } return { field: rule.field, name: rule.field, type: rule.type, input: rule.input, operator: rule.operator, value: rule.value }
            }) || [],
        };
    }
    return initialQuery;
}

const MQueryBuilder = React.forwardRef((props, ref) => {
    const { editData, defaultOperator, hideGroupButton, hideNotButton, fields, initialData, combinators, combinatorName, showRemoveCheckbox, useCustomEditor } = props;
    let initialQuery = _.isEmpty(editData) ? initialData : queryDataParser(editData, defaultOperator);
    const validateFields = fields.map(f => ({ ...f, validator: (f.allowEmpty && typeof (f.validator) === 'function') ? f.validator : validator }));
    const getCheckMethod = () => showRemoveCheckbox ? (editData?.method || initialQuery.method || 'remove') : 'modify';
    const checkValue = getCheckMethod();
    const formatCombinator = combinatorName || 'condition';
    const [query, setQuery] = React.useState(initialQuery);
    const [validating, setValidating] = React.useState(false);
    const [removeCheck, setRemoveCheck] = React.useState(checkValue);

    React.useEffect(() => {
        editData && setQuery(initialQuery);
        setRemoveCheck(getCheckMethod());
    }, [])

    const formatQueryValidator = q => {
        const validations = {};
        const addInvalid = id => validations[id] = false;
        const processGroup = g => {
            if (g.rules.length === 0) {
                validations[g.id] = false;
            }
            g.rules.forEach(r => {
                if ('rules' in r) {
                    processGroup(r);
                } else {
                    if (r.validator && !r.validator(r)) {
                        addInvalid(r.id);
                        return;
                    }
                    if (['between', 'not_between'].includes(r.operator)) {
                        if (!r.allowEmpty) {
                            if (!Array.isArray(r.value) || r.value.length <= 1) {
                                addInvalid(r.id);
                                return;
                            }
                            if (r.type === 'integer' && r.value.some(item => isNaN(parseInt(item)))) {
                                addInvalid(r.id);
                                return;
                            }
                            if (r.type === 'date') {
                                if (r.inputType === 'time') {
                                    if (r.value.some(x => !isValidTime(x))) {
                                        addInvalid(r.id);
                                        return;
                                    }
                                } else if (r.inputType === 'date') {
                                    if (r.value.some(item => isNaN(Date.parse(item)))) {
                                        addInvalid(r.id);
                                        return;
                                    }
                                }
                            }
                        }
                    } else {
                        if (((!r.allowEmpty && !nullValueOperator.includes(r.operator)) //if allow empty and operator do not need value ==> uncheck
                            && (!r.value || r.value.length === 0)) //check must have value then check other case
                            || ((r.type === 'integer' && isNaN(parseInt(r.value)) //check must int format
                                || (r.type === 'date' && r.inputType === 'date' && (isNaN(Date.parse(r.value)) && !isValidTime(r.value))))))  //check must date format
                        {
                            validations[r.id] = false;
                            return;
                        }
                    }
                }
            });
        };
        processGroup(q);
        return validations;
    };

    useImperativeHandle(ref, () => ({
        getValues(formatType) {
            if (removeCheck === 'remove') return JSON.stringify({ method: "remove", rules: [] }, null, 2);
            if (query.combinator !== 'modify' || removeCheck === 'modify') {
                const validations = formatQueryValidator(query);
                if (!_.isEmpty(validations)) { setValidating(true); return null };
            }
            const newQuery = transformQuery(query, { ruleProcessor: formatRule, ruleGroupProcessor: formatGroup, propertyMap: { combinator: formatCombinator } });
            const formattedData = formatQuery(newQuery, formatType);
            if (formatType === 'sql') {
                return getSQL(newQuery);
            }
            return formattedData;
        },
        method: removeCheck
    }));

    const formatGroup = (group) => {
        //https://react-querybuilder.js.org/docs/utils/misc#transformquery
        return formatCombinator === 'condition' ? {
            condition: group.condition,
            rules: group.rules
        } : {
            method: group.method,
            rules: group.rules
        }
    }

    const formatRule = (rule) =>
    ({
        //https://react-querybuilder.js.org/docs/utils/misc#transformquery
        id: rule.field,
        field: rule.field,
        type: rule.type,
        input: rule.input,
        operator: rule.operator === 'notIn' ? 'not_in' : rule.operator,
        value: formatValue(rule.type, rule.operator, rule.value, rule.inputType)
    })

    const formatValue = (type, operator, value, editor) => {
        if (nullValueOperator.includes(operator)) return null;

        if (['between', 'not_between'].includes(operator)) {
            return value.map(x => {
                return format(x);
            });
        }
        return format(value);

        function format(input) {
            if (type === 'date') {
                if (editor === 'time') {
                    return formatTime(input, ['hh:mm A', 'HH:mm'], 'HH:mm:ss');
                }
                if (editor === 'date') {
                    const date = new Date(input);
                    return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}-${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;
                }
            }
            //Formatted value as string
            let valueAsString = (Array.isArray(input) ? input.join(',') : input) + '';
            return valueAsString;
        }
    }

    const getOperators = (fieldName) => {
        const field = fields.find(fld => fld.name === fieldName);
        if (!field) return [];
        if (field.excludes && field.excludes?.length > 0) {
            return defaultOperators.filter(x => !field.excludes.includes(x.name));
        }
        return defaultOperators;
    };

    const ruleProcessor = (rule) => ({
        //https://react-querybuilder.js.org/docs/utils/export#rule-processor
        id: rule?.id,
        field: rule?.field || '',
        type: fields.filter(x => x.name === rule.field)[0]?.type || '',
        input: fields.filter(x => x.name === rule.field)[0]?.input || '',
        operator: rule.field !== undefined ? rule.operator : '',
        value: rule?.value || '',
        allowEmpty: fields.filter(x => x.name === rule.field)[0]?.allowEmpty || false,
        inputType: fields.filter(x => x.name === rule.field)[0]?.inputType || '',
        validator: fields.filter(x => x.name === rule.field)[0]?.validator || '',
    });

    const ruleProcessorParser = (rule) => {

        return {
            ...rule,
            rules: rule.rules?.map(r => {
                if ('rules' in r) {
                    r = ruleProcessorParser(r);
                    return r;
                }
                return {
                    //https://react-querybuilder.js.org/docs/utils/export#rule-processor
                    id: r?.id,
                    field: r?.field || '',
                    type: fields.filter(x => x.name === r.field)[0]?.type || '',
                    input: fields.filter(x => x.name === r.field)[0]?.input || '',
                    operator: r?.field !== undefined ? r.operator : '',
                    value: r?.value || '',
                    allowEmpty: fields.filter(x => x.name === r.field)[0]?.allowEmpty || false,
                    inputType: fields.filter(x => x.name === r.field)[0]?.inputType || '',
                    validator: fields.filter(x => x.name === r.field)[0]?.validator || '',
                }
            })
        }
    };
    const getValueEditorType = (_, operator) => {
        //https://react-querybuilder.js.org/docs/components/querybuilder#getvalueeditortype
        switch (operator) {
            case 'is_not_empty':
            case 'is_empty':
            case 'is_null':
            case 'is_not_null':
                return 'radio';
        }
    }



    const controlElements = {
        //https://react-querybuilder.js.org/docs/4/tips/custom-with-fallback
        ...(useCustomEditor ? { valueEditor: MValueEditor } : {}),
        ...(hideGroupButton ? { addGroupAction: NullComponent, combinatorSelector: NullComponent } : {})

    }
    return (
        <>
            {showRemoveCheckbox &&
                <TagSelector removeCheck={removeCheck} setRemoveCheck={setRemoveCheck} />}
            <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
                <div className={validating ? 'validateQuery' : ''}>
                    <QueryBuilder
                        fields={validateFields}
                        query={transformQuery(query, { ruleProcessor })}
                        onQueryChange={(q) => { setQuery(ruleProcessorParser(q)); setValidating(false); }}
                        getOperators={getOperators}
                        validator={defaultValidator}
                        listsAsArrays
                        getValueEditorType={getValueEditorType}
                        controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
                        operators={defaultOperators}
                        showNotToggle={!hideNotButton}
                        disabled={showRemoveCheckbox && (removeCheck === 'remove' ? true : false)}
                        combinators={combinators || defaultCombinators}
                        showCloneButtons
                        // getDefaultField={getDefaultField}
                        // getDefaultValue={getDefaultValue}
                        // getValues={getValues}
                        controlElements={controlElements}
                    />
                </div>
            </QueryBuilderDnD>
        </>
    );
});



MQueryBuilder.defaultProps = {
    defaultOperator: 'AND',
    hideNotButton: false,
    hideGroupButton: false,
    hideLockButtons: false,
    showRemoveCheckbox: false,
    useCustomEditor: false
};

MQueryBuilder.propTypes = {
    defaultOperator: PropTypes.string,
    hideNotButton: PropTypes.bool,
    hideGroupButton: PropTypes.bool,
    hideLockButtons: PropTypes.bool,
    showRemoveCheckbox: PropTypes.bool,
    useCustomEditor: PropTypes.bool
};

export default React.memo(MQueryBuilder);