import * as React from 'react';
import MDialog from '../../../components/controls/MDialog';
import { TextField, Button, Link, LinearProgress, Stack } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { dataProvider } from '../../../DataProvider';
import { useNotify } from 'react-admin';

const StubHubDialog = (props) => {
    const { recordContext, eventData, onClose, source } = props;
    const notify = useNotify();
    const stubhubLink = 'https://www.stubhub.com/secure/search/?q=';
    const [inputValue, setInputValue] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);

    const event = recordContext ? recordContext : eventData;

    const handleClose = () => {
        onClose();
    }
    const handleInput = (ev) => {
        setInputValue(ev.target.value);
    }
    const handleSubmit = () => {
        setIsLoading(true);
        dataProvider
            .postData(source, `GetTicketStatistics`,
                {
                    orderId: recordContext ? recordContext.id : -1,// id = order_id
                    eventIdOrUrl: inputValue,
                    sm_event_id: recordContext ? -1 : event.sm_original_id
                })
            .then((response) => {
                setIsLoading(false);
                if (response.status !== 200 && response.data) {
                    if (response.data < 0)
                        notify(`TicketStatistics has no data for the event`, { type: 'success', autoHideDuration: 3000 });
                    else if (response.data > 0) {
                        notify(`${response.data} + " records from TicketStatistics was added. Refreshing data in the grid...`, { type: 'success', autoHideDuration: 3000 });
                    } else
                        notify(`"TicketStatistics Data for the event is up-to-date, no further action required.
                        `, { type: 'success', autoHideDuration: 3000 });



                };
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(`Error code: ${err.status}, Err: ${err.body}`)
                notify(`Unable to download data from TicketStatistics`, { type: 'error', autoHideDuration: 5000 })
            });
    }
    return (
        <MDialog
            open={true}
            handleClose={handleClose}
            title={'Information'}
            name={'stubhubsearch'}
            action={<Stack spacing={2}><Button variant='contained' startIcon={<SendIcon />} onClick={handleSubmit}>Submit</Button></Stack>}
            children={
                <div>
                    <p>Please enter Stubhub event ID or the whole link of the event:</p>
                    <TextField
                        sx={{ width: '100%' }}
                        variant='outlined'
                        id='stubhub-search-text'
                        value={inputValue}
                        onChange={handleInput}
                        placeholder='eg. 9805820 or https://www.stubhub.com/the-1975-tickets-the-1975-seattle-wamu-theater-4-30-2017/event/9805820/?mbox=1&sort=price+asc'
                    >
                    </TextField>
                    {isLoading && <LinearProgress color='secondary' />}
                    <Link sx={{ color: 'orange' }}
                        href={stubhubLink +
                            (recordContext ? event.event_name.trim() : event.sm_event_name.trim())
                            + ' ' +
                            (recordContext ? event.venue_name.trim() : event.sm_event_venue)}
                        target='_blank'
                    >Search Event: {recordContext ? event.event_name.trim() : event.sm_event_name.trim()} on Stubhub</Link>
                </div>
            }
        />
    )
}
export default StubHubDialog;