import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { dayjs } from '../ex_dayjs';
import MDateControl from './MDateControl';

const defaultProps = {
    format: "MM/DD/YYYY",
    isUTC: false
};

const MDatePicker = ({
    format = defaultProps.format,
    isUTC = defaultProps.isUTC,
    label, value, defaultValue, onChange, ...props
}) => {
    const [mValue, setMValue] = React.useState(null);

    React.useEffect(() => {
        if (value) setMValue(dayjs(value));
    }, [value]);

    React.useEffect(() => {
        if (defaultValue) setMValue(dayjs(defaultValue));
    }, [defaultValue]);

    const handleChange = (newValue) => {
        onChange(newValue);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MDateControl
                {...props}
                label={label}
                defaultValue={mValue}
                format={format}
                isUTC={isUTC}
                onChange={handleChange}
            />
        </LocalizationProvider>
    );
};

export default React.memo(MDatePicker);