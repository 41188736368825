import { useRecordContext } from 'react-admin';
import { DeliveryMethodEnum } from '../../../../utils/enum';

export const DeliveryMethodFormatter = (props) => {
    const record = useRecordContext(props);
    if (!record.delivery_method) {
        return "Unknown";
    } else {
        return DeliveryMethodEnum.find(x => x.id == record.delivery_method)?.name;
    }
}

DeliveryMethodFormatter.defaultProps = { label: 'Delivery method' };