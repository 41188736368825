import * as React from 'react';
import { Grid } from '@mui/material';
import MNumber from './MNumber';

const MNumberRange = (props) => {
    const { name, onChange, defaultValue, rangeMin, rangeMax } = props;
    const [state, setState] = React.useState({ from: defaultValue?.[0], to: defaultValue?.[1] });

    React.useEffect(() => {
        setState({ ...state, from: defaultValue?.[0], to: defaultValue?.[1] });
    }, [defaultValue])

    const handleChange = (value, label, op) => {
        setState((prev) => ({ ...prev, [label]: value }));
        onChange(value, `${name}_${op}`);
    }

    //Remove waring
    const newProps = Object.assign({}, props);
    delete newProps.rangeMin;
    delete newProps.rangeMax;
    //

    return (
        <Grid container>
            <Grid item xs={6}>
                <MNumber {...newProps} defaultValue={state.from} range={rangeMin} onChange={(e) => handleChange(e, 'from', 'ge')} />
            </Grid>
            <Grid item xs={6}>
                <MNumber {...newProps} label="to" defaultValue={state.to} range={rangeMax} onChange={(e) => handleChange(e, 'to', 'le')} />
            </Grid>
        </Grid>
    )
}

export default React.memo(MNumberRange);