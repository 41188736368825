import React, { useState, useRef } from 'react';
import OrderBase from '../order-base';
import { create as createSpecificVendor } from '../order-base/vendor';

const GoTicketsOrder = () => {
    const specificVendor = createSpecificVendor('GoTickets');  
    return (
        <OrderBase     
            vendor={specificVendor}
        >

        </OrderBase>
    )
}
const GoTicketsOrderPage = {
    name: 'GoTickets',
    list: GoTicketsOrder
}
export default GoTicketsOrderPage;