import * as React from 'react';
import { Button, TextField, Stack, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Grid, Divider, FormHelperText, Box } from '@mui/material';
import { Input, ArrowCircleRightOutlined, PreviewOutlined } from '@mui/icons-material';
import MDialog from '../../../components/controls/MDialog';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { dayjs } from '../../../components/ex_dayjs'
import { WeekSelector } from './weekselector';
import { useNotify } from 'react-admin';
import { useFilterList } from '../../../context/FilterListProvider';
import { TeamOptions } from '../../../utils/enum';

export const WorkDataImport = (props) => {
    const { setTwoWeeksSchedule, setLengthOfTime, setHideSavePreview } = props;
    const [filterList] = useFilterList();
    const [open, setOpen] = React.useState(false);
    const notify = useNotify();
    const [importData, setImportData] = React.useState({
        source: dayjs(),
        destFrom: dayjs().add(1, 'month'),
        destTo: dayjs().add(2, 'month')
    });
    const [importType, setImportType] = React.useState('oneone');    
    const [firstMonday, setFirstMonday] = React.useState(null);
    const [mondayRequire, setMondayRequire] = React.useState(false);
    const [invalidRangeImport, setInvalidRangeImport] = React.useState(false);

    const handleOpen = () => {
        if (!filterList.teamId || filterList.teamId == 0) {
            notify('Please select Team in Filter first.', { type: 'error' });
            return;
        }
        setOpen(true);
    }
    const handleClose = () => {
        setMondayRequire(false);
        setImportType('oneone');
        setOpen(false);       
    }

    function validDate(date) {
        let today = dayjs();
        if (date.isBefore(today)) return today;
        else return date;
    }

    const handleFirstMonday = (value) => {
        setFirstMonday(value);
    }
    const handleDestFromChange = (value) => {
        setImportData(prevState => ({ ...prevState, destFrom: validDate(dayjs(value.$d)), destTo: validDate(dayjs(value.$d).add(1, 'month')) }))
    }
    const handleDestToChange = (value) => {
        setImportData(prevState => ({ ...prevState, destTo: validDate(dayjs(value.$d)) }))
    }
    const handleSourceChange = (value) => {
        setImportData(prevState => ({
            ...prevState, source: dayjs(value.$d),
            destFrom: validDate(dayjs(value.$d).add(1, 'month')),
            destTo: validDate(dayjs(value.$d).add(2, 'month')),
        }))
    }
    const handleRadioChange = (param) => {
        let impType = param.target.value;
        setImportType(impType);
    }
    const getWeeksByDate = (firstMonday, mInput, yInput) => {
        if (!firstMonday) {
            setMondayRequire(true);
            return;
        }
        var weekDays = [];
        let startDay = Number(firstMonday.slice(8));
        for (let i = startDay; i <= startDay + 13; i++) {
            weekDays.push({ day: `Day_${i}`, name: dayjs(`${yInput}-${mInput}-${i}`).format('ddd') });
        }
        return { weekDays, month: mInput, year: yInput };
    }
    const handleSubmit = () => {        
        var originDate = dayjs(`${importData.source.$y}-${importData.source.$M + 1}-01`).format('YYYY-MM-DD');
        if (importType == 'oneone') {
            var endDate = dayjs(`${importData.destFrom.$y}-${importData.destFrom.$M + 1}-${dayjs(importData.destFrom.$d).daysInMonth()}`).format('YYYY-MM-DD');
        } else {
            var startDate = dayjs(`${importData.destFrom.$y}-${importData.destFrom.$M + 1}-01`).format('YYYY-MM-DD');
            var endDate = dayjs(`${importData.destTo.$y}-${importData.destTo.$M + 1}-${dayjs(importData.destTo.$d).daysInMonth()}`).format('YYYY-MM-DD');
        }

        if (dayjs(endDate).diff(dayjs(), 'month') > 12) {
            setInvalidRangeImport(true);
            return;
        } else { setInvalidRangeImport(false); }

        var twoWeeksSchedule = getWeeksByDate(firstMonday, importData.source.$M + 1, importData.source.$y);
        twoWeeksSchedule.destFrom = startDate;
        twoWeeksSchedule.destTo = endDate;

        setTwoWeeksSchedule(twoWeeksSchedule);
        setLengthOfTime({
            startDate: originDate,
            endDate: endDate,
            teamId: filterList.teamId,
            isFilter: false
        });
        setHideSavePreview(false);
        if (mondayRequire) {
            setMondayRequire(false);
        }
    }   
    return (
        <>
            <Button variant='text' onClick={handleOpen} startIcon={<Input />}>
                Import Work Schedule
            </Button>
            <MDialog
                maxWidth={'md'}
                open={open}
                handleClose={handleClose}
                title={`Work Schedule Importer (Team: ${TeamOptions.find(x => x.id == filterList?.teamId)?.name})`}
                name={'work-schedule-import'}
                action={<Stack spacing={2}><Button variant='contained' color='info' startIcon={<PreviewOutlined />} onClick={handleSubmit}>Review</Button></Stack>}
                children={
                    <div>                       
                        <FormControl>
                            <FormLabel id="select-import-type">Select import types</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="select-import-type"
                                name="radio-buttons-import-types"
                                defaultValue={'oneone'}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value="oneone" control={<Radio />} label="2 Weeks-To-OneMonth" />
                                <FormControlLabel value="onemany" control={<Radio />} label="2 Weeks-To-Months" />
                            </RadioGroup>
                        </FormControl>
                        <Divider />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid container style={{ height: '100%' }}>
                                <Grid item xs={5}>
                                    <FormControl fullWidth>
                                        <WeekSelector handleFirstMonday={handleFirstMonday} handleSourceChange={handleSourceChange} />
                                        {mondayRequire && <FormHelperText error>Require*</FormHelperText>}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={2}>
                                    <Grid><br /></Grid>
                                    <Grid>
                                        <Divider orientation='vertical'><ArrowCircleRightOutlined color={'primary'} /></Divider>
                                    </Grid>

                                </Grid>
                                <Grid item xs={5}>
                                    <Grid item xs={12}>
                                        <DatePicker
                                            views={['year', 'month']}
                                            label={importType == 'onemany' ? 'from' : ""}
                                            value={importData.destFrom}
                                            onChange={handleDestFromChange}
                                            renderInput={(params) => <TextField {...params} helperText={null} />}
                                            minDate={dayjs()}
                                        />
                                    </Grid>
                                    {importType == 'onemany' && <Grid item xs={12}>
                                        <DatePicker
                                            views={['year', 'month']}
                                            label={"to"}
                                            value={importData.destTo}
                                            onChange={handleDestToChange}
                                            renderInput={(params) => <TextField {...params} helperText={null} />}
                                            minDate={importData.destFrom.add(1, 'month')}
                                        />
                                    </Grid>}
                                    {invalidRangeImport && <FormHelperText style={{ color: '#f44336' }}>Only Import data within next 12 months</FormHelperText>}
                                </Grid>
                            </Grid>
                        </LocalizationProvider>
                    </div>
                }
            />
        </>

    )
}