import React, { useEffect, useState, useRef } from "react";
import { formatLocalTime, dayjs, toLocalTime } from "../../components/ex_dayjs";
import { Datagrid, FunctionField, TextField } from "react-admin";
import RenderControls from "../../components/ra-list/RenderControls";
import RaList from "../../components/ra-list/RaList";
import { autoLoad, getRaStore, loadCountryTeam, loadExchanges, setRaStore } from "../../utils/common";
import { dataProvider } from "../../DataProvider";
import ChartView from "./component/chart-view";
import { FilterListProvider } from "../../context/FilterListProvider";
import ChartButton from "./component/chart-button";
import { errorLevel } from "./common";
import StatusField from "./component/status-field";
import ReceiverField from "./component/receiver-field";

const OrderMistakeReport = () => {
    const refbtnFilter = useRef(null);
    const [defaultSort] = useState(['reported_date desc']);
    const [defaultValueFilter] = useState({
        reported_date: [dayjs().startOf('M'), dayjs()]
    });
    const [exchange, setExchange] = useState([]);
    const [chart, setChart] = useState({ open: false, type: 'bar' });
    const [isAdmin, setIsAdmin] = useState(false);
    const [cTeam, setCTeam] = useState([]);
    const [listUser, setListUser] = useState([]);
    const [forRender, setForRender] = useState(null);//trigger re-render row, row must be FunctionField (it has render feature)
    const [rowInfo, setRowInfo] = useState(null);

    useEffect(() => {
        let _countryTeam = getRaStore('countryTeam');
        if (_countryTeam == null) {
            loadCountryTeam(dataProvider, (response) => {
                let arr = response.map(x => ({ id: x.id, name: x.name.replace(' ', '') }));
                setCTeam(arr);
                setRaStore('countryTeam', arr)
            });
        } else { setCTeam(_countryTeam); }

        loadExchanges(dataProvider, (response) => {
            setExchange(response.filter(obj => [1, 2, 13].includes(obj.id)));
        })

        dataProvider.fetchData('OrderMistakeReport', 'IsAdmin').then(resonse => {
            if (resonse.data) {
                setIsAdmin(true);
            }
        }).catch(_ => _);

        dataProvider
            .fetchData('OrderMistakeReport', `GetUsers`)
            .then((response) => {
                if (response.data) {
                    let newData = response.data.map(x => ({ id: x.email, name: x.email, nick: x.aliasName }));
                    setListUser(newData);
                }
            })
            .catch((error) => console.log(error));

        autoLoad(refbtnFilter);
    }, []);

    const controlsFilter = [
        { component: 'select', name: 'teamId_eq', label: 'Team', choices: cTeam || [] },
        { component: 'text', name: 'receiver_contains', label: 'User' },
        { component: 'daterange', name: 'reported_date', label: 'Date' },
        { component: 'select', name: 'mistake_type_eq', label: 'Mistake Type', choices: [{ id: 'Processing', name: 'Processing' }, { id: 'Shipping', name: 'Shipping' }] },
        { component: 'select', name: 'exchange_id_eq', label: 'Exchange', choices: exchange },
        { component: 'number', name: 'order_number_eq', label: 'Order Number' },
        { component: 'select', name: 'error_level_eq', label: 'Error Level', isEmptyOption: false, choices: errorLevel },
    ]

    const openChartView = (filterList) => {
        setChart(prev => ({ ...prev, open: true, filterList: filterList }));
    };

    return <>
        <RaList
            sortby={defaultSort}
            perPage={25}
            title={'Order Mistaken Report'}
            filters={
                <FilterListProvider>
                    <RenderControls
                        refFilter={refbtnFilter}
                        controls={controlsFilter}
                        defaultValues={defaultValueFilter}
                        actions={<ChartButton callback={openChartView} />}
                        exportFile={{
                            filename: `OrderMistakeReport-${formatLocalTime(new Date(), 'YY-MM-DD')}`,
                            columns: ['reported_by', 'reported_date', 'receiver', 'occured_date', 'order_number', 'exchange', 'mistake_type', 'note', 'error_level_name', 'modified', 'modified_by'],
                            limit: 1000000
                        }}
                    />
                </FilterListProvider>
            }
        >
            <Datagrid
                bulkActionButtons={false}
                rowClick={(id, resource, record) => {
                    setRowInfo({ rowId: id });
                }}
            >
                <TextField source="reported_by" label={'Reported By'} />
                <FunctionField source="reported_date" label={'Reported Date'} render={record =>
                    <><span>{toLocalTime(record.reported_date).format('MM/DD/YYYY')}</span><br /><span>{toLocalTime(record.reported_date).format('hh:mm A')}</span></>
                } />
                {isAdmin && <ReceiverField label='User' listUser={listUser} setForRender={setForRender} rowInfo={rowInfo}/>
                    || <TextField source="receiver" label={'User'} />}
                <FunctionField source="occured_date" label={'Occured Date'} render={record =>
                    <><span>{toLocalTime(record.occured_date).format('MM/DD/YYYY')}</span><br /><span>{toLocalTime(record.occured_date).format('hh:mm A')}</span></>
                } />
                <TextField source="order_number" label={'Order Number'} />
                <TextField source="exchange" label={'Exchange'} />
                <TextField source="mistake_type" label={'Mistake Type'} />
                <TextField source="note" label={'Issue'} />
                {isAdmin && <StatusField label='Error Level' isAdmin={isAdmin} setForRender={setForRender} />
                    || <FunctionField source="error_level" label={'Error Level'} render={record => errorLevel.find(x => x.id == record.error_level)?.name} />}
                <FunctionField source="modified" label={'Modified'} render={record => record.modified ?
                    <><span>{toLocalTime(record.modified).format('MM/DD/YYYY')}</span><br /><span>{toLocalTime(record.modified).format('hh:mm A')}</span></>
                    : ""
                } />
                <FunctionField source="modified_by" label={'Modified By'} render={record => record.modified_by} />
            </Datagrid>
            {chart.open && <ChartView chart={chart} setChart={setChart} />}
        </RaList >
    </>
}
const OrderMistakeReportPage = {
    name: 'OrderMistakeReport',
    list: OrderMistakeReport
}
export default OrderMistakeReportPage;