import React, { useState } from "react";
import { Chip, LinearProgress, Divider, Box, Button, TextField, Badge } from "@mui/material";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { useRecordContext, useNotify } from "react-admin";
import MDialog from "../../../components/controls/MDialog";
import { DataGrid } from "@mui/x-data-grid";
import { toLocalTime } from "../../../components/ex_dayjs";
import { styled } from "@mui/system";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -1,
      top: 9,  
      padding: '0 4px',
    },
  }));
const CommentField = (props) => {
    const { dataProvider } = props;
    const notify = useNotify();
    const record = useRecordContext();
    const [open, setOpen] = useState(false);
    const [comments, setComments] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [allowAddComment, setAllowAddComment] = useState(true);
    const openCommentBox = (ev) => {
        setOpen(true);//open dialog
        setIsLoading(true);
        dataProvider.fetchData('SingleEventAnalysis', `OrderComment/?eventId=${record.event_id}`)
            .then(rs => {
                setIsLoading(false);
                if (rs.data) {
                    let insertedComments = JSON.parse(rs.data)
                    setComments(insertedComments);
                    if (insertedComments.length > 0) {
                        setAllowAddComment(false);
                    }
                }
            })
            .catch(error => {
                notify(`Load comment failed: Status ${error.status}, Error ${error}`, { type: 'error', autoHideDuration: 2000 })
            })
    }
    const addComment = (ev) => {
        ev.preventDefault();
        setIsLoading(true);

        dataProvider.postData('SingleEventAnalysis', 'AddComment', { event_id: record.event_id, comment: inputValue })
            .then(rs => {
                setIsLoading(false);
                if (rs.data) {
                    setComments((prev) => [...prev, JSON.parse(rs.data)])
                    setAllowAddComment(false);
                    notify('Add comment successfully', { type: 'success', autoHideDuration: 2000 });
                }
            })
            .catch(error => {
                setIsLoading(false);
                notify(`Add comment failed: Status ${error.status}, Error ${error}`, { type: 'error', autoHideDuration: 3000 })
            })
    }
    const handleClose = (ev) => {
        setOpen(false);
    }
    const handleInput = (ev) => {
        setInputValue(ev.target.value);
    }
    const columnDefs = [
        { field: 'event_id', headerName: 'Event ID', width: 100 },
        { field: 'comment', headerName: 'Comment', width: 400 },
        { field: 'posted_on', headerName: 'Posted On', width: 200, valueFormatter: ({ value }) => toLocalTime(value).format('MMM DD, YYYY hh:mm A') },
        { field: 'post_by', headerName: 'By', width: 200 },
    ];
    return record ? (
        <div>
            <StyledBadge badgeContent={record.cmt_cnt} color='warning' showZero>
                <Chip label={record.event_id} onClick={openCommentBox} />
            </StyledBadge>
            <MDialog
                maxWidth='lg'
                open={open}
                handleClose={handleClose}
                title={record.event_id + ' - ' + record.event_name}
                name={'ticketnetwork-search'}

                children={
                    <div>                       
                        <p>Venue: <b>{record.venue_name}</b></p>
                        <p>Date: <b>{record.event_date}</b></p>
                        <Divider>COMMENTS</Divider>
                        {allowAddComment && <form onSubmit={addComment}>
                            <TextField
                                sx={{ width: '60%' }}
                                variant='standard'
                                id='user-comment'
                                label='Your comment or note about this item ...'
                                value={inputValue}
                                onChange={handleInput}
                                required
                            >
                            </TextField>
                            <Button variant="contained" size="small" sx={{ marginLeft: '5px', marginTop: '20px' }} startIcon={<ChatBubbleOutlineIcon />} type='submit'>Add comment</Button>
                        </form>}
                        {isLoading && <LinearProgress color="secondary" />}

                        <Box sx={{ height: 300, width: '100%' }}>

                            {isLoading && <LinearProgress color='secondary' />}
                            <DataGrid
                                rows={comments}
                                columns={columnDefs}
                                getRowId={(r) => r.event_id}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 25,
                                        },
                                    },
                                }}
                            />
                        </Box>
                    </div>
                }
            />

        </div>
    ) : null;
}
export default React.memo(CommentField)