import React from 'react';
import { useRecordContext } from 'react-admin';
import { Badge, Stack } from '@mui/material';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
const EventIdField = () => {
    const record = useRecordContext();
    if (!record) return null;
    return (
        <Stack direction="row">
            {(record.posnext_count > 0 || record.ttsListed) && 
            <WarningAmberRoundedIcon sx={{fontSize:20}} color={record.posnext_count > 0 ? 'warning' : record.ttsListed ? 'success' : 'default'} />}
            <a target='_blank' href={`${location.origin}/SingleEventAnalysis/?event_id=${record.id}`} style={{ color: 'orange' }}>
                {record['id']}
            </a>
        </Stack>
    )
}
export default EventIdField