import * as React from 'react';
import { createContext, useContext, useState } from 'react';

const FilterListContext = createContext();

export const FilterListProvider = ({ children }) => {
    const [filterList, setFilterList] = useState({ _toggleFilter: false });

    return (
        <FilterListContext.Provider value={[filterList, setFilterList]}>
            {children}
        </FilterListContext.Provider>
    )
}

export const useFilterList = () => useContext(FilterListContext);