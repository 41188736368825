import React, { useState } from 'react';
import { Button } from '@mui/material';
import { tnMode } from '../common';
const LastMinuteButton = (props) => {
    const { setInfo, info, setDefaultValues } = props;

    const handleStatus = (ev) => {
        setInfo((prev) => ({
            ...prev, mode: info.mode == tnMode.lastminute ? tnMode.normal : tnMode.lastminute,
            title: info.mode == tnMode.lastminute ? 'TN Sales Analysis' : 'TN Sales Analysis (LM)'
        }));  
        setDefaultValues((prev) => ({ ...prev, user_mode_eq: info.mode == tnMode.lastminute ? tnMode.normal : tnMode.lastminute }));          
    }
    return (
        <Button onClick={handleStatus}
            color={info.mode == tnMode.lastminute ? 'success' : 'info'}
            variant={info.mode == tnMode.lastminute ? 'contained' : 'outlined'}
        >Last-Minute</Button>
    )
}
export default LastMinuteButton