import React, { useState } from 'react';
import RaList from '../../components/ra-list/RaList';
import RenderControls from '../../components/ra-list/RenderControls';
import { Datagrid, TextField, FunctionField, NumberField, useDataProvider } from 'react-admin';
import { dayjs, formatDateTime, formatLocalTime } from '../../components/ex_dayjs';
import EventIdField from './components/eventid-field';
import StatusField from './components/status-field';

const MinuteByMinute = () => {

    const dataProvider = useDataProvider();
    const [defaultSort, setDefaultSort] = useState(['threeHour desc']);
    const [defaultFilter, setDefaultFilter] = useState({
        remove_sports_eq: true,
        days_in_futures_eq: 30
    });
    const [lastUpdate, setLastUpdate] = useState(undefined);

    React.useEffect(() => {
        dataProvider.fetchData('MinuteByMinute', 'LastUpdate').then((response) => {
            if (response.status !== 200 && response.data) {
                setLastUpdate(dayjs.utc(response.data).fromNow());
            }
        }).catch((error) => console.log(error));
    }, [])

    const controls = [
        { component: 'text', name: 'event_name_eq', label: 'Event Name', xs: 2 },
        { component: 'text', name: 'venue_name_eq', label: 'Venue Name', xs: 2 },
        { component: 'number', name: 'days_in_futures_eq', label: 'Days in Future' },
        { component: 'select', name: 'status', label: 'Status', choices: status },
        { component: 'checkbox', name: 'remove_sports_eq', label: 'Exclude Sports' },
        { component: 'checkbox', name: 'category_tickets_only_eq', label: 'Category Tickets Only' },
        { component: 'checkbox', name: 'exclude_supplemental_eq', label: 'Exclude supplemental' },
        { component: 'checkbox', name: 'show_more_time_periods_eq', label: 'Show more time period' },

    ]

    return (
        <>
            <RaList
                sortby={defaultSort}
                perPage={25}
                title={'Minute By Minute'}
                filters={
                    <RenderControls controls={controls} defaultValues={defaultFilter}
                        exportFile={{
                            filename: `MinuteByMinute-${formatLocalTime(new Date(), 'YY-MM-DD')}`,
                            columns: ['id','event_name', 'venue_name', 'event_date', 'ThirtyMin', 'OneHour','ThreeHour', 'SixHour', 'TwelveHour','TotalSales', 'StatusName'],
                            limit: 1000000
                        }}
                    />
                }
            >
                {lastUpdate && <p>Last updated {lastUpdate}</p>}
                <Datagrid bulkActionButtons={false}>
                    <EventIdField source="id" label={'Event ID'} />
                    <TextField source="event_name" label={'Event'} />
                    <TextField source="venue_name" label={'Venue'} />
                    <FunctionField label='EventDate' source='event_date' render={record => (formatDateTime(record.event_date))} />
                    <NumberField source="thirtyMin" label={'ThirtyMin'} />
                    <NumberField source="oneHour" label='OneHour' />
                    <NumberField source="threeHour" label='ThreeHour' />
                    <NumberField source="sixHour" label='SixHour' />
                    <NumberField source="twelveHour" label='TwelveHour' />
                    <NumberField source="totalSales" label='Total Sales' />
                    <StatusField source="status" label='Status' />
                </Datagrid>
            </RaList >
        </>
    );

}
const status = [
    { id: 0, name: "Not specified" },
    { id: 1, name: "Purchased" },
    { id: 2, name: "Sold Out" },
    { id: 3, name: "Cheap" },
    { id: 4, name: "Check Back" },
    { id: 5, name: "Blacklist Venue" },
    { id: 6, name: "Not On Sale" }
]
export default MinuteByMinute;