import React from "react";
import { Datagrid, FunctionField, TextField } from "react-admin";
import { formatDateTime } from "../../components/ex_dayjs";
import RaList from "../../components/ra-list/RaList";
import RenderControls from "../../components/ra-list/RenderControls";
import UrlFormatter from "../../components/ra-list/formatters/UrlFormatter";
import './style.css';

const colsCss = {
    ticket_link: 'ticket-search-col-ticket_link',
};

const TicketSearch = () => {
    const [sortModel] = React.useState(["order_date desc"]);

    return (
        <RaList
            title={"Search Ticket Purchase"}
            perPage={200}
            pagination={[200, 250, 500, 1000]}
            sortby={sortModel}
            filters={
                <RenderControls
                    controls={[
                        { component: 'text', name: 'orderNumber_contains', label: 'Order Number' },
                        { component: 'text', name: 'primary_market_abbrev_contains', label: 'Type' },
                        { component: 'text', name: 'mailUser_contains', label: 'Email' },
                        { component: 'text', name: 'purchased_by_contains', label: 'Purchased By' }
                    ]}
                    exportFile={{
                        filename: `TicketSearch-${formatDateTime(new Date(), 'YY-MM-DD')}.csv`,
                        columns: ['OrderNumber', 'primary_market_abbrev', 'MailUser', 'ticket_link', 'TixPrice', 'NumTicket', 'order_date', 'purchased_by']
                    }}
                />
            }
        >
            <Datagrid
                optimized
                bulkActionButtons={false}
            >
                <TextField source="orderNumber" label="Order Number" />
                <TextField source="primary_market_abbrev" label="Type" />
                <TextField source="mailUser" label="Email" />
                <FunctionField source="ticket_link" label="Ticket"
                    render={(record) => <UrlFormatter record={record} href='ticket_link' source='ticket_link' />}
                    cellClassName={colsCss.ticket_link} />
                <TextField source="tixPrice" label="Total" />
                <TextField source="numTicket" label="Quantity" />
                <FunctionField source="order_date" label="Order Date" render={record => formatDateTime(record.order_date)} />
                <TextField source="purchased_by" label="Purchased By" />
            </Datagrid>
        </RaList>
    );
};

const TicketSearchPage = {
    name: 'SearchTicketReceipt',
    list: TicketSearch
};
export default TicketSearchPage;