import * as React from 'react';
import { RaPagination } from "../../components/ra-list/RaList";
import {
    TopToolbar,
    FilterButton,
    CreateButton,
    List, Datagrid, useListContext,
    TextField, NumberField,
    TextInput, NumberInput, SelectField, FunctionField, SelectInput, useListParams,
    useLoading
} from 'react-admin';

import { formatLocalTime } from "../../components/ex_dayjs";
import PostForm from "./Form";
import { dataProvider } from '../../DataProvider';
import { TeamOptions, UserStatus } from '../../utils/enum';
import RaExporter from '../../components/ra-list/RaExporter';

const objFilter = {
    Team: TeamOptions,
    Status: UserStatus
};

const FormView = () => <PostForm objFilter={objFilter}></PostForm>;

const ListActions = () => {
    const { resource, filter, sort } = useListContext();
    return (
        <TopToolbar>
            <FilterButton />
            <CreateButton />
            <RaExporter resource={resource}
                exportFile={{
                    filename: `User-${formatLocalTime(new Date(), 'YY-MM-DD')}`,
                    columns: ['Email', 'AliasName', 'LastName', 'Indicator', 'TeamId', 'Status', 'Modified', 'ModifiedBy'],
                    limit: 1000000
                }}
                params={{ filter, sort }} />
        </TopToolbar>
    );
};
const filters = [
    <TextInput source="email" label="Email" alwaysOn />,
    <TextInput label="Alias Name" source="AliasName" alwaysOn />,
    <NumberInput label="Indicator" source="Indicator" alwaysOn />,
    <SelectInput label="Team" source="teamId" choices={objFilter.Team} translateChoice={false} alwaysOn />,
];
const UserList = () => {
    const { isLoading } = useLoading();
    const [postFilters, setPostFilters] = React.useState(filters);
    const [userTeam, setUserTeam] = React.useState(0);
    const [isGetTeam, setIsGetTeam] = React.useState(true);
    let isDisable = false;
    React.useEffect(() => {
        dataProvider.fetchData('User', 'GetTeam')
            .then((response) => {
                if (response) {
                    isDisable = response.data !== 0;
                    setUserTeam(response.data);
                    setIsGetTeam(false);
                    setPostFilters(prev => {
                        return prev.map(filter => {
                            if (filter.props.source === "teamId") {
                                return (
                                    <SelectInput alwaysOn label="Team" source="teamId" choices={objFilter.Team} translateChoice={false} disabled={isDisable} />
                                );
                            }
                            return filter;
                        });
                    });
                }
            }).catch(err => {
                console.log(`Error:`, err);
            });

    }, []);
    if (isGetTeam && !isLoading) return;
    return (
        <List title="Users"
            storeKey={false}
            disableSyncWithLocation
            perPage={25}
            exporter={false}
            pagination={<RaPagination />}
            sort={{ field: 'order', order: 'ACS' }}
            filters={postFilters}
            filterDefaultValues={userTeam !== 0 ? { teamId: userTeam } : undefined}
            actions={<ListActions />}>
            <Datagrid rowClick="edit" bulkActionButtons={false}>
                <TextField label="Email" source="email" />
                <TextField label="Alias Name" source="aliasName" />
                <FunctionField source="lastName" label="Name" render={record => `${record.firstName} ${record.lastName}`} />
                <NumberField label="Indicator" source="indicator" />
                <NumberField label="Order" source="order" />
                {/* <FunctionField label="Created" source="created"  render={record => formatDateTime(record.created)}/> */}
                <SelectField label="Team" source="teamId" choices={objFilter.Team} translateChoice={false} />
                <SelectField label="Status" source="status" choices={objFilter.Status} translateChoice={false} />
                <FunctionField label="Modified" source="modified" render={record => formatLocalTime(record.modified)} />
                <TextField label="Modified By" source="modifiedBy" />
            </Datagrid>
        </List>
    );
};

const UserPage = {
    name: 'User',
    list: UserList,
    edit: FormView,
    create: FormView,
};

export default UserPage;