import * as React from 'react';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { dayjs } from '../ex_dayjs';
import PropTypes from 'prop-types';

const defaultProps = {
    isUTC: false,
    isEndOfDate: false,
    format: "MM/DD/YYYY"
};

export function formattedDate(date, isUTC = defaultProps.isUTC, isTime = false, isEndOfDate = defaultProps.isEndOfDate) {
    if (!date) return null;
    const FormatDate = "YYYY-MM-DD";
    const FormatUTC = "YYYY-MM-DDTHH:mm:ss[Z]";

    let _dateTime = dayjs(date);
    let _dateVal = dayjs(_dateTime.format(FormatDate));
    if (isUTC) {
        if (isTime) return _dateTime.utc().format(FormatUTC);
        else if (isEndOfDate) return _dateVal.utc().add(1, 'day').add(-1, 'second').format(FormatUTC); // end of day
        else return _dateVal.utc().format(FormatUTC);
    } else {
        if (isTime) return _dateTime.format(FormatUTC);
        else if (isEndOfDate) return _dateVal.add(1, 'day').add(-1, 'second').format(FormatUTC); // end of day
        else return _dateVal.format(FormatUTC);
    }
}

const MDateControl = ({
    format = defaultProps.format,
    isUTC = defaultProps.isUTC,
    isEndOfDate = defaultProps.isEndOfDate,
    label, value, defaultValue, onChange, ...props
}) => {
    const [mValue, setMValue] = React.useState(null);
    const isTime = format.includes(':');

    React.useEffect(() => {
        if (value) setMValue(dayjs(value));
    }, [value]);

    React.useEffect(() => {
        if (defaultValue) setMValue(dayjs(defaultValue));
    }, [defaultValue]);

    const handleChange = (newValue) => {
        setMValue(newValue);
        onChange(isNaN(newValue?.$d) ? null : formattedDate(newValue, isUTC, isTime, isEndOfDate));
    };

    return isTime ?
        <DateTimePicker           
            {...props} 
            label={label}
            value={mValue}
            format={format}
            onChange={handleChange}
            slotProps={{
                clearButton: { size: 'small' },
                clearIcon: { style: { fontSize: "9px" } },
                openPickerButton: { style: { padding: 0, size: '9px' } },
                field: { clearable: true },
            }}
        /> :
        <DatePicker
            {...props}
            label={label}
            value={mValue}
            format={format}
            onChange={handleChange}
            slotProps={{
                clearButton: { size: 'small' },
                clearIcon: { style: { fontSize: "9px" } },
                openPickerButton: { style: { padding: 0, size: '9px' } },
                field: { clearable: true },
            }}
        />;
};

MDateControl.prototype = {
    label: PropTypes.string,
    name: PropTypes.string,
    defaultValue: PropTypes.any,
    onChange: PropTypes.func,
    isUTC: PropTypes.bool,
    isEndOfDate: PropTypes.bool
};

export default React.memo(MDateControl);