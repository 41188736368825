import * as React from 'react';
import { useEffect, useState, } from 'react';
import { useRecordContext, useDataProvider } from 'ra-core';
import { base64ArrayBuffer, renderHtml } from './helper'
import { Button, Stack, Typography, Divider } from '@mui/material';
import { FileDownload } from '@mui/icons-material';
import { PdfSvgIcon } from './PdfIcon';
import { MessageViewer } from './MessageViewer';

export const EmailBodyView = () => {
    const record = useRecordContext();
    const [html, setHtml] = useState(null);
    const [attachments, setAttachments] = useState([]);
    var dataProvider = useDataProvider();
    useEffect(() => {
        if (record) {
            // call the function
            dataProvider.fetchR2(record.meta.r2_parsed)
                .then((response) => {
                    if (response.status !== 200) {
                        console.error(response);
                        console.error("Unable to fetch mail data from R2");
                        return;
                    }
                    setHtml(response.json.html);
                    setAttachments(response.json.attachments);
                })
                .catch(console.error);
        }
    }, [record]);
    if (!record)
        return <span>Loading</span>;
    return <>
        {attachments.length > 0 &&
            <Stack direction="row" spacing={2}>
                <Typography variant="caption">Attachments (ctrl+click to preview)</Typography>
                {attachments.map(attachment =>
                    <Button
                        key={attachment.filename}
                        startIcon={<PdfSvgIcon />}
                        endIcon={<FileDownload />}
                        size="medium"
                        variant="outlined"
                        href={`data:application/pdf;base64,${base64ArrayBuffer(attachment.content.data)}`}
                        download={attachment.filename}>
                        {attachment.filename}
                    </Button>
                )}
            </Stack>
        }
        <Divider />
        <MessageViewer html={html || renderHtml(record.bodyText)} />
    </>
};