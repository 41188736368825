import React, { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import { Pagination, Stack } from '@mui/material';
import { dataProvider } from '../../DataProvider';

const PAGE_SIZE = 200;
const PagingComponent = ({ pagingState, paramsAdminState, setPaging, setParamsAdmin }) => {
    const myStyle = { fontSize: '0.9rem' }
    const onPageSizeChanged = (event) => {
        setPaging({ ...pagingState, page: 1, pageSize: Number(event.target.value) });
        setParamsAdmin({
            ...paramsAdminState,
            pagination: { page: 1, perPage: Number(event.target.value) }
        });
    };

    const onPageChange = (e, pIndex) => {
        setPaging({ ...pagingState, page: pIndex });
        setParamsAdmin({
            ...paramsAdminState,
            pagination: { page: pIndex, perPage: pagingState.pageSize }
        });
    };
    function itemDisplay(pagingState) {
        let min = 1, max = pagingState.pageSize * pagingState.page;
        if (pagingState.pageSize <= pagingState.total) {
            min = min + (max - pagingState.pageSize);
        }
        if (max >= pagingState.total) { max = pagingState.total; }
        if (pagingState.total == 0) { return ''; }
        return min + '-' + max + ' of ' + pagingState.total.toLocaleString();
    }

    return (
        <div>
            <Stack direction='row' spacing={2} justifyContent='right' alignItems='baseline'>
                <div className="page-size" style={myStyle}>
                    Rows per page: <span> </span>
                    <select id="select-page-size" defaultValue={PAGE_SIZE} onChange={onPageSizeChanged} >
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="300">300</option>
                        <option value="1000">1000</option>
                    </select>
                </div>
                <p style={myStyle}>{itemDisplay(pagingState)}</p>
                <Pagination count={pagingState.pageCount} page={pagingState.page} onChange={onPageChange} />
            </Stack>
        </div>
    );
}

const AgGridPaging = (props) => {

    const { dataApi, setDataApi } = props;
    const notify = useNotify();
    const [paging, setPaging] = useState({ page: 1, pageCount: 1, pageSize: PAGE_SIZE, total: 0 })
    const [paramsAdmin, setParamsAdmin] = useState({
        pagination: { page: 1, perPage: paging.pageSize },
        sort: { field: dataApi.sortField.field, order: dataApi.sortField.order },
        filter: {},
        meta: undefined,
    });

    //Load data here
    let doLoadData = true;
    useEffect(() => {
        if (doLoadData && dataApi.isSecondLoad) {
            notify(`Loading records ...`, { type: 'warning', autoHideDuration: 120000 });
            dataProvider.getList(props.list, paramsAdmin)
                .then((result) => {
                    setDataApi({ ...dataApi, rowData: result.data, rowCount: result.total, isLoading: false });
                    setPaging({ ...paging, pageCount: Math.ceil(result.total / paging.pageSize), total: result.total });
                    notify(`Found ${result.total.toLocaleString()} records`, { type: 'success', autoHideDuration: 2000 });
                })
                .catch(err => {
                    setDataApi({ ...dataApi, isLoading: false });
                    if(err.status === 401){
                        notify(`Your session is expired. Press F5 to re-login.`, { type: 'error', autoHideDuration: 3000 });
                    }else{
                        notify(err.message ? err.message : `Server communication failed.`, { type: 'error', autoHideDuration: 3000 });
                    }
                })
            doLoadData = false;
        }
    }, [paramsAdmin]);
    //column sort event
    useEffect(() => {
        if (paramsAdmin.sort.field !== dataApi.sortField.field || paramsAdmin.sort.order.toLowerCase() !== dataApi.sortField.order.toLowerCase()) {
            setParamsAdmin({ ...paramsAdmin, sort: dataApi.sortField });
            doLoadData = true;
        }
    }, [dataApi.sortField]);
    //search event
    useEffect(() => {
        if (dataApi.isSearch) {
            setParamsAdmin({
                ...paramsAdmin,
                filter: dataApi.filter,
                pagination: { page: 1, perPage: paging.pageSize }
            })
            setPaging({ ...paging, page: 1 });
            setDataApi({ ...dataApi, isSearch: false, isSecondLoad: true, isLoading: true })
            doLoadData = true;
        }
    }, [dataApi.isSearch]);

    return (dataApi.rowData != null &&
        <PagingComponent
            pagingState={paging}
            paramsAdminState={paramsAdmin}
            setPaging={setPaging}
            setParamsAdmin={setParamsAdmin}
        />
    );
}
export default AgGridPaging