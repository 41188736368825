const REMOTE_API = 'remoteApi/csv/merge';

function objectToFormData(obj, formData = new FormData(), parentKey = '') {
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const value = obj[key];
            const fullKey = parentKey ? `${parentKey}[${key}]` : key;

            if (value && typeof value === 'object' && !(value instanceof File)) {
                objectToFormData(value, formData, fullKey);
            } else {
                formData.append(fullKey, value);
            }
        }
    }

    return formData;
}

function getDataUpstream(url, formattedUrl) {
    var urlData = url.split('?');
    var parameters = urlData[1] || '';
    var upstreams = url.match(/(?:upstream=)(.[\w.*,]*)/)?.[1];
    var market = (formattedUrl || url).match(/(?:market=)(\w+)/)?.[1];
    var autopricer = (formattedUrl || url).match(/(?:autopricer=)(\w+)/)?.[1];
    if (parameters) {
        if (upstreams) {
            parameters = parameters.replace(`upstream=${upstreams}`, '');
        }
        if (market) {
            parameters = parameters.replace(`&market=${market}`, '').replace(`market=${market}`, '');
        }
        if (autopricer) {
            parameters = parameters.replace(`&autopricer=${autopricer}`, '').replace(`autopricer=${autopricer}`, '');
        }
        if (parameters[0] == '&') {
            parameters = parameters.slice(1, parameters.length);
        }
    }
    return {
        BaseUrl: urlData[0],
        Upstream: upstreams,
        Market: market,
        AutoPricer: autopricer == true,
        Anothers: parameters || ''
    };
}

/**
 * Convert string like 'abc,xyx' => ['abc','xyz']
 * @param {*} value 
 */
function convertStringToArray(value) {
    if (!value) return [];
    if (typeof value === 'string') return value.split(',').filter(x => x).map(str => str.trim());
    return value;
}

export {
    REMOTE_API,
    objectToFormData,
    getDataUpstream,
    convertStringToArray
};