'use strict';

import React, { useMemo, useEffect, useState, useRef } from 'react';
import { Title } from 'react-admin';
import { useUpdate, useDelete, useNotify, Confirm } from 'react-admin';
import { dataProvider } from '../../DataProvider';
import NumericEditor from '../../components/controls/numeric-editor';
import { ActionColumn } from '../../components/ag-grid/aggrid-components';
import AgGrid from '../../components/ag-grid/ag-grid';
import { autoLoad, loadAccountMaps, loadExchanges } from '../../utils/common';
import { setRaStore, getRaStore } from '../../utils/common';
import RenderControls from '../../components/ra-list/RenderControls';
import { formatLocalTime } from '../../components/ex_dayjs';

const ListVariable = () => {
  const refInput = useRef(null);
  const refbtnFilter = useRef(null);
  const notify = useNotify();
  const [handleUpdate] = useUpdate();
  const [deleteOne] = useDelete();
  const [market, setMarket] = useState([]);
  const [accountList, setAccountList] = useState([])
  const [open, setOpen] = useState(false);
  const [recordData, setRecordData] = useState();
  const [defaultValues, setDefaultValues] = useState({
    sortField: { field: 'id', order: 'asc' }, // default sort field       
  })

  const handleDelete = (e) => {    
    deleteOne('ListingVariable', { id: recordData.id }, {
      onSuccess: (data) => {
        notify('Delete successfully', { type: 'success', autoHideDuration: 2000 });
        setOpen(false);
        autoLoad(refbtnFilter);
      },
      onError: (error) => {
        notify(`Delete error: ${error.message}`, { type: 'error', autoHideDuration: 5000 });
      },
    });
  }

  let doLoadData = true;
  useEffect(() => {
    if (doLoadData) {
      loadExchanges(dataProvider, (response) => {
        setMarket(response);
      })

      let _accountList = getRaStore('accountListVariable');
      if (_accountList == null) {
        loadAccountMaps(dataProvider, (response) => {
          let convertedData = response.map((item) => ({ id: item.listing_account + '.', name: item.listing_account }));
          setAccountList(convertedData);
          setRaStore('accountListVariable', convertedData);
          setRaStore('accountOrigin', response);
        })
      } else {
        setAccountList(_accountList);
      }
      doLoadData = false;
    }

  }, []);

  const handleAccount = (marketId) => {
    let _accountOrigin = getRaStore('accountOrigin');
    if(marketId.value != ''){
      _accountOrigin = _accountOrigin.filter(acc => acc.market_id == marketId.value);
    }
    setAccountList(_accountOrigin.map(acc => ({id: acc.listing_account + '.', name: acc.listing_account})));
  }

  const defaultColDef = useMemo(() => {
    return {
      // editable: true,
      sortable: true,
      resizable: true,
      flex: 1,
      minWidth: 100,
      filter: false,
      suppressMovable: true//block move columns
    };
  }, []);

  const onCellClicked = ((params) => {

    if (params.column.colId === "action" && params.event.target.dataset.action) {
      let action = params.event.target.dataset.action;

      if (action === "edit") {
        console.log('Start edit cell');
        params.api.startEditingCell({
          rowIndex: params.node.rowIndex,
          // gets the first columnKey, due to full row edit => open 1 cell for edit will open all editable cells in row
          colKey: params.columnApi.getDisplayedCenterColumns()[0].colId
        });
      }

      if (action === "delete") {
        setOpen(true);
        setRecordData(params.data);
      }

      if (action === "update") {
        params.api.stopEditing(false);
        handleUpdate('ListingVariable', { id: params.data.id, data: params.data }, {
          onSuccess: (data) => {
            notify('Update successfully', { type: 'success', autoHideDuration: 2000 });
          },
          onError: (error) => {
            notify(`Update error: ${error.message}`, { type: 'error', autoHideDuration: 5000 });
          },
        });
        params.api.stopEditing();

      }

      if (action === "cancel") {
        params.api.stopEditing(true);
      }
    }
  });
  const onCellEditingStopped = (params) => {
    params.api.refreshCells({
      columns: ["action"],
      rowNodes: [params.node],
      force: true
    });
    console.log('stop edit cell');
  }

  const onCellEditingStarted = (params) => {
    params.api.refreshCells({
      columns: ["action"],
      rowNodes: [params.node],
      force: true
    });
  };

  var colDefs = [
    {
      field: 'sm_market', headerName: 'Secondary Market',
      valueFormatter: (params) => {
        return market.find(i => (i.id !== '') && i.id == params.value)?.name || 'UNKNOWN';
      }, tooltipValueGetter: (params) => {
        return market.find(i => (i.id !== '') && i.id == params.value)?.name || 'UNKNOWN';
      }
    },
    { field: 'var_name', tooltipField: 'var_name', headerName: 'Name' },
    {
      field: 'var_decimal_value', tooltipField: 'var_decimal_value', headerName: 'Value',
      editable: true,
      singleClickEdit: true,
      valueParser: params => { return Number(params.newValue); },
      cellEditor: NumericEditor
    },
    {
      headerName: "Action", minWidth: 150,
      cellRenderer: ActionColumn,
      colId: "action"
    }
  ]

  const controls = [
    { component: 'select', name: 'sm_market_eq', label: 'Secondary Market', choices: market.map(x => ({ id: Number(x.id), name: x.name })), onChange: handleAccount, xs: 1.5 },
    { component: 'select', name: 'var_name_startswith', label: 'Account', choices: accountList, xs: 1.5 },
    { component: 'text', name: 'var_name_contains', label: 'Name', xs: 2 },
    { component: 'number', name: 'var_decimal_value_eq', label: 'Value', decimal: 2, xs: 1.5 },
  ]

  const handleSearch = (filterList) => {
    refInput.current.customFilterSearch(filterList);
  }
  return (
    <div >
      <Title title={'Listing Variable'}></Title>
      <RenderControls
        controls={controls}
        onSearch={handleSearch}
        refFilter={refbtnFilter}
        agSource='ListingVariable'
        agSort={defaultValues.sortField}
        exportFile={{
          filename: `ListVariable-${formatLocalTime(new Date(), 'YY-MM-DD')}`,
          columns: ['market_name', 'var_name', 'var_decimal_value'],
          limit: 1000000
        }}
      />
      <AgGrid //https://www.ag-grid.com/javascript-data-grid/grid-options
        ref={refInput}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        setDefaultValues={setDefaultValues}
        rowHeight={45}
        rowSelection='single'
        editType={'fullRow'}
        onCellClicked={onCellClicked}
        onCellEditingStopped={onCellEditingStopped}
        onCellEditingStarted={onCellEditingStarted}
        list={'ListingVariable'}
      ></AgGrid>
      <Confirm isOpen={open} title={`Delete Listing Variable?`} content={`Name: ${recordData?.var_name}`} onConfirm={handleDelete} onClose={() => setOpen(false)} />
    </div>
  );
};

export default ListVariable;