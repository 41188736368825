import React, { useState } from "react";
import { AddCircle, Delete, DeleteSweep, Save } from "@mui/icons-material";
import { Button, Checkbox, FormControlLabel, Grid, IconButton, LinearProgress, Stack } from "@mui/material";
import MDialog from "../../../components/controls/MDialog";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { dayjs } from '../../../components/ex_dayjs'
import { TimePicker } from "@mui/x-date-pickers";
import { dataProvider } from "../../../DataProvider";
import { useNotify } from "react-admin";
import { getNotification, getZero, validDateTime } from "../common";

const ShiftTimeDialog = (props) => {
    const notify = useNotify();
    const { shiftWorkTime, setShiftWorkTime } = props;
    const [timeList, setTimeList] = useState([{ from: null, to: null }]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = React.useState(null);
    const [ampm, setAMPM] = useState(true);
    var dateString = shiftWorkTime.ref.column.colId.split('-');

    React.useEffect(() => {
        if (shiftWorkTime.ref) {
            var selectDate = 'Day_' + dateString[2];
            let record = shiftWorkTime.ref?.data['ShiftWorkDateDetails']?.find(x => x.DayInMonth == selectDate);
            if (record) {
                let arrTime = JSON.parse(record.TimeRangeString).map(x => ({
                    from: dayjs(`${shiftWorkTime.ref.column.colId} ${x.From}`),
                    to: dayjs(`${shiftWorkTime.ref.column.colId} ${x.To}`)
                }));
                setTimeList(arrTime);
            } else {
                setTimeList([{ from: null, to: null }]);
            }
        }
    }, [shiftWorkTime.renderCount]);

    React.useEffect(() => {
        if (timeList.length == 0) {
            setTimeList(prev => ([...prev, { from: null, to: null }]));
        }
    }, [timeList])

    const handleClose = () => {
        setShiftWorkTime(prev => ({ ...prev, open: false }));
    }
    const handleAdd = (param) => {
        setTimeList(prev => ([...prev, { from: null, to: null }]));
    }
    const handleDelete = (param) => {
        let index = param.currentTarget?.id?.split('-')?.[2] / 1;
        setTimeList(prev => prev.filter(x => timeList.indexOf(x) != index));
    }

    const saveShiftWork = (param) => {
        var checkValidTime = validDateTime(timeList, setTimeList);
        if (!checkValidTime || error) return;

        var workingTimes = JSON.stringify(timeList.map(time => { return { From: time.from.format('HH:mm'), To: time.to.format('HH:mm') } }));

        let pushData = {
            Email: shiftWorkTime.ref.data.UserEmail,
            Date: dateString[0] + '-' + dateString[1] + '-' + getZero(dateString[2]),
            MonthYear: shiftWorkTime.ref.data.MonthYear,
            WorkingTimes: workingTimes,
            Status: 1,
        }

        dataProvider.postData('WeeklyWorkTime', 'ApplyShiftWork', pushData)
            .then((response) => {
                if (response.data == 'created' || response.data == 'updated') {
                    let applyDay = `Day_${shiftWorkTime.ref.column.colId.split('-')[2]}`;
                    let existData = shiftWorkTime.ref.data['ShiftWorkDateDetails'].find(x => x.DayInMonth == applyDay);
                    if (existData) {
                        existData.TimeRangeString = workingTimes;
                    } else {
                        shiftWorkTime.ref.data['ShiftWorkDateDetails'].push({ DayInMonth: applyDay, TimeRangeString: workingTimes });
                    }

                    notify(`${response.data == 'created' ? 'CREATED' : 'UPDATED'} !!!`, { type: 'success', autoHideDuration: 2000 });
                }
                else {
                    let mess = getNotification(response);
                    notify(mess, { type: 'error', autoHideDuration: 2000 });
                }
            }).catch(console.error).finally(_ => {
                setShiftWorkTime(prev => ({ ...prev, open: false }));
            });
    }

    const deleteShiftWork = (param) => {
        var day = shiftWorkTime.ref.column.colId;
        var email = shiftWorkTime.ref.data['UserEmail'];
        setIsLoading(true);
        dataProvider.fetchData('WeeklyWorkTime', `DeleteShiftWork/?date=${day}&email=${email}`)
            .then(response => {
                if (response.data == 'deleted' || response?.status == 404) {
                    let selectDate = `Day_${day.split('-')[2]}`;
                    shiftWorkTime.ref.data['ShiftWorkDateDetails'] = shiftWorkTime.ref.data['ShiftWorkDateDetails'].filter(x => x.DayInMonth != selectDate);
                    setTimeList([{ from: null, to: null }]);
                    notify(`DELETED !!!`, { type: 'success', autoHideDuration: 2000 });
                } else {
                    let mess = getNotification(response);
                    notify(mess, { type: 'error', autoHideDuration: 2000 });
                }
            }).finally(_ => { setIsLoading(false); setShiftWorkTime(prev => ({ ...prev, open: false })); })
    }

    const convertTimeZone = (datetime, isUSTime) => {
        if (!datetime) return;
        var userOffset = shiftWorkTime.ref.data.TeamId == 1 ? 420 : 480;
        var timeZoneName = shiftWorkTime.ref.data.TeamId == 1 ? 'Asia/Ho_Chi_Minh' : 'Asia/Manila'
        if (isUSTime) {
            return datetime.utcOffset(userOffset, true).tz('America/Los_Angeles');
        } else {
            return datetime.tz('America/Los_Angeles', true).tz(timeZoneName);
        }
    }

    const handleAMPM = (param) => {
        setAMPM(param.target.checked);
    }

    return (<>
        <MDialog
            maxWidth={'sm'}
            open={shiftWorkTime.open}
            handleClose={handleClose}
            title={`Work Time on (${dateString[0] + '-' + dateString[1] + '-' + getZero(dateString[2])}) - ${shiftWorkTime.ref?.data['UserEmail']}`}
            name={'work-time'}
            action={<Stack spacing={1} direction='row'>
                <Button variant='contained' color='info' startIcon={<Save />} onClick={saveShiftWork}>Save</Button>
                {shiftWorkTime.ref?.data['ShiftWorkDateDetails']?.find(x => x.DayInMonth == ('Day_' + shiftWorkTime.ref?.column.colId.split('-')[2])) &&
                    <Button variant='contained' color='error' startIcon={<DeleteSweep />} onClick={deleteShiftWork}>Delete</Button>}
            </Stack>}
            children={
                <>
                <FormControlLabel control={<Checkbox checked={ampm} onChange={handleAMPM} />} label='AM/PM' />
                    {timeList?.map((time, index) =>
                        <div id={`timepicker-${index}`} key={`timepicker-${index}`}>
                            <Grid container style={{ height: '100%' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Grid item xs={5}>
                                        <TimePicker
                                            ampm={ampm}
                                            label="From"
                                            value={time.from}
                                            defaultValue={time.from}
                                            onChange={(newValue) => {
                                                setTimeList(prev => {
                                                    return prev.map(obj => {
                                                        if (prev.indexOf(obj) == timeList.indexOf(time)) {
                                                            return { ...obj, from: dayjs(`${shiftWorkTime.ref.column.colId} ${newValue.format('HH:mm')}`) };
                                                        }
                                                        return obj;
                                                    })
                                                });
                                            }}
                                            onError={(newError) => setError(newError)}
                                            slotProps={{
                                                textField: {
                                                    helperText: error == 'minTime' ? 'From > To, please select gain' : "",
                                                },
                                            }}
                                            maxTime={time.to}
                                        />
                                        <span style={{ fontSize: '12px' }}>{time.from ? `US: ${convertTimeZone(time.from, true)?.format(`YYYY-MM-DD ${ampm ? `h:mm A` : 'HH:mm'}`)}` : ''}</span>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TimePicker
                                            ampm={ampm}
                                            label="To"
                                            value={time.to}
                                            defaultValue={time.to}
                                            onChange={(newValue) => {
                                                setTimeList(prev => {
                                                    return prev.map(obj => {
                                                        if (prev.indexOf(obj) == timeList.indexOf(time)) {
                                                            return { ...obj, to: dayjs(`${shiftWorkTime.ref.column.colId} ${newValue.format('HH:mm')}`) };
                                                        }
                                                        return obj;
                                                    })
                                                });
                                            }}
                                            onError={(newError) => setError(newError)}
                                            slotProps={{
                                                textField: {
                                                    helperText: error == 'maxTime' ? 'To > From, please select gain' : "",
                                                },
                                            }}
                                            minTime={time.from}
                                        />
                                        <span style={{ fontSize: '12px' }}>{time.to ? `US: ${convertTimeZone(time.to, true)?.format(`YYYY-MM-DD ${ampm ? `h:mm A` : 'HH:mm'}`)}` : ''}</span>
                                    </Grid>
                                </LocalizationProvider>
                                <Grid item xs={2}>
                                    <Stack direction="row" spacing={1} sx={{ marginTop: '15px' }}>
                                        <IconButton color="primary" aria-label="add" onClick={handleAdd}>
                                            <AddCircle />
                                        </IconButton>
                                        <IconButton id={`time-delete-${timeList.indexOf(time)}`} aria-label="delete" color='secondary' onClick={handleDelete}>
                                            <Delete />
                                        </IconButton>
                                    </Stack>
                                </Grid>

                            </Grid>
                        </div>
                    )}
                    {isLoading && <LinearProgress color='secondary' />}
                </ >
            }
        />
    </>);
}
export default ShiftTimeDialog