import { Button, Checkbox, FormControlLabel, Grid, Stack, TextField, Divider, IconButton } from "@mui/material";
import React, { useState, useCallback, useRef } from "react";
import { MultipleStop, RemoveCircleOutline, Save, Upload, UploadFile } from '@mui/icons-material';
import MDialog from "../../../../components/controls/MDialog";
import { useDropzone } from 'react-dropzone';
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import jsQR from "jsqr";
import { useRecordContext } from "react-admin";
import { formatDateTime } from "../../../../components/ex_dayjs";
import { STATUS_ENUM } from "../../../../utils/enum";

const OrderShipping = (props) => {
    const { notify, dataProvider, vendor, validate, validComplete, openShipping, closePopup } = props;
    const order = useRecordContext();
    const controller = vendor.controller;
    if (!order) return;

    const [shippingPopup, setShippingPopup] = useState({ isUploadUrl: false, open: false, showShippingBtn: false });
    const [previewPDF, setPreviewPDF] = useState({ width: '100%', height: '500px', blobURL: null, isMobileQR: false, filesNum: 0, selectedFiles: null });
    const [transferLink, setTransferLink] = useState([{ url: '' }, { url: '' }]);
    const fileInputRef = useRef(null);

    React.useEffect(() => {
        let event_market = order.event_market || '';
        let deliveryMethod = order.delivery_method_value;
        var isDirectTransfer = deliveryMethod == 5;
        var isWillCall = deliveryMethod == 6;
        var isHardTicket = deliveryMethod == 1;

        //Axs & Tdc: upload proof for DT
        var allowUploadUrl = isDirectTransfer && !["axs", "tdc"].some(s => event_market.toLowerCase() === s);
        if (allowUploadUrl) {
            setShippingPopup(prev => ({ ...prev, isUploadUrl: true, showShippingBtn: true })); // Exact: Delivery = Direct Transfer ? Upload ULRs            
        } else if (!isWillCall && !isHardTicket) {
            setShippingPopup(prev => ({ ...prev, isUploadUrl: false, showShippingBtn: true }));
        }
    }, [order.delivery_method_value]);

    React.useEffect(() => {
        setShippingPopup(prev => ({ ...prev, open: openShipping }));
    }, [openShipping])

    const closeShippingPopup = () => {
        setPreviewPDF(prev => ({ ...prev, blobURL: null, isMobileQR: false, filesNum: 0, selectedFiles: null }));
        setTransferLink([{ url: '' }, { url: '' }]);
        setShippingPopup(prev => ({ ...prev, open: false }));
        closePopup();
    };
    //#region upload file
    const onDrop = useCallback(async (acceptedFiles) => {                
        await workWithFiles(acceptedFiles);
        setPreviewPDF(prev => ({ ...prev, selectedFiles: acceptedFiles, filesNum: acceptedFiles.length }));
    }, [previewPDF?.excludes, previewPDF?.isMobileQR]);   

    async function workWithFiles(inputFiles) {
        let combinedFile = await combineFilesToPDF(inputFiles, getPageExcludes(previewPDF?.excludes));
        fileInputRef.current = combinedFile;
        let blobUrl = URL.createObjectURL(combinedFile) + '#zoom=FitWidth';
        setPreviewPDF(prev => ({ ...prev, blobURL: blobUrl }));
    }
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    // Determine the image embedding method based on the file type
    function getImageEmbedMethod(type) {
        return new Promise((resolve) => {
            const fileType = type.toLowerCase();
            if (fileType === 'image/png') {
                resolve((doc, bytes) => doc.embedPng(bytes));
            } else if (fileType === 'image/jpeg' || fileType === 'image/jpg') {
                resolve((doc, bytes) => doc.embedJpg(bytes));
            } else {
                // Handle other image formats accordingly
                // For example, you can implement additional embedding methods for GIF, BMP, etc.
                // resolve((doc, bytes) => doc.embedGif(bytes));
                // resolve((doc, bytes) => doc.embedBmp(bytes));
                // ...

                // For unsupported formats, you can skip processing or display an error message
                console.error('Unsupported image format:', fileType);
                resolve(null);
            }
        });
    }

    function getPageExcludes(range) {
        const _excludes = [];
        if (!range) return;

        const parts = range.split(',');
        for (let part of parts) {
            part = part.trim();
            if (part.includes('-')) {
                const [start, end] = part.split('-').map(Number);
                for (let i = start; i <= end; i++) _excludes.push(i);
            } else {
                _excludes.push(Number(part));
            }
        }

        return _excludes;
    }

    const combineFilesToPDF = async (filesInput, excludePages = []) => {
        const readerPromises = [], arrQR = [];

        // const files = Array.from(fileInput.files);
        filesInput.forEach((file) => {
            const reader = new FileReader();
            const promise = new Promise((resolve) => {
                reader.onload = function () {
                    const pdfBytes = new Uint8Array(reader.result);
                    resolve({
                        name: file.name,
                        type: file.type,
                        data: pdfBytes,
                        file: file
                    });
                };
            });

            reader.readAsArrayBuffer(file);
            readerPromises.push(promise);
        });
        //===========================================================
        try {
            // Wait for all reader promises to resolve
            const results = await Promise.all(readerPromises);

            // Create a merged pdf
            const mergedPdf = await PDFDocument.create();

            // Combine files result into the merged PDF
            for (const result of results) {
                await combineFile(mergedPdf, result);
            }

            // Save the merged PDF
            const original = await mergedPdf.save();

            // Create a new modified PDF document
            const modifiedPDF = await PDFDocument.create();
            const loadedPDF = await PDFDocument.load(original);
            //get pages in original pdf
            const copiedPages = await modifiedPDF.copyPages(loadedPDF, loadedPDF.getPageIndices());

            // Add copied-pages to the modified-PDF, excluding specified pages (user enter them)
            for (let i = 0; i < copiedPages.length; i++) {
                if (excludePages.includes(i + 1)) continue;
                modifiedPDF.addPage(copiedPages[i]);
            }

            // Embed font in the modified PDF
            const font = await modifiedPDF.embedFont(StandardFonts.Helvetica);

            // Get the total number of pages in the modified PDF
            const pageCount = modifiedPDF.getPageCount();

            // Draw page numbers on each page
            for (let pageNum = 0; pageNum < pageCount; pageNum++) {
                const page = modifiedPDF.getPage(pageNum);
                const pageNumberText = `Page ${pageNum + 1}`;
                const { width, height } = page.getSize();
                const textSize = 10;
                const textWidth = font.widthOfTextAtSize(pageNumberText, textSize);
                const textHeight = font.heightAtSize(textSize);

                page.drawText(pageNumberText, {
                    x: (width - textWidth) / 2,
                    y: textHeight + 10, // Adjust the position as needed
                    size: textSize,
                    font: font,
                    color: rgb(0, 0, 0), // Black color
                });
            }

            // Check if the number of pages matches the number of tickets on the order
            let unmatching = ![4, 5].some(x => x === order.delivery_method_value) && order.quantity !== pageCount;
            if (unmatching) {
                notify('The number of pages must match the number of tickets on the order.', { type: 'error' });
            }
            setPreviewPDF(prev => ({ ...prev, unmatching: unmatching }));

            // Save the modified PDF and return as a blob
            const modifiedPdfBytes = await modifiedPDF.save();
            let blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

            return blob;
        } catch (error) {
            console.error('Error merging and processing PDFs:', error);
            throw error; // Re-throw the error to be handled by the caller
        }
        //===========================================================   
        function combineFile(mergedPdf, fileData) {
            const { name, type, data, file } = fileData;
            if (type.includes('image')) {
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');
                // Create a new Image object
                var img = new Image();
                img.onload = function () {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                    // Decode QR code from the canvas
                    var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                    var code = jsQR(imageData.data, imageData.width, imageData.height);
                    if (code) {
                        console.log("QR Code detected:", code.data);
                        arrQR.push(code.data);
                        setPreviewPDF(prev => ({ ...prev, isMobileQR: true }))
                        // You can do further processing here
                    } else {
                        console.log("No QR Code detected");
                    }
                };
                img.src = URL.createObjectURL(file);

                return addImageToPDF(data);
            } else if (type === 'application/pdf') {
                return addPDFToPDF(data);
            } else {
                return Promise.resolve();
            }

            function addPDFToPDF(blob) {
                return new Promise((resolve, reject) => {
                    PDFDocument.load(blob, { ignoreEncryption: true })
                        .then((pdfDoc) => {
                            if (pdfDoc.isEncrypted) {
                                return removeRestriction(file)
                                    .then(newBlob => {
                                        return PDFDocument.load(newBlob).then(_ => mergedPdf.copyPages(_, _.getPageIndices()));
                                    });
                            }
                            return mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
                        })
                        .then((copiedPages) => {
                            copiedPages.forEach((page) => {
                                mergedPdf.addPage(page);
                            });
                            resolve();
                        })
                        .catch(reject);
                });

                function removeRestriction(blob) {
                    const formData = new FormData();
                    formData.append("file", blob, 'tickets.pdf');
                    return $.ajax({
                        type: 'POST',
                        url: 'RemoveRestriction',
                        data: formData,
                        contentType: false,
                        processData: false,
                    }).then(base64 => base64ToUint8Array(base64));
                }
            }

            function addImageToPDF() {
                return new Promise((resolve, reject) => {
                    getImageEmbedMethod(type)
                        .then((embedMethod) => {
                            if (embedMethod) {
                                embedMethod(mergedPdf, data).then((image) => {
                                    const page = mergedPdf.addPage();

                                    const pageWidth = page.getWidth();
                                    const pageHeight = page.getHeight();
                                    const imageWidth = image.width;
                                    const imageHeight = image.height;

                                    const scaleWidth = pageWidth / imageWidth;
                                    const scaleHeight = pageHeight / imageHeight;
                                    const scale = Math.min(scaleWidth, scaleHeight);

                                    const scaledWidth = imageWidth * scale;
                                    const scaledHeight = imageHeight * scale;

                                    const x = (pageWidth - scaledWidth) / 2; // Center horizontally
                                    const y = (pageHeight - scaledHeight) / 2; // Center vertically

                                    page.drawImage(image, {
                                        x: x,
                                        y: y,
                                        width: scaledWidth,
                                        height: scaledHeight,
                                    });
                                    // Add a label with the image name at the bottom of the page
                                    page.drawText(name, { x: 10, y: 10, size: 10 });
                                    resolve();
                                }).catch(reject);
                            } else {
                                console.error('Unsupported image format:', type);
                            }
                        }).catch(reject);
                });
            }
        }
    }
    //#endregion
    const showMobileQR = order.delivery_method_value == 3;
    const handleExcludes = (props) => {
        setPreviewPDF(prev => ({ ...prev, excludes: props.target.value }));
    }
    const handleKeyUpExclude = async (props) => {
        if (props.key == 'Enter') {
            await workWithFiles(previewPDF.selectedFiles);
        }
    }
    const deleteTransferLink = (props) => {
        let index = props.currentTarget.id.split('-')[1] / 1;
        setTransferLink(prev => prev.filter(x => prev.indexOf(x) != index));//id with index always reset after render, so this condition is true
    }
    const uploadShipping = () => {
        const file = fileInputRef.current;
        if (!file) {
            notify('Please select a file first');
            return;
        }
        let orderNumber = order.order_number;
        const formData = new FormData();
        formData.append('file', file);
        if (shippingPopup.isUploadUrl) {
            dataProvider.postData(controller, `CompleteUrl?orderNumber=${orderNumber}`, transferLink.map(x => x.url))
                .then(response => {
                    if (response?.data) {
                        notify(`${response.data.message}`, { type: response.data.success ? 'success' : 'error', autoHideDuration: 2000 });
                    }
                })
                .catch()
                .finally(_ => closePopup())
        } else {
            dataProvider.postData(controller, `CompleteETickets?orderNumber=${orderNumber}&mobileQR=${previewPDF?.isMobileQR}`, formData)
                .then(response => {
                    if (response?.data) {
                        notify(`${response.data.message}`, { type: response.data.success ? 'success' : 'error', autoHideDuration: 2000 });
                    }
                })
                .catch()
                .finally(_ => closePopup())
        }
    }
    const clickShippingButton = () => {
        let isShowShipping = !validate('shipping');
        if (isShowShipping) {          
            let internal_status = order.internal_status;
            if(internal_status == STATUS_ENUM.Completed){            
                if (!validComplete()) {                    
                    return;
                }
            }            
            setShippingPopup(prev => ({ ...prev, open: true }));
        }
    }
    return (
        <>
            {shippingPopup.showShippingBtn && <Button variant="contained" size="small" startIcon={<MultipleStop />}
                onClick={clickShippingButton} style={{ backgroundColor: '#5cc0de' }}>Shipping</Button>}
            <MDialog
                title={`#${order.order_number} - ${shippingPopup.isUploadUrl ? 'Upload URL' : 'Upload Files'}`}
                maxWidth={'md'}
                open={shippingPopup.open}
                handleClose={closeShippingPopup}
                name={'order-shipping-dialog'}
                action={
                    <Stack direction="row">
                        <Button onClick={uploadShipping} variant="contained" color="primary" disabled={previewPDF?.unmatching || false} startIcon={<Upload />}>Upload</Button>
                    </Stack>
                }
                className={'order-dialog'}
                children={
                    <Grid container spacing={1}>
                        <Grid item xs={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={3} sx={{ textAlign: "right" }}>
                                    <b>Event</b>
                                </Grid>
                                <Grid item xs={9}>
                                    <span>{order?.event}</span>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3} sx={{ textAlign: "right" }}>
                                    <b>Venue</b>
                                </Grid>
                                <Grid item xs={8} >
                                    <span>{order?.venue}</span>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3} sx={{ textAlign: "right" }}>
                                    <b>Date</b>
                                </Grid>
                                <Grid item xs={9}>
                                    <span>{formatDateTime(order?.date, 'MMM DD YYYY hh:mm A')}</span>
                                </Grid>
                            </Grid>
                            {order?.customer?.name.trim() && <Grid container spacing={2}>
                                <Grid item xs={3} sx={{ textAlign: "right" }}>
                                    <b>Customer Name</b>
                                </Grid>
                                <Grid item xs={9}>
                                    <span>{`${order?.customer?.name}`}</span>
                                </Grid>
                            </Grid>}
                            {order?.customer?.email.trim() && <Grid container spacing={2}>
                                <Grid item xs={3} sx={{ textAlign: "right" }}>
                                    <b>Customer Email</b>
                                </Grid>
                                <Grid item xs={8} >
                                    <span>{order?.customer?.email}</span>
                                </Grid>
                            </Grid>}
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={3} sx={{ textAlign: "right" }}>
                                    <b>Section</b>
                                </Grid>
                                <Grid item xs={9}>
                                    <span>{order?.section}</span>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3} sx={{ textAlign: "right" }}>
                                    <b>Row</b>
                                </Grid>
                                <Grid item xs={8} >
                                    <span>{order?.row}</span>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3} sx={{ textAlign: "right" }}>
                                    <b>Seat</b>
                                </Grid>
                                <Grid item xs={9}>
                                    <span>{order?.lo_seat ? `${order.lo_seat} - ${order.hi_seat}` : order?.seat}</span>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3} sx={{ textAlign: "right" }}>
                                    <b>Qty</b>
                                </Grid>
                                <Grid item xs={9}>
                                    <span>{order?.quantity}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                            <Grid container spacing={1} sx={{ marginTop: '10px' }}>
                                <Grid item xs={12}>
                                    {shippingPopup.isUploadUrl && <b>Transfer links</b> || <b>Upload tickets, proof of transfer screenshots (acceptable: pdf, image)</b>}
                                </Grid>

                                {!shippingPopup.isUploadUrl &&
                                    <><Grid item xs={6} sx={{ marginTop: '7px' }}>
                                        {/* upload pdf or img */}
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            {
                                                <Stack direction='row' style={{ border: 'solid 0.5px gray', borderRadius: '4px', justifyContent: 'center' }}>
                                                    <UploadFile sx={{ marginTop: '6px' }} />
                                                    <p style={{ margin: '7px' }}>Click to select files {previewPDF.filesNum ? `(${previewPDF.filesNum})` : ''}</p>

                                                </Stack>

                                            }
                                        </div>
                                    </Grid>
                                        <Grid item xs={4}>
                                            <TextField placeholder="excludes: e.g. 1-5, 8, 11-13" variant="outlined" size="small" margin="none" sx={{ marginTop: '7px' }}
                                                value={previewPDF.excludes || ''}
                                                onChange={handleExcludes}
                                                onKeyUp={handleKeyUpExclude}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            {showMobileQR && <FormControlLabel label="Mobile QRs" control={<Checkbox checked={previewPDF.isMobileQR} sx={{ fontSize: '13px' }} />} />}
                                        </Grid></>
                                    || <> {transferLink.map((tl, index) => {
                                        return <><Grid item xs={10}>
                                            <TextField value={tl.url} variant="outlined" margin="none" onChange={(props) => setTransferLink(prev => prev.map(obj =>
                                                prev.indexOf(obj) == transferLink.indexOf(tl) ? { ...obj, url: props.target.value } : obj
                                            ))} /></Grid>
                                            <Grid item xs={1} sx={{ alignContent: 'center' }}>
                                                {index == 0 && <Button variant="outlined" size="small" onClick={() => setTransferLink(prev => ([...prev, { url: '' }]))}>Add</Button>}
                                                {index > 1 && <IconButton id={`rmbtn-${index}`} onClick={deleteTransferLink} >
                                                    <RemoveCircleOutline /></IconButton>}
                                            </Grid>
                                        </>
                                    })}
                                    </>
                                }
                            </Grid>
                        </Grid>
                        {previewPDF.blobURL && <Grid item xs={12}>
                            <embed src={previewPDF.blobURL} type={'application/pdf'} width={previewPDF.width} height={previewPDF.height}></embed>
                        </Grid>
                        }
                    </Grid>

                }
            >
            </MDialog>
        </>

    )
}
export default OrderShipping;